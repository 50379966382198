import httpClient from '@/common/http'
export default {
  create(obj) {
    return httpClient.request('post', 'settings/general/id_types', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `settings/general/id_types/${id}`, obj);
  },

  delete(id, replaceID) {
    return httpClient.request('delete', `settings/general/id_types/${id}/replacement/${replaceID}`);
  },

  findOne(id) {
    return httpClient.request('get', `settings/general/id_types/${id}`)
  },

  findAll() {
    return httpClient.request('get', `settings/general/id_types`)
  }

}