<template>
  <div id="loan-settings-layout">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<style>

</style>

<script>
import {mapMutations} from 'vuex'
import settingsService from '@/services/general-settings-service'
import { EventBus } from "@/event-bus";

export default {
  async created() {
    await this.init()
    this.TOGGLE_SIDEBAR_VISIBILITY(true)
  },

  data() {
    return {
      loanSettings: {},
      routes: [        
        { heading: 'Loan Origination Settings' },
        { title: 'General', route: '/app/app-settings/loan-origination-settings/loan-general', icon: 'fa-cog', iconSize: '12px'},

        { divider: true },
        { heading: 'Loan Policy Settings' },
        { title: 'Loan Review Checklist', route: '/app/app-settings/loan-origination-settings/loan-checklist-settings', icon: 'fa-tasks', iconSize: '12px'},
        { title: 'Loan Fees', route: '/app/app-settings/loan-origination-settings/loan-fees', icon: 'fa-dollar-sign', iconSize: '12px'},
        { title: 'Loan Types', route: '/app/app-settings/loan-origination-settings/loan-types', icon: 'fa-list', iconSize: '12px'},
        { title: 'Loan Products', route: '/app/app-settings/loan-origination-settings/loan-products', icon: 'fa-list-alt', iconSize: '12px'},
        // { title: 'Collateral Types', route: '/app/app-settings/loan-origination-settings/collateral-types', icon: 'fa-list-alt', iconSize: '12px'},
       
        { divider: true },
        { heading: 'Security Policies' },
        { title: 'Loan Waiver', route: '/app/app-settings/loan-origination-settings/loan-waiver', icon: 'fa-key', iconSize: '12px'},
        { title: 'Loan Approval', route: '/app/app-settings/loan-origination-settings/loan-approval', icon: 'fa-shield-alt', iconSize: '12px'},
        
        { divider: true },
        { heading: 'Other Settings' },
        { title: 'Personal Account Types', route: '/app/app-settings/loan-origination-settings/loan-accounts', icon: 'fa-vote-yea', iconSize: '12px'},
        { title: 'YAPU Management', route: '/app/app-settings/loan-origination-settings/yapu-management', icon: 'fa-vote-yea', iconSize: '12px'},
        { title: 'DSR Calculation Methods', route: '/app/app-settings/loan-origination-settings/dsr-calculation-methods', icon: 'fa-calculator', iconSize: '12px'},
      ]
    }
  },

  beforeDestroy() {
  },

  methods: {
    ...mapMutations([
      'SET_SIDEBAR_ROUTES',
      'TOGGLE_SIDEBAR_MINI',
      'TOGGLE_SIDEBAR_VISIBILITY',
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    async init() {
      await this.fetchSettings()
      this.routes.forEach((el, i, arr) => {
        if(el.title === "Checklist") {
          el.hide = !this.loanSettings.use_checklist
        }
      })

      EventBus.$on('SETTINGS_CHANGE', (data) => {    
        this.routes.forEach((el, i, arr) => {
          if(el.title === "Checklist") {
            el.hide = !data.use_checklist
          }
        })
        this.SET_SIDEBAR_ROUTES(this.routes)
      })
      this.SET_SIDEBAR_ROUTES(this.routes)
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let result = await settingsService.findLoanSettings()
        if(Object.keys(result).length > 0) {
          for(let key in result) {
            if(typeof result[key] === 'string' && result[key].indexOf("{'") > -1) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'))
            }
          }
          this.loanSettings = result 
          return true
        }
        return false;
      } catch(e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },
  },


}
</script>
