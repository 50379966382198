<template>
  <div>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="confirmationDialog" max-width="290">
      <v-card>
        <v-card-title class="headline">Confrimation</v-card-title>

        <v-card-text>
          {{ confirmationMessage }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary darken-1"
            flat="flat"
            @click="confirmationDialog = false"
          >
            No
          </v-btn>

          <v-btn color="primary darken-1" flat="flat" @click="confirmationFunc">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-tabs
      v-model="tabModel"
      class="mb-3 shadow-md"
      color="white"
      slider-color="primary lighten-2"
    >
      <v-tab href="#tab-general">
        <v-icon class="mr-2" small>fas fa-sliders-h</v-icon> Account
        Settings</v-tab
      >
      <v-tab href="#tab-documents"
        ><v-icon class="mr-2" small>far fa-file</v-icon> Account
        Documents</v-tab
      >
      <v-tab href="#tab-e-services"
        ><v-icon class="mr-2" small>fas fa-globe</v-icon> E-Services
        Settings</v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tabModel">
      <v-tab-item :value="`tab-general`">
        <!-- <v-card class="pa-4 mb-4 elevation-0">
          <h2>Account Status Settings</h2>
          <v-divider class="mb-4"></v-divider>
          <v-layout>
            <v-card class="ma-2" flat>
              <v-card-title class=""><h3>Statuses</h3></v-card-title>
              <v-data-table
                class="mt-1"
                :items="settings.statuses"
                :headers="statusHeaders"
                hide-actions="hide-actions"
              >
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.name | capitalize }}</td>
                  <td title="click to edit">
                    <span v-if="props.item.name === 'Monthly'">{{
                      props.item.period
                    }}</span>
                    <v-edit-dialog
                      v-else-if="props.item.name !== 'Monthly'"
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item)"
                      @open="onDialogOpened(props.item)"
                      @cancel="
                        props.item.period = previousItem.period;
                        disableEventStyle = '';
                      "
                    >
                      {{ props.item.period }}
                      <v-text-field
                        slot="input"
                        v-validate="'required|numeric|min_value:0'"
                        data-vv-scope="freq"
                        data-vv-name="period"
                        data-vv-as="maximum score"
                        :error-messages="errors.collect('freq.period')"
                        v-model="selectedValue.period"
                        label="Edit"
                        single-line
                        counter
                      ></v-text-field>
                    </v-edit-dialog>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-layout>
        </v-card> -->

        <v-card class="pa-4 shadow-sm">
          <h2>Age Policies</h2>
          <p class="mb-5">Controls the age policies per account basis.</p>
          <!-- <v-divider class="mb-4"></v-divider> -->
          <v-layout>
            <v-flex md4 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.youth_max_age"
                v-validate="'required|integer'"
                data-vv-scope="setting"
                placeholder=" "
                ref="youth"
                data-vv-name="youth_max_age"
                data-vv-as="youth max age"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('youth_max_age')"
                :error-messages="errors.collect('setting.youth_max_age')"
                label="Youth Maximum Age"
              />
            </v-flex>
            <v-flex md4 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.senior_min_age"
                v-validate="
                  'required|integer|min_value:' + (settings.youth_max_age + 1)
                "
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="senior_min_age"
                data-vv-as="senior minimum age"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('senior_min_age')"
                :error-messages="errors.collect('setting.senior_min_age')"
                label="Senior Minimum Age"
              />
            </v-flex>
            <v-flex md4 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.e_service_min_age"
                v-validate="'required|integer'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="e_service_min_age"
                data-vv-as="e service minimum age"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('senior_min_age')"
                :error-messages="errors.collect('setting.e_service_min_age')"
                label="E Service Minimum Account Opening Age"
              />
            </v-flex>
          </v-layout>
        </v-card>

        <v-card class="pa-4 mt-4 shadow-sm">
          <h2>Per Account Creation Settings</h2>
          <v-divider class="mb-4"></v-divider>
          <v-layout wrap>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_sub_accounts_per_account"
                v-validate="'required|integer|max_value:50|min_value:1'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_sub_accounts_per_account"
                data-vv-as="number of sub accounts"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_sub_accounts_per_account')"
                :error-messages="
                  errors.collect('setting.number_of_sub_accounts_per_account')
                "
                label="Maximum number of sub accounts"
              />
            </v-flex>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_owners_per_account"
                v-validate="'required|integer|max_value:50|min_value:1'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_owners_per_account"
                data-vv-as="number of account holders"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_owners_per_account')"
                :error-messages="
                  errors.collect('setting.number_of_owners_per_account')
                "
                label="Maximum number of account holders"
              />
            </v-flex>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_authorisee_per_account"
                v-validate="'required|integer|max_value:50|min_value:0'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_authorisee_per_account"
                data-vv-as="maximum number of authorisee"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_authorisee_per_account')"
                :error-messages="
                  errors.collect('setting.number_of_authorisee_per_account')
                "
                label="Maximum number of authorisee"
              />
            </v-flex>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_beneficiary_per_account"
                v-validate="'required|integer|max_value:50|min_value:0'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_beneficiary_per_account"
                data-vv-as="maximum number of beneficiaries"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_beneficiary_per_account')"
                :error-messages="
                  errors.collect('setting.number_of_beneficiary_per_account')
                "
                label="Maximum number of beneficiaries"
              />
            </v-flex>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_references_per_account"
                v-validate="'required|integer|max_value:50|min_value:0'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_references_per_account"
                data-vv-as="maximum number of references"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_references_per_account')"
                :error-messages="
                  errors.collect('setting.number_of_references_per_account')
                "
                label="Number of Required References"
              />
            </v-flex>
            <v-flex md3 class="pl-2 pr-2">
              <v-text-field
                box
                v-model="settings.number_of_nearest_contacts_per_account"
                v-validate="'required|integer|max_value:50|min_value:0'"
                data-vv-scope="setting"
                placeholder=" "
                data-vv-name="number_of_nearest_contacts_per_account"
                data-vv-as="Number of nearest contacts"
                @focus="onInputFocus(settings)"
                @blur="onInputBlur('number_of_nearest_contacts_per_account')"
                :error-messages="
                  errors.collect(
                    'setting.number_of_nearest_contacts_per_account'
                  )
                "
                label="Number of Required Nearest Contacts"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="`tab-documents`">
        <v-toolbar class="shadow-md" color="white">
          <v-text-field
            v-model="documentSearch"
            class="mt-2"
            append-icon="search"
            label="Search"
            box
          />
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-btn
            @click="onAddNewDocumentClicked"
            color="primary"
            dark
            class="mb-2"
            >New document</v-btn
          >
          <v-dialog persistent v-model="documentDialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ selected.ID ? "Edit Document" : "New Document" }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-alert
                  v-if="message.visible"
                  :value="true"
                  :type="message.type"
                  style="margin: -20px 20px -20px 20px; padding: 10px"
                >
                  <b>{{ message.title }}</b> {{ message.content }}
                </v-alert>
                <v-container grid-list-md style="margin: -20px 0 -20px 0">
                  <v-layout wrap row>
                    <v-flex xs12>
                      <v-text-field
                        v-model="selected.code"
                        v-validate="'required|min:3|max:50'"
                        data-vv-scope="document"
                        :error-messages="errors.collect('document.code')"
                        placeholder=" "
                        label="Document Code"
                        :max-width="'420px'"
                        data-vv-validate-on="blur"
                        data-vv-name="code"
                        show-clean
                      />

                      <v-text-field
                        v-model="selected.name"
                        v-validate="'required|min:3|max:50'"
                        data-vv-scope="document"
                        :error-messages="errors.collect('document.name')"
                        placeholder=" "
                        label="Document Name"
                        :max-width="'420px'"
                        data-vv-validate-on="blur"
                        data-vv-name="name"
                        show-clean
                      />

                      <v-text-field
                        type="number"
                        v-model="selected.life_span"
                        v-validate="'required|min:0|max_value:900'"
                        data-vv-scope="document"
                        :error-messages="errors.collect('document.life_span')"
                        placeholder=" "
                        hint="Enter zero if this document doesn't have a lifespan"
                        label="Document Lifespan (Months)"
                        :max-width="'420px'"
                        data-vv-validate-on="blur"
                        data-vv-name="life_span"
                        data-vv-as="life span"
                        class="mb-3"
                      />

                      <label for="" class="grey--text lighten-1 mb-3"
                        >Description</label
                      >
                      <wysiwyg
                        disabled
                        data-vv-scope="document"
                        v-validate="'min:0|max:4000'"
                        data-vv-name="description"
                        v-model="selected.description"
                      />
                      <small
                        v-if="errors.first('document.description')"
                        class="red--text"
                      >
                        {{ errors.first("document.description") }}
                      </small>

                      <v-checkbox
                        v-model="selected.required"
                        label="Mandatory"
                        class="mt-2"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
                <v-spacer></v-spacer>
                <v-btn flat @click="documentDialog = false">Cancel</v-btn>
                <v-btn
                  color="primary"
                  :disabled="errors.collect('document').length > 0"
                  @click="saveDocument"
                  >Save</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-data-table
          :headers="documentsHeaders"
          class="shadow-md"
          :search="documentSearch"
          :items="this.settings.account_documents"
          :loading="documentsLoading"
          :rows-per-page-items="[
            20,
            40,
            80,
            { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
          ]"
        >
          <v-progress-linear
            slot="progress"
            color="blue"
            indeterminate
          ></v-progress-linear>
          <template slot="items" slot-scope="props">
            <td class="justify-center layout">
              <v-switch
                v-model="props.item.is_active"
                label=""
                class="mt-2"
                @change="toggleDocumentActive(props.item)"
              ></v-switch>
            </td>
            <td>
              {{ props.item.required ? "Yes" : "No" }}
            </td>
            <td>{{ props.item.code }}</td>
            <td>{{ props.item.name }}</td>
            <td>{{ props.item.life_span + " Months" }}</td>
            <td>
              <v-icon
                small
                class="mr-2"
                title="Edit"
                @click="onDocumentEditClicked(props.item)"
              >
                edit
              </v-icon>
              <v-icon
                small
                class="mr-2"
                title="Delete"
                @click="onDocumentDeleteClicked(props.item)"
              >
                delete
              </v-icon>
            </td>
          </template>
          <template slot="no-data"> </template>
        </v-data-table>
      </v-tab-item>

      <v-tab-item :value="`tab-e-services`">
        <v-layout>
          <v-flex>
            <v-card class="pa-4 shadow-sm ma-2">
              <h2>Account Opening</h2>
              <p class="mb-5">Global settings for E-account opening.</p>
              <v-switch
                @change="saveEServicesSettings('eAccountServices')"
                style="position: absolute; right: 10px; top: 10px"
                v-model="settings.e_services.e_account_is_enabled"
                :label="
                  settings.e_services.e_account_is_enabled
                    ? `Enabled`
                    : 'Disabled'
                "
              ></v-switch>
              <v-layout wrap>
                <v-flex xs12 class="pl-2 pr-2">
                  <v-text-field
                    box
                    v-model="settings.e_services.account_opening_response_time"
                    v-validate="'required|integer'"
                    data-vv-scope="eAccountServices"
                    placeholder=" "
                    data-vv-name="account_response_time"
                    data-vv-as="response time"
                    :error-messages="
                      errors.collect('eAccountServices.account_response_time')
                    "
                    label="Response Time (Hours)"
                  />
                </v-flex>
                <v-flex xs12 class="pl-2 pr-2">
                  <label for="" class="grey--text lighten-1 mb-3"
                    >Response Message</label
                  >
                  <wysiwyg
                    disabled
                    data-vv-scope="eAccountServices"
                    v-validate="'min:0|max:4000'"
                    data-vv-name="account_response_message"
                    data-vv-as="response message"
                    v-model="
                      settings.e_services.account_opening_response_message
                    "
                  />
                  <small
                    v-if="
                      errors.first('eAccountServices.account_response_message')
                    "
                    class="red--text"
                  >
                    {{
                      errors.first("eAccountServices.account_response_message")
                    }}
                  </small>
                </v-flex>
                <v-flex>
                  <v-btn
                    @click="saveEServicesSettings('eAccountServices')"
                    color="primary"
                    dark
                    class="shadow-md"
                    >Save</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex>
            <v-card class="pa-4 shadow-sm ma-2">
              <h2>Loan Application</h2>
              <p class="mb-5">Global settings for E-loan applications.</p>
              <v-switch
                class="float-right"
                @change="saveEServicesSettings('eLoanServices')"
                v-model="settings.e_services.e_loan_is_enabled"
                :label="
                  settings.e_services.e_loan_is_enabled ? `Enabled` : 'Disabled'
                "
              ></v-switch>
              <v-layout wrap>
                <v-flex xs12 class="pl-2 pr-2">
                  <v-text-field
                    box
                    v-model="settings.e_services.loan_response_time"
                    v-validate="'required|integer'"
                    data-vv-scope="eLoanServices"
                    placeholder=" "
                    data-vv-name="loan_response_time"
                    data-vv-as="response time"
                    :error-messages="
                      errors.collect('eLoanServices.loan_response_time')
                    "
                    label="Response Time (Hours)"
                  />

                  <v-flex xs12 class="pl-2 pr-2">
                    <label for="" class="grey--text lighten-1 mb-3"
                      >Response Message</label
                    >
                    <wysiwyg
                      disabled
                      data-vv-scope="eLoanServices"
                      v-validate="'min:0|max:4000'"
                      data-vv-name="loan_response_message"
                      data-vv-as="response message"
                      v-model="
                        settings.e_services.loan_general_response_message
                      "
                    />
                    <small
                      v-if="errors.first('eLoanServices.loan_response_message')"
                      class="red--text"
                    >
                      {{ errors.first("eLoanServices.loan_response_message") }}
                    </small>
                  </v-flex>
                </v-flex>
                <v-flex xs12>
                  <v-list subheader two-line>
                    <v-list-tile>
                      <v-list-tile-action>
                        <v-checkbox
                          v-model="
                            settings.e_services
                              .account_is_required_for_loan_application
                          "
                        ></v-checkbox>
                      </v-list-tile-action>

                      <v-list-tile-content>
                        <v-list-tile-title>Account Required</v-list-tile-title>
                        <v-list-tile-sub-title
                          >Only allow persons with accounts to apply for a
                          loan</v-list-tile-sub-title
                        >
                      </v-list-tile-content>
                    </v-list-tile>

                    <v-list-tile>
                      <v-list-tile-action>
                        <v-checkbox
                          v-model="settings.e_services.keep_terminated_loans"
                        ></v-checkbox>
                      </v-list-tile-action>

                      <v-list-tile-content>
                        <v-list-tile-title
                          >Keep Terminated Loans</v-list-tile-title
                        >
                        <v-list-tile-sub-title
                          >Keep terminated loans for statistical
                          purposes.</v-list-tile-sub-title
                        >
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-flex>
                <v-flex>
                  <v-btn
                    @click="saveEServicesSettings('eLoanServices')"
                    color="primary"
                    dark
                    class="shadow-md"
                    >Save</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex>
            <v-dialog
              persistent
              v-model="serviceOfferedDialog"
              max-width="540px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">
                    {{ selected.ID ? "Edit Service" : "New Service" }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-alert
                    v-if="message.visible"
                    :value="true"
                    :type="message.type"
                    style="margin: -20px 20px -20px 20px; padding: 10px"
                  >
                    <b>{{ message.title }}</b> {{ message.content }}
                  </v-alert>
                  <v-container grid-list-md style="margin: -20px 0 -20px 0">
                    <v-layout wrap row>
                      <v-flex xs12>
                        <v-text-field
                          v-model="selected.name"
                          v-validate="'required|min:3|max:50'"
                          data-vv-scope="servicesOffered"
                          :error-messages="
                            errors.collect('servicesOffered.name')
                          "
                          placeholder=" "
                          label="Service Name"
                          :max-width="'420px'"
                          data-vv-validate-on="blur"
                          data-vv-name="name"
                          show-clean
                        />

                        <label for="" class="grey--text lighten-1 mb-3"
                          >Description</label
                        >
                        <wysiwyg
                          disabled
                          data-vv-scope="servicesOffered"
                          v-validate="'min:0|max:2000'"
                          data-vv-name="description"
                          v-model="selected.description"
                        />
                        <small
                          v-if="errors.first('servicesOffered.description')"
                          class="red--text"
                        >
                          {{ errors.first("servicesOffered.description") }}
                        </small>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions class="ml-4 mr-4">
                  <v-spacer></v-spacer>
                  <v-btn flat @click="serviceOfferedDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="saveServiceOffered"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card class="pa-4 shadow-sm ma-2">
              <h2>Services Offered</h2>
              <v-btn
                @click="onAddNewServiceOfferedClicked"
                class="float-right primary"
                >Add Service</v-btn
              >
              <p class="mb-5">List of services offered by your institution.</p>
              <v-data-table
                :headers="servicesOfferedHeaders"
                class="shadow-sm"
                :search="searchServicesOffered"
                :items="this.settings.e_services.services_offered"
                :loading="loading"
                :rows-per-page-items="[
                  20,
                  40,
                  80,
                  { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
                ]"
              >
                <v-progress-linear
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <td class="justify-center layout">
                    <v-switch
                      v-model="props.item.is_active"
                      label=""
                      class="mt-2"
                      @change="toggleServiceOfferedEdActive(props.item)"
                    ></v-switch>
                  </td>
                  <td>{{ props.item.name }}</td>
                  <!-- <td>{{ props.item.description }}</td> -->
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      title="Edit"
                      @click="onServiceOfferedEditClicked(props.item)"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      title="Delete"
                      @click="onServiceOfferedDeleteClicked(props.item)"
                    >
                      delete
                    </v-icon>
                  </td>
                </template>
                <template slot="no-data"> </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex>
            <v-dialog
              persistent
              v-model="advertisementMediumDialog"
              max-width="300px"
            >
              <v-card>
                <v-card-title>
                  <span class="headline">
                    {{ selected.ID ? "Edit Service" : "New Service" }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <v-alert
                    v-if="message.visible"
                    :value="true"
                    :type="message.type"
                    style="margin: -20px 20px -20px 20px; padding: 10px"
                  >
                    <b>{{ message.title }}</b> {{ message.content }}
                  </v-alert>
                  <v-container grid-list-md style="margin: -20px 0 -20px 0">
                    <v-layout wrap row>
                      <v-flex xs12>
                        <v-text-field
                          v-model="selected.name"
                          v-validate="'required|min:3|max:50'"
                          data-vv-scope="advertisementMedium"
                          :error-messages="
                            errors.collect('advertisementMedium.name')
                          "
                          placeholder=" "
                          label="Advertisement Medium"
                          :max-width="'420px'"
                          data-vv-validate-on="blur"
                          data-vv-name="name"
                          show-clean
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>

                <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
                  <v-spacer></v-spacer>
                  <v-btn flat @click="advertisementMediumDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn
                    color="primary"
                    :loading="loading"
                    @click="saveAdvertisementMedium"
                    >Save</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-card class="pa-4 shadow-sm ma-2">
              <h2>Advertisement Mediums</h2>
              <p class="mb-5">
                List of advertisement avenues used by your institution.
              </p>
              <v-btn
                @click="onAddNewAdvertisementMediumClicked"
                class="primary float-right"
                >Add Medium</v-btn
              >
              <v-data-table
                :headers="advertisementMediumHeaders"
                class="shadow-sm"
                :search="searchAdvertisementMedium"
                :items="this.settings.e_services.advertisement_mediums"
                :loading="loading"
                :rows-per-page-items="[
                  20,
                  40,
                  80,
                  { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
                ]"
              >
                <v-progress-linear
                  slot="progress"
                  color="blue"
                  indeterminate
                ></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <td class="justify-center layout">
                    <v-switch
                      v-model="props.item.is_active"
                      label=""
                      class="mt-2"
                      @change="toggleAdvertisementMediumActive(props.item)"
                    ></v-switch>
                  </td>
                  <td>{{ props.item.name }}</td>
                  <td>
                    <v-icon
                      small
                      class="mr-2"
                      title="Edit"
                      @click="onAdvertisementMediumEditClicked(props.item)"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      class="mr-2"
                      title="Delete"
                      @click="onAdvertisementMediumDeleteClicked(props.item)"
                    >
                      delete
                    </v-icon>
                  </td>
                </template>
                <template slot="no-data"> </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style type="css" scoped>
h1,
h2,
h3 {
  color: #505a6b;
}

.float-right {
  position: absolute;
  right: 10px;
  top: 10px;
}
</style>

<script>
import { mapMutations } from "vuex";
import settingsService from "@/services/general-settings-service";
import _ from "lodash";
import validations from "@/common/validator";
import { v4 as uuidv4 } from "uuid";
export default {
  async created() {
    this.$validator.extend(
      validations.isBigger.name,
      validations.isBigger.validator,
      { hasTarget: true }
    );
    let result = await this.fetchSettings();
    if (!result) {
      await this.save();
    }
  },

  beforeDestroy() {
    this.$toast.destroy();
  },

  data() {
    return {
      tabModel: "tab-general",
      statusHeaders: [
        { text: "Status", align: "left", sortable: false },
        { text: "Minimum Days", align: "left", sortable: false },
      ],
      documentsHeaders: [
        { text: "Active", align: "left", sortable: false, value: "is_active" },
        { text: "Mandatory", align: "left", sortable: true, value: "required" },
        { text: "Code", align: "left", sortable: true, value: "code" },
        { text: "Name", align: "left", sortable: true, value: "name" },
        {
          text: "Life Span",
          align: "left",
          sortable: true,
          value: "life_span",
        },
        { text: "Actions", align: "left", sortable: false },
      ],
      servicesOfferedHeaders: [
        { text: "Active", align: "left", sortable: false, value: "is_active" },
        { text: "Name", align: "left", sortable: true, value: "name" },
        // { text: "Descritpion", align: "left", sortable: false, value: "description" },
        { text: "Actions", align: "left", sortable: false },
      ],
      advertisementMediumHeaders: [
        { text: "Active", align: "left", sortable: false, value: "is_active" },
        { text: "Name", align: "left", sortable: true, value: "name" },
        { text: "Actions", align: "left", sortable: false },
      ],
      confirmationMessage: "",
      confirmationFunc: function() {},
      confirmationDialog: false,
      advertisementMediumDialog: false,
      searchAdvertisementMedium: "",
      serviceOfferedDialog: false,
      searchServicesOffered: "",
      documentSearch: "",
      documentsLoading: false,
      documentDialog: false,
      documents: [],
      selected: {},
      loading: false,
      settings: {
        youth_max_age: 18,
        senior_min_age: 55,
        e_service_min_age: 18,
        statuses: [
          { name: "Inactive", period: 20 },
          { name: "Dormant", period: 20 },
        ],
        number_of_sub_accounts_per_account: 7,
        number_of_owners_per_account: 7,
        number_of_authorisee_per_account: 7,
        number_of_beneficiary_per_account: 7,
        number_of_references_per_account: 1,
        account_documents: [],
        e_services: {
          e_account_is_enabled: true,
          e_loan_is_enabled: true,
          account_is_required_for_loan_application: true,
          loan_response_time: 24,
          loan_general_response_message: "",
          keep_terminated_loans: true,
          account_opening_response_time: 24,
          account_opening_general_response_message: "",
          advertisement_mediums: [],
          services_offered: [],
        },
      },
      eServicesDefault: {
        e_account_is_enabled: true,
        e_loan_is_enabled: true,
        account_is_required_for_loan_application: true,
        loan_response_time: 24,
        loan_general_response_message: "",
        keep_terminated_loans: true,
        account_opening_response_time: 24,
        account_opening_general_response_message: "",
        advertisement_mediums: [],
        services_offered: [],
      },
      disableEventStyle: "",
      previousItem: {},
      selectedValue: 0,
      message: {
        visible: false,
        title: "",
        content: "",
        type: "success",
      },
    };
  },

  computed: {},

  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

    onDialogOpened(item) {
      this.disableEventStyle = "pointer-events: none";
      this.previousItem = _.cloneDeep(item);
      this.selectedValue = _.cloneDeep(item);
    },

    onInputFocus(item) {
      this.previousItem = _.cloneDeep(item);
    },

    async onInputBlur(key) {
      let isValid = await this.$validator.validateAll(`setting`);
      if (isValid) {
        this.save();
      } else {
        this.settings[key] = this.previousItem[key];
      }
    },

    async saveEServicesSettings(key) {
      let isValid = await this.$validator.validateAll(key);
      if (isValid) {
        this.save();
      }
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let result = await settingsService.findAccountSettings();
        if (!_.isEmpty(result)) {
          for (let key in result) {
            if (
              typeof result[key] === "string" &&
              (result[key].indexOf("{'") > -1 || result[key].indexOf("[") > -1)
            ) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }

          this.settings = result;
          if (!this.settings["account_documents"]) {
            this.settings.account_documents = [];
          }
          if (!this.settings["e_services"]) {
            this.settings.e_services = _.cloneDeep(this.eServicesDefault);
          }

          return true;
        }

        return false;
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async save() {
      try {
        this.$toast.destroy();
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let updatedSettings = _.cloneDeep(this.settings);
        this.$toast.success("Saved settings data", "", { position: "topLeft" });
        await settingsService.updateAccountSettings(updatedSettings);
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    //Account Document Methods
    async onAddNewDocumentClicked() {
      this.selected = {};
      this.documentDialog = true;
    },

    async toggleDocumentActive(item) {
      this.selected = { ...item };
      await this.saveDocument();
    },

    onDocumentEditClicked(item) {
      this.selected = { ...item };
      this.documentDialog = true;
    },

    onDocumentDeleteClicked(item) {
      this.selected = { ...item };
      let updatedSettings = _.cloneDeep(this.settings);

      this.confirmationMessage =
        "Are you sure you want to remove this document?";
      this.confirmationFunc = async () => {
        updatedSettings.account_documents.forEach((el, i, arr) => {
          if (el.ID == this.selected.ID) {
            arr.splice(i, 1);
          }
        });
        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.account_documents = _.cloneDeep(
          updatedSettings.account_documents
        );
        this.confirmationDialog = false;
      };
      this.confirmationDialog = true;
    },

    async saveDocument() {
      try {
        //Reset Form
        this.message.visible = false;
        this.documentLoading = true;
        this.$toast.destroy();
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);

        //Check if form is valid
        if (!(await this.$validator.validateAll("document"))) {
          this.message.title = "Form is invalid";
          this.message.content =
            "Please ensure all fields are correctly filled out.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        //Check if document is unique
        const isNotUnique = this.settings.account_documents.some((el) => {
          let newCode = this.selected.code.toLowerCase();
          let newName = this.selected.name.toLowerCase();
          let code = el.code.toLowerCase();
          let name = el.name.toLowerCase();

          return (
            el.ID != this.selected.ID && (code == newCode || name == newName)
          );
        });

        if (isNotUnique) {
          this.message.title = "Document Conflict!";
          this.message.content =
            "Please ensure the document code and name are unique.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        let msg = "Document created successfully";
        let updatedSettings = _.cloneDeep(this.settings);
        let document = _.cloneDeep(this.selected);

        //Set default values
        document.life_span = document.life_span || 0;

        if (document.ID) {
          updatedSettings.account_documents.forEach((el, i, arr) => {
            if (el.ID == document.ID) {
              arr.splice(i, 1, document);
            }
          });
          msg = "Document updated successfully";
        } else {
          document.ID = uuidv4();
          document.is_active = true;
          updatedSettings.account_documents.push(document);
        }

        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.account_documents = _.cloneDeep(
          updatedSettings.account_documents
        );
        this.documentDialog = false;
        this.$toast.success(msg, "", { position: "topLeft" });
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.documentLoading = false;
      }
    },

    //Services Offered Methods
    async onAddNewServiceOfferedClicked() {
      this.selected = {};
      this.serviceOfferedDialog = true;
    },

    async toggleServiceOfferedActive(item) {
      this.selected = { ...item };
      await this.saveServiceOffered();
    },

    onServiceOfferedEditClicked(item) {
      this.selected = { ...item };
      this.serviceOfferedDialog = true;
    },

    onServiceOfferedDeleteClicked(item) {
      this.selected = { ...item };
      let updatedSettings = _.cloneDeep(this.settings);

      this.confirmationMessage =
        "Are you sure you want to remove this service?";
      this.confirmationFunc = async () => {
        updatedSettings.e_services.services_offered.forEach((el, i, arr) => {
          if (el.ID == this.selected.ID) {
            arr.splice(i, 1);
          }
        });
        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.e_services.services_offered = _.cloneDeep(
          updatedSettings.account_documents
        );
        this.confirmationDialog = false;
      };
      this.confirmationDialog = true;
    },

    async saveServiceOffered() {
      try {
        //Reset Form
        this.message.visible = false;
        this.loading = true;
        this.$toast.destroy();
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);

        //Check if form is valid
        if (!(await this.$validator.validateAll("servicesOffered"))) {
          this.message.title = "Form is invalid";
          this.message.content =
            "Please ensure all fields are correctly filled out.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        //Check if service is unique
        const isNotUnique = this.settings.e_services.services_offered.some(
          (el) => {
            let newName = this.selected.name.toLowerCase();
            let name = el.name.toLowerCase();

            return el.ID != this.selected.ID && name == newName;
          }
        );

        if (isNotUnique) {
          this.message.title = "Service Conflict!";
          this.message.content = "Please ensure the service entered is unique.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        let msg = "Service created successfully";
        let updatedSettings = _.cloneDeep(this.settings);
        let service = _.cloneDeep(this.selected);

        if (service.ID) {
          updatedSettings.e_services.services_offered.forEach((el, i, arr) => {
            if (el.ID == service.ID) {
              arr.splice(i, 1, service);
            }
          });
          msg = "Service updated successfully";
        } else {
          service.ID = uuidv4();
          service.is_active = true;
          updatedSettings.e_services.services_offered.push(service);
        }

        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.e_services.services_offered = _.cloneDeep(
          updatedSettings.e_services.services_offered
        );
        this.serviceOfferedDialog = false;
        this.$toast.success(msg, "", { position: "topLeft" });
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.loading = false;
      }
    },

    //Advertisement Medium Methods
    async onAddNewAdvertisementMediumClicked() {
      this.selected = {};
      this.advertisementMediumDialog = true;
    },

    async toggleAdvertisementMediumActive(item) {
      this.selected = { ...item };
      await this.saveAdvertisementMedium();
    },

    onAdvertisementMediumEditClicked(item) {
      this.selected = { ...item };
      this.advertisementMediumDialog = true;
    },

    onAdvertisementMediumDeleteClicked(item) {
      this.selected = { ...item };
      let updatedSettings = _.cloneDeep(this.settings);

      this.confirmationMessage = "Are you sure you want to remove this medium?";
      this.confirmationFunc = async () => {
        updatedSettings.e_services.advertisement_mediums.forEach(
          (el, i, arr) => {
            if (el.ID == this.selected.ID) {
              arr.splice(i, 1);
            }
          }
        );
        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.e_services.advertisement_mediums = _.cloneDeep(
          updatedSettings.account_documents
        );
        this.confirmationDialog = false;
      };
      this.confirmationDialog = true;
    },

    async saveAdvertisementMedium() {
      try {
        //Reset Form
        this.message.visible = false;
        this.loading = true;
        this.$toast.destroy();
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);

        //Check if form is valid
        if (!(await this.$validator.validateAll("advertisementMedium"))) {
          this.message.title = "Form is invalid";
          this.message.content =
            "Please ensure all fields are correctly filled out.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        //Check if service is unique
        const isNotUnique = this.settings.e_services.advertisement_mediums.some(
          (el) => {
            let newName = this.selected.name.toLowerCase();
            let name = el.name.toLowerCase();

            return el.ID != this.selected.ID && name == newName;
          }
        );

        if (isNotUnique) {
          this.message.title = "Medium Conflict!";
          this.message.content = "Please ensure the medium entered is unique.";
          this.message.type = "error";
          this.message.visible = true;
          return;
        }

        let msg = "Advertisement Medium created successfully";
        let updatedSettings = _.cloneDeep(this.settings);
        let medium = _.cloneDeep(this.selected);

        if (medium.ID) {
          updatedSettings.e_services.advertisement_mediums.forEach(
            (el, i, arr) => {
              if (el.ID == medium.ID) {
                arr.splice(i, 1, medium);
              }
            }
          );
          msg = "Advertisement Medium updated successfully";
        } else {
          medium.ID = uuidv4();
          medium.is_active = true;
          updatedSettings.e_services.advertisement_mediums.push(medium);
        }

        await settingsService.updateAccountSettings(updatedSettings);
        this.settings.e_services.advertisement_mediums = _.cloneDeep(
          updatedSettings.e_services.advertisement_mediums
        );
        this.advertisementMediumDialog = false;
        this.$toast.success(msg, "", { position: "topLeft" });
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
