import moment from 'moment'
export default {

  greaterThanToday: {
    name: 'not_greater_than_today',
    validator: {
      getMessage(field) {
        return 'The ' + field + ' field be cannot be greater than today'
      },

      validate(value) {
        let dateGiven = value.split('-')
        let start = new Date(dateGiven[0], dateGiven[1] - 1, dateGiven[2])
        let today = new Date()
        return today > start
      }
    }
  },
 

  dateLessThan: {
    name: 'date_less_than',
    validator: {
      getMessage(field, [otherValue]) {
        return 'Calculated years be cannot be greater than ' + (Number(otherValue) - 1) + ''
      },

      validate(value, [otherValue]) {
        let age = Number(
          moment().diff(
            moment(value, "YYYY-MM-DD"),
            "years"
          )
        )

        return Number(age) <= Number(otherValue)
      }
    }
  },


  isBigger: {
    name: 'is_bigger',
    validator: {
      getMessage(field, [otherValue]) {
        return 'The ' + field + ' field be cannot be less than ' + otherValue + ''
      },

      validate(value, [otherValue]) {
        return Number(value.toString().split(',').join('')) >= Number(otherValue.split(',').join(''))
      }
    }
  },

  notNegative: {
    name: 'not_negative',
    validator: {
      getMessage(field) {
        return 'The ' + field + ' field be cannot be a negative value'
      },

      validate(value) {
        return Number(value.toString().split(',').join('')) >= 0
      }
    }
  },

  greaterThan: {
    name: 'greater_than',
    validator: {
      getMessage(field, [otherValue]) {
        return 'The ' + field + ' field must be greater than ' + otherValue + ''
      },

      validate(value, [otherValue]) {
        return Number(value.toString().split(',').join('')) > Number(otherValue)
      }
    }
  },

  verifyPassword: {
    name: 'verify_password',
    validator: {
      getMessage: (field) => `The password must be 8 characters long and contains at least:   1 uppercase letter, 1 lowercase letter, 1 number, and one special character (E.g. , . _ & ? etc)`,

      validate: (value) => {
        let strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        return strongRegex.test(value);
      }
    }
  }

}