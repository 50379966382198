export default {
  json2htable(data) {
    let style = `
    <style> 
      table.json_table {
        font-size: 12px;
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 8px;
      }
      table.json_table th,  table.json_table td {
        border: 1px solid #777; padding: 5px; text-align: left;
        max-width: 250px; overflow-wrap: break-word;
      } 
    </style>`;
    let html = '<table class="json_table">';
    html += '<tr>';
    let properties = Object.keys(data[0]);
    properties.forEach((header) => {
      if(header.indexOf('ID') == -1)
        html += '<th>' + header.toUpperCase().replace(/_/g, ' ') + '</th>';
    });
    html += '</tr>';
    data.forEach((record) => {
        html += '<tr>';
        properties.forEach((key) => {
            if (key === 'event_data') {
                html += '<td>' + JSON.stringify(record[key]) + '</td>';

            } else {
                html += '<td>' + record[key] + '</td>';
            }
        });
        html += '<tr>';
    });
    html += '</table>';
    return style + html;
  }
}
