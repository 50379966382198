import httpClient from '@/common/http'

export default {
  async fetchLogs({filters, skip, limit}) {
    try{
      let queryString = '';
      for(const key in filters) {
        queryString+=`${key}=${filters[key]}&`;
      }

      queryString+=`skip=${skip}`;
      if(limit || false) {
        queryString+=`&limit=${limit}`;
      }

      let res = await httpClient.request('get', `audit-log/?${queryString}`);
      return res.data;
    } catch(e) {
      throw e;
    }
  },

  async exportLogs({type, filters, skip, limit}) {
    try{
      let queryString = `type=${type}&`;
      for(const key in filters) {
        queryString+=`${key}=${filters[key]}&`;
      }

      queryString+=`skip=${skip}`;
      if(limit || false) {
        queryString+=`&limit=${limit}`;
      }

      let res = await httpClient.request('get', `audit-log/export?${queryString}`, null);
      return res;
    } catch(e) {
      throw e;
    }
  },

  async fetchActions() {
    try {
      let res = await httpClient.request('get', `audit-log/actions`);
      return res.data;
    } catch(e) {
      throw e;
    }

  },

  async fetchModules() {
    try {
      let res = await httpClient.request('get', `audit-log/modules`);
      return res.data;
    } catch(e) {
      throw e;
    }
  },
}