<template>
 <div>
   <v-card class="pa-2 pl-4 mb-3 elevation-0">
    <h2>User Management</h2>

   </v-card>

    <!-- Datatable -->   
    <user-batch-load ref="batchUserUpload" @finished="fetchData"/>
        
    <v-toolbar class="elevation-1" color="white">
       <v-text-field
        solo
        v-model="globalSearch"
        class="va-form-control"
        append-icon="search"
        label=""
        @keyup="onSearch()"
        placeholder="Search by first name, last name or email"
      />
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-btn dark @click="$refs.batchUserUpload.open()">Batch Load</v-btn>
      <v-dialog persistent v-model="dialog" max-width="620px">
        <v-btn slot="activator" color="primary" dark class="mb-2">New User</v-btn>
        <v-card>
          <v-card-title>
            <span class="headline">
              {{ formTitle }}
            </span>
           
          </v-card-title>

          <v-card-text>
            <v-alert v-if="message.visible" :value="true" :type="message.type" style="margin: -20px 20px -20px 20px; padding: 10px">
              <b>{{message.title}}</b> {{message.content}}
            </v-alert>
            <v-container grid-list-md style="margin: -20px 0 -10px 0">
              <v-layout wrap row>
                <v-flex xs12>
                  
                  <v-autocomplete
                    v-if="editedIndex < 0"
                    v-model="editedItem.person_id"
                    :search-input.sync="searchCustomerModel"
                    :items="customers"
                    :loading="isLoading"
                    chips
                    placeholder="Search for staff without account"
                    clearable
                    item-text="trn"
                    item-value="ID"
                    :filter="searchFilter"
                    ref="searchCustomerRef"
                    @change="onCustomerSelected($event)"
                    solo
                  >
                    <template
                      v-slot:selection="{ item }"
                    >{{(item.full_name ? item.full_name : item.first_name + ' ' + item.last_name + ' - ' + (item.trn || 'N/A')) | capitalize}}</template>
                    <template v-slot:item="{ item }">
                      <v-list-tile-avatar>
                        <img
                          :src="item.avatar || require('@/assets/img/avatar.png')"
                          style="border-radius: 50%; width 36px; height: 36px"
                        >
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{(item.first_name + ' ' + item.last_name) | capitalize}}</v-list-tile-title>
                        <v-list-tile-sub-title v-text="item.trn || 'N/A'"></v-list-tile-sub-title>
                      </v-list-tile-content>
                      <v-list-tile-action>
                        <!-- <v-icon>fa-user</v-icon> -->
                      </v-list-tile-action>
                    </template>
                  </v-autocomplete>
                  <h2 v-else>{{(editedItem.full_name + ' - ' + (editedItem.trn || 'N/A')) | capitalize}}</h2>
                  <v-layout>
                    <v-flex class="mr-2" xs6>
                      <va-select 
                      v-model="editedItem.user_scope"
                      v-validate="'required'"
                      placeholder=" "
                      label="User Scope"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.user_scope')"
                      :items="userScope"
                      data-vv-name="user_scope"
                      data-vv-as="user scope"
                      />
                    </v-flex>
                    <v-flex class="ml-2" xs6>
                      <va-select 
                      v-model="editedItem.role_id"
                      v-validate="'required'"
                      placeholder=" "
                      label="Role"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.role')"
                      :items="roles"
                      :item-text="'role_name'"
                      :item-value="'ID'"
                      data-vv-name="role"/>
                    </v-flex>
                  </v-layout>
                  
                  <v-layout>
                    <v-flex class="mr-2" xs6>
                      <va-input 
                        v-model="editedItem.core_id"
                        v-validate="'min:1|max:20'"
                        placeholder=" "
                        label="Employee ID (Optional)"
                        data-vv-scope="user"
                        :error-messages="errors.collect('user.core_id')"
                        persistent-hint
                        :hint="'Employer ID use in integrated system'"
                        data-vv-name="core_id"
                        data-vv-as="employee id"
                        />
                    </v-flex>
                    <v-flex class="ml-2" xs6>
                      <va-select 
                        v-model="editedItem.branch_id"
                        v-validate="'required'"
                        placeholder=" "
                        label="Branch Assigned To"
                        data-vv-scope="user"
                        :item-text="'branch_name'"
                        :item-value="'ID'"
                        :error-messages="errors.collect('user.branch_id')"
                        :items="branches"
                        data-vv-name="branch_id"
                        data-vv-as="branch"
                        />
                    </v-flex>
                  </v-layout>


                  <va-input 
                    v-model="editedItem.email"
                    v-validate="'required|email'"
                    placeholder=" "
                    label="Account Email"
                    data-vv-scope="user"
                    :error-messages="errors.collect('user.email')"
                    :items="branches"
                    data-vv-name="email"/>

                  <v-layout>
                    <v-flex class="mr-2" xs6>
                      <va-select 
                      v-model="editedItem.waiver_level"
                      placeholder=" "
                      label="Waiver Level (Optional)"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.waiver_level')"
                      :items="waiverLevels"
                      data-vv-name="waiver_level"
                      data-vv-as="waiver level"
                      />
                    </v-flex>
                    <v-flex class="ml-2" xs6>
                      <va-select 
                      v-model="editedItem.approval_level"
                      placeholder=" "
                      label="Approval Level (Optional)"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.approval_level')"
                      :items="approvalLevels"
                      data-vv-name="approval_level"
                      data-vv-as="approval level"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex class="mr-2" xs6>
                      <va-select 
                      v-model="editedItem.dsr_waiver_level"
                      placeholder=" "
                      label="DSR Waiver Level (Optional)"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.dsr_waiver_level')"
                      :items="dsrWaiverLevels"
                      data-vv-name="dsr_waiver_level"
                      data-vv-as="dsr waiver level"
                      />
                    </v-flex>
                    <v-flex class="ml-2" xs6>
                      <va-select 
                      v-model="editedItem.insurance_waiver_level"
                      placeholder=" "
                      label="Insurance Waiver Level(Optional)"
                      data-vv-scope="user"
                      :error-messages="errors.collect('user.insurance_waiver_level')"
                      :items="insuranceWaiverLevels"
                      data-vv-name="insurance_waiver_level"
                      data-vv-as="insurance waiver level"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>

              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn flat @click="close">Cancel</v-btn>
            <v-btn color="primary" :loading="isLoading" :disabled="isLoading" @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-data-table
    class="elevation-1"
      :headers="headers"
      :items="items"
      :total-items="totalCount"
      :loading="isLoading"
      :pagination.sync="pagination"
      :rows-per-page-items='[20,50, 100,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    >
     <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props" >
        <tr v-if="!props.item.is_admin">
          <td class="">
          
          <v-switch
            v-if="props.item.person"
            style="margin-top: 10px"
            v-model="props.item.is_active"
            label=""
            @change="toggleActive(props.item, props.index)"
            ></v-switch>
          </td>
          <td>{{ props.item.full_name | capitalize }}</td>
          <td>{{ props.item.email }}</td>
          <td>{{ props.item.user_scope || '-' }}</td>
          <td>{{ props.item.role ? props.item.role.role_name : '-' }}</td>
          <td>
            <v-icon title="Generate New Password" small class="mr-2" @click="generateNewPassword(props.item)">
              refresh
            </v-icon>
            <v-icon v-if="props.item.person" small class="mr-2" @click="onEditItemClicked(props.item)">
              edit
            </v-icon>
            <v-icon v-if="props.item.person" small @click="onDeleteClicked(props.item)">
              delete
            </v-icon>
          </td>
        </tr>
        
      </template>
      <template slot="no-data">
        
      </template>
    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import PaginationMixin from '@/mixins/PaginationMixin'
import CustomerService from "@/services/customer-service";
import BranchService from "@/services/branch-service";
import RoleService from "@/services/role-service";
import UserService from "@/services/user-service";
import WaiverLevelService from '@/services/loan-waiver-policy-setting.service'
import ApprovalLevelService from '@/services/loan-approval-policy.service'
import UserBatchLoad from '@/components/batch-insert/UserBatchLoad.vue';
import _ from 'lodash';

export default {
  components: {
    UserBatchLoad,
    VaInput,
    VaSelect
  },

  mixins: [PaginationMixin],

  created () {
    this.initialize()
  },

  data() {
    return {

      toast: null,
      dialog: false,
      isLoading: false,
      headers: [
        { text: 'Active', align: 'left', sortable: false, value: 'is_active'},
        { text: 'Name', align: 'left', sortable: true, value: 'first_name'},
        { text: 'Email', align: 'left', sortable: true, value: 'email'},
        { text: 'User Scope', align: 'left', sortable: false, value: 'branch_type'},
        { text: 'Role', align: 'left', sortable: false, value: 'branch_email'},
        { text: 'Action', value: ''},
      ],
      items: [],
      userScope: ['Company', 'Region', 'Branch'],
      editedIndex: -1,
      editedItem: {},
      customers: [],
      branches: [],
      roles: [],
      waiverLevels: [],
      approvalLevels: [],
      dsrWaiverLevels: [],
      insuranceWaiverLevels: [],
      searchCustomerModel: null,
      message: {
        visible: false,
        title: '',
        content: '',
        type:true
      }, 
    }
  },

  computed: {
    ...mapState([
      'appState',
    ]), 
    
    formTitle () {
      return this.editedIndex === -1 ? 'New User' : 'Edit User'
    }
  },

  methods: {

    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),
    
    async initialize () {   
      this.toast = this.$toast
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        await this.findCustomer()
        await this.fetchBranches()
        await this.fetchRoles()
        await this.fetchWaiverLevels()
        await this.fetchApprovalLevels()
        await this.fetchDsrWaiverLevels()
        await this.fetchInsuranceWaiverLevels()
      } catch(e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    close () {
      this.dialog = false
      this.editedItem = {}
      this.editedIndex = -1
      this.$validator.reset({scope: 'user'});
      this.$nextTick(() => {
        this.searchCustomerModel = null;
      });
    },


    async findCustomer() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      try {
        this.isLoading = true;

        let res = await CustomerService.findAllNonUsers();
        this.customers = res.data;
      } catch (e) {
        console.log("fetching customer: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    onEditItemClicked(item) {
      this.editedIndex = this.items.findIndex((el) => el.ID === item.ID)
      this.editedItem = {
        ID: item.ID,
        person_id: item.person.ID,
        email: item.email,
        trn: item.trn,
        core_id: item.core_id || '',
        last_name: item.last_name,
        full_name: item.full_name,
        first_name: item.first_name,
        user_scope: item.user_scope,
        waiver_level: item.waiver_level,
        approval_level: item.approval_level,
        dsr_waiver_level: item.dsr_waiver_level,
        insurance_waiver_level: item.insurance_waiver_level,
        role_id: item.role ? item.role.ID : '',
        branch_id: item.branch ? item.branch.ID : '',
      }
      this.dialog = true
    },

    async onDeleteClicked(item) {
      this.$toast.question(`You are about to remove ${item.full_name} as a user. Are you sure you want to perform this action.`, '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
              let index = this.items.findIndex((el) => el.ID === item.ID )
              await UserService.delete(item.ID)
              this.items.splice(index,1)
            } catch(e) {
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    onCustomerSelected(ev) {
      if(this.editedIndex < 0 && ev) {
        let item = this.customers.find((el) => el.ID == ev)
        this.editedItem.email = item.contact_info.email
        this.editedItem.full_name = item.first_name + ' ' + item.last_name
      } 
      this.editedItem.person_id = ev     
    },

    searchFilter(item, queryText, itemText) {
        const name = (item.first_name || '').toLowerCase() + ' ' +  (item.last_name || '').toLowerCase()
        const trn = (item.trn || '').toLowerCase()
        const searchText = queryText.toLowerCase()

        return name.indexOf(searchText) > -1 ||
          trn.indexOf(searchText) > -1
    },

    async fetchBranches() {
       try {
        let response = await BranchService.findAll();
        this.branches = response.data;
      } catch(e) {
        throw e
      } 
    },

    async fetchRoles() {
       try {
        let response = await RoleService.findAll();
        this.roles = response.data;
      } catch(e) {
        throw e
      } 
    },
    
    async fetchWaiverLevels() {
       try {
        let response = await WaiverLevelService.findAll();
        this.waiverLevels = response.reduce((acc, cur)=>{
          acc = acc.concat(cur)
          return acc
        });
      } catch(e) {
        throw e
      } 
    },

    async fetchApprovalLevels() {
       try {
        let response = await ApprovalLevelService.findAll();
        this.approvalLevels = response.reduce((acc, cur)=>{
          acc = acc.concat(cur)
          return acc
        });
      } catch(e) {
        throw e
      } 
    },

    async fetchDsrWaiverLevels() {
       try {
        let response = await WaiverLevelService.getDSRWaiver();
        this.dsrWaiverLevels = response.map(({ waiver_level }) => waiver_level)
      } catch(e) {
        throw e
      } 
    },

    async fetchInsuranceWaiverLevels() {
       try {
        let response = await WaiverLevelService.getInsuranceWaiver();
        this.insuranceWaiverLevels = response.map(({ waiver_level }) => waiver_level)
      } catch(e) {
        throw e
      } 
    },

    async fetchData() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true
        let search = `first_name=lk:${this.globalSearch}:or&email=lk:${this.globalSearch}:or&last_name=lk:${this.globalSearch}:or`
        let response = await UserService.findAll(search, this.sortString, {skip: this.skip, limit: this.limit})
        this.items = response.data
        this.totalCount = response.total 
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },

    async generateNewPassword(item) {
      this.$toast.question(`You are about to generate a new password for ${item.full_name}. Are you sure you want to perform this action.`, '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
              this.isLoading = true
              await UserService.generateNewPassword(item.ID)

              this.toast.success('New password has been sent to user email', '', {position: 'topCenter'})

            } catch(e) {
              console.log('fetching data: ', e)
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
            } finally {
              this.isLoading = false
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
    },

    async save () {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true

        let isValid = await this.$validator.validateAll("user")
        if(isValid) {
          let postData = _.cloneDeep(this.editedItem)
          postData.waiver_level = this.editedItem.waiver_level ? this.editedItem.waiver_level[0] : null;
          postData.approval_level = this.editedItem.approval_level ? this.editedItem.approval_level[0] : null;
          postData.dsr_waiver_level = this.editedItem.dsr_waiver_level ? this.editedItem.dsr_waiver_level : null;
          postData.insurance_waiver_level = this.editedItem.insurance_waiver_level ? this.editedItem.insurance_waiver_level : null;
          if (this.editedIndex > -1) {
            
            delete postData['ID']
            delete postData['full_name']
            delete postData['first_name']
            delete postData['last_name']
            delete postData['trn']
            await UserService.update(this.editedItem.ID, postData)
            let updated = await UserService.findOne(this.editedItem.ID)
            this.items.splice(this.editedIndex, 1, updated)
            this.toast.success('Successfully updated record!', '', {position: 'topCenter'})

          } else {
            let response = await UserService.create(postData)
            let data = await UserService.findOne(response.data)
            this.items.push(data)

            //Remove user from non staff list
            let index = this.customers.findIndex((el)=> el.ID === this.editedItem.person_id)
            this.customers.splice(index, 1)
            this.toast.success('Successfully created user!', '', {position: 'topCenter'})
          }
          this.close()
        } else {
          this.toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
        }
      } catch(e) {
        console.log('saving/updating: ',e)
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
        
      } finally {
        this.isLoading = false
      }
    },

    async toggleActive(item, index) {
      this.editedIndex = index
      this.editedItem =  {
        is_active: item.is_active,
        ID: item.ID,
        person_id: item.person.ID,
        email: item.email,
        core_id: item.core_id || '',
        trn: item.trn, 
        last_name: item.last_name,
        full_name: item.full_name,
        first_name: item.first_name,
        user_scope: item.user_scope,
        waiver_level: item.waiver.length > 0 ? [item.waiver[0].waiver_level] : '',
        approval_level: item.approval.length > 0 ? [item.approval[0].approval_level] : '',
        role_id: item.role.ID,
        branch_id: item.branch.ID,
      }
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true

        let postData = _.cloneDeep(this.editedItem)
        postData.waiver_level = this.editedItem.waiver_level ? this.editedItem.waiver_level[0] : null
        postData.approval_level = this.editedItem.approval_level ? this.editedItem.approval_level[0] : null

        delete postData['ID']
        delete postData['full_name']
        delete postData['first_name']
        delete postData['last_name']
        delete postData['trn']
        await UserService.update(this.editedItem.ID, postData)
        this.toast.warning(`User is now ${item.is_active ? 'Active' : 'Inactive'}`, '', {position: 'topCenter'})
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)        
      } finally {
        this.isLoading = false
      }
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    }
  }
}
</script>

 
