<template>
  <v-container>
    <v-btn @click="dialog = true" color="blue darken-2" dark bottom right>
      Add Data
    </v-btn>
    <v-dialog v-model="dialog" persistent max-width="400">
      <v-card flat>
        <v-card-title class="headline white--text primary"
          >Add Data
          <v-spacer></v-spacer>
          <v-btn dark color="primary" depressed @click="dialog = false"
            >Exit</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-layout row wrap mx-1>
              <v-flex xs12>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formData.agreement_date"
                      label="DATED"
                      readonly
                      v-on="on"
                      placeholder="Select date"
                      box
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="formData.agreement_date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="2000-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex pr-2 xs12>
                <va-input
                  label="MEMBER NAME"
                  placeholder="Enter Member Name"
                  v-model="formData.customer_name"
                ></va-input>
              </v-flex>
              <v-flex pr-2 xs12>
                <va-input
                  label="WITNESS NAME"
                  placeholder="Enter Witness Name"
                  v-model="formData.witness_name"
                ></va-input>
              </v-flex>
              <v-flex pr-2 xs12>
                <va-input
                  label="MEMBER ADDRESS"
                  placeholder="Enter Member Address"
                  v-model="formData.address"
                ></va-input>
              </v-flex>
              <v-flex pr-2 pt-2 xs6>
                <va-select
                  :items="parishes"
                  label="MEMBER PARISH"
                  placeholder="Select Member Parish"
                  v-model="formData.parish"
                ></va-select>
              </v-flex>
              <v-flex pr-2 xs6>
                <va-input
                  label="MEMBER TRN"
                  placeholder="Enter Member TRN"
                  v-model="formData.trn"
                ></va-input>
              </v-flex>
            </v-layout>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click="formData = {}">Clear</v-btn>
          <v-btn color="blue darken-1" flat @click="dialog = false">Add</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card elevation="5" raised>
      <div id="body" class="pa-4">
        <div>
          <div v-if="company_logo != null">
            <img :src="company_logo" class="img" />
          </div>
          <div>
            <p class="font-weight-bold">
              Dated:
              <span class="ml-4 font-weight-regular">{{
                formData.agreement_date
              }}</span>
            </p>
            <p class="font-weight-bold">
              To:
              <span class="ml-5 font-weight-regular">
                The Credit Bureau<span class="font-weight-black">*</span>                
              </span>
            </p>
            <p class="font-weight-bold">
              Name:
              <span class="ml-4 font-weight-regular">{{
                formData.customer_name
              }}</span>
            </p>

            <p class="font-weight-bold">
              Re:
              <span class="ml-5 font-weight-black"
                >Disclosure of my Credit Information</span
              >
            </p>

            <p>
              I,
              <span class="font-weight-black">{{
                formData.customer_name
              }}</span>, of
              <span class="font-weight-black">{{ formData.address }}</span> in
              the parish of
              <span class="font-weight-black">{{ formData.parish }}</span> with
              Tax Registration Number
              <span class="font-weight-black">{{ formData.trn }}</span> hereby
              consent:
            </p>
            <ol type="a">
              <li>
                to The Credit Bureau<span class="font-weight-black">*</span>
                disclosing to <span class="font-weight-black">{{ company.company_name }} </span>                
                such credit information which it may have in regard to me.
              </li>
              <li>
                to
                <span class="font-weight-black">{{
                  company.company_name
                }}</span>
                disclosing to any credit bureau duly licensed under the Credit
                Reporting Act any and all credit information which it may
                receive in regard to me pursuant to this consent, in so far as
                this is required by law.
              </li>
              <li>
                to
                <span class="font-weight-black">{{
                  company.company_name
                }}</span>
                providing this signed consent form to The Credit Bureau<span class="font-weight-black">*</span> 
                (pursuant to item (b) by electronic means).
              </li>
            </ol>

            <p class="font-weight-black">
              I understand and agree that my consent which is hereby given:
            </p>
            <ol type="i">
              <li>
                shall subsist for the duration of my application for this or any
                future credit facility that I may have or apply for with
                <span class="font-weight-black">{{
                  company.company_name
                }}</span>
                and for so long as this credit facility or such future credit
                facility shall subsist.
              </li>
              <li>
                shall remain valid and binding until it is expressly revoked by
                me.
              </li>
              <li>
                cannot be revoked during the subsistence of any credit facility
                that I may have with
                <span class="font-weight-black">{{
                  company.company_name
                }}</span>
                but only upon or after the termination of such facility.
              </li>
              <li>
                will be applicable to all applications that I may make to obtain
                a credit facility from
                <span class="font-weight-black">{{
                  company.company_name
                }}</span>
                where I am involved in that application either as the borrower
                or surety/guarantor and also for the purpose of facilitating
                risk assessment for granting further extension of credit by
                <span class="font-weight-black">{{
                  formData.customer_name
                }}</span>
                in relation to any credit facility currenty existing or which
                may come into existence in the future.
              </li>
            </ol>

            <div class="pagebreak">
              <p class="font-weight-black pb-4">
                I,
                <span>{{ formData.customer_name }}</span
                >, hereby sign this Consent of Natural Person of my own free
                will and voilition the same having been read by/to me and fully
                understood.
              </p>

              <v-layout row wrap>
                <v-flex xs12 sm12 md6>
                  <span class="line">{{ formData.customer_name }}</span>
                  <p class="font-weight-bold mb-4">Name of Member</p>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <span class="line"></span>
                  <p class="font-weight-bold">Signature of Member</p>
                </v-flex>
              </v-layout>

              <v-layout row wrap>
                <v-flex xs12 sm12 md6>
                  <span class="line">{{ formData.witness_name }}</span>
                  <p class="font-weight-bold mb-4">
                    Name of Witness
                    <span class="font-weight-black">**</span>
                  </p>
                </v-flex>
                <v-flex xs12 sm12 md6>
                  <span class="line"></span>
                  <p class="font-weight-bold">Signature of Witness</p>
                </v-flex>
              </v-layout>

              <div class="outline1">
                <p class="pt-4 mx-4">
                  <span class="font-weight-black">*</span>Any Licensed Credit
                  Bureau within Jamaica.
                </p>
                <p class="pb-4 mx-4">
                  <span class="font-weight-black">**</span>Signature may be
                  witnessed by either an officer employed to
                  <span class="font-weight-black">{{
                    company.company_name
                  }}</span
                  >, Credit Union Manager, an Attorney-at-Law, Justice of the
                  Peace and/or by verification methods consistent with the
                  Electronic Transactions Act.
                </p>
              </div>
            </div>
            <footer class="pa-3">
              <div class="font-weight-bold">Revised {{ revision_date }}</div>
            </footer>
          </div>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import companyService from "@/services/company-service";
import VaSelect from '../../../../components/form-components/VaSelect.vue';

export default {
  components: { VaSelect },
  data() {
    return {
      company: {},
      company_logo: "",
      revision_date: "July 2013",
      formData: {},
      dialog: false,
      menu: false,
      parishes: ['Kingston', 'St. Andrew', 'St. Catherine', 'Clarendon', 'Manchester', 
                'St. Elizabeth', 'Westmoreland', 'Hanover', 'St. James', 'Trelawny', 
                'St. Ann', 'St. Mary', 'Portland', 'St. Thomas']
    };
  },

  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    async getLogo() {
      try {
        let result = await companyService.find();
        let logo_result = await fetch(result.data.logo);
        if (Object.keys(result.data).length > 0) {
          this.company = result.data;
          this.company_logo =
            logo_result.status == 200
              ? this.company.logo
              : null;
        } else {
          this.company = {};
          this.company_logo = null;
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", {
            position: "topCenter",
          });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            {
              position: "topCenter",
            }
          );
        }
      }
    },
  },

  mounted() {
    this.getLogo();
  },
};
</script>
<style scoped>
.pagebreak {
  break-before: left;
}

p {
  font-size: 15px;
  display: block;
  padding: 3px;
  text-align: justify;
  font-weight: 400;
}

li {
  padding: 12px;
  text-align: justify;
  font-weight: 400;
}

footer {
  text-align: center;
}

img {
  height: 100px;
  width: 100px;
  padding-bottom: 12px;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-black {
  font-weight: bolder;
}

.font-weight-regular {
  font-weight: normal;
}

.mb-4 {
  margin-bottom: 10px;
}

.pb-4 {
  padding-bottom: 10px;
}

.ml-4 {
  margin-left: 10px;
}

.ml-5 {
  margin-left: 12px;
}

.pt-4 {
  padding-top: 10px;
}

.mx-4 {
  margin: 10px;
}

.outline1 {
  border: 1px solid black;
  outline-style: solid;
  outline-color: black;
  outline-width: thin;
  background-color: #e0e0e0;
  margin-top: 15px;
}

.line {
  display: inline-block;
  border-bottom: 1px solid;
  width: 250px;
}

.flex-container {
  display: flex;
}

.flex-container > div {
  margin: 10px;
  padding: 20px;
}
</style>