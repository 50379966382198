<template>
  <div>
    <h2>YAPU Management Settings</h2>
    <v-subheader>* Ensure that all values entered, synchronize with values from YAPU</v-subheader>
    <v-divider class="mb-4"></v-divider>

    <v-tabs v-model="tabs" centered>
      <v-tab v-for="option in yapuOptions" :key="option.label" @click="onReportOptionClick(option)">
        {{ option.label }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item v-for="option in yapuOptions" :key="option.label">
        <component :is="option.component"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import propertyComponent from './yapu/property.vue'
import exposureComponent from './yapu/exposure.vue'
import sensitivityComponent from './yapu/sensitivity.vue'
import adaptiveCapacityComponent from './yapu/adaptiveCapacity.vue'

export default {
  data() {
    return {
      tabs: null,
      yapuOptions: [
        {
          label: 'Property Details',
          description: 'Physical characteristics of a location.',
          component: propertyComponent,
          subOptions: []
        },
        {
          label: 'Exposure',
          description: 'The extent to which a location is susceptible to climate hazards.',
          component: exposureComponent,
          subOptions: []
        },
        {
          label: 'Sensitivity',
          description: 'The degree to which a location is vulnerable to climate hazards.',
          component: sensitivityComponent,
          subOptions: []
        },
        {
          label: 'Adaptive Capacity',
          description: 'Questions to the resilience of a location from climate hazards.',
          component: adaptiveCapacityComponent,
          subOptions: []
        }
      ],
      selectedOption: null
    };
  },

  methods: {
    onReportOptionClick(option) {
      this.selectedOption = option;
    }
  }
};
</script>

<style scoped>
    .filter-card {
        height: calc(100vh - 200px);
        overflow-y: auto;
        margin-right: 10px;
    }

    .table-card {
        height: calc(100vh - 200px);
        /* overflow-y: auto; */
    }

    .fixed-title {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: rgb(241, 236, 236);
    }
</style>