<template>
 <div>
   
    <v-layout flat color="white">
       
      <v-spacer></v-spacer>
       <v-dialog persistent v-model="dialog" max-width="650px">
          <v-toolbar  dense color="primary" dark class="elevation-3">
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

            <v-spacer></v-spacer>

          </v-toolbar>
        <v-card>

          <v-card-text>
              <v-layout align-content-space-between justify-space-between row wrap>
                <v-flex xs6>
                    <va-input
                      v-model="editedItem.loan_type_name"
                      class="mr-2"
                      v-validate="'required|min:3|max:50'"
                      data-vv-scope="loan"
                      :error-messages="errors.collect('loan.loan_type_name')"
                      placeholder=" "
                      label="Loan Type Name"
                      data-vv-validate-on="blur"         
                      data-vv-name="loan_type_name"
                      data-vv-as="loan type name"
                      show-clean />
                </v-flex>
                <v-flex xs6>
                    <va-select 
                      v-model="editedItem.loan_type_category"
                      v-validate="'required'"
                      class="ml-2"
                      placeholder=" "
                      label="Loan Type Category"
                      data-vv-scope="loan"
                      data-vv-as="loan type category"
                      :error-messages="errors.collect('loan.loan_type_category')"
                      :items="loanTypeCatgories"
                      :item-text="'text'"
                      :item-value="'value'"
                      data-vv-name="loan_type_category"/>
                </v-flex>
                <v-flex xs6>
                    <va-input 
                      v-model="editedItem.response_time"
                      v-validate="'integer|min:0|max:744'"
                      placeholder=" "
                      label="Response Time (Hours)"
                      data-vv-scope="loan"
                      data-vv-as="response time"
                      :error-messages="errors.collect('loan.response_time')"
                      data-vv-name="response_time"/>
                </v-flex>
              </v-layout>
              <v-tabs icons-and-text  slider-color="primary" class="elevate-2" v-model="tab" centered>
                <v-tab href="#tab-fee"> Loan Fees  <v-icon color="primary">fa-dollar-sign</v-icon> </v-tab>
                <v-tab href="#tab-document" v-if="loanSettings.use_checklist"> Loan Type Checklist  <v-icon color="primary">fa-tasks</v-icon> </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item :value="`tab-fee`">
                  <v-list two-line>
                    <v-subheader>
                      <v-checkbox :label="'Select All Fees'" v-model="allFeesCheckBox" @change="onSelectAllFees($event); checkAllFees()"></v-checkbox>  
                      <v-spacer></v-spacer>
                      <v-text-field placeholder="Search..." style="width: 140px!important" v-model="feeSearch" clearable single-line append-icon="search"></v-text-field>
                    </v-subheader>

                    <v-divider></v-divider>
                    <div style="overflow-y: scroll !important; max-height: 200px; touch-action: manipulation">
                      <v-list-tile v-for="item in filterFees(fees)" :key="item.loan_fee_name">
                        <v-list-tile-action>
                          <v-checkbox v-model="item.is_selected" @change="checkAllFees()"></v-checkbox> 
                        </v-list-tile-action>
                        
                        <v-list-tile-content>
                          <v-list-tile-title> {{item.loan_fee_name}} </v-list-tile-title>
                          <v-list-tile-sub-title> {{item.loan_fee_code}} </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </div>
                    
                  </v-list>
                </v-tab-item>

                <v-tab-item :value="`tab-document`">
                  <v-list two-line>
                    <v-subheader>
                      <v-checkbox :label="'Select All Items'" v-model="allDocumentCheckBox" @change="onSelectAllActions($event); checkAllCheck()"></v-checkbox>  
                      <v-spacer></v-spacer>
                      <v-text-field placeholder="Search..." style="width: 140px!important" v-model="documentSearch" clearable single-line append-icon="search"></v-text-field>
                    </v-subheader>

                    <v-divider></v-divider>
                    <div style="overflow-y: scroll !important; max-height: 200px;">
                      <v-list-tile   v-for="item in filterActions(checklist)" :key="item.checklist_name">
                        <v-list-tile-action>
                          <v-checkbox v-model="item.is_selected" @change="checkAllCheck"></v-checkbox> 
                        </v-list-tile-action>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{item.checklist_name | capitalize }}                        
                          </v-list-tile-title>
                          <v-list-tile-sub-title>
                            Type: {{item.checklist_type | capitalize }} / Priority: {{item.priority | capitalize({ onlyFirstLetter: false })}}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </v-list-tile>
                    </div>
                    
                  </v-list>
                </v-tab-item>
              </v-tabs-items>
             
          </v-card-text>

          <v-card-actions class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn flat @click="close">Cancel</v-btn>
            <v-btn color="primary" @click="submit">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>

    <v-dialog v-model="deleteDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>
          <h3 class="text-xs-center pb-3">In order to delete this loan type you must select a replacement type.</h3>  
          
          <va-select 
            v-model="replacementID"
            v-validate="'required'"
            placeholder=" "
            label="Select a replacement loan type"
            data-vv-scope="delete"
            data-vv-as="replacement loan type"
            :error-messages="errors.collect('delete.replacement')"
            :items="otherLoanTypes"
            :item-text="'loan_type_name'"
            :item-value="'ID'"
            data-vv-name="replacement"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
          <v-btn color="red darken-3" flat @click="deleteType">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h2>Loan Type Management</h2>
    <v-divider class="mb-4"></v-divider>
    <v-toolbar dense color="white" class="pa-0">
      <v-text-field
        v-model="globalSearch"
        @keyup="onSearch()"
        solo
        label="Search"
        prepend-inner-icon="search"
      ></v-text-field>

      <v-divider class="mr-2" vertical></v-divider>

      <v-btn  @click="onDialogOpenedClicked" color="primary" dark class="mb-2">New Loan Type</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      class="elevation-1"
      :items="items"
      :loading="loanTypeState.isLoading"
      :pagination.sync="pagination"
      :total-items="totalCount"
      :rows-per-page-items='[10,20, 30,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    >
    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td @click="props.expanded = !props.expanded">{{ props.item.loan_type_name }}</td>
        <td>{{ props.item.loan_type_category }}</td>
        <td>{{ props.item.date_created }}</td>
        <td>
          <v-icon title="edit" small class="mr-2" @click="onEditItemClicked(props.item)">
            edit
          </v-icon>
          <v-icon title="delete" small class="mr-2" @click="onDeleteItemClicked(props.item)">
            delete
          </v-icon>
        </td>
      </template>
      <template v-slot:expand="props">
        <v-card flat>
          <v-card-text>Peek-a-boo!</v-card-text>
        </v-card>
      </template>
      <template slot="no-data">
        
      </template>

    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import PaginationMixin from '@/mixins/PaginationMixin'
import loanTypeService from '@/services/loan-type-service'
import loanFeeService from '@/services/loan-fee-service'
import checklistService from '@/services/loan-checklist-service'
import settingsService from '@/services/general-settings-service'

export default {
  components: {
    VaInput,
    VaSelect
  },

  mixins: [PaginationMixin],

  created () {
    this.initialize()
  },
  
  beforeDestroy() {
    this.$toast.destroy()
  },

  data() {
    return {
      loanSettings: {},
      dialog: false,
      feeSearch: '',
      allFeesCheckBox: false,
      allDocumentCheckBox: false,
      documentSearch: '',
      fees:[],
      feeList: [],
      otherLoanTypes: [],
      replacementID: false,
      deleteItem: {},
      deleteDialog: false,
      checklist:[],
      checklistCache:[],
      tab:null,
      headers: [
        { text: 'Loan Type Name', align: 'left', sortable: true, value: 'loan_type_name'},
        { text: 'Loan Type Category', align: 'left', sortable: true, value: 'loan_type_category'},
        { text: 'Date Created', value: 'date_created', sortable: false },
        { text: 'Action', value: '', sortable: false},
      ],
      items: [],
      loanTypeCatgories: [
        {value: 'Motor Vehicle', text:'Motor Vehicle'}, 
        {value: 'Real Estate', text:'Real Estate'}, 
        {value: 'Cash Secured', text:'Cash Secured'}, 
        {value: 'Unsecured', text:'Unsecured'}
      ],
      editedIndex: -1,
      editedItem: {},
      provinces:[],
      message: {
        visible: false,
        title: '',
        content: '',
        type: 'success'
      }, 
    }
  },

  computed: {
    ...mapState([
      'appState',
      'loanTypeState',
    ]), 
    
    formTitle () {
      return this.editedIndex === -1 ? 'New Loan Type' : 'Edit Loan Type'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),
    ...mapActions([
      'getLoanTypes',
      'createLoanType',
      'updateLoanType',
      'deleteLoanType'
    ]),
    
    async initialize () {
      try{
        await this.fetchSettings()

        Object.assign(this.editedItem, this.loanTypeState.loanType)

        let response = await checklistService.findAll() 
        let res = await loanFeeService.findAll()

        this.fees = res.data
        this.feeList = res.data
        this.checklist = response.data
        this.checklistCache = response.data
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let result = await settingsService.findLoanSettings()
        if(Object.keys(result).length > 0) {
          for(let key in result) {
            if(typeof result[key] === 'string' && result[key].indexOf("{'") > -1) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'))
            }
          }
          this.loanSettings = result 
          return true
        }
        return false;
      } catch(e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },


    close () {
      this.$validator.reset({scope: 'loan'})
      this.dialog = false
      this.editedItem = Object.assign({}, this.loanTypeState.loanType)
      this.editedIndex = -1
      this.allDocumentCheckBox = false
      this.allFeesCheckBox = false
    },

    setupOnDialogOpened() {
      this.fees = this.feeList.map((el) => {
        el.is_selected = false
        this.editedItem.loan_type_fees.forEach((fee)=>{
          if(el.ID == fee.ID) {
            el.is_selected = true
          }
        })
        return el
      }) 

      this.checklist = this.checklistCache.map((el) => {
        el.is_selected = false
        this.editedItem.loan_type_checklist.forEach((document)=>{
          if(el.ID == document.ID) {
            el.is_selected = true
          }
        })
        return el
      }) 
     
      this.editedItem.loan_type_fees = this.fees
      this.editedItem.loan_type_checklist = this.checklist
      
    },

    onDialogOpenedClicked() {
      this.resetState()
      this.editedItem = Object.assign({}, this.loanTypeState.loanType)
      this.editedItem.response_time = 24;
      this.setupOnDialogOpened()
      this.$validator.reset({scope: 'loan'})
      this.dialog = true
    },

    onEditItemClicked(item) {
      this.items.forEach((el, i)=>{
        if(item.ID === el.ID){
          this.editedIndex = i
        }
      })
      this.editedItem = Object.assign({}, item)
      this.setupOnDialogOpened()
      this.checkAllCheck()
      this.checkAllFees()
      this.dialog = true
    },

    async fetchData() {
      try {
        this.resetState()
        let search = `loan_type_name=lk:${this.globalSearch}:or&loan_type_category=lk:${this.globalSearch}`

        await this.getLoanTypes({filter: search, sort: this.sortString, paginate: {skip: this.skip, limit: this.limit}})
        this.items = this.loanTypeState.loanTypes 
        this.totalCount = this.loanTypeState.totalCount  
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } 
    },

    async submit() {
      this.resetState()
      let isValid = await this.$validator.validateAll("loan")
      if(!isValid) {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
        return
      }
  
      await this.save()
    },

    async save () {
      try {
        let exist = await this.doesRecordExist()
        if(exist) return

        if(this.editedItem.loan_type_fees.length == 0) {
          this.$toast.error('You need to select at least one fee!', '', {position: 'topCenter'})
          return
        }

     
        if (this.editedIndex > -1) {
          await this.updateLoanType({index: this.editedIndex, obj: this.editedItem})
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          await this.createLoanType(this.editedItem)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.close()
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    async doesRecordExist() {
      let search = `loan_type_name=eq:${this.editedItem.loan_type_name}:or`
      let types = await loanTypeService.findAll(search, '&sort=loan_type_name:asc', {skip: 0, limit: 100})
      let msg = 'A record already exist with the following values: '
      let existProps = []

      types = types.data

      if (this.editedIndex > -1) {
        types = types.filter((el)=> {
          return el.ID !== this.editedItem.ID
        })
      }

      types.forEach((el) => {
        if(this.editedItem.loan_type_name.toLowerCase() === el.loan_type_name.toLowerCase()) {
          existProps.push('Loan Type Name: '+this.editedItem.loan_type_name)
        }
      })
      if(existProps.length > 0) {
       this.$toast.warning(msg+existProps.join(', '), '', {position: 'topCenter', timeout: 7000})
        return true
      } 

      return false      
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    },


    onSelectAllFees(ev) {
      this.fees.forEach((el)=> {
        if(ev) {
          el.is_selected = true
        } else { 
          el.is_selected = false
        }
      })
      this.$forceUpdate()
    },

    onDeleteItemClicked(item) {
      this.$validator.reset({scope: 'delete'});
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      this.deleteItem = item
      this.otherLoanTypes = this.loanTypeState.loanTypes.filter((el)=> el.ID != item.ID)
      this.deleteDialog = true
   },

   async  deleteType() {
      
      if(!this.replacementID ) {
        this.$toast.error('Please select a replacement loan type', '', {position: 'topCenter'})
        return
      }
      this.$toast.question('Are you sure you want to delete this loan type?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [['<button><b>YES</b></button>', async (instance, toast) => {
              try{
                let index = this.items.indexOf(this.deleteItem)
                await this.deleteLoanType({index: index, replacementID: this.replacementID})
                this.$toast.warning('loan type has been removed!', '', {position: 'topCenter'})
                this.deleteDialog = false
                this.replacementID = false
              } catch(e) {
                console.log('saving/updating: ',e)
                this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
    },

    onSelectAllActions(ev) {
      this.checklist.forEach((el)=> {
        if(ev) {
          el.is_selected = true
        } else { 
          el.is_selected = false
        }
      })
      this.$forceUpdate()
    },

    checkAllCheck() {
      let len = this.checklist.length;
      let selectedLength = this.checklist.filter((el) => el.is_selected ).length;

      this.allDocumentCheckBox = selectedLength == len
    },

    checkAllFees() {
      let len = this.fees.length;
      let selectedLength = this.fees.filter((el) => el.is_selected ).length;

      this.allFeesCheckBox = selectedLength == len
    },

    filterFees(fees) {
      return fees.filter((fee) => {
        let search = this.feeSearch ? this.feeSearch.toLowerCase() : ''
        return fee.loan_fee_name.toLowerCase().indexOf(search) != -1
      })
    },


    filterActions(items) {
      return items.filter((fee) => {
        let search = this.documentSearch ? this.documentSearch.toLowerCase() : ''
        return fee.checklist_name.toLowerCase().indexOf(search) != -1
      })
    },
  }
}
</script>

 
