import httpClient from '@/common/http';

export default {

    // Loan Service #####
    findLoan(id) {
        return httpClient.request('get', '/edit-loan/findLoan/' + id);
    },

    // Loan Service #####
    findAllAccounts(progress, skip, limit, filter) {
        return httpClient.request('get', `/edit-loan/findAllAccounts/${progress}?skip=${skip}&limit=${limit}&filter=${filter}`);
    },

    updateLoanInformation(loanID, obj) {
        return httpClient.request('put', `/edit-loan/updateLoanInformation/${loanID}`, obj);
    },

    // Account Service #####
    findAccount(id) {
        return httpClient.request('get', '/edit-loan/findAccount/' + id);
    },

    // Risk Factor Service #####
    findAllRisks(filter) {
        return httpClient.request('get', `/edit-loan/findRiskFactors/?`+(filter? '?'+filter : ''));
    },

    // Loan Disbursement Service #####
    findDisbursedLoan(loanId) {
        return httpClient.request('get', `/edit-loan/findDisbursedLoans?loanId=${loanId}`);
    },

    // Settings Service #####
    findLoanSettings() {
        return httpClient.request('get', '/edit-loan/findLoanSettings');
    },

}