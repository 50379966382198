<template>
    <div>
        <!-- ADD DIALOG -->
        <v-layout flat color="white">

            <v-spacer></v-spacer>
            <v-dialog persistent v-model="dialog" max-width="650px">
                <v-toolbar dense color="primary" dark class="elevation-3">
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

                    <v-spacer></v-spacer>

                </v-toolbar>
                <v-card>

                    <v-card-text>
                        <v-layout align-content-space-between justify-space-between row wrap>
                            <v-flex xs6>
                                <va-input v-model="editedItem.collateral_name" class="mr-2"
                                    v-validate="'required|min:3|max:50'" data-vv-scope="collateral"
                                    :error-messages="errors.collect('collateral.collateral_name')" placeholder=" "
                                    label="Collateral Name" data-vv-validate-on="blur" data-vv-name="collateral_name"
                                    data-vv-as="collateral name" show-clean />
                            </v-flex>
                            <v-flex xs6>
                                <va-select v-model="editedItem.collateral_category" v-validate="'required'" class="ml-2"
                                    placeholder=" " label="Collateral Category" data-vv-scope="collateral"
                                    data-vv-as="collateral category"
                                    :error-messages="errors.collect('collateral.collateral_category')"
                                    :items="CollateralCatgories" :item-text="'text'" :item-value="'value'"
                                    data-vv-name="collateral_category" />
                            </v-flex>
                        </v-layout>
                    </v-card-text>

                    <v-card-actions class="ml-4 mr-4">
                        <v-spacer></v-spacer>
                        <v-btn flat @click="close">Cancel</v-btn>
                        <v-btn color="primary" @click="submit">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

        <!-- DELETE DIALOG -->
        <v-dialog v-model="deleteDialog" persistent max-width="400">
            <v-card>
                <v-card-title class="headline"></v-card-title>
                <v-card-text>
                    <h3 class="text-xs-center pb-3">This action is irreversible. You will not be able to retrieve data
                        after.</h3>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
                    <v-btn color="red darken-3" flat @click="deleteCollateral()">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <h2>Collateral Management</h2>
        <v-divider class="mb-4"></v-divider>
        <v-toolbar dense color="white" class="pa-0">
            <v-text-field v-model="search" solo label="Search" prepend-inner-icon="search">
            </v-text-field>

            <v-divider class="mr-2" vertical></v-divider>

            <v-btn @click="onDialogOpenedClicked" color="primary" dark class="mb-2">New Collateral</v-btn>
        </v-toolbar>
        <v-data-table :headers="headers" class="elevation-1" :items="filteredList" :loading="isLoading"
            :total-items="totalCount" :search="search">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <td>
                    <v-checkbox :disabled="isLoading" @change="onActivieChanged(props.item)"
                        v-model="props.item.is_active" primary hide-details></v-checkbox>
                </td>
                <td>{{ props.item.collateral_name }}</td>
                <td>{{ props.item.collateral_category }}</td>
                <td>{{ props.item.date_created }}</td>
                <td>
                    <v-icon title="edit" small class="mr-2" @click="onEditItemClicked(props.item)">
                        edit
                    </v-icon>
                    <v-icon title="delete" small class="mr-2" @click="onDeleteItemClicked(props.item)">
                        delete
                    </v-icon>
                </td>
            </template>
            <!-- <template v-slot:expand="props">
        <v-card flat>
          <v-card-text>Peek-a-boo!</v-card-text>
        </v-card>
      </template> -->
            <template slot="no-data">

            </template>

        </v-data-table>
    </div>
</template>

<style type="css" scoped>
    h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
    import {
        mapState,
        mapGetters,
        mapMutations,
        mapActions
    } from 'vuex'
    import VaInput from '@/components/form-components/VaInput.vue'
    import VaSelect from '@/components/form-components/VaSelect.vue'
    import PaginationMixin from '@/mixins/PaginationMixin'
    import collateralService from '@/services/collateral-service'

    export default {
        components: {
            VaInput,
            VaSelect
        },

        mixins: [PaginationMixin],

        created() {
            this.fetchData()
        },

        beforeDestroy() {
            this.$toast.destroy()
        },

        data() {
            return {
                dialog: false,
                isLoading: true,
                search: '',
                headers: [{
                        text: 'Active',
                        align: 'left',
                        sortable: false,
                        value: 'is_active'
                    },
                    {
                        text: 'Collateral Name',
                        align: 'left',
                        sortable: false,
                        value: 'collateral_name'
                    },
                    {
                        text: 'Collateral Category',
                        align: 'left',
                        sortable: false,
                        value: 'collateral_category'
                    },
                    {
                        text: 'Date Created',
                        value: 'date_created',
                        sortable: false
                    },
                    {
                        text: 'Action',
                        value: '',
                        sortable: false
                    },
                ],
                items: [],
                CollateralCatgories: [{
                        value: 'Motor Vehicle',
                        text: 'Motor Vehicle'
                    },
                    {
                        value: 'Real Estate',
                        text: 'Real Estate'
                    },
                    {
                        value: 'Cash',
                        text: 'Cash'
                    }
                ],
                editedIndex: -1,
                editedItem: {},
                provinces: [],
                message: {
                    visible: false,
                    title: '',
                    content: '',
                    type: 'success'
                },

                deleteItem: {},
                deleteDialog: false,
            }
        },

        computed: {

            formTitle() {
                return this.editedIndex === -1 ? 'Add New Collateral' : 'Edit Collateral'
            },

            filteredList() {
                return this.items.filter(f => {
                    return f.collateral_name.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },

        methods: {
            ...mapMutations([
                'SHOW_UNEXPECTED_ERROR_DIALOG'
            ]),

            close() {
                this.$validator.reset({
                    scope: 'collateral'
                })
                this.dialog = false
                this.deleteDialog = false
                this.editedItem = Object.assign({}, )
                this.editedIndex = -1
                this.fetchData()
            },

            onDialogOpenedClicked() {
                this.resetState()
                this.$validator.reset({
                    scope: 'collateral'
                })
                this.dialog = true
            },

            onEditItemClicked(item) {
                this.items.forEach((el, i) => {
                    if (item.ID === el.ID) {
                        this.editedIndex = i
                    }
                })
                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            async fetchData() {
                try {
                    let res = await collateralService.findAll()
                    this.items = res.data
                    this.totalCount = res.total
                } catch (e) {
                    console.log('fetching data: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                } finally {
                    this.isLoading = false
                }
            },

            async submit() {
                this.resetState()
                let isValid = await this.$validator.validateAll("collateral")
                if (!isValid) {
                    this.$toast.error('Please check to ensure that all fields are valid.', '', {
                        position: 'topCenter'
                    })
                    return
                }

                await this.save()
            },

            async save() {
                try {
                    if (this.editedIndex > -1) {
                        await collateralService.update(this.editedItem.ID, this.editedItem)
                        this.$toast.success('Successfully updated record!', '', {
                            position: 'topCenter'
                        })
                    } else {
                        await collateralService.create(this.editedItem)
                        this.$toast.success('Successfully inserted record!', '', {
                            position: 'topCenter'
                        })
                    }
                    this.close()
                } catch (e) {
                    console.log('saving/updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },

            resetState() {
                this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
            },

            async onActivieChanged(item) {
                try {
                    await collateralService.changeIsActive(item.ID, item.is_active)

                    this.$toast.success('Successfully updated record!', '', {
                        position: 'topCenter'
                    })
                } catch (e) {
                    item.is_active = !item.is_active
                    console.log('saving/updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },

            onDeleteItemClicked(item) {
                this.$validator.reset({
                    scope: 'delete'
                });
                this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
                this.deleteItem = item
                this.deleteDialog = true
            },

            async deleteCollateral() {
                try {
                    await collateralService.delete(this.deleteItem.ID)
                    this.$toast.success('Successfully deleted record!', '', {
                        position: 'topCenter'
                    })

                    this.close()
                } catch (e) {
                    console.log('deleting/updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },
        }
    }
</script>