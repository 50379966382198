<template>
  <div>
    <v-card class="pa-2 mb-3">
      <v-card-title class="title font-weight-bold">
        <span>Insurance Waiver Policy Settings</span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reset()">
          <v-icon left>add</v-icon>Add Level
        </v-btn>
      </v-card-title>
    </v-card>

    <v-layout wrap row>
      <v-flex md12 sm12>
        <!-- display if there is no data -->
        <v-card v-if="items.length == 0">
          <v-flex xs12 pt-5 class="text-xs-center">
            <v-icon color="primary" size="70">fa-folder-open</v-icon>
          </v-flex>
          <v-flex xs12 pt-2 pb-4>
            <h2 class="text-xs-center">Nothing Here</h2>
          </v-flex>
        </v-card>

        <!-- display if data is able -->
        <v-card class="pa-2 mb-3" v-else>
          <v-data-table
            :headers="header"
            :items="items"
            :loading="waiverSettingsStore.loading"
            class="v-table"
            item-key="ID"
          >
            <template v-slot:items="props">
              <td>
                <v-switch
                  v-model="props.item.waiver_active"
                  @change="activateLevel(props.item.ID, $event)"
                  :label="props.item.waiver_active == false ? 'Off' : 'On'"
                ></v-switch>
              </td>
              <td>{{props.item.waiver_level}}</td>
              <td>{{props.item.motor_vehicle | currency }}</td>
              <td>{{props.item.unsecured | currency }}</td>
              <td>{{props.item.cash_secured | currency }}</td>
              <td>{{props.item.real_estate | currency }}</td>
              <td>{{props.item.chattels | currency }}</td>
              <td>
                <v-icon @click="setEdit(props.item)">edit</v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- form dialog -->
    <v-dialog v-model="form_dialog" width="50%" persisitent scrollable>
      <v-card>
        <v-card-title class="primary layout justify-center">
          <span class="headline white--text">Enter Level Details</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex xs12 class="pa-1">
                  <v-text-field
                    prepend-icon="edit"
                    v-model="form_data.waiver_level"
                    label="Waiver Level*"
                    type="number"
                    :disabled="true"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    prepend-icon="attach_money"
                    v-model="form_data.motor_vehicle"
                    label="Motor Vehicle Loan Amount*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    prepend-icon="attach_money"
                    v-model="form_data.unsecured"
                    label="Unsecured Loan Amount*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    prepend-icon="attach_money"
                    v-model="form_data.cash_secured"
                    label="Cash Secured Loan Amount*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    prepend-icon="attach_money"
                    v-model="form_data.real_estate"
                    label="Real Estate Loan Amount*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 class="pa-1">
                  <v-text-field
                    prepend-icon="attach_money"
                    v-model="form_data.chattels"
                    label="Chattels Loan Amount*"
                    type="number"
                    :rules="moneyRules"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <small>*all fields are required</small>
          </v-form>
        </v-card-text>

        <v-card-actions class="headline grey lighten-2">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" flat @click="close()"> Close <v-icon dark left>remove_circle</v-icon></v-btn>
          <v-btn color="blue darken-1" flat @click="validateForm()" :disabled="!valid"> Submit <v-icon dark right>check_circle</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "InsuranceWaiverPolicySettings",
  data() {
    return {
      form_data: {},
      moneyRules: [
        v => !!v || "Amount Required",
        v => (v && parseInt(v) > 100) || "Amount must be greater than 100"
      ],
      header: [
        {
          text: "Off / On",
          value: "waiver_active",
          align: "left",
          sortable: false
        },
        {
          text: "Waiver Level",
          value: "waiver_level",
          align: "left",
          sortable: false
        },
        {
          text: "Motor Vehicle",
          value: "motor_vehicle",
          align: "left",
          sortable: false
        },
        {
          text: "Unsecured",
          value: "unsecured",
          align: "left",
          sortable: false
        },
        {
          text: "Cash Secured",
          value: "cash_secured",
          align: "left",
          sortable: false
        },
        {
          text: "Real Estate",
          value: "real_estate",
          align: "left",
          sortable: false
        },
        {
          text: "Chattels",
          value: "chattels",
          align: "left",
          sortable: false
        },
        {
          text: "Actions",
          value: "actions",
          align: "left",
          sortable: false
        }
      ],
      form_dialog: false,
      items: [],
      valid: false
    };
  },
  methods: {
    ...mapGetters([
        "getAllInsuranceLevels"
    ]),
    ...mapActions([
        "addInsuranceWaiver",
        "editInsuranceWaiver",
        "getInsuranceWaiver",
        "setInsuranceWaiverStatus"
    ]),
    reset() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.errors.clear();
      this.form_data.ID = "";
      this.form_data.waiver_level = this.items.length + 1;
      this.form_data.waiver_active = false;
      this.form_dialog = true;
    },
    close() {
      this.form_dialog = false;
    },
    setEdit(data){
      this.form_data = {
                          ID: data.ID,
                          waiver_level: data.waiver_level,
                          motor_vehicle: data.motor_vehicle,
                          chattels: data.chattels,
                          unsecured: data.unsecured,
                          real_estate: data.real_estate,
                          cash_secured: data.cash_secured
                      };
      this.form_dialog = true;
    },
    async validateForm() {
      if (this.$refs.form.validate()) {
        if (this.form_data.ID == "") {
          this.addData(this.form_data);
        } else {
          this.editData(this.form_data);
        }
      } else {
        this.$toast.error("Please complete the form with valid data.", "", {
          position: "topCenter"
        });
      }
    },
    async addData(obj) {
        try {
            const result = await this.addInsuranceWaiver(obj);
            if (result) {
            await this.getData();
            this.$toast.success("Waiver Level Added Successfully", "", { position: "topCenter" });
            this.reset();
            } else {
            this.$toast.error("Operation Failed", "", { position: "topCenter" });
            }
        } catch (error) {
            try {
            const err = await error;
            const msg = err.response.data.error;
            this.$toast.error(`${msg}`, "", { position: "topCenter" });
            } catch (e) {
            this.$toast.error(
                "Network Error. Please check your internet connection",
                "",
                { position: "topCenter" }
            );
            }
        }
      },
    async editData(obj) {
          try {
              const result = await this.editInsuranceWaiver(obj);
              if (result) {
              this.getData();
              this.$toast.success("Waiver Level Updated Successfully", "", { position: "topCenter" });
              this.close();
              } else {
              this.$toast.error("Operation Failed", "", { position: "topCenter" });
              }
          } catch (error) {
              try {
              const err = await error;
              const msg = err.response.data.error;
              this.$toast.error(`${msg}`, "", { position: "topCenter" });
              } catch (e) {
              this.$toast.error(
                  "Network Error. Please check your internet connection",
                  "",
                  { position: "topCenter" }
              );
              }
          }
        },
    async getData(){
          try {
              const result = await this.getInsuranceWaiver();
              if (result) {
                this.items = this.waiverSettingsStore.insurance;
              } else {
                this.items = [];
              }
          } catch (error) {
              try {
              const err = await error;
              const msg = err.response.data.error;
              this.$toast.error(`${msg}`, "", { position: "topCenter" });
              } catch (e) {
              this.$toast.error(
                  "Network Error. Please check your internet connection",
                  "",
                  { position: "topCenter" }
              );
              }
          }
        },
    async activateLevel(id, value) {
          let data = {
                      ID:id,
                      value:value
                    };
          try {
              const result = await this.setInsuranceWaiverStatus(data);
              if (result) {
                if(value == false){
                    this.$toast.success("Waiver Level Deactivated", "", { position: "topCenter" });
                } else {
                    this.$toast.success("Waiver Level Activated", "", { position: "topCenter" });
                }
              }
          } catch (error) {
              try {
              const err = await error;
              const msg = err.response.data.error;
              this.$toast.error(`${msg}`, "", { position: "topCenter" });
              } catch (e) {
              this.$toast.error(
                  "Network Error. Please check your internet connection",
                  "",
                  { position: "topCenter" }
              );
              }
          }
          }
  },
  
  watch: {
    form_dialog(newVal, oldVal) {
      if(!newVal) {
        this.$refs.form.reset();
        this.errors.clear();
      }
    }
  },

  computed: {
    ...mapState(["waiverSettingsStore"])
  },
  mounted() {
    this.getData();
  }
};
</script>

<style scoped>
.toggle-switch {
  width: 100px;
  height: 37px !important;
  margin: 0px !important;
  border-radius: 5px;
  padding-left: 10px !important;
  background: #f7f7f7 !important;
  border: 2px solid #e3e3e3;
}

.switches {
  /* width:40px !important; 
        margin: 0px auto; */
  padding-top: 15px;
  padding-bottom: -10px;
}
</style>