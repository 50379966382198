<template>
	<div>
		<!-- Instructions Dialog -->
		<template>
			<div class="text-xs-center">
				<v-dialog v-model="dialog" width="500">
					<v-card>
						<v-card-title class="title" primary-title>
							<v-icon class="mr-2">list</v-icon> {{ selectedItem.title }}:
							Instructions
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text v-html="selectedItem.instructions"> </v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" flat @click="dialog = false">
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</template>

		<!-- Exceptions Dialog -->
		<template>
			<div class="text-xs-center">
				<v-dialog v-model="exceptions_dialog" width="800">
					<v-card>
						<v-card-title class="title" primary-title>
							<v-icon class="mr-2">list</v-icon> The following accounts where
							not updated :
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-data-table
								:headers="headers"
								:items="this.ux.exceptions"
								class="elevation-1"
							>
								<template slot="items" slot-scope="props">
									<td>{{ props.item.account }}</td>
									<td>{{ props.item.exception }}</td>
								</template>
							</v-data-table>
						</v-card-text>
						<v-divider></v-divider>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn color="primary" flat @click="exceptions_dialog = false">
								Close
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>
			</div>
		</template>

		<!-- Upload Options -->
		<v-card>
			<v-toolbar color="primary" dark flat>
				<v-toolbar-title>Mass Upload</v-toolbar-title>
			</v-toolbar>

			<v-card-text v-if="ux.processing">
				<div class="mx-3">
					<p class="title blinking mt-3 text-md-center">
						<span class="text-capitalize">{{ ux.option }}</span
						>: {{ ux.progress }}
					</p>
					<v-progress-linear
						v-if="uploadProgress < 100"
						v-model="uploadProgress"
					></v-progress-linear>
					<v-progress-linear :indeterminate="true" v-else></v-progress-linear>
				</div>
			</v-card-text>

			<v-card-text v-if="ux.success" class="text-md-center mt-4">
				<div class="mx-3 d-inline-flex">
					<v-icon x-large color="green">fas fa-check-circle</v-icon>
					<p class="display-1 ml-2">Successfully Uploaded</p>
				</div>
				<p class="title">Mass Upload successful. Processing results below:</p>
				<div class="text-xs-center mx-auto">
					<span v-if="ux.exceptions">
						<v-chip
							class="ma-2"
							color="warning"
							@click="exceptions_dialog = true"
							outlined
						>
							Exceptions
						</v-chip>
						<br />
					</span>
					<span v-if="ux.totalPersons">
						Persons: <b>{{ ux.totalPersons }}</b> <br
					/></span>
					<span v-if="ux.totalLoans">
						Total Current Balance: <b>{{ asMoney(ux.totalLoans) }}</b
						><br
					/></span>
					<span v-if="ux.totalCC"
						>Total Cash Collateral Amount: <b>{{ asMoney(ux.totalCC) }}</b
						><br
					/></span>
					<span v-if="ux.totalMVC"
						>Total Motor Vehicle Collateral Amount:
						<b>{{ asMoney(ux.totalMVC) }}</b
						><br
					/></span>

					<span v-if="ux.totalREC"
						>Total Real Estate Collateral Amount:
						<b>{{ asMoney(ux.totalREC) }}</b
						><br
					/></span>

					<span v-if="ux.countTransactions"
						>Count Transactions: <b>{{ ux.countTransactions }}</b
						><br
					/></span>
					<span v-if="ux.sumTransactions"
						>Sum Transactions: <b>{{ asMoney(ux.sumTransactions) }}</b> <br
					/></span>
				</div>
			</v-card-text>

			<v-card-text v-if="ux.error" class="text-md-center mt-4">
				<div class="mx-3 d-inline-flex">
					<v-icon x-large color="red">fas fa-exclamation-circle</v-icon>
					<p class="display-1 ml-2">Server Error</p>
				</div>
				<p class="title">
					Failed to <b>process file</b> on server. Try checking the file
					requirements and try again.
				</p>
				<div class="text-md-center">
					<li>Ensure the file provided for upload is of CSV file format.</li>
					<li>
						Column headers should be placed at ROW 1 - the top of the file.
					</li>
					<li>
						All dates in file should follow the YYYY-MM-DD date format for
						processing.
					</li>
				</div>
			</v-card-text>
			<v-card-text>
				<v-list two-line subheader>
					<template v-for="(item, index) in uploadMethods">
						<v-list-tile :key="item.title" avatar>
							<v-list-tile-action>
								<v-list-tile-action-text>
									<input
										accept=".csv"
										class="inputfile"
										type="file"
										id="file"
										@click="clearFile"
										ref="file"
										v-on:change="handleFileUpload(index)"
									/>
								</v-list-tile-action-text>
							</v-list-tile-action>

							<v-list-tile-content>
								<v-list-tile-title>{{ item.title }}</v-list-tile-title>
								<v-list-tile-sub-title
									>{{ item.subtitle
									}}<v-icon @click="showInstructions(item)" class="ml-3"
										>info</v-icon
									>
								</v-list-tile-sub-title>
							</v-list-tile-content>
							<div v-show="item.show">
								<v-btn
									:disabled="validateStartUpload"
									color="green"
									class="white--text d-block"
									v-on:click="validateFile(item)"
									depressed
								>
									<v-icon left>cloud_upload</v-icon>
									Start Upload
								</v-btn>
								<span class="d-block caption red--text pl-2" v-if="validateStartUpload">
									Please fix file errors
								</span>
							</div>
						</v-list-tile>
						<v-divider
							v-if="index + 1 < uploadMethods.length"
							:key="index"
						></v-divider>
					</template>
				</v-list>
			</v-card-text>
		</v-card>

		<!-- Loading overlay -->
		<v-dialog v-model="loadingDialog" persistent width="300">
			<v-card color="primary" dark>
				<v-card-text>
					Processing file...
					<v-progress-linear
            :value="fileUploadProgress"
						color="white"
						class="mb-0"
					></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>

		<!-- Dialog used to show that error are missing from file -->
		<v-dialog
			scrollable
			fullscreen
			persistent
			v-model="transactionUploadDialog"
			width="900"
		>
			<v-card>
				<v-card-title class="grey lighten-2" primary-title>
					<div>
            <h3>File Issues</h3>
            <div>
					    <span class="font-weight-regular subheading">There seems to be an issue with the file uploaded just now</span>
            </div>
          </div>
				</v-card-title>

				<v-card-text>
          <template v-if="missingHeaders.length > 0">
            <v-card style="margin: 10px 0px 310px 0px">
              <v-card-title>
                <div>
                  <h3>Missing Headers</h3>
                  <div>
                    <span class="font-weight-regular subheading">This Document is missing headers that are required for upload</span>
                  </div>
                </div>
              </v-card-title>
              <v-card-text class="pt-0" >
                <v-container 
                  style="max-height: 350px"
                  class="scroll-y pa-0"
                >
                  <v-list>
                    <v-list-tile v-for="(missingHeader, index) in missingHeaders" :key="index">
                      <v-list-tile-avatar>
                        <v-icon size="20">fas fa-chevron-circle-right</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        {{ missingHeader }}
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-container>
              </v-card-text>
            </v-card>
          </template>

          <template v-if="missingData" >
            <v-card class="mb-5">
              <v-card-title>
                <div>
                  <h3>Empty Cells</h3>
                  <div>
                    <span class="font-weight-regular subheading">These cells in the csv files are empty</span>
                  </div>
                </div>
              </v-card-title>
              <v-card-text>
                <v-container
                  style="max-height: 450px"
                  class="scroll-y pa-0"
                >
                    <p class="font-weight-regular subheading">
                      Use the table below to check and correct your file. The empty cells
                      are highlighted in red
                    </p>
                    <v-data-table
                      v-if="missingData"
                      :rows-per-page-items="[
                        emptyRowsPerPage,
                        { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
                      ]"
                      :headers="missingDataHeaders"
                      :items="missingDataRows"
                      class="mb-4 elevation-1"
                      hide-actions
                    >
                      <template #items="{item}">
                        <td>{{ item.rowId }}</td>
                        <td :class="item.branch ? '' : 'red lighten-3'">
                          {{ item.branch }}
                        </td>
            
                        <template v-if="
                        'currentBalance' in item &&
                        'maturityDate' in item &&
                        'accountNumber' in item &&
                        'originationDate' in item &&
                        'dob' in item "
                        >
                          <td :class="item.currentBalance ? '' : 'red lighten-3'">
                            {{ item.currentBalance }}
                          </td>
                          <td :class="item.maturityDate ? '' : 'red lighten-3'">
                            {{ item.maturityDate }}
                          </td>
                          <td :class="item.accountNumber ? '' : 'red lighten-3'">
                            {{ item.accountNumber }}
                          </td>
                          <td :class="item.loanRefNo ? '' : 'red lighten-3'">
                            {{ item.loanRefNo }}
                          </td>
                          <td :class="item.dob ? '' : 'red lighten-3'">
                            {{ item.dob }}
                          </td>
                          <td :class="item.originationDate ? '' : 'red lighten-3'">
                            {{ item.originationDate }}
                          </td>
                        </template>
                        <template v-if="'transId' in item && 'transAmount' in item">
                          <td :class="item.transId ? '' : 'red lighten-3'">
                            {{ item.transId }}
                          </td>
                          <td :class="item.transAmount ? '' : 'red lighten-3'">
                            {{ item.transAmount }}
                          </td>
                        </template>
                      </template>
                    </v-data-table>
                    <br />
                </v-container>
              </v-card-text>
            </v-card>
          </template>

          <template v-if="incorrectBranches.length > 0">
            <v-card>
              <v-card-title>
                <div>
                  <h3>Incorrect Branch</h3>
                  <div>
                    <span class="font-weight-regular subheading">These branches are not a part of the list of branches for your company</span>
                  </div>
                </div>
              </v-card-title>
              <v-card-text class="pt-0" >
                <v-container 
                  style="max-height: 200px"
                  class="scroll-y pa-0"
                >
                  <v-list>
                    <v-list-tile v-for="(branch, index) in incorrectBranches" :key="index">
                      <v-list-tile-avatar>
                        <v-icon size="20">fas fa-chevron-circle-right</v-icon>
                      </v-list-tile-avatar>
                      <v-list-tile-content>
                        {{ branch }}
                      </v-list-tile-content>
                    </v-list-tile>
                  </v-list>
                </v-container>
              </v-card-text>
            </v-card>
          </template>

          <template v-if="incorrectDate">
            <v-card class="my-5">
              <v-card-title>
                <div>
                  <h3>Incorrect Date Format</h3>
                  <div>
                    <span class="font-weight-regular subheading">These dates don't match the correct date format. Ensure the month is between 1-12 and follows the format of: "DD/MM/YYYY" or "YYYY/MM/DD"</span>
                  </div>
                </div>
              </v-card-title>

              <v-card-text>
                <v-container
                style="max-height: 650px"
                class="scroll-y pa-0"
                >
                  <p v-if="missingData" class="font-weight-regular subheading">
                    Use the table below to check and correct your file. The wrong dates
                    are highlighted in red
                  </p>
                  <v-data-table
                    :rows-per-page-items="[
                      datesRowsPerPage,
                      { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
                    ]"
                    :headers="incorrectDateHeaders"
                    :items="incorrectDateRows"
                    class="mb-4 elevation-1"
                    hide-actions
                  >
                    <template #items="{item}">
                      <td>{{ item.rowId }}</td>
                      <td :class="item.maturityDate.isValid ? '' : 'red lighten-3'">
                        {{ item.maturityDate.date }}
                      </td>
                      <td :class="item.dob.isValid ? '' : 'red lighten-3'">
                        {{ item.dob.date }}
                      </td>
                      <td :class="item.originationDate.isValid ? '' : 'red lighten-3'">
                        {{ item.originationDate.date }}
                      </td>
                    </template>
                  </v-data-table>
                </v-container>
              </v-card-text>
            </v-card>
          </template>
					<br/>
					Are you sure you want to proceed with the upload?
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="error" flat @click="clearFile()">
						Cancel
					</v-btn>
					<v-btn
						color="primary"
						class="elevation-0"
						@click="transactionUploadDialog = false"
					>
						Continue
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<style scoped>
.inputfile {
	font-size: 16px;
}
</style>

<script scoped>
import MassUploadService from "@/services/mass-upload-service";
import { mapMutations } from "vuex";
import BranchService from "@/services/branch-service";
import moment from 'moment';
import Vue from 'vue';

export default {
	data() {
		return {
      fileUpload: "",
      fileUploadProgress: 0,
			uploadProgress: 0,
			ux: {
				processing: false,
				success: false,
				error: false,
				option: "",
				progress: "",

				totalPersons: 0,
				totalLoans: 0,
				totalCC: 0,
				totalMVC: 0,
				totalREC: 0,
			},

			file: "",
			totalPersons: 0,
			totalLoans: 0,
			invalidRecords: [],
			LoaderDialog: false,
			showButton: false,
			dialogInfo: {
				title: "",
				message: "",
			},
			loader: false,
			error: false,
			instructions: "",
			selectedItem: "",
			dialog: false,
			exceptions_dialog: false,

			uploadMethods: [
				{
					title: "Mass Update",
					subtitle: "Creates or Updates : Persons, Accounts, Loans.",
					option: "all",
					instructions:
						"<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li><li>TRN</li>",
					show: false,
				},
				{
					title: "Person Upload",
					subtitle: "Creates or Updates : Persons ( Demography ) and Accounts.",
					option: "persons",
					instructions:
						"<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>TRN</li>",
					show: false,
				},
				{
					title: "Loan Upload/Update",
					subtitle: "Creates or Updates : Loans.",
					option: "loans",
					instructions:
						"<h3><strong>* Required Fields for CSV :</strong></h3> <li>Branch_Name</li><li>Account_Number</li><li>Loan_Ref_No.</li>",
					show: false,
				},
				{
					title: "Loan Collaterals",
					subtitle: "Creates or Updates : Loan Collaterals.",
					option: "collaterals",
					instructions:
						"<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li><li>Loan_Ref_No.</li><li>Cash_Collateral</li><li>Motor_Vehicle_Collateral</li><li>Real_Estate_Collateral</li>",
					show: false,
				},
				{
					title: "Transactions Upload",
					subtitle: "Creates or Updates : Transactions and Sub-Accounts.",
					option: "transactions-upload",
					instructions:
						"<h3><strong>* Required Fields for CSV :</strong></h3> <li>Account_Number</li>",
					show: false,
				},
			],

			headers: [
				{
					text: "Account Number",
					sortable: false,
					value: "account",
				},
				{
					text: "Error Field",
					value: "exception",
				},
			],
			// Transaction Upload varaibles
			loadingDialog: false,
			transactionUploadDialog: false,
			fileUploadErrorText: "",
			// Varibale used to state whether data is missing from the required cells or not
			//If data is missing, displays a table
			missingData: false,
			incorrectData: false,
			missingDataHeaders: [],
			missingDataRows: [],
      		missingHeaders: [],
      		branches: [],
      		incorrectBranches: [],
      		incorrectDate: false,
      		incorrectDateHeaders: [],
      		incorrectDateRows: [],
      		datesRowsPerPage: 100,
      		emptyRowsPerPage: 100
		};
	},
	computed:{
		validateStartUpload(){
			return this.missingData || this.incorrectData
			||this.incorrectDate || this.missingHeaders.length > 0 
			|| this.incorrectBranches.length > 0
		}
	},

	async created() {
		//Get all branches
		const { data } = await BranchService.findAll();
		this.branches = data.map((b) => b.branch_name);
	},

	methods: {
		...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

		/**
		 * Method used to clear all files uploaded by the user
		 */
		clearFile() {
			//Clear file
			this.file = null;
			//Clear `show` value on the uploaded methods
			this.uploadMethods.forEach((m) => (m.show = false));
			//Clear each input file ref
			this.$refs.file.forEach((f) => (f.value = null));
			//Clear validation message and close dialog
			this.transactionUploadDialog = false;
			this.fileUploadErrorText = "";
			//Clear mass upload fields
			this.missingHeaders = [];
        	this.missingDataRows = [];
        	this.incorrectDateRows = [];
        	this.incorrectBranches = [];
        	this.fileUpload = "";
        	this.fileUploadProgress = 0;
        	this.incorrectData = false;
        	this.missingData = false;
        	this.incorrectDate = false;
		},

    async loadMassUploadFile(file, offset = 0) {
      try {
        if(!file) return;
        const CHUNK_SIZE = file.size / 250;
        const fileChunk = file.slice(offset, offset + CHUNK_SIZE);
        const fileReader = new FileReader();

        fileReader.onload = (event) => { 
          this.fileUpload = this.fileUpload + event.target.result;
          this.fileUploadProgress = (offset / file.size) * 100;
          if(CHUNK_SIZE + offset > file.size) {
            this.loadingDialog = false;
            this.cleanMassUploadCSV(this.fileUpload);
          }
        };
        fileReader.readAsText(fileChunk);
        if( CHUNK_SIZE + offset <= file.size)
          this.loadMassUploadFile(file, CHUNK_SIZE + offset);
      } catch (error) {
        this.loadingDialog = false
      }
    },

    cleanMassUploadCSV(fileData) {
      try {
        // stop function if no file is passed
        if (!fileData) return;
        //Put each row of the file in an array
        const rows = fileData.split("\n");
        //Store the header of the file in an array
        const headers = rows[0].split(/(?!\B"[^"]*),(?![^"]*"\B)/);
        //Get the position of the required fields from the header
        const branchIndex = this.findHeaderIndex(headers, "branch name");
        const currentBalanceIndex = this.findHeaderIndex(headers, "current balance");
        const maturityDateIndex = this.findHeaderIndex(headers, "maturity date");
        const accountNumberIndex = this.findHeaderIndex(headers, "account number");
        const loanRefNoIndex = this.findHeaderIndex(headers, "loan ref no.");
        const dobIndex = this.findHeaderIndex(headers, "date of birth");
        const originationDateIndex = this.findHeaderIndex(headers, "origination date");
        
        if(branchIndex == -1) 
          this.missingHeaders.push("Branch Name");
        if(currentBalanceIndex == -1) 
          this.missingHeaders.push("Current Balance");
        if(accountNumberIndex == -1) 
          this.missingHeaders.push("Account Number");
        if(originationDateIndex == -1)
          this.missingHeaders.push("Origination Date");
        if(maturityDateIndex == -1)
          this.missingHeaders.push("Maturity Date");
        if(loanRefNoIndex == -1)
          this.missingHeaders.push("Loan Ref No.");
        if(dobIndex == -1)
          this.missingHeaders.push("Date of Birth");

        if( this.missingHeaders.length > 0){
          this.transactionUploadDialog = true; 
          return;
        }
        
        rows.forEach((row, rowIndex) => {
          if(rowIndex == 0 || rowIndex == rows.length - 1)
            return;

          const cells = row.split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/);
          
          if(
            !cells[branchIndex] ||
            !cells[currentBalanceIndex] ||
            !cells[maturityDateIndex] ||
            !cells[accountNumberIndex] ||
            !cells[loanRefNoIndex] ||
            !cells[dobIndex] ||
            !cells[originationDateIndex]
          ) {
            this.missingData = true;
            this.incorrectData = true;
            this.missingDataHeaders = [
              { text: "Row#", value: "rowId" },
              { text: "Branch Name", value: "branch" },
              { text: "Current Balance", value: "currentBalance" },
              { text: "Maturity Date", value: "maturityDate" },
              { text: "Account Number", value: "accountNumber" },
              { text: "Loan Ref No.", value: "loanRefNo" },
              { text: "Date of Birth", value: "dob" },
              { text: "Origination Date", value: "orignationDate" },
            ];
            this.missingDataRows.push({
              rowId: rowIndex + 1,
              branch: cells[branchIndex],
              currentBalance: cells[currentBalanceIndex],
              maturityDate: cells[maturityDateIndex],
              accountNumber: cells[accountNumberIndex],
              loanRefNo: cells[loanRefNoIndex],
              dob: cells[dobIndex],
              originationDate: cells[originationDateIndex],
            });
            this.emptyRowsPerPage = this.missingDataRows.length
          }

          if(
            cells[maturityDateIndex] ||
            cells[dobIndex] ||
            cells[originationDateIndex]
          ) {
            let isMaturityDateValid = false;
            let isDOBValid = false;
            let isOriginationDateValid = false;
            
            if(
              moment(cells[maturityDateIndex], "DD/MM/YYYY").isValid() ||
              moment(cells[maturityDateIndex], "DD-MM-YYYY").isValid() ||
              moment(cells[maturityDateIndex], "YYYY-MM-DD").isValid() ||
              moment(cells[maturityDateIndex], "YYYY/MM/DD").isValid() ||
              moment(cells[maturityDateIndex], "DD/M/YYYY").isValid() ||
              moment(cells[maturityDateIndex], "D/M/YYYY").isValid() ||
              moment(cells[maturityDateIndex], "D/MM/YYYY").isValid() ||
              moment(cells[maturityDateIndex], "D-M-YYYY").isValid() ||
              moment(cells[maturityDateIndex], "D-MM-YYYY").isValid() ||
              moment(cells[maturityDateIndex], "DD-M-YYYY").isValid() ||
              moment(cells[maturityDateIndex], "YYYY-M-D").isValid() ||
              moment(cells[maturityDateIndex], "YYYY-MM-D").isValid() ||
              moment(cells[maturityDateIndex], "YYYY-M-DD").isValid() ||
              moment(cells[maturityDateIndex], "YYYY/M/D").isValid() ||
              moment(cells[maturityDateIndex], "YYYY/MM/D").isValid() 
            ) {
              isMaturityDateValid = true;
            }

            if(
              moment(cells[dobIndex], "DD/MM/YYYY").isValid() ||
              moment(cells[dobIndex], "DD-MM-YYYY").isValid() ||
              moment(cells[dobIndex], "YYYY-MM-DD").isValid() ||
              moment(cells[dobIndex], "YYYY/MM/DD").isValid() ||
              moment(cells[dobIndex], "DD/M/YYYY").isValid() ||
              moment(cells[dobIndex], "D/M/YYYY").isValid() ||
              moment(cells[dobIndex], "D/MM/YYYY").isValid() ||
              moment(cells[dobIndex], "D-M-YYYY").isValid() ||
              moment(cells[dobIndex], "D-MM-YYYY").isValid() ||
              moment(cells[dobIndex], "DD-M-YYYY").isValid() ||
              moment(cells[dobIndex], "YYYY-M-D").isValid() ||
              moment(cells[dobIndex], "YYYY-MM-D").isValid() ||
              moment(cells[dobIndex], "YYYY-M-DD").isValid() ||
              moment(cells[dobIndex], "YYYY/M/D").isValid() ||
              moment(cells[dobIndex], "YYYY/MM/D").isValid() ||
              moment(cells[dobIndex], "YYYY/M/D").isValid()
            ) {
              isDOBValid = true;
            }

            if(
              moment(cells[originationDateIndex], "DD/MM/YYYY").isValid() ||
              moment(cells[originationDateIndex], "DD-MM-YYYY").isValid() ||
              moment(cells[originationDateIndex], "YYYY-MM-DD").isValid() ||
              moment(cells[originationDateIndex], "YYYY/MM/DD").isValid() ||
              moment(cells[originationDateIndex], "DD/M/YYYY").isValid() ||
              moment(cells[originationDateIndex], "D/M/YYYY").isValid() ||
              moment(cells[originationDateIndex], "D/MM/YYYY").isValid() ||
              moment(cells[originationDateIndex], "D-M-YYYY").isValid() ||
              moment(cells[originationDateIndex], "D-MM-YYYY").isValid() ||
              moment(cells[originationDateIndex], "DD-M-YYYY").isValid() ||
              moment(cells[originationDateIndex], "YYYY-M-D").isValid() ||
              moment(cells[originationDateIndex], "YYYY-MM-D").isValid() ||
              moment(cells[originationDateIndex], "YYYY-M-DD").isValid() ||
              moment(cells[originationDateIndex], "YYYY/M/D").isValid() ||
              moment(cells[originationDateIndex], "YYYY/MM/D").isValid() ||
              moment(cells[originationDateIndex], "YYYY/M/D").isValid()
            ) {
              isOriginationDateValid = true;
            }

            if(!isMaturityDateValid || !isDOBValid || !isOriginationDateValid) {
              this.incorrectData = true;
              this.incorrectDate = true;
              this.incorrectDateHeaders = [
                {text: "Row#", value: "rowId"},
                {text: "Maturity Date", value: "maturityDate"},
                {text: "Date Of Birth", value: "dob"},
                {text: "Origination Date", value: "originationDate"}
              ]
              this.incorrectDateRows.push(
                {
                  rowId: rowIndex + 1,
                  maturityDate: {isValid: isMaturityDateValid, date: cells[maturityDateIndex]},
                  dob: {isValid: isDOBValid, date: cells[dobIndex]},
                  originationDate: {isValid: isOriginationDateValid, date: cells[originationDateIndex]},
                }
              )
              this.rowsPerPage = this.incorrectDateRows.length;
            }
          }
          
          // Check if the transaction branch in each row is included in the branch list returned from the API
          if (!this.branches.includes(cells[branchIndex]) && !!cells[branchIndex] ) {
            this.incorrectData = true;
            if( !this.incorrectBranches.includes(cells[branchIndex]) )
              this.incorrectBranches.push(cells[branchIndex]);
          }
        });

        //Show dialog if data is missing
        if (this.incorrectData) {
          this.transactionUploadDialog = true;
        }
      } catch (error) {
        this.loadingDialog = false;
      } 
    },

		/**
		 * Method used to read CSV file uploaded for transactions
		 * If an empty cell is found in the Branch, Transaction Id, Transaction Amount columns, then a dialog is presented to the user
		 *
		 * @param {*} File file
		 * @param {*} Integer The selected file input's index
		 */
		async cleanTransactionCSV(file, inputFileIndex) {
			try {
				// stop function if nno file is passed
				if (!file) return;
				let fileReader = new FileReader();
				this.loadingDialog = true;
				//Variable used to store the branches that do not mathc those in the list above
				let missingBranches = [];

				fileReader.onload = (file) => {
					//Put each row of the file in an array
					const rows = file.target.result.split(/(?:\r\n|\n+)/);
					//Store the header of the file in an array
					const headers = rows[0].split(/(?!\B"[^"]*),(?![^"]*"\B)/);
					//Get the position of the required fields from the header
					const transactionBranchIndex = this.findHeaderIndex(headers, "transaction branch");
					const transactionIdIndex = this.findHeaderIndex(headers, "transaction id");
					const transactionAmountIndex = this.findHeaderIndex(headers, "transaction amount");
					//Loop over each row in the array starting at row 2
					for (let i = 1; i < rows.length; i++) {
						// Store each value from the row in an array
						// This regex searches each item in the array to check if it is quoted ("")
						// If the value is quoted, then it does not split the quoted value by any comma that may be present inside the string
						// Visit https://regexr.com/ for more details
						const cells = rows[i].split(/,(?=(?:[^\"]*\"[^\"]*\")*[^\"]*$)/);
						//Check if the cells length is greater than 1
						if (cells.length > 1) {
							for (let j = 0; j < cells.length; j++) {
								const singleCell = cells[j].trim();
								//Check if the transaction branch in ecach row is included in the branch list returned from the API
								if (
									j == transactionBranchIndex && !this.branches.includes(singleCell) && !!singleCell
								) {
									missingBranches.push(singleCell);
								}
								//Check if any of the required cells are missing data
								if (
									!singleCell &&
									[
										transactionBranchIndex,
										transactionIdIndex,
										transactionAmountIndex,
									].includes(j)
								) {
									this.missingData = true;
									this.missingDataHeaders = [
										{ text: "Row#", value: "rowId" },
										{ text: "Branch", value: "branch" },
										{ text: "Transaction ID", value: "transId" },
										{ text: "Transaction Amount", value: "transAmount" },
									];
									this.missingDataRows.push({
										rowId: i + 1,
										branch: cells[transactionBranchIndex],
										transId: cells[transactionIdIndex],
										transAmount: cells[transactionAmountIndex],
									});
								}
							}
						}
					}
					//Show dialog if data is missing
					if (this.missingData) {
						//remove duplicate rows from the array
						this.missingDataRows = Array.from(
							new Set(this.missingDataRows.map((a) => a.rowId))
						).map((rowId) => {
							return this.missingDataRows.find((a) => a.rowId === rowId);
						});
            			this.emptyRowsPerPage = this.missingDataRows.length
						this.transactionUploadDialog = true;
						this.loadingDialog = false;
					}

					//Add missing branch message to the text if any were present
					if (missingBranches.length > 0) {
					  this.incorrectBranches = [...new Set(missingBranches)];
						this.transactionUploadDialog = true;
					}
					this.loadingDialog = false;
				};
				//Call file reader
				fileReader.readAsText(file);
			} catch (error) {
				this.loadingDialog = false;
			}
		},

    findHeaderIndex(headers, searchString) {
       return headers.findIndex(
          (h) => h.trim().toLowerCase() === searchString
        );
    },

		async handleFileUpload(val) {
			this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
			let index = val;

			var i = -1;
			//gets selected file object
			this.file = this.$refs.file[index].files;
			//Behon: Check to see if a file was selected
			if (!this.file[0]) return;
			//Check file type
			const csvContentTypes = [
				"text/csv",
				"text/x-csv",
				"application/csv",
				"application/x-csv",
				"text/comma-separated-values",
				"application/vnd.ms-excel"
			];
			if(!csvContentTypes.includes(this.file[0].type)){
				return	this.$toast.error("Please select a CSV file for upload", "Invalid File Type", {
					timeout: 4000,
					position: "topCenter"
				});
			}
			//Behon: Check to see all required cells are filled in the CSV file
			//This will only happen if the transaction upload method was selected
			if (val === 4) {
				this.cleanTransactionCSV(this.file[0], index);
			} else {
				this.loadingDialog = true;
				await this.loadMassUploadFile(this.file[0]);
			}

			//sets upload button by selected option
			for (i = 0; i < this.uploadMethods.length; i++) {
				if (i == index) {
					this.uploadMethods[i].show = true;
				} else {
					this.uploadMethods[i].show = false;
					//reset previous selected file field value
					this.$refs.file[i].value = "";
				}
			}

			this.dialogInfo.message = "";
			this.dialogInfo.title = "";
			this.error = false;
		},

		showInstructions(item) {
			this.dialog = true;
			this.selectedItem = item;
		},

		validateFile(item) {
			let uploadOption = item.option;
			this.ux.option = uploadOption;

			this.ux.processing = true;
			this.ux.success = false;
			this.ux.error = false;

			this.ux.progress = "File is uploading...";
			this.resetValues();
			switch (uploadOption) {
				case "transactions-upload":
					this.transactionUpload();
					break;
				case "all":
				case "loans":
				case "collaterals":
				case "persons":
					this.massUpload(uploadOption);
					break;
			}
		},

		async massUpload(option) {
			const formData = new FormData();
			formData.append("file", this.file[0]);
			try {
				let response = await MassUploadService.massUpload(
					option,
					formData,
					(event) => {
						this.uploadProgress = Math.round(
							(100 * event.loaded) / event.total
						);
						if (this.uploadProgress == 100) {
							this.ux.progress = "Now processing file data...";
						}
					}
				);

				this.ux.success = true;

				if (response.data) {
					const {results, invalid_records} = response.data;

					//Check to see if results is null
					if(!results){
						this.dialogInfo.message = "Error in Processing File";
						this.error = true;
						this.ux.processing = false;
						this.ux.success = false;
						this.ux.error = true;
						return;
					}

					if (invalid_records.length && invalid_records.length > 0) {
						var res = Object.keys(invalid_records).map((key) => [
							key,
							invalid_records[key].invalid_params[0].field,
						]);
						this.ux.exceptions = res.map(function(el) {
							return {
								account: el[0],
								exception: el[1],
							};
						});
					}
					if(results){
						this.ux.totalPersons = results.total_persons;
						this.ux.totalLoans = results.total_loan_amount;
						if (results.collaterals || false) {
							this.ux.totalCC = results.collaterals.total_cash_amount || 0.0;
							this.ux.totalMVC =
								results.collaterals.total_motor_vehicle_amount || 0.0;
							this.ux.totalREC =
								results.collaterals.total_real_estate_amount || 0.0;
						}
					}
					
					
				}
			} catch (e) {
				if (e.response && e.response.status == 422) {
				this.dialogInfo.message = "Error in Processing File";
				this.error = true;
				this.ux.error = true;
				}else{
					this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
				}
			} finally {
				this.$refs.file.forEach((el) => (el.value = ""));
				this.ux.processing = false;
				this.showButton = true;
				this.dialogInfo.message =
					"<v-subheader> Successfully Uploaded </v-subheader> <li><b>" +
					" " +
					this.totalPersons +
					" " +
					"</b> Persons with Accounts </li> <li><b>" +
					" " +
					this.totalLoans +
					" " +
					"</b>Loans in Total </li>";
				this.clearFile();
			}
		},

		async transactionUpload() {
			const formData = new FormData();
			formData.append("file", this.file[0]);

			try {
				let response = await MassUploadService.transactionUpload(
					formData,
					(event) => {
						this.uploadProgress = Math.round(
							(100 * event.loaded) / event.total
						);
						if (this.uploadProgress == 100) {
							this.ux.progress = "Now processing file data...";
						}
					}
				);

				this.ux.success = true;

				this.error = false;

				if (response.data) {
					const {results, invalid_records} = response.data;

					//Check to see if results is null
					if(!results){
						this.dialogInfo.message = "Error in Processing File";
						this.error = true;
						this.ux.processing = false;
						this.ux.success = false;
						this.ux.error = true;
						return;
					}

					if (invalid_records.length && invalid_records.length > 0) {
						const res = Object.keys(invalid_records).map((key) => [
							key,
							invalid_records[key].invalid_params[0].field,
						]);
						this.ux.exceptions = res.map(function(el) {
							return {
								account: el[0],
								exception: el[1],
							};
						});
					}
					if(results){
						this.ux.countTransactions = results.total_number_of_transactions || 0;
						this.ux.sumTransactions = results.total_transaction_amount || 0;
					}
					
				}
			} catch (e) {
				this.dialogInfo.message = "Error in Processing File";
				this.error = true;
				this.ux.processing = false;
				this.ux.success = false;
				this.ux.error = true;
			} finally {
				this.$refs.file.forEach((el) => (el.value = ""));
				this.ux.processing = false;
				this.showButton = true;
				this.dialogInfo.message =
					"<v-subheader> Successfully Uploaded </v-subheader> <li><b>" +
					" " +
					this.countTransactions +
					" " +
					"</b> Sub-Accounts </li> <li><b>" +
					" " +
					this.sumTransactions +
					" " +
					"</b>Transactions in Total </li>";
				this.clearFile();
			}
		},

		resetValues() {
			this.ux.exceptions = [];
			this.ux.countTransactions = "";
			this.ux.sumTransactions = "";
			this.ux.totalPersons = "";
			this.ux.totalLoans = "";
			this.ux.totalCC = "";
			this.ux.totalMVC = "";
			this.ux.totalREC = "";
		},
	},
};
</script>
