<template>
  <div>
      <div class="bg">
          <!-- Large image on the left. DIsplayed only on desktop -->
        <div class="img hidden-sm-and-down"
          :style="{'background': `linear-gradient(206.31deg, rgba(0, 0, 0, 0.69) 0%, rgba(0, 0, 0, 0.21) 100%), url('${require('@/assets/img/maintenance.jpg')}') no-repeat center`}">
        </div>
        <!-- Text on the right -->
        <div class="text-container">
            <!-- Logo -->
            <v-img class="mb-2" max-height="110" width="220" :src="require('@/assets/img/technosoft_shorten_logo.jpg')"></v-img>
            <h1 class="display-2 font-weight-light">This module is currently down for<br/>
            <span class="primary--text font-weight-bold">maintenance.</span></h1>
            <br/>
            <p class="headline text--disabled mt-4">We apologize for any inconveniences caused. We're almost done.</p>
            <v-btn color="primary" style="min-width: 130px; min-height: 45px" depressed class="mt-4 text-capitalize" to="/">Go Home</v-btn>
            <v-btn @click="dialog = true" style="min-width: 130px; min-height: 45px" outline class="mt-4 text-capitalize">Contact Us</v-btn>
        </div>
      </div>
      <!-- Contact Modal -->
      <v-dialog v-model="dialog" width="350">
        <v-card>
            <!-- Modal title -->
            <v-card-title>
                <span class="headline">Contact Us</span>
            </v-card-title>
            <v-divider/>
            <v-list subheader>
                <v-subheader>Click to contact us</v-subheader>
                <template v-for="(contact,i) in contacts">
                    <v-list-tile :href="contact.link" target="_blank" :key="`contact-${i}`">
                        <v-list-tile-action >
                            <v-icon >{{contact.icon}}</v-icon>
                        </v-list-tile-action>
                        <v-list-tile-content>
                            <v-list-tile-title v-html="contact.text"></v-list-tile-title>
                        </v-list-tile-content>
                    </v-list-tile>
                </template>
            </v-list>
        </v-card> 
      </v-dialog>
  </div>
</template>
<script>
export default {
    data() {
        return {
            dialog: false,
            contacts:[
                {icon : 'phone', text: '+1 (876) 822-3610', link: 'tel:+18768223610'},
                {icon : 'phone', text: '+1 (876) 634-9276', link: 'tel:+18766349276'},
                {icon : 'phone', text: '+1 (876) 630-9934', link: 'tel:+18766309934'},
                {icon : 'email', text: 'info@technosoftja.com', link: 'mailto:info@technosoftja.com'},
            ]
        }
    },
}
</script>

<style scoped>
.bg{
    height: 100vh;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
}
.img{
    height: 100vh;
    width: 100%;
    background-size: cover !important;
}
.text-container{
    width: 50%;
    margin: auto 40px;
}
@media screen and (max-width:959px) {
    .text-container{
        width: 100%;
        margin: auto 5%;
    }    
}
</style>