import httpClient from '@/common/http'
export default {
  create(obj) {
    return httpClient.request('post', 'settings/loan_origination/collaterals', obj);
  },

  findAll() {
    return httpClient.request('get', `settings/loan_origination/collaterals/find-all`)
  },

  update(id, obj) {
    return httpClient.request('put', `settings/loan_origination/collaterals/${id}`, obj);
  },

  changeIsActive(id, val) {
    return httpClient.request('put', `settings/loan_origination/collaterals/${id}/active/${val}`);
  },

  delete(id) {
    return httpClient.request('delete', `settings/loan_origination/collaterals/${id}`);
  },

  findByType(category) {
    return httpClient.request('get', `settings/loan_origination/collaterals/findByType/${category}`);
  },

}