import httpClient from '@/common/http'
export default {
  create(obj) {
    return httpClient.request('post', '/settings/loan_origination/account_types', obj);
  },

  update(id, obj) {
    return httpClient.request('put', `/settings/loan_origination/account_types/${id}`, obj);
  },

  delete(id) {
    return httpClient.request('delete', `/settings/loan_origination/account_types/${id}`);
  },

  findOne(id) {
    return httpClient.request('get', `/settings/loan_origination/account_types/${id}`)
  },

  findAll() {
    return httpClient.request('get', `/settings/loan_origination/account_types`)
  }

}