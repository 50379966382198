<template>
  <v-dialog v-model="formOpened" persistent max-width="400">
    <v-card class="pl-4 pr-4 pt-4">
      <h2 class="title underline mb-4">Change Password</h2>
      <v-alert :value="errMsg !== ''" class="mb-4" color="error">{{
        errMsg
      }}</v-alert>
      <va-input
        v-validate="'required'"
        v-model="user.oldPassword"
        type="password"
        name="oldPassword"
        label="Old Password"
        data-vv-scope="data"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('data.oldPassword')"
        data-vv-as="old password"
        placeholder=" "
      />
      <va-input
        v-validate="'required|verify_password'"
        v-model="user.newPassword"
        type="password"
        name="newPassword"
        label="New Password"
        ref="password"
        data-vv-scope="data"
        data-vv-validate-on="blur"
        :error-messages="errors.collect('data.newPassword')"
        data-vv-as="new passoword"
        placeholder=" "
      />
      <va-input
        v-validate="'required|confirmed:password'"
        v-model="user.confirmPassword"
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        data-vv-scope="data"
        :error-messages="errors.collect('data.confirmPassword')"
        data-vv-as="confirm password"
        placeholder=" "
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="yellow darken-2" @click="onHide">Close</v-btn>
        <v-btn color="blue darken-1" dark @click="onConfirm">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from "vuex";
import VaInput from "@/components/form-components/VaInput.vue";
import validations from "@/common/validator";
import AuthService from "@/services/authentication-service";
import storageService from "@/services/storage-service";

export default {
  components: {
    "va-input": VaInput,
  },

  data() {
    return {
      currentUser: {},
      errMsg: "",
      user: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
    };
  },

  created() {
    this.$validator.extend(
      validations.verifyPassword.name,
      validations.verifyPassword.validator
    );
  },

  props: {
    opened: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    opened: async function () {
      if (this.opened == true) {
        try {
          this.tabModel = "tab-personal";
        } finally {
          this.isLoading = false;
        }
      }
    },
  },

  computed: {
    formOpened: {
      get: function () {
        return this.opened;
      },

      set: function (newValue) {
        this.$emit("update:opened", newValue);
      },
    },
  },

  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

    reset() {
      this.user = {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      };
    },

    onHide() {
      this.formOpened = false;
      this.reset();
    },

    beforeDestroy() {
      this.$toast.destroy();
    },

    async onConfirm() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      this.errMsg = "";
      let isValid = await this.$validator.validateAll("data");
      if (isValid) {
        if (this.user.newPassword === this.user.oldPassword) {
          this.errMsg =
            "Your New Password cannot be the same as your Old Password.";
          return;
        }
        this.currentUser = storageService.getItem("user");
        try {
          let response = await AuthService.changePassword(
            this.currentUser.ID,
            this.user.oldPassword,
            this.user.newPassword
          );

          if (response.status !== 401) {
            this.formOpened = false;
            this.$emit("submit", true);
            this.reset();
            AuthService.logout(2);
          } else {
            this.errMsg =
              "Your Old Password is incorrect. Try again. If you continue getting this message try login out and request a password reset.";
          }
          this.$emit("submit", false);
        } catch (e) {
          switch (e.response.status) {
            case 422:
              this.errMsg =
                "Invalid request data. Please review request and try again.";
              break;
            default:
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
          }
          this.$emit("submit", false);
        }
      }
    },
  },
};
</script>
