<template>
  <div id="apps">
    <v-dialog v-model="detailsDialogModel" :max-width="'100%'">
      <v-card class="px-4 pt-2">
        <v-toolbar-title class="font-weight-light">   
          Log Details
        </v-toolbar-title>
        <!-- <p class="font-weight-light mt-4">
          Due to inactivity your session will terminate at the end of the countdown.
        </p> -->
        <ul style="list-style: none; margin-top:10px; padding-left: 20px">
          <li v-for="log in this.logDetails" :key="log.key" style="color: #333; margin-left: -20px">
            <b style="margin-right: 10px">{{log.key | capitalize}}</b>
            <span v-if="log.type == 'number'">{{log.value | currency}}</span>
            <span v-if="log.type == 'string'">{{log.value | capitalize}}</span>
            <span v-if="log.type == 'date'">{{log.value}}</span>

            <span v-if="log.nested" v-html="log.nested">

            </span>
          </li>
        </ul>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal darken-1" flat @click="detailsDialogModel = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-layout class="mb-2">
        <v-toolbar class="elevation-1" dense>
     
          <span class="title ml-3 mr-5">
           {{toggleMap ? 'Geo' : ''}} Audit&nbsp;
            <span class="text">Log</span>
          </span>

          <v-spacer></v-spacer>
       
          <v-menu style="z-index: 9999 !important" v-model="filterMenu" :close-on-content-click="false" :nudge-width="300" >
            <template v-slot:activator="{ on }">
              <v-btn small color="primary" v-on="on">
                <v-icon class="mr-2">filter_list</v-icon>
                Filters
              </v-btn>
            </template>
            <v-card>
              <v-list>
                <v-list-tile>
                  <v-list-tile-content>
                    <v-list-tile-title>Filters</v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
              </v-list>

              <v-divider></v-divider>
                <div class="px-4">
                  <v-select 
                    v-model="filters.user" 
                    :items="users" 
                    :item-text="`full_name`" 
                    :item-value="`ID`" 
                    label="User" ></v-select>
                  <v-select v-model="filters.module" :items="modules" label="Module" ></v-select>
                  <v-select v-model="filters.event" :items="events" label="Event" ></v-select>
                  <v-text-field 
                    v-model="filters.from" v-validate="'date_format:YYYY-MM-DD'"
                    data-vv-scope="filter"
                    :error-messages="errors.collect('filter.from')"
                    placeholder=" "
                    ref="afterTarget"
                    return-masked-value
                    mask="####-##-##"
                    label="Start Date (yyyy-mm-dd)"        
                    data-vv-name="from"/>
                  <v-text-field 
                    v-model="filters.to" 
                    v-validate="'date_format:YYYY-MM-DD|after:afterTarget'"
                    data-vv-scope="loan"
                    :error-messages="errors.collect('loan.to')"
                    placeholder=" "
                    return-masked-value
                    label="To (yyyy-mm-dd)"
                    mask="####-##-##"
                    data-vv-validate-on="blur"         
                    data-vv-name="to"
                    />
                </div>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn flat @click="filterMenu = false">Cancel</v-btn>
                <v-btn color="primary" :loading="loading" flat @click="search()">Search</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
          <v-btn @click="exportLogs('pdf')" class="elevation-1" dark color="red darken-2" small >
            <v-icon  class="mr-2" small>fa-file-pdf</v-icon> Export
          </v-btn>
          <!-- <v-btn  @click="viewMap()" class="elevation-1" dark color="primary darken-3" small >
            <template v-if="!toggleMap">
               <v-icon class="mr-2" small>fa-map-marker-alt</v-icon> Map View
            </template>
             <template v-else>
               <v-icon class="mr-2">list</v-icon> Table View
             </template>
          </v-btn>      -->
        </v-toolbar>
      </v-layout>
      <v-layout class="mb-2">
        <v-flex  xs12>
          <v-data-table
            v-if="!toggleMap"
            :pagination.sync="pagination"
            :headers="headers"
            :items="logs"
            :loading="loading"
            :rows-per-page-items='[50, 100, 150, 200,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
            class="elevation-0"
          >
            <template 
            v-slot:items="props"
            >
              <td>{{ moment.utc(props.item.date)
                .local()
                .format("MMM D, YYYY hh:mm:ss a")
                 }}</td>
              <td>{{ props.item.event_message  }}</td>
              <td><b>{{  props.item.module || 'N/A' }}</b></td>
              <td>
                <span v-if="!props.item.description && props.item.action == 'Update'">
                  <b>[No Changes Made]</b>
                </span>
                <a v-else-if="props.item.description" @click="showDetailsDialog(props.item)">{{ props.item.action =="Update" ? "Changes" : "Details"  }}</a>
                <b v-else>[No Details Available]</b>
              </td>
              <!-- <td>{{ props.item.label }}</td> -->
            </template>
          </v-data-table>
          <l-map ref="lmap" v-if="toggleMap" />
        </v-flex>
        
      </v-layout>
    </v-container>


  </div>
</template>
<style lang="scss">
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>

<script>
import { mapMutations } from "vuex";
import AuditLogService from "@/services/audit-log-service.js";
import UserService from "@/services/user-service.js";
import LocationService from "@/services/location-service.js";
import moment from 'moment';
import util from '@/common/util.js';
import _ from 'lodash';

import L from 'leaflet';
import LMap from '@/components/Map.vue';
import storageService from "@/services/storage-service";

export default {
  components: {
    LMap,
  },
  data() {
    return {
      detailsDialogModel: false,
      logDetails: {},
      toggleMap: false,
      user: null,
      filterMenu: false,
      loading: false,
      defaultHeaders: [
        { can_not_toggle_visibility: true, text: 'Date', align: 'left', sortable: false, value: 'date'},
        { can_not_toggle_visibility: true, text: 'Event', align: 'left', sortable: true, value: 'event_message'},
        { text: 'Module', align: 'left', sortable: false, value: 'module'},
        { text: 'Details', align: 'left', sortable: true, value: 'email'},
      ],
      moment: {},
      headers: [],
      users: [],
      events: [],
      modules: [],
      logs: [],

      visibleColumns: [],
      filters: {
        user_id: 'All',
        start_date: '',
        end_date: '',
        module: 'All',
        events: 'All',
      },
      pagination: {}
    }
  },

  beforeMount() {
    this.moment = moment;
    this.headers = _.cloneDeep(this.defaultHeaders);
  },

  async created() {
    this.user = storageService.getItem('user')
    this.loading = true;
    await this.initialize();
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    this.SET_SIDEBAR_ROUTES([]);
  },

  watch: {
    pagination: async function () {
      await this.filterAuditLogs() 
    }
  },

  methods: {
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SHOW_UNEXPECTED_ERROR_DIALOG",
      "SET_SIDEBAR_ROUTES"
    ]),

   

    async viewMap() {
      if(this.toggleMap) {
        this.toggleMap = false;
        return;
      }

      let coords = {
        latitude: 18,
        longitude: -77,
      }

      try {
        coords = await LocationService.currentCoordinates();
      } catch(err){
        console.log(err)
      }

      let logs  = this.logs
      .filter( el =>  el.actor ? el.actor.geo_coords || false : false)
      .map((el) => {
        let gcoords = el.actor.geo_coords 
        return {
          geo_id: gcoords.latitude+'_'+gcoords.longitude,
          ...el
        }
      })

      const groupBy = key => array =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

      logs = groupBy('geo_id')(logs);
      
      let geoCoords = [];
      Object.keys(logs).forEach((key)=> {
        let [lat, lng] = key.split('_')
        geoCoords.push(  {
           id: key,
            position: {lat: Number(lat), lng: Number(lng)},
            content: `
            <h3>Events done at this Location</h3>
            <ul style="padding-left: 5px; margin-top: 5px; list-style: none; max-height: 200px; overflow-y: auto">
            ${logs[key].map(el=> `<li>${'<b>'+el.full_name +'</b> '+ el.event_message +' @ '+ 
                moment.utc(el.date)
                .local()
                .format("MMM D, YYYY hh:mm:ss a")}</li>`).join('')}
            </ul>`,
            tooltip: `${logs[key].length} logs`,
            draggable: false,
            visible: true
        })
      })

      this.toggleMap = true;
      
      setTimeout(()=>{
        this.$refs.lmap.initMarkers(
          [Number(coords.latitude.toFixed(4)), Number(coords.longitude.toFixed(2))],
          geoCoords
        )
      }, 500) 
    },

    async search() {
      let valid = await this.$validator.validateAll("filter")
      if(valid) {
        this.filterAuditLogs();
        this.filterMenu = false;
      }
    },

    async exportLogs(type){
      try {
        let filters = {};

        for(const key in this.filters) {
          if(this.filters[key] && this.filters[key] !== "All") {
            filters[key] = this.filters[key];
          }
        }
          
        let res = await AuditLogService.exportLogs({type, filters})
       
        let win;
        let html
        switch(type){
          case 'pdf':
            win = window.open("", "_blank");
            html = `
                <html>
                <body style="margin:0!important">
                <embed width="100%" height="100%" src="data:application/pdf;base64,${res.data}" type="application/pdf" />
                </body>
                </html>
            `;

            setTimeout(() => {
                win.document.write(html);
            }, 0);

        }
      } catch (err) {
        console.log('err: ', err);
      }
    },

    async filterAuditLogs() {
      try {
        this.loading = true;
        const { sortBy, descending, page, rowsPerPage } = this.pagination;
        let skip = page == 1 ? 0 : (page - 1) * rowsPerPage;
        let limit= rowsPerPage;
        let filters = {};

        for(const key in this.filters) {
          if(this.filters[key] && this.filters[key] !== "All") {
            filters[key] = this.filters[key];
          }
        }
          
        let logs = await AuditLogService.fetchLogs({filters, skip, limit});
        
        this.logs = logs.map((el)=>{
          el.full_name = el.actor.first_name ? el.actor.first_name + ' ' + el.actor.last_name : 'System Administrator';
          el.details = (el.description || false) ? el.description.changes || el.description :''
          return el;
        })
      } catch (err) {
        console.log('err: ', err);

      } finally {
        this.loading = false;
      }
    },

    showDetailsDialog(log) {
      this.logDetails = this.formatDetails(log.details)
      this.detailsDialogModel = true;
    },

    formatDetails(details) {
      let logDetails = []
      Object.keys(details).forEach((key) => {
        let nested = false;
        if(Array.isArray(details[key])){
          nested = util.json2htable(details[key])
          details[key] = details[key].length
        }else if (typeof details[key] == "object") {
          nested = util.json2htable([details[key]])
          details[key] = details[key].length
        }

        if(key.indexOf('ID') == -1) {
          let type = 'date'

          if(isNaN(details[key])) {
            if(key.indexOf('date') == -1) {
              type = "string"
            }
            
          } else {
            type = "string"
          }

          logDetails.push({
            key: key.toUpperCase().replace(/_/g, ' '),
            value: type == "date" ? moment.utc(details[key]).local().format("MMM D, YYYY hh:mm:ss a") : details[key],
            type: type,
            nested: nested
          })
        }
        
      })
      return logDetails;
    },

    async initialize() {
      try {
        let res =  await UserService.findAll();
        this.users = res.data.map((el) => {
          el.full_name = !el.first_name ? "SYSTEM ADMIN" : el.first_name+' '+el.last_name
          el.full_name = el.full_name.toUpperCase();
          return el;
        })
        this.events = ['All', ...await AuditLogService.fetchActions()];
        this.modules = ['All', ...await AuditLogService.fetchModules()];
     
     } catch (error) {
        console.log('error: ', error);
      } finally {
        this.loading = false;
      }
    },
  }
};
</script>
