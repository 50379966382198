<template>
  <div id="apps">
    <v-container fluid>
      <v-toolbar justify-start align-start>
        <h3 style="color: #172b4d">{{user.first_name | capitalize}} {{user.last_name | capitalize}}'s Daily Activity Report</h3>
      </v-toolbar>
    </v-container>

    <v-container grid-list-lg class="mt-0 pt-0" fluid>
      <v-layout justify-center row wrap>
        <v-flex lg2 sm4 xs6 md2 class="mt-1">
          <v-card flat>
            <v-layout justify-center align-center>
              <v-flex xs12 style="text-align:center; margin-top: -5px">
                <v-avatar
                  :size="75"
                  class="green img-circle font-weight-black text-xs-center white--text"
                >
                  AC
                </v-avatar>
              </v-flex>
            </v-layout>
            <v-card-text style="text-align:center; margin-top: -5px">
              <div style="color: #505a6b; font-size: 14px; word-wrap: wrap; font-weight: bold;">App Center</div>
              <v-btn small color="primary" @click="showComponent('appCenterComponent', 'App Center')">OPEN</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex v-for="app in apps" :key="app.app_name" lg2 sm4 xs6 md2 class="mt-1">
          <v-card flat>
            <v-layout justify-center align-center>
              <v-flex xs5>
                <v-img
                  :src="app.app_icon"
                  aspect-ratio="1"
                  class="grey lighten-2 img-circle"
                >
                  <v-layout slot="placeholder" ma-0>
                    <v-progress-circular indeterminate :size="20" color="grey lighten-5"></v-progress-circular>
                  </v-layout>
                  <v-expand-transition>
                    
                  </v-expand-transition>
                </v-img>
              </v-flex>
            </v-layout>
            <v-card-text style="text-align:center; margin-top: -5px">
              <div style="color: #505a6b; font-size: 14px; word-wrap: wrap; font-weight: bold;">{{app.app_name}}</div>
              <v-btn small color="primary" @click="showComponent(app.component_name, app.app_name)">OPEN</v-btn>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>

    <!-- FULLSCREEN MODAL -->
    <v-dialog fullscreen v-model="open_dialog" scrollable>
      <v-card color="white">
        <v-card-title class="blue darken-3 mb-1 white--text" style="max-height: 80px;">
          <v-btn icon dark @click="closeAdditionsDialog">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{fullScreenDialogTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeAdditionsDialog">Exit</v-btn>
        </v-card-title>
        <v-card-text>
        <div class="pa-0" fluid>
          <component
            ref="largeDialogComponent"
            :opened="open_dialog"
            :is="active_component"
            :data="data"
          />
        </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    </v-container>
  </div>
</template>

<script>
import companyService from "../../services/company-service";
import loanComponent from "./user-stats/loan-pro";
import documentComponent from "./user-stats/doc-pro";
import ifrs9Component from "./user-stats/ifrs9";
import riskComponent from "./user-stats/risk";
import accountComponent from "./user-stats/account";
import customerComponent from "./user-stats/customer";
import appCenterComponent from "./user-stats/app-center";
import Applogo from "../../assets/img/technosoft_shorten_logo.jpg";
import auditService from "../../services/user_stat.service";
import { mapMutations } from "vuex";
import storageService from "@/services/storage-service";

export default {
  components:{
    loanComponent,
    accountComponent,
    customerComponent,
    documentComponent,
    ifrs9Component,
    riskComponent,
    appCenterComponent
  },
  data: () => ({
    apps: [],
    data: [],
    loan_log: [],
    logo: Applogo,
    open_dialog: false,
    active_component: "",
    dialog_title: "",
    fullScreenDialogTitle: "",
    
  }),
  methods: {
    
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SET_SIDEBAR_ROUTES"
    ]),

    closeAdditionsDialog() {
      this.open_dialog = false;
    },

    async print() {
       
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://cdn.jsdelivr.net/npm/vuetify@1.5/dist/vuetify.min.css',
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons',
        ]
      }
      this.$htmlToPaper("print", options, data => {
        console.log("Printing completed or was cancelled!", data);
      });
    },

    showComponent(component, title){
        this.active_component = component;
        this.fullScreenDialogTitle = title;
        this.open_dialog = true;
    }
  },
  async beforeMount(){
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    this.SET_SIDEBAR_ROUTES([]);
      this.user = storageService.getItem("user");
      try {
        this.apps = []
        let result = await companyService.fetchModules();
        for(let key in result) {
          let found = false
          this.user.app_access.forEach((app) => {
            if(app.app_name ===  result[key].app_name && result[key].is_active) {
              found = true;
            }
          })
          
          if(found || this.user.is_admin) {
            this.apps.push( result[key])
          }
        } 
        for(let a = 0; a < this.apps.length; a++){
          if(this.apps[a].app_name.includes("Loan-Pro")){
            this.apps[a].component_name = "loanComponent";
          }
          if(this.apps[a].app_name.includes("Doc-Pro")){
            this.apps[a].component_name = "documentComponent";
          }
          if(this.apps[a].app_name.includes("CRM-Pro")){
            this.apps[a].component_name = "customerComponent";
          }
          if(this.apps[a].app_name.includes("Account-Pro")){
            this.apps[a].component_name = "accountComponent";
          }
          if(this.apps[a].app_name.includes("IFRS9")){
            this.apps[a].component_name = "ifrs9Component";
          }
        }
      } catch (e) {
        console.log(e);
      }
  }
};
</script>