<template>
 <div class="pa-3">
   <account-form 
    :type="accountType" 
    @update="onUpdated"
    @create="onCreated"
    @closed="onClose" 
    :account-prop="account" 
    :opened.sync="accountOptionsDialog" />
  
  <v-toolbar dense  color="white" class="elevation-1">
    <v-text-field
      solo
      v-model="globalSearch"
      label="Search"
      @keyup="onSearch"
      prepend-inner-icon="search"
    ></v-text-field>
    <v-select
      @change="onSearch"
      v-model="searchFilter"
      style="max-width:280px !important"
      :items="['Account #', 'Tax Number', 'Name On Account']"
      solo
    ></v-select>

    <v-divider class="mr-2" vertical></v-divider>
    <v-btn   color="primary" @click="accountOptionsDialog = true" >New Account</v-btn>
  </v-toolbar>
  <v-data-table 
    :headers="headers" 
    :items="accounts"
    :expand="true"
    item-key="ID"
    :pagination.sync="pagination"
    :total-items="totalAccounts"
    :rows-per-page-items='[10, 20,40, 50,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    :loading="isLoading" class="elevation-1">
    <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
    <template slot="items" slot-scope="props">
      <tr>
        <td>
            <v-btn icon  @click="props.expanded = !props.expanded"><v-icon>{{ !props.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}}</v-icon></v-btn>
        </td>
        <td>{{props.item.main_account_number}}</td>
        <td>{{props.item.account_type}}</td>
        <td>{{props.item.branch.branch_name}}</td>
        <td>
          <v-icon small class="mr-2" @click="onEditItemClicked(props.index, props.item)">
            edit
          </v-icon>
        </td>
      </tr>
     
    </template>
    <template v-slot:expand="props">
      <v-layout>
        <v-flex xs6>
          <v-list two-line>
            <v-subheader>Account Holders</v-subheader>
              <template v-for="item in props.item.account_holders">

                <v-list-tile
                  :key="item.ID"
                  avatar
                >
                  <v-list-tile-avatar>
                    <v-icon>fa-user</v-icon>
                  </v-list-tile-avatar>

                  <v-list-tile-content>
                    <v-list-tile-title >
                      <span class="body-2 mb-2 grey--text text--darken-3 font-weight-bold mb-1">
                      {{item.first_name | capitalize }} {{item.last_name | capitalize}}
                      </span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>{{item.gender}} | {{item.date_of_birth}} </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
          </v-list>
        </v-flex>
        <v-flex xs6>
          <v-list two-line>
            <v-subheader>Sub Accounts</v-subheader>
              <template v-for="item in props.item.sub_accounts">

                <v-list-tile
                  :key="item.ID"
                  avatar
                >
                  <v-list-tile-avatar>
                    {{item.sub_account_number}}
                  </v-list-tile-avatar>
                  <v-list-tile-content>
                    <v-list-tile-title >
                      <span class="body-2 mb-2 grey--text text--darken-3 font-weight-bold mb-1">
                        {{item.sub_account_type_name | capitalize }} | {{item.account_definition_name | capitalize}} ({{item.account_type_name | capitalize}})
                      </span>
                    </v-list-tile-title>
                    <v-list-tile-sub-title>{{item.account_balance | currency}} | {{item.account_status || 'New'}} </v-list-tile-sub-title>
                  </v-list-tile-content>
                </v-list-tile>
              </template>
          </v-list>
        </v-flex>
      </v-layout>
    </template>
    <template slot="no-results">
      <v-btn style="margin: auto 0" icon-text flat color="blue" @click="resetFilters">
        No data found. Click here to refresh.
        <v-icon>refresh</v-icon>
      </v-btn>
    </template>
  </v-data-table>

  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}


label {
  color: #505a6b;}

.active {
  background: #eee;
}
</style>

<script>

import { mapState, mapMutations, mapActions } from 'vuex'
import AccountForm from './components/Account-Options.vue'
import accountService from '@/services/account-service'
import PaginationMixin from '@/mixins/PaginationMixin'
import _ from 'lodash'
import validations from '@/common/validator'

export default {
  components: {
    AccountForm
  },

  mixins: [PaginationMixin],

  data() {
    return {
      searchFilter: 'Account #',
      accountOptionsDialog: false,
      isLoading: false,
      headers: [
        { text: 'Expand', align: 'left', sortable: false},
        { text: 'Main Account #', align: 'left', sortable: false},
        { text: 'Type', align: 'left', sortable: false },
        { text: 'Branch Name', align: 'left', sortable: false},
        { text: 'Action', align: 'left', sortable: false},
      ],
      accounts: [],
      accountType: '',
      account: {
        account_holders: [],
        sub_accounts: [],
        authorisees: [],
        beneficiaries: []
      },
      totalAccounts: 0,
      selectedIndex: -1,
    }
  },

  created () {
    this.initialize()
    
  },

  beforeDestroy() {
    this.$toast.destroy()
  },

  computed: {
    ...mapState([
      'appState',
    ]), 
    
  },
  
  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG',
      'TOGGLE_SIDEBAR_VISIBILITY'
    ]),

    initialize() {
      this.TOGGLE_SIDEBAR_VISIBILITY(false);
    },

    onEditItemClicked(index, item) {
      item.average_number_of_deposits = Number(item.average_number_of_deposits).toString()
      item.authorisees = item.authorisees || []
      item.beneficiaries = item.beneficiaries || []
      for(let key in item) { 
        item[key] = item[key]  || '' 
      }
      this.account = _.clone(item)
      this.accountOptionsDialog = true
      this.accountType = item.account_type.toLowerCase()
    },

    async fetchData() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let search = ''
        
        if(this.globalSearch) {
          switch(this.searchFilter) {
            case 'Account #':
              search = `main_account_number=${this.globalSearch}`
              break;
            case 'Tax Number':
              search = `trn=${this.globalSearch}`
              break;
            case 'Name On Account':
              search = `name=${this.globalSearch}`
              break;
          }

        }

        this.isLoading = true
      
        let result = await accountService.findAll(search, this.sortString, {skip: this.skip, limit: this.limit})
        this.accounts = result.data 
        this.totalAccounts = result.total  
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },

    onUpdated(account) {
      this.accounts.splice(this.selectedIndex, 1, account)
      this.selectedIndex = -1
    },

    onCreated(account) {
      this.accounts.push(account)
      this.totalAccounts++
    },

    onClose(){
      this.account = {
        account_holders: [],
        sub_accounts: [],
        authorisees: [],
        beneficiaries: []
      }
      this.selectedIndex = -1
      this.accountType = ''
    },

    async resetFilters() {
      this.searchFilter = 'Account #';
      this.globalSearch = ""
      await this.fetchData()
    }

  }
}
</script>

 
