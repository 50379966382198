<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="headline"
          ><span>{{
            "Edit " + editInfo.type || "Edit Climate Data"
          }}</span></v-card-title
        >
        <v-card-text>
          <va-input
            v-validate="'required|min_value:0|max_value:100'"
            data-vv-scope="climate"
            :error-messages="errors.collect('climate.drought')"
            v-model.number="editInfo.veryLow"
            placeholder="Must be less than Flooding"
            type="number"
            min="0"
            max="100"
            data-vv-name="drought"
            data-vv-as="Drought"
            label="Drought"
          />
          <va-input
            v-validate="
              `required|min_value:${editInfo.veryLow}|max_value:${editInfo.middle}`
            "
            data-vv-scope="climate"
            :error-messages="errors.collect('climate.flooding')"
            v-model.number="editInfo.low"
            placeholder="Must be less than Storm & more than Drought"
            type="number"
            min="0"
            max="100"
            data-vv-name="flooding"
            data-vv-as="Flooding"
            label="Flooding"
          />
          <va-input
            v-validate="
              `required|min_value:${editInfo.low}|max_value:${editInfo.high}`
            "
            data-vv-scope="climate"
            :error-messages="errors.collect('climate.storm')"
            v-model.number="editInfo.middle"
            placeholder="Must be less than Frost & more than Flooding"
            type="number"
            min="0"
            max="100"
            data-vv-name="storm"
            data-vv-as="Storm"
            label="Storm"
          />
          <va-input
            v-validate="
              `required|min_value:${editInfo.middle}|max_value:${editInfo.veryHigh}`
            "
            data-vv-scope="climate"
            :error-messages="errors.collect('climate.frost')"
            v-model.number="editInfo.high"
            placeholder="Must be less than Heat & more than Storm"
            type="number"
            min="0"
            max="100"
            data-vv-name="frost"
            data-vv-as="Frost"
            label="Frost"
          />
          <va-input
            v-validate="`required|min_value:${editInfo.high}|max_value:100`"
            data-vv-scope="climate"
            :error-messages="errors.collect('climate.heat')"
            v-model.number="editInfo.veryHigh"
            placeholder="Must be less than 100 & more than Frost"
            type="number"
            min="0"
            max="100"
            data-vv-name="heat"
            data-vv-as="Heat"
            label="Heat"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed text @click="close()">Cancel</v-btn>
          <v-btn color="primary" text @click="saveData()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VaInput from "@/components/form-components/VaInput.vue";
import { cloneDeep } from "lodash";
export default {
  name: "EditClimateRateModal",
  components: {
    VaInput,
  },
  data() {
    return {
      dialog: false,
      editInfo: {},
    };
  },
  methods: {
    open(item) {
      if (item) this.editInfo = cloneDeep(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      // reset validator
      this.$validator.reset(`climate`);
    },
    async saveData() {
      let isValid = await this.$validator.validateAll(`climate`);
      if (!isValid)
        return this.$toast.error("Please check the values entered. ", "", {
          position: "topCenter",
        });
      this.$emit("save", cloneDeep(this.editInfo));
      this.close();
    },
  },
};
</script>

