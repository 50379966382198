<template>
  <div id="company-details">
    <v-card class="pa-2 pl-4 mb-3 elevation-0">
      <h2>Company Details</h2>
    </v-card>
    <v-container grid-list-xs12>
      <v-layout row  wrap>
        <v-flex xs12 md6 lg6>
          <v-tooltip bottom>
            <v-img aspect-ratio="1" slot="activator" :src="imgDataUrl" :lazy-src="``" id="logo" @click="toggleShow"/>
            <span>Click to change image</span>
          </v-tooltip>
          <avatar-uploader
            lang-type="en"
            field="logo"
            @crop-success="cropSuccess"
            @crop-upload-success="cropUploadSuccess"
            @crop-upload-fail="cropUploadFail"
            v-model="show"
            :width="200"
            :height="200"
            :url="uploadUrl"
            :headers="headers"
            img-format="png">
          </avatar-uploader>
          <va-input
            class="mt-4"
            :error-messages="errors.collect('company.company_name')"
            v-validate="'required|min:3|max:150'"
            data-vv-validate-on="blur"
            data-vv-scope="company"
            data-vv-name="company_name"
            data-vv-as="company name"
            :max-width="'680px'"
            v-model="company.company_name"
            :counter="150"
            label="Company Full Name"
            placeholder=" "
          ></va-input> 
         
          <va-input
            :error-messages="errors.collect('company.short_name')"
            v-validate="'required|min:3|max:60'"
            data-vv-validate-on="blur"
            data-vv-scope="company"
            data-vv-name="short_name"
            data-vv-as="short name"
            :max-width="'400px'"
            v-model="company.company_short_name"
            :counter="60"
            label="Company Short Name"
            placeholder=" "
          ></va-input> 

          <va-input
            v-model="company.company_website"
            v-validate="'max:255|url'"
            :error-messages="errors.collect('company.company_website')"
            data-vv-validate-on="blur"
            data-vv-scope="company"
            data-vv-name="company_website"
            data-vv-as="company website"
            :counter="255"
            :max-width="'400px'"
            :persistent-hint="true"
            placeholder=" "
            :hint="'Expected Format: http://www.domain.com'"
            label="Company Website"
          ></va-input>
           <v-btn  class="mt-4" :loading="authState.isLoading" color="primary" @click="onUpdateClicked">Update</v-btn>
        </v-flex>
        <v-flex justify-center align-center xs12 md6 lg6>
          <div class="page" size="A4">
             <img :src="imgDataUrl" style="width: 32px; height: 32px; margin-right: 10px; float: left;display:inline" @click="toggleShow"/>
             <p style="float: left; font-size: 12px">{{company.company_short_name}}</p> 
             <p style="position: absolute; font-size: 10px; margin-top: 15px; margin-left: 42px">{{company.company_name}}</p>
             <div class="content-container">
              <div class="content-placeholder heading"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
              <div class="content-placeholder"></div>
             </div>
             <br />
          </div>
          
        </v-flex>
      </v-layout>
     
      </v-container>
  </div>
</template>

<style scoped>

#logo {
  margin: 5px;
  width: 120px;
  height: 120px;
  box-shadow: 0 0 0.1cm rgba(0,0,0,0.25);}
.page {
  background: white;
  display: block;
  margin: 0 auto;
  padding: 20px;
  margin-bottom: 0.5cm;
  box-shadow: 0 0 0.2cm rgba(0,0,0,0.3);}
div[size="A4"] {  
   width: 90mm;
  height: 127mm; }

.content-container {
  margin-top: 80px;}
.content-placeholder.heading {
  width: 140px;
  margin: 0 auto;}
.content-placeholder {
  width: 100%;
  height: 10px;
  margin-top: 5px;
  background: #eee;}

</style>

<script scoped>

import 'babel-polyfill';
import uploader from 'vue-image-crop-upload';
import VaInput from '@/components/form-components/VaInput.vue'
import { mapActions, mapState, mapMutations } from 'vuex';
import storageService from "@/services/storage-service";

export default {
  components: {
    'avatar-uploader': uploader,
    VaInput,
  },
  data() {
    return {
      uploadUrl: '',
      show: false,
      params: {
          token: '123456798',
          name: 'avatar'
      },
      updating: false,
      headers: {
          'Authorization': `Bearer `+storageService.getItem('token') || null,
      },
      company: {

      },
      imgDataUrl: '',
      croppedImage: '',
    }
  },
  created() {
    this.uploadUrl = process.env.VUE_APP_ROOT_API + 'company/logo'
    this.initialize()
  },
  computed: {
    ...mapState([
      'authState'
    ])
  },
  methods: {
      ...mapMutations([
        'SHOW_UNEXPECTED_ERROR_DIALOG',
      ]),
      ...mapActions([
        'fetchCompanyDetails',
        'updateCompanyDetails'
      ]),
      
      async initialize() {
        try {
          this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
          await this.fetchCompanyDetails()
          this.company = Object.assign({}, this.authState.company)
          this.imgDataUrl = this.company.logo || require('@/assets/img/logo-here.png')
        } catch (e) {
          console.log(e)
          this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      },

      toggleShow() {
          this.show = !this.show;
      },

      cropSuccess(imgDataUrl){
          this.croppedImage = imgDataUrl
      },

      cropUploadSuccess(jsonData){
         this.imgDataUrl = this.croppedImage
         this.$toast.success('Company logo was successfully changed')
      },
    
      cropUploadFail(status){
        this.$toast.error('Unable to upload file due to an unexpected error please try again')
      },

      async onUpdateClicked() {
         try {
          this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
          let isValid = await this.$validator.validateAll("company")
        
          if(isValid) {
            let updatedObj = Object.assign({}, this.company)
            delete updatedObj['logo']
            delete updatedObj['ID']
            delete updatedObj['date_created']
            delete updatedObj['date_updated']

            await this.updateCompanyDetails(updatedObj)
            this.$toast.success('Company details were updated successfully')
          } else {
            this.$toast.error('Please check to ensure that the values entered are valid.', '', {position: 'topCenter'})
          }
        } catch (e) {
          console.log(e)
          this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      }
  }
}
</script>