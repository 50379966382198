import httpClient from '@/common/http'
import storageService from "@/services/storage-service";

export default {
  create(obj) {
    let branch = storageService.getItem('user').branch || null
    let branchID = branch.ID || ''
    return httpClient.request('post', `branches/${branchID}/persons`, obj)
  },

  update(id, obj) {
    return httpClient.request('put', `persons/${id}`, obj)
  },

  createBusiness(obj) {
    let branch = storageService.getItem('user').branch || null
    let branchID = branch.ID || ''
    return httpClient.request('post', `branches/${branchID}/businesses`, obj)
  },

  updateBusiness(id, obj) {
    return httpClient.request('put', `businesses/${id}`, obj)
  },

  findBusiness(id) {
    return httpClient.request('get', `businesses/${id}`)
  },

  delete(id) {
    return httpClient.request('delete', `persons/${id}`)
  },

  findOne(id) {
    return httpClient.request('get', `persons/${id}`)
  },

  findAll(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
   
    return httpClient.request('get', `persons?${filter}${query}`)
  },

  findAllNonUsers(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
   
    return httpClient.request('get', `persons/find/non_users?${filter}${query}`)
  },

  findBusinesses(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
   
    return httpClient.request('get', `businesses?${filter}${query}`)
  },

  findById(filter, sort, paginate = {skip: 0, limit: -1}) {
    let query = `${sort}`
    query += (paginate.limit < 1) ? '' : `&skip=${paginate.skip}&limit=${paginate.limit}`
    return httpClient.request('get', `persons/find/identifications?${filter}${query}`)
  },

  updateDSR(id, dsrItems) {
    return httpClient.request('put', `persons/${id}/dsr`, dsrItems)
  },

  updateNetworth(id, items) {
    return httpClient.request('put', `persons/${id}/networth`, items)
  },

  getDSRItems(id) {
    return httpClient.request('get', `persons/${id}/dsr`)
  },



}