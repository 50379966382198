import httpClient from '@/common/http'

export default {
  addTemplate(obj) {
    return httpClient.request('post', 'settings/loan-templates/add-template-type', obj, 'app-center')
  },

  getTemplate() {
    return httpClient.request('get', 'settings/loan-templates/get-template-type', null, 'app-center')
  },

  editTemplate(obj) {
    return httpClient.request('put', 'settings/loan-templates/edit-template-type', obj, 'app-center')
  }

}