<template>
  <div id="loan-settings-layout">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<style>

</style>

<script>
import {mapMutations} from 'vuex'
export default {
  created() {
   this.init()
    this.TOGGLE_SIDEBAR_VISIBILITY(true)
  },

  data() {
    return {
      routes: [        
        { heading: 'Transaction Monitoring Settings' },
        { title: 'General', route: '/app/app-settings/loan-origination-settings/loan-general', icon: 'fa-cog', iconSize: '12px'},

        { divider: true },
        { heading: 'Loan Policy Settings' },
      
      ]
    }
  },

  beforeDestroy() {
  },

  methods: {
    ...mapMutations([
      'SET_SIDEBAR_ROUTES',
      'TOGGLE_SIDEBAR_MINI',
      'TOGGLE_SIDEBAR_VISIBILITY'
    ]),
    init() {
      this.SET_SIDEBAR_ROUTES(this.routes)
    }
  },


}
</script>
