<template>
  <div>
    <v-card class="elevation-0 pa-2 pl-4 mb-3">
      <h2>Loan Review Checklist Items</h2>
    </v-card>
    <v-dialog v-model="modal" persistent max-width="700">
      <v-card>
        <v-toolbar dense color="primary" class="elevation-3" dark tabs>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="mb-4">
          <v-layout>
            <va-input
              class="mr-3"
              v-model="item.checklist_code"
              v-validate="'required|min:2|max:20'"
              data-vv-scope="loan"
              :error-messages="errors.collect('loan.checklist_code')"
              placeholder=" "
              width="320px"
              label="Checklist Code"
              data-vv-validate-on="blur"
              data-vv-name="checklist_code"
              data-vv-as="checklist code"
              show-clean
            />

            <va-input
              class="ml-2"
              v-model="item.checklist_name"
              v-validate="'required|min:3|max:40'"
              data-vv-scope="loan"
              width="320px"
              :error-messages="errors.collect('loan.checklist_name')"
              placeholder=" "
              label="Checklist Name"
              data-vv-validate-on="blur"
              data-vv-name="checklist_name"
              data-vv-as="checklist name"
              show-clean
            />
          </v-layout>

          <v-layout>
            <va-select
              class="mr-3"
              v-model="item.checklist_type"
              v-validate="'required'"
              width="320px"
              placeholder=" "
              label="Checklist Type"
              data-vv-scope="loan"
              :error-messages="errors.collect('loan.checklist_type')"
              :items="['Document', 'Action']"
              data-vv-as="check list type"
              data-vv-name="checklist_type"
            />

            <va-select
              class="ml-2"
              v-model="item.priority"
              v-validate="'required'"
              width="320px"
              placeholder=" "
              label="Priority Level"
              data-vv-scope="loan"
              :error-messages="errors.collect('loan.priority')"
              :items="['High', 'Medium', 'Low']"
              data-vv-as="priority"
              data-vv-name="priority"
            />
          </v-layout>
          <label for style="font-size: 12px;">Minimum Amount</label>
          <money
            v-model="item.minimum_amount"
            v-bind="money"
            v-validate="'required|min_value:0'"
            data-vv-scope="loan"
            width="320px"
            :error-messages="errors.collect('loan.minimum_amount')"
            data-vv-validate-on="blur"
            data-vv-name="minimum_amount"
            data-vv-as="minimum amount"
          />
          <p class="error-message">{{ errors.first("loan.minimum_amount") }}</p>
          <v-layout>
            <v-flex>
              <v-checkbox
                v-model="item.enabled_on_e_service"
                v-if="item.checklist_type == 'Document'"
                :label="`Enable on E-Lender`"
              ></v-checkbox>
            </v-flex>
            <v-flex>
              <v-checkbox
                v-model="item.mandatory_on_e_service"
                v-if="
                  item.checklist_type == 'Document' && item.enabled_on_e_service
                "
                :label="`Mandatory on E-Lender`"
              ></v-checkbox>
            </v-flex>
          </v-layout>
          <h3>Select the stage this item is required at</h3>
          <v-divider></v-divider>
          <v-radio-group
            style="width: 100% !important"
            v-model="item.required_stage"
            column
          >
            <v-radio
              :value="'pre-approval'"
              label="Pre-approval Securities Check"
            ></v-radio>
            <v-radio :value="'reviewal'" label="Reviewing Officer"></v-radio>
            <v-radio :value="'approval'" label="Approving Officer"></v-radio>
            <v-radio
              :value="'pre-disbursement'"
              label="Pre-disbursement Securities Check"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeModal">Cancel</v-btn>
          <v-btn color="primary" @click="submit">{{
            item.ID ? "Update" : "Save"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-toolbar dense color="white" class="pa-0 elevation-0">
        <v-text-field
          v-model="search"
          solo
          label="Search"
          prepend-inner-icon="search"
        ></v-text-field>

        <v-divider class="mr-2" vertical></v-divider>

        <v-btn color="primary" @click="openModal" dark class="mb-2"
          >New ITEM</v-btn
        >
      </v-toolbar>

      <v-tabs
        v-model="tabModel"
        centered=""
        class="mb-3 elevation-0"
        slider-color="blue"
      >
        <v-tab :href="`#tab-all`">All Items</v-tab>
        <v-tab :href="`#tab-papproval`">Loan Processing Officer</v-tab>
        <v-tab :href="`#tab-review`">Reviewing Officer</v-tab>
        <v-tab :href="`#tab-approval`">Approving Officer</v-tab>
        <v-tab :href="`#tab-disburse`"
          >Pre-disbursement/Legal Securities Check</v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tabModel">
        <v-tab-item :value="'tab-all'">
          <v-data-table
            hide-actions
            :headers="tblHeaders"
            :items="
              items.filter(
                (item) =>
                  item.checklist_name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
            "
            class="elevation-0"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                {{ props.item.checklist_code | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_name | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_type | capitalize }}
              </td>
              <td class="text-xs-left">{{ props.item.priority }}</td>
              <td class="text-xs-left">
                {{ props.item.minimum_amount | currency }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="edit(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item :value="'tab-papproval'">
          <v-data-table
            hide-actions
            :headers="tblHeaders"
            :items="
              items.filter(
                (item) =>
                  item.required_stage === 'pre-approval' &&
                  item.checklist_name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
            "
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                {{ props.item.checklist_code | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_name | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_type | capitalize }}
              </td>
              <td class="text-xs-left">{{ props.item.priority }}</td>
              <td class="text-xs-left">
                {{ props.item.minimum_amount | currency }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="edit(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item :value="'tab-review'">
          <v-data-table
            hide-actions
            :headers="tblHeaders"
            :items="
              items.filter(
                (item) =>
                  item.required_stage === 'reviewal' &&
                  item.checklist_name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
            "
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                {{ props.item.checklist_code | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_name | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_type | capitalize }}
              </td>
              <td class="text-xs-left">{{ props.item.priority }}</td>
              <td class="text-xs-left">
                {{ props.item.minimum_amount | currency }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="edit(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item :value="'tab-approval'">
          <v-data-table
            hide-actions
            :headers="tblHeaders"
            :items="
              items.filter(
                (item) =>
                  item.required_stage === 'approval' &&
                  item.checklist_name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
            "
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                {{ props.item.checklist_code | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_name | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_type | capitalize }}
              </td>
              <td class="text-xs-left">{{ props.item.priority }}</td>
              <td class="text-xs-left">
                {{ props.item.minimum_amount | currency }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="edit(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item :value="'tab-disburse'">
          <v-data-table
            hide-actions
            :headers="tblHeaders"
            :items="
              items.filter(
                (item) =>
                  item.required_stage === 'pre-disbursement' &&
                  item.checklist_name
                    .toLowerCase()
                    .indexOf(search.toLowerCase()) !== -1
              )
            "
            class="elevation-1"
          >
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">
                {{ props.item.checklist_code | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_name | capitalize }}
              </td>
              <td class="text-xs-left">
                {{ props.item.checklist_type | capitalize }}
              </td>
              <td class="text-xs-left">{{ props.item.priority }}</td>
              <td class="text-xs-left">
                {{ props.item.minimum_amount | currency }}
              </td>
              <td>
                <v-icon small class="mr-2" @click="edit(props.item)">
                  edit
                </v-icon>
                <v-icon small class="mr-2" @click="deleteItem(props.item)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<style type="css" scoped>
h1,
h2,
h3 {
  color: #505a6b;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
import checklistService from "@/services/loan-checklist-service";
import validations from "@/common/validator";
import settingsService from "@/services/general-settings-service";
import { Money } from "v-money";
export default {
  components: {
    VaInput,
    VaSelect,
    Money,
  },

  created() {
    this.initialize();
  },

  data() {
    return {
      selectedStage: null,
      modal: false,
      search: "",
      loanSettings: {},
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      totalCount: 0,
      tabModel: "tab-all",
      tblHeaders: [
        {
          text: "Code",
          align: "left",
          sortable: false,
          value: "checklist_code",
        },
        {
          text: "Name",
          align: "left",
          sortable: false,
          value: "checklist_name",
        },
        {
          text: "Type",
          align: "left",
          sortable: false,
          value: "checklist_type",
        },
        { text: "Priority", align: "left", sortable: false, value: "priority" },
        {
          text: "Minimum Amount",
          align: "left",
          sortable: false,
          value: "minimum_amount",
        },
        { text: "Action", align: "left", sortable: false },
      ],
      defaultItem: {
        checklist_code: "",
        checklist_name: "",
        checklist_type: "",
        minimum_amount: 0,
        required_stage: "",
        priority: "",
      },
      item: {},
      items: [],
    };
  },

  computed: {
    ...mapState(["appState", "loanItemState", "deleteLoanItem"]),

    formTitle() {
      return this.item.ID ? "Edit Checklist Item" : "New Checklist Item";
    },
  },

  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

    openModal() {
      this.item = { ...this.defaultItem };
      this.modal = true;
    },

    closeModal() {
      this.item = { ...this.defaultItem };
      this.modal = false;
    },

    edit(item) {
      this.item = { ...item };
      this.modal = true;
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let result = await settingsService.findLoanSettings();
        if (Object.keys(result).length > 0) {
          for (let key in result) {
            if (
              typeof result[key] === "string" &&
              result[key].indexOf("{'") > -1
            ) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }
          this.loanSettings = result;
          return true;
        }
        return false;
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async initialize() {
      await this.fetchSettings();
      if (!this.loanSettings.use_checklist) {
        this.$router.replace(
          "/app/app-settings/loan-origination-settings/loan-general"
        );
      }
      this.$validator.extend(
        validations.notNegative.name,
        validations.notNegative.validator
      );
      await this.fetchData();
    },

    async deleteItem(item) {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      this.$toast.question(
        "Are you sure you want to delete this checklist item?",
        "",
        {
          timeout: 20000,
          close: false,
          overlay: true,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              async (instance, toast) => {
                try {
                  await checklistService.delete(item.ID);

                  let len = this.items.length;
                  for (let i = 0; i < len; i++) {
                    if (item.ID == this.items[i].ID) this.items.splice(i, 1);
                  }

                  this.$toast.warning("Record has been deleted!", "", {
                    position: "topCenter",
                  });
                } catch (e) {
                  console.log("deleting: ", e);
                  if (e.response) {
                    if (e.response.status === 404)
                      this.$toast.error(
                        "Account has been already deleted",
                        "",
                        { position: "center" }
                      );
                    else this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
                  }
                }
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
              true,
            ],
            [
              "<button>NO</button>",
              function(instance, toast) {
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
            ],
          ],
        }
      );
    },

    async submit() {
      if (!this.item.required_stage) {
        this.$toast.error(
          "Please select at least one of the stages this item is required at.",
          "",
          { position: "topCenter" }
        );
        return;
      }

      let isValid = await this.$validator.validateAll("loan");
      if (isValid) {
        await this.save();
      } else {
        this.$toast.error(
          "Please check to ensure that all fields are valid.",
          "",
          { position: "topCenter" }
        );
      }
    },

    async save() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let data = { ...this.item };
        if (this.item.ID) {
          delete data["date_created"];
          delete data["date_updated"];

          await checklistService.update(this.item.ID, data);
          let len = this.items.length;
          for (let i = 0; i < len; i++) {
            if (this.item.ID == this.items[i].ID)
              this.items.splice(i, 1, { ...this.item });
          }
          this.$toast.success("Successfully updated record!", "", {
            position: "topCenter",
          });
        } else {
          let res = await checklistService.create(data);
          this.item.ID = res.data;
          this.items.push(this.item);
          this.$toast.success("Successfully inserted record!", "", {
            position: "topCenter",
          });
        }
        this.closeModal();
      } catch (e) {
        console.log("saving/updating: ", e);
        if (e.response) {
          if (e.response.status === 409)
            this.$toast.error(e.response.data.error.message, "", {
              position: "center",
            });
          else this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
        }
      }
    },

    async fetchData() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let response = await checklistService.findAll();
        this.items = response.data;
      } catch (e) {
        console.log("fetching: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },
  },
};
</script>
