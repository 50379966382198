<template>
  <div id="business-form">
   
    <v-dialog persistent v-model="customerSearchDialog" width="600px">
      <v-card class="pr-4 pl-4 pt-4 pb-2" color="white">
        <p class="subheading">Search for an existing customer</p>
        <v-autocomplete
          v-model="searchCustomerModel"
          :items="customers"
          :loading="isLoading"
          chips
          @input.native="onFindCustomer($event)"
          @change="onSelectCreated($event)"
          clearable
          item-text="trn"
          item-value="ID"
          label="Search for a customer..."
          ref="searchCustomerRef"
          solo
        >
          <template
            v-slot:selection="{ item, selected }"
          >{{item.first_name + ' ' + item.last_name + ' - ' + item.trn}}</template>
          <template v-slot:item="{ item }">
            <v-list-tile-avatar>
              <img
                :src="item.avatar || require('@/assets/img/avatar.png')"
                style="border-radius: 50%; width 36px; height: 36px"
                class="mb-3"
              >
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title v-text="item.first_name + ' ' + item.last_name"></v-list-tile-title>
              <v-list-tile-sub-title v-text="item.trn"></v-list-tile-sub-title>
            </v-list-tile-content>
            <v-list-tile-action>
              <v-icon>fa-user</v-icon>
            </v-list-tile-action>
          </template>
        </v-autocomplete>
        <v-card-actions class="pt-4">
          <v-spacer/>
          <v-btn
            color="primary"
            :loading="isLoading"
            @click="customerSearchDialog = false; searchCustomerDB = ''; customers = []"
          >Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="formOpened"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >

    <!-- Possible Duplcation Found Dialog -->
     <v-dialog persistent v-model="isUiqDialog" max-width="550">
      <v-card dark>
        <v-card-title class="headline font-weight-bold pb-0 mb-0">Found Business With Similar Details</v-card-title>

        <v-card-text>
          <v-layout>
            <v-flex xs4>
            <v-img
              aspect-ratio="1"
              slot="activator"
              class="mt-3"
              :src="possibleDuplicateBusiness.logo || require('@/assets/img/logo-here.png')"
              :lazy-src="``"
              id="miniLogo"
              @click="showUploaderDialog"
            />
            </v-flex>
            <v-flex xs8>
              <div style="text-align:left">
                <p class="headline mt-3 white--text text--darken-3 font-weight-bold mb-1">{{possibleDuplicateBusiness.business_name}}</p>
                <p class="body-2 mb-1 white--text text--darken-3 font-weight-medium">{{possibleDuplicateBusiness.trn || 'N/A'}} (Tax Number)</p>
                <p class="body-2 mb-2 white--text text--darken-3 font-weight-bold mb-1">{{possibleDuplicateBusiness.business_type}} (Business Type)</p>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn flat="flat"  @click="isUiqDialog = false" > Cancel </v-btn>
          <v-btn flat="flat" @click="gotoProfile"> Go to Profile </v-btn>
          <v-btn color="red lighten-1" flat="flat" @click="save" > Still Save? </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-card>
        <v-dialog v-model="isLoading" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              {{loadingText}}
              <v-progress-linear indeterminate color="white" class="mb-0"/>
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-toolbar  dense dark color="blue darken-3">
          <v-btn icon dark @click="formOpened = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{formTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat :loading="isLoading" @click="onSubmit">Save</v-btn>
          </v-toolbar-items>
          <template slot="extension">
            <v-tabs
            flat
              v-model="tabModel"
              fixed-tabs
              centered
              color="blue darken-2"
              slider-color="white"
            >
              <v-tab :href="`#tab-personal`">Business Details</v-tab>
              <v-tab :href="`#tab-owner`">Owner(s)</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tabModel">
          <v-tab-item :value="`tab-personal`" class="pa-4">
            <h3>DETAILS</h3>
            <v-divider></v-divider>
            <v-layout row>
              <v-flex xs12 md3 class="pa-3">
                <v-tooltip bottom>
                  <v-img
                    aspect-ratio="1"
                    slot="activator"
                    class="mt-3"
                    :src="imgDataUrl"
                    :lazy-src="``"
                    id="logo"
                    @click="showUploaderDialog"
                  />
                  <span>Click to change logo</span>
                </v-tooltip>
                <avatar-uploader
                  lang-type="en"
                  field="avatar"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  v-model="showUploader"
                  :params="uploadParams"
                  :width="200"
                  :height="200"
                  :url="uploadUrl"
                  :headers="headers"
                  img-format="png"
                />
              </v-flex>
              <v-flex xs12 md5 class="pa-3">
                <v-layout row>
                  <v-flex xs12 class="pa-3">
                    <va-input
                      v-model="business.business_name"
                      v-validate="'required|min:2|max:100'"
                      data-vv-scope="business"
                      data-vv-validate-on="blur"
                      data-vv-as="business name"
                      label="Business Name"
                      data-vv-name="business_name"
                      placeholder=" "
                      :error-messages="errors.collect('business.business_name')"
                    />
                    <va-select
                      v-model="business.business_type"
                      v-validate="'required'"
                      data-vv-scope="business"
                      placeholder=" "
                      label="Business Type"
                      :error-messages="errors.collect('business.business_type')"
                      data-vv-validate-on="blur"
                      :items="['Sole Proprietor','Partnership','Company']"
                      data-vv-as="business type"
                      data-vv-name="business_type"
                    />
                    <va-input
                      v-model="business.trn"
                      v-validate="business.business_type === 'Company' ? 'required|min:6|max:40' : 'min:6|max:40'"
                      data-vv-scope="business"
                      data-vv-validate-on="blur"
                      data-vv-as="tax registration number"
                      label="Tax Registration Number"
                      data-vv-name="trn"
                      placeholder=" "
                      :error-messages="errors.collect('business.trn')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 md4 class="pa-3">
                <v-flex xs12 class="pa-3">
                  <va-input
                    v-validate="{ required: true, regex:/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/}"
                    v-model="business.contact_info.cell"
                    placeholder=" "
                    data-vv-scope="business"
                    label="Cell"
                    data-vv-name="cell"
                    data-vv-as="cell"
                    :return-masked-value="true"
                    :persistent-hint="true"
                    :hint="'Expected Format: 000 000 0000'"
                    :mask="'(###) ###-####'"
                    :error-messages="errors.collect('business.cell')"
                  />
                  <va-input
                    v-validate="{ required: false, regex:/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/}"
                    v-model="business.contact_info.phone1"
                    placeholder=" "
                    data-vv-scope="business"
                    label="Telephone"
                    data-vv-name="phone1"
                    data-vv-as="telephone"
                    :return-masked-value="true"
                    :persistent-hint="true"
                    :hint="'Expected Format: 000 000 0000'"
                    :mask="'(###) ###-####'"
                    :error-messages="errors.collect('business.phone1')"
                  />

                  <va-input
                    v-validate="'email|max:255'"
                    v-model="business.contact_info.email"
                    data-vv-scope="business"
                    data-vv-validate-on="blur"
                    placeholder=" "
                    label="Email"
                    data-vv-name="email"
                    :error-messages="errors.collect('business.email')"
                  />
                </v-flex>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>
            <h3>ADDITIONAL INFORMATION</h3>
            <v-layout row wrap>
              <v-flex xs12 md3 class="pa-3">
                 <va-input
                  v-model="business.date_of_inception"
                  v-validate="'required|date_format:YYYY-MM-DD|not_greater_than_today'"
                  data-vv-scope="business"
                  data-vv-as="date of inception"
                  :error-messages="errors.collect('business.date_of_inception')"
                  placeholder=" "
                  return-masked-value
                  mask="####-##-##"
                  label="Date of Inception (yyyy-mm-dd)"
                  data-vv-validate-on="blur"
                  data-vv-name="date_of_inception"
                />
              </v-flex>
              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate
                  v-model="business.main_business_activity"
                  placeholder=" "
                  data-vv-scope="business"
                  label="Main Buisness Activity"
                  data-vv-name="main_business_activity"
                  data-vv-as="main business activity"
                  :error-messages="errors.collect('business.main_business_activity')"
                />
              </v-flex>
              <v-flex xs12 md3 class="pa-3">
                <label for="">Expected Monthly Sales</label>
                <money
                  v-model.lazy="business.expected_monthly_sales"
                  v-validate="'required|decimal|not_negative|greater_than:0'"
                  v-bind="money"
                  placeholder=" "
                  data-vv-scope="business"
                  label=""
                  data-vv-name="expected_monthly_sales"
                  data-vv-as="expected monthly sales"
                  single-line
                  bottom
                ></money>  
                <p class="error-message">{{errors.first('business.expected_monthly_sales')}}</p>
              </v-flex>
              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate="'required'"
                  v-model="business.source_of_fund"
                  placeholder=" "
                  data-vv-scope="business"
                  label="Source of Fund"
                  data-vv-name="source_of_fund"
                  data-vv-as="source of fund"
                  :error-messages="errors.collect('business.source_of_fund')"
                />
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
            <h3>ADDRESS</h3>
            <v-layout>
              <v-flex xs12 md6 class="pa-2">
                <h4 class="mt-4 mb-4">Living Address</h4>
                <va-select
                  v-model="business.living_address.country"
                  v-validate="'required'"
                  data-vv-scope="business"
                  placeholder=" "
                  label="Country"
                  @change="onCountryChanged($event)"
                  :error-messages="errors.collect('business.country')"
                  :items="commonState.countries"
                  :item-text="'name'"
                  :item-value="'name'"
                  data-vv-name="country"
                />

                <va-select
                  :disabled="!business.living_address.country"
                  v-model="business.living_address.province"
                  v-validate="'required'"
                  placeholder=" "
                  label="Province/State"
                  data-vv-scope="business"
                  :error-messages="errors.collect('business.living_province')"
                  :items="provinces"
                  data-vv-name="living_province"
                  data-vv-as="living province"
                />

                <va-input
                  v-model="business.living_address.city"
                  v-validate="'required|min:3|max:50'"
                  data-vv-scope="business"
                  :error-messages="errors.collect('business.living_city')"
                  placeholder=" "
                  label="City/Town"
                  data-vv-validate-on="blur"
                  data-vv-name="living_city"
                  data-vv-as="living city"
                  show-clean
                />

                <va-input
                  v-model="business.living_address.street"
                  v-validate="'required|min:3|max:100'"
                  :error-messages="errors.collect('business.living_street')"
                  data-vv-scope="business"
                  placeholder=" "
                  label="Address"
                  data-vv-validate-on="blur"
                  data-vv-name="lliving_street"
                  data-vv-as="living street"
                  show-clean
                />
              </v-flex>

              <v-flex xs12 md6 class="pa-2">
                <h4 class="mt-4">Mailing Address</h4>
                <p class="mb-3 red--text text--darken-4">Leave <b>empty</b> if is the same as <b>living address</b></p>
                <va-select
                  :disabled="!business.living_address.country"
                  v-model="business.mailing_address.province"
                  placeholder=" "
                  label="Province/State"
                  data-vv-scope="business"
                  :error-messages="errors.collect('business.mailing_province')"
                  :items="provinces"
                  data-vv-name="mailing_province"
                  data-vv-as="mailing province"
                />

                <va-input
                  v-model="business.mailing_address.city"
                  v-validate="'min:3|max:50'"
                  data-vv-scope="business"
                  :error-messages="errors.collect('business.mailing_city')"
                  placeholder=" "
                  label="City/Town"
                  data-vv-validate-on="blur"
                  data-vv-name="mailing_city"
                  data-vv-as="mailing city"
                />

                <va-input
                  v-model="business.mailing_address.street"
                  v-validate="'min:3|max:100'"
                  :error-messages="errors.collect('business.mailing_street')"
                  data-vv-scope="business"
                  placeholder=" "
                  label="Street/District"
                  data-vv-validate-on="blur"
                  data-vv-name="mailing_street"
                  data-vv-as="mailing street"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>
          <v-tab-item :value="`tab-owner`" class="pa-4">
            <v-toolbar dense dark color="#485468" class="elevation-1">
              <v-toolbar-title>Business Owners</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="hidden-sm-and-down">
                <v-btn dark flat @click="addExisting">Add</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-data-table
              :headers="idHeaders"
              hide-actions
              :items="business.owners"
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td class="text-xs-left">{{ props.item.trn }}</td>
                  <td class="text-xs-left">{{ props.item.first_name + ' ' + props.item.last_name }}</td>
                  <td>
                    <va-select
                      v-validate="'required'"
                      :items="ownerTypes"
                      v-model="props.item.owner_type"
                      placeholder="Select Owner Type"
                      data-vv-scope="owner"
                      :data-vv-name="'type'+props.index"
                      data-vv-as="owner type"
                      :error-messages="errors.first('owner.type'+props.index)"
                      single-line
                    ></va-select>
                  </td>
                  <td class="text-xs-left">
                    <va-input
                      v-model="props.item.percentage_ownership"
                      placeholder="Percentage Ownership"
                      single-line
                      data-vv-scope="ben"
                      v-validate="'required|decimal|greater_than:0|max_value:100'"
                      :data-vv-name="'percentage_ownership'+props.index"
                      data-vv-as="percentage ownership"
                      :error-messages="errors.first('ben.percentage_ownership'+props.index)"
                    />
                  </td>
                  <td>
                    <v-icon small class="mr-2" @click="deleteId(props.item)">remove</v-icon>
                  </td>
                </tr>
              </template>
              <template slot="footer">
                <tr>
                  <td class="text-xs-right" colspan="3"> <b>Total Percentage of Ownership</b></td>
                  <td class="text-xs-left" colspan="2">{{totalPercentage + '%'}}</td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
h1,
h2,
h3 {
  color: #485468;
}

#logo {
  margin: 0 auto;
  border: 1px solid #ddd;
  width: 200px;
  height: 200px;
}

#miniLogo {
  margin: 0 auto;
  border: 1px solid #ddd;
  width: 100px;
  height: 100px;
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
import uploader from "vue-image-crop-upload";
import validations from "@/common/validator";
import { Money } from 'v-money'
import _ from 'lodash';

import storageService from "@/services/storage-service";
import customerService from "@/services/customer-service";
import AuthService from "@/services/authentication-service";
export default {
  components: {
    "avatar-uploader": uploader,
    VaInput,
    VaSelect,
    Money
  },
  data() {
    return {
      searchCustomerDB: "",
      customerSearchDialog: false,
      customers: [],
      searchCustomerModel: null,
      uploadUrl: "",
      imgDataUrl: "",
      loadingText: "",
      showUploader: false,
      isLoading: false,
      tabModel: "tab-personal",
      idHeaders: [
        { text: "Tax Number", align: "left", sortable: false, value: "number" },
        { text: "Name", align: "left", sortable: false, value: "id_type" },
        { text: "Ownership Type", align: "left", sortable: false },
        { text: "Percentage Ownership", align: "left", sortable: false },
        { text: "Action", align: "left", sortable: false }
      ],
      uploadParams: { ID: "" },
      headers: {
        Authorization: `Bearer ` + storageService.getItem("token") || null
      },
      id: {},
      idIndex: -1,
      idDialog: false,
      previousId: {},
      provinces: [],
      employmentProvinces: [],
      schoolProvinces: [],
      countries: [],
      totalPercentage: 0,
      ownerTypes: ["Principal Owner", "Joint Owner"],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false
      },

      isUiqDialog: null,
      possibleDuplicateBusiness: {},
    };
  },

  props: {
    opened: {
      type: Boolean,
      default: false
    },
    business: {
      type: Object,
      default: function() {
        return {
          trn: "",
          logo: "",
          business_name: "",
          business_type: "",
          main_business_activity: "",
          source_of_fund: "",
          expected_monthly_sales: 0.00,
          contact_info: {},
          living_address: { type: "Living" },
          mailing_address: { type: "Mailing" },
          owners: []
        };
      }
    }
  },

  created() {
    this.uploadUrl = process.env.VUE_APP_ROOT_API + "businesses/logo";
    this.imgDataUrl = require("@/assets/img/logo-here.png");
    
    this.setupValidations();
  },

  watch: {
    opened: async function() {
      if (!this.opened) {
        this.isLoading = false;
        return false;
      }

      if(!storageService.user) {
        AuthService.logout();
        this.isLoading = false;
        return false
      }

      try {
        let user = JSON.parse(storageService.user)
        let country = this.business.living_address.country || user.branch.branch_country
        this.loadingText = "Loading...";
        this.isLoading = true;
        this.$validator.reset()

        await this.init();
        
        this.imgDataUrl = this.business.logo || require("@/assets/img/logo-here.png");

        this.onCountryChanged(country);
        this.tabModel = "tab-personal";
        this.uploadParams["ID"] = this.business.ID;
      } finally {
        this.isLoading = false;
      }
    },
    'business.owners' : {
      deep: true,
      handler: function() {
        this.totalPercentage = this.business.owners.reduce((acc, cur) => {
          acc += Number(cur.percentage_ownership || 0)
          return acc
        }, 0)
        
      }
    }
  },

  computed: {
    ...mapState(["appState", "commonState"]),

    formOpened: {
      get: function() {
        return this.opened;
      },

      set: function(newValue) {
        this.$validator.reset();
        this.$emit("update:opened", newValue);
      }
    },

    formTitle() {
      return this.business.ID ? "Edit Business" : "New Business";
    }
  },
  methods: {
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),

    ...mapActions(["getCountries"]),

    async init() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        await this.getCountries();
      } catch (e) {
        console.log(e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    setupValidations() {
      this.$validator.extend(
        validations.greaterThanToday.name,
        validations.greaterThanToday.validator
      );
      this.$validator.extend(
        validations.isBigger.name,
        validations.isBigger.validator,
        { hasTarget: true }
      );
      this.$validator.extend(
        validations.notNegative.name,
        validations.notNegative.validator
      );
      this.$validator.extend(
        validations.greaterThan.name,
        validations.greaterThan.validator
      );
    },

    onCountryChanged(ev) {
      this.provinces = [];
      this.business.living_address.country = ev;
      this.business.mailing_address.country = ev;
      for (let key in this.commonState.countries) {
        if (this.commonState.countries[key].name === ev) {
          let divisions = [];
          Object.keys(this.commonState.countries[key].divisions).forEach(
            keyx => {
              divisions.push(this.commonState.countries[key].divisions[keyx]);
            }
          );
          this.provinces = divisions;
          break;
        }
      }
    },

    addExisting() {
      this.customerSearchDialog = true;
      this.customers = [];
    },

    closeIdDialog() {
      this.customerSearchDialog = false;
    },

    deleteId(item) {
      let index = this.business.owners.indexOf(item);
      this.business.owners.splice(index, 1);
    },

    showUploaderDialog() {
      if (!this.business.ID) {
        this.$toast.destroy();
        this.$toast.error(
          "Unable to complete action please save business details then try again",
          "",
          { position: "topRight" }
        );
        return;
      }
      this.showUploader = true;
    },

    cropSuccess(imgDataUrl) {
      this.croppedImage = imgDataUrl;
    },

    cropUploadSuccess(jsonData) {
      this.imgDataUrl = this.croppedImage;
      this.business.avatar = jsonData.data;
      this.$emit("updated", { ...this.business });
      this.$toast.success("Customer Image was successfully changed");
    },

    cropUploadFail() {
      this.$toast.error(
        "Unable to upload file due to an unexpected error please try again"
      );
    },

    async onSubmit() {
      let isValid = await this.$validator.validateAll("business");
      if (!isValid) {
        this.tabModel = "tab-personal";
        this.$toast.error(
          "Unable to complete action please ensure all fields are valid",
          "",
          { position: "topCenter" }
        );
        return;
      }

      if (this.business.owners.length === 0) {
        this.tabModel = "tab-owner";
        this.$toast.error("Business must have at least one owner", "", {
          position: "topCenter"
        });
        return;
      }

      if(this.totalPercentage !== 100) {
        this.tabModel = "tab-owner";
        this.$toast.error("Total Percentage of Ownership must be 100%", "", {
          position: "topCenter"
        });
        return;
      }
      
      Object.keys(this.business.living_address).forEach(key => {
        if (!this.business.mailing_address[key])
          this.business.mailing_address[key] = this.business.living_address[
            key
          ];
      });
      
      try{
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        //check if business is unique
        let result = await this.checkIfBusinessIsUnqiue()
        if(result.length === 0) {
          await this.save();
          return
        } 

        this.possibleDuplicateBusiness = result[0];
        this.isUiqDialog = true;
      } catch (e) {
        console.error('[saving business] ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false;
      }
    },

    async checkIfBusinessIsUnqiue() {
      this.loadingText = "Checking Uniqueness...";
      this.isLoading = true;
      let result = {data: []}

      try {
        let searchMap = {
          "trn" : this.business.trn,
          "business_name" : this.business.business_name,
          "business_type" : this.business.business_type,
        }

        let search = ``

        //remove empty strings
        Object.keys(searchMap).forEach((key)=>{
          if(!searchMap[key] || !searchMap[key].trim()) {
            delete searchMap[key]
          }
        })

        if(this.business.ID) {
          let resultSet = await customerService.findBusiness(this.business.ID)
          let previousData = resultSet.data

          if(
            previousData["trn"] === searchMap["trn"] &&
            previousData["business_name"] === searchMap["business_name"] &&
            previousData["business_type"] === searchMap["business_type"]
          ) {
            delete searchMap["trn"]
            delete searchMap["business_name"]
            delete searchMap["business_type"]
          }
        }

        Object.keys(searchMap).forEach((key)=>{
          if(key === "trn") {
            search += `${key}=eq:${searchMap[key]}:or&`
          } else {
            search += `${key}=eq:${searchMap[key]}:and&`
          }
        })
        
        if(search !== '')
          result = await customerService.findBusinesses(search, '', {skip: 0, limit:0})

      } catch (e) {
        throw e
      }
      return result.data
    },

    gotoProfile() {
      this.isUiqDialog = false;
      this.formOpened = false;
      this.$emit('gotoprofile', this.possibleDuplicateBusiness);
    },

    async save() {
      try {
        this.loadingText = "Saving...";
        this.isLoading = true;
        let editedObj = _.cloneDeep(this.business);

        this.deleteUnwantedProperties(editedObj);
        //editedObj.expected_monthly_sales = editedObj.expected_monthly_sales.replace(/,/g, "")

        if (!editedObj.ID) {
          let response = await customerService.createBusiness(editedObj);
          let resultSet = await customerService.findBusiness(response.data);

          this.uploadParams["ID"] = response.data;
          
          this.$emit("created", _.cloneDeep(resultSet.data));
          this.$toast.success("Successfully added business record!", "OK", {
            position: "topRight"
          });
          
          this.tabModel = "tab-personal";
        } else {
          delete editedObj.ID;

          await customerService.updateBusiness(this.business.ID, editedObj);
          this.$toast.success("Successfully updated business!", "OK", {
            position: "topRight"
          });
          this.$emit("updated", { ...this.business });
          this.formOpened = false;
        }
      } catch (e) {
        if (e.response) {
          if (e.response.status !== 409) {
            throw e
          }
          this.$toast.error(e.response.data.error.message, "", {
            position: "center"
          });
        } else {
          throw e
        }
      } 
    },

    deleteUnwantedProperties(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          this.deleteUnwantedProperties(obj[key]);
        } else if (
          key === "date_created" ||
          key === "date_updated" ||
          key === "logo"
        ) {
          delete obj[key];
        }

        if (obj[key] === null) {
          obj[key] = "";
        }
      }
    },

    onSelectCreated(id) {
      let obj = this.customers.find(el => el.ID === id);

      if (obj) {
        this.business.owners.push(obj);
        this.customerSearchDialog = false;
        this.$nextTick(() => {
          this.searchCustomerModel = null;
        });
      }
    },

    searchValueExist(searchVal) {
      let searchList = this.business.owners.map(el => ({
        ID: el.ID,
        trn: el.trn,
        full_name: el.first_name + " " + el.last_name
      }));
      return searchList.filter(el => el.trn === searchVal).length > 0;
    },

    async findCustomer() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      try {
        this.isLoading = true;
        let value =
          this.$refs.searchCustomerRef.$el.getElementsByTagName("input")[0]
            .value || "";
        if (this.searchValueExist(value)) {
          this.$toast.warning("Person already exist on this business", "", {
            position: "center"
          });
          return;
        }

        let search = `trn=eq:${value}&`;
        let res = await customerService.findAll(search, "");
        this.customers = res.data;
      } catch (e) {
        console.log("fetching customer: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    onFindCustomer() {
      return this.findCustomer();
    }
  }
};
</script>
