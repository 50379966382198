<template>
 <div>
  <v-dialog v-model="modal" persistent max-width="360">
    <v-card>
      <v-card-title ><h2>{{formTitle}}</h2></v-card-title>
      <v-card-text class="mb-4">
        <va-input
          v-model="item.sub_account_type_code"
          v-validate="'required|min:2|max:10'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.sub_account_type_code')"
          placeholder=" "
          label="Sub Account Code"
          data-vv-validate-on="blur"         
          data-vv-name="sub_account_type_code"
          data-vv-as="sub account code"
          show-clean />

        <va-input
          v-model="item.sub_account_type_name"
          v-validate="'required|min:3|max:45'"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.sub_account_type_name')"
          placeholder=" "
          label="Sub Account Name"
          data-vv-validate-on="blur"         
          data-vv-name="sub_account_type_name"
          data-vv-as="sub account name"
          show-clean />

        <va-input 
          v-model="item.account_minimum_age"
          v-validate="'required|integer|min_value:0'"
          placeholder=" "
          label="Minimum Age"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.account_minimum_age')"
          data-vv-as="minimum age"
          data-vv-name="account_minimum_age"/>

        <va-select 
          v-model="item.account_type_id"
          v-validate="'required'"
          placeholder=" "
          label="Account Type"
          data-vv-scope="loan"
          :error-messages="errors.collect('loan.account_type')"
          :items="accountTypes"
          :item-value="'ID'"
          :item-text="'account_type_name'"
          data-vv-as="account scope"
          data-vv-name="account_type"/>

      </v-card-text>

      <v-card-actions style="margin-top:-30px" class="ml-4 mr-2">
        <v-spacer></v-spacer>
        <v-btn flat @click="closeModal">Cancel</v-btn>
        <v-btn color="primary" @click="submit">{{item.ID ? 'Update' : 'Save'}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-toolbar dense color="white" class="pa-0">
    <v-text-field
      v-model="search"
      solo
      label="Search"
      prepend-inner-icon="search"
    ></v-text-field>

    <v-divider class="mr-2" vertical></v-divider>

    <v-btn  color="primary" @click="openModal">
      ADD SUB TYPE
    </v-btn>
  </v-toolbar>
  <v-data-table 
    :loading="isLoading"
    :search="search"
    :headers="tblHeaders" 
    :items="items" 
    :rows-per-page-items='[10,20, 30,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    class="elevation-1">
    <template slot="items" slot-scope="props">
      <td><v-checkbox  @change="toggleSwitch(props.item)"  class="mt-3" v-model="props.item.is_active"></v-checkbox></td>
      <td class="text-xs-left">{{ props.item.sub_account_type_code }}</td>
      <td class="text-xs-left">{{ props.item.sub_account_type_name | capitalize }}</td>
      <td class="text-xs-left">{{ props.item.account_type_name | capitalize }}</td>
      <td class="text-xs-left">{{ props.item.account_minimum_age || 0 }}</td>
      <td >
        <v-icon small title="edit" class="mr-2" @click="edit(props.item)">
          edit
        </v-icon>
        <v-icon small title="delete" class="mr-2" @click="onDeleteItemClicked(props.item)">
          delete
        </v-icon>
      </td>
    </template>
  </v-data-table>
  
  <v-dialog v-model="deleteDialog" persistent max-width="400">
    <v-card>
      <v-card-title class="headline"></v-card-title>
      <v-card-text>
        <h3 class="text-xs-center pb-3">In order to delete this sub account you must select a replacement type.</h3>  
        
        <va-select 
          v-model="replacementID"
          v-validate="'required'"
          placeholder=" "
          label="Select a replacement sub account"
          data-vv-scope="delete"
          data-vv-as="replacement sub account"
          :error-messages="errors.collect('delete.replacement')"
          :items="items.filter( (el) => el.ID !== item.ID )"
          :item-text="'sub_account_type_name'"
          :item-value="'ID'"
          data-vv-name="replacement"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="deleteDialog = false">Cancel</v-btn>
        <v-btn color="red darken-3" flat @click="deleteItem">Delete</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapMutations } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import accountSettingsService from '@/services/account-settings-service'
import _ from 'lodash'

export default {
  components: {
    VaInput,
    VaSelect
  },

  created () {
    this.initialize()
  },
  
  data() {
    return {
      modal: false,
      isLoading: false,
      deleteDialog: false,
      replacementID: '',
      search: '',
      tblHeaders: [
        { text: 'Active', align: 'left', sortable: false },
        { text: 'Code', align: 'left', sortable: false, value: 'sub_account_type_code'},
        { text: 'Name', align: 'left', sortable: false, value: 'sub_account_type_name'},
        { text: 'Type', align: 'left', sortable: false, value: 'account_type'},
        { text: 'Minimum Age', align: 'left', sortable: false, value: 'account_minimum_age'},
        { text: 'Action', align: 'left', sortable: false },
      ],
      defaultItem: {
        sub_account_type_name: "",
        sub_account_type_code: "",
        account_type_id:"",
        account_minimum_age: 18,
        is_active: true
      },
      item: {},
      accountTypes: [],
      items: [],
    }
  },

  computed: {
    ...mapState([
      'appState',
      'loanItemState',
      'deleteLoanItem'
    ]), 
    
    formTitle () {
      return this.item.ID ? 'Edit Sub Account Type' : 'New Sub Account Type'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    openModal() {
      this.$toast.destroy()
      this.item = {...this.defaultItem}
      this.modal = true
    },

    closeModal() {
      this.$validator.reset()
      this.item = {...this.defaultItem}
      this.modal = false
    },

    edit(item) {
      this.item = {...item}     
      this.modal = true
    },

    async initialize() {
      await this.fetchData()
    },

    onDeleteItemClicked(item) {
      this.item = {...item}
      this.deleteDialog = true
    },

    async deleteItem() {
      let isValid = await this.$validator.validateAll("delete")
      if(!isValid) return

      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      this.$toast.question('Are you sure you want to delete this sub account type?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              await accountSettingsService.deleteSubAccountType(this.item.ID, this.replacementID)

              let len = this.items.length
              for(let i = 0; i < len; i++) {
                if(this.item.ID == this.items[i].ID) {
                  this.items.splice(i,1)
                  break
                }
              }
              this.deleteDialog = false

              this.$toast.warning('Sub Account has been deleted!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('deleting: ',e)
              if(e.response) {
                if(e.response.status === 404)
                  this.$toast.error("Sub account has been already deleted", '',{position: 'center'})
                else
                  this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              }
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    async submit() {
      if(this.item.required_at_pre_approval == false &&
         this.item.required_at_reviewing == false &&
         this.item.required_at_approval == false &&
         this.item.required_at_pre_disbursement == false) {
           this.$toast.error('Please select at least one of the stages this item is required at.', '', {position: 'topCenter'})
           return
      }

      let isValid = await this.$validator.validateAll("loan")
      if(isValid) {
        await this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },

    toggleSwitch(item) {
      this.item = {...item}
      this.save()
    },

    async save () {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true
        let data = {...this.item}

        delete data['date_created']
        delete data['date_updated']
        delete data['account_type_id']
        delete data['account_type_name']
        delete data['account_definitions']

        this.accountTypes.forEach((el)=>{
          if(el.ID === this.item.account_type_id)
            this.item.account_type_name = el.account_type_name
        })

        if (this.item.ID) {
          await accountSettingsService.updateSubAccountType(this.item.account_type_id, this.item.ID, data)
          let len = this.items.length
          for(let i = 0; i < len; i++) {
            if(this.item.ID == this.items[i].ID) 
              this.items.splice(i, 1, {...this.item})
          }
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          
          let res = await accountSettingsService.createSubAccountType(this.item.account_type_id, data)
          this.item.ID = res.data
          this.items.push(this.item)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        this.closeModal()
       
      } catch(e) {
        console.log('saving/updating: ',e)
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      } finally {
        this.isLoading = false
      }
    },

    async fetchData() {
      try {
        this.isLoading = true
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let response = await accountSettingsService.findAll() 
        this.accountTypes = response.data
          .map((el) => {
            let temp = _.cloneDeep(el)
            delete temp["sub_account_types"]
            return temp
         })

        this.items = response.data
          .filter((el) => el.sub_account_types.length > 0)
          .reduce((acc, cur) => {
            let subs = cur.sub_account_types.map((el)=>{
              el.account_type_id = cur.ID
              el.account_type_name = cur.account_type_name
              return el
            })
            return acc.concat(subs)
          }, [])

      } catch(e) {
        console.log('fetching: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },
  },

}
</script>

 
