<template>
  <div style="margin-top: -25px">
    <v-tabs color="primary lighten-1" dark slider-color="yellow" >
      <v-tab ripple :key="1" >General</v-tab>
      <v-tab ripple :key="2" >Limits</v-tab>
      <v-tab ripple :key="3" >Loan Stoppers</v-tab>
      <v-tab ripple :key="4" >Payment Frequency Definitions</v-tab>
      <v-tab ripple :key="5" >Life Insurance</v-tab>
      <v-tab ripple :key="6" >Risk-based Lending Deviation</v-tab>
      <v-tab ripple :key="7" >YAPU Settings</v-tab>

      <v-tab-item key="1">
         <v-list three-line subheader>
      <!-- <v-subheader>General</v-subheader> -->
      <v-list-tile avatar>
        <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.use_risk_scoring"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Enable Risk Factor Scoring</v-list-tile-title>
              <v-list-tile-sub-title>Enable/Disable risk factor scoring across application</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.use_risk_base_lending"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Enable Risk Based Adjustments</v-list-tile-title>
              <v-list-tile-sub-title>Auto-adjust loan policies based on adjustments calculations</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.remove_missing_factors"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Remove Missing Factors from Credit Assessment</v-list-tile-title>
              <v-list-tile-sub-title>Remove missing factors from credit assessment and risk adjustment</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.use_checklist"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Enable Checklist</v-list-tile-title>
              <v-list-tile-sub-title>Enable checklist across system</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.allow_processing_officer_to_approve_own_loan"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Allow Processing Officer to Approval Own Loan</v-list-tile-title>
              <v-list-tile-sub-title>Allow processing officer to approve his/her loan</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.allow_processing_officer_to_waive_own_loan"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Allow Processing Officer to Waive Own Loan</v-list-tile-title>
              <v-list-tile-sub-title>Allow processing officer to waive his/her loan</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.allow_processing_officer_to_disburse_own_loan"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Allow Processing Officer to Disburse Own Loan</v-list-tile-title>
              <v-list-tile-sub-title>Allow processing officer to disburse his/her loan</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <v-list-tile avatar>
            <v-list-tile-action>
              <v-checkbox @change="save" v-model="settings.allow_manual_disbursement"></v-checkbox>
            </v-list-tile-action>
            <v-list-tile-content>
              <v-list-tile-title>Allow Manual Disbursement</v-list-tile-title>
              <v-list-tile-sub-title>Allow disbursement officer to manually disburse loan when integrated</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <v-card class="pa-2 mb-4">
          <v-layout>
            <v-flex md4  class="pl-2 pr-2">
              <va-input 
                v-model="settings.gct"  
                v-validate="'required|decimal|min_value:0|max_value:100'" 
                data-vv-scope="gct"
                placeholder=" "
                data-vv-name="gct"
                data-vv-as="gct"
                append-icon="fa-percentage"
                @focus="onInputFocus(settings.gct)" 
                @blur="onInputGCTBlur(settings)"
                :error-messages="errors.collect('gct.gct')"
                label="General Consumption Tax (G.C.T)"/>
            </v-flex>
          </v-layout>
        </v-card>
      </v-tab-item>

      <v-tab-item key="2">
        <v-layout>
          <v-flex md6="md3">
            <v-card height="97%"  class="ma-2">
              <v-card-title class=""><h3>Credit Score Ratings</h3></v-card-title>
              <v-data-table class="mt-1" :items="settings.credit_score_ratings" :headers="creditLimitHeaders" hide-actions="hide-actions">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.rating_name | capitalize }}</td>
                  
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditCreditScoreDialogClosed(props.item, props.index)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.max_score = previousItem.max_score; disableEventStyle = ''" 
                      > 
                      {{ props.item.max_score  }}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|min_value:0'" 
                        data-vv-scope="limit"
                        data-vv-name="max_score"
                        data-vv-as="maximum score"
                        :error-messages="errors.collect('limit.max_score')"
                        v-model="selectedValue.max_score"  
                        label="Edit" 
                        single-line counter></v-text-field>
                      </v-edit-dialog>
                    </td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
          <v-flex md6="md9">
            <v-card class="ma-2">
              <v-card-title class=""><h3>Debt Service Ratio Ratings</h3></v-card-title>
              <v-data-table class="mt-1" :items="settings.dsr_ratings" :headers="dsrLimitHeaders" hide-actions="hide-actions">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.rating_name | capitalize }}</td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditDSRDialogClosed(props.item, props.index)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.max_score = previousItem.max_score; disableEventStyle = ''" 
                      > 
                      {{ props.item.dsr_max_score + ' %'  }}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|min_value:0'" 
                        data-vv-scope="limit"
                        data-vv-name="dsr_max_score"
                        data-vv-as="dsr maximum score"
                        :error-messages="errors.collect('limit.dsr_max_score')"
                        v-model="selectedValue.dsr_max_score"  
                        label="Edit" 
                        single-line counter></v-text-field>
                      </v-edit-dialog>
                  </td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditIERDialogClosed(props.item, props.index)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.max_score = previousItem.max_score; disableEventStyle = ''" 
                      > 
                      {{ props.item.ie_max_score + ' %'  }}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|min_value:0'" 
                        data-vv-scope="limit"
                        data-vv-name="ie_max_score"
                        data-vv-as="income:expense ratio maximum score"
                        :error-messages="errors.collect('limit.ie_max_score')"
                        v-model="selectedValue.ie_max_score"  
                        label="Edit" 
                        single-line counter></v-text-field>
                      </v-edit-dialog>
                  </td>
                </template>
              </v-data-table>
            </v-card>
            <v-card  class="ma-2">
              <v-card-title class=""><h3>Net Worth Ratings</h3></v-card-title>
              <v-data-table class="mt-1" :items="settings.network_ratings" :headers="creditLimitHeaders" hide-actions="hide-actions">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.rating_name | capitalize }}</td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditNetRDialogClosed(props.item, props.index)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.max_score = previousItem.max_score; disableEventStyle = ''" 
                      > 
                      {{ props.item.max_score +' %' }}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|min_value:0'" 
                        data-vv-scope="limit"
                        data-vv-name="net_score"
                        data-vv-as="maximum score"
                        :error-messages="errors.collect('limit.net_score')"
                        v-model="selectedValue.max_score" 
                        label="Edit" 
                        single-line counter></v-text-field>
                      </v-edit-dialog></td>
                </template>
              </v-data-table>
            </v-card>
          </v-flex>
        </v-layout>

      </v-tab-item>
      <v-tab-item class="pt-2" key="3">
        <v-layout>
          <v-flex md6  class="pl-2 pr-2">
            <va-input 
              v-model="settings.loan_stoppers.credit_score"  
              v-validate="'required|decimal'" 
              data-vv-scope="credit"
              placeholder=" "
              data-vv-name="credit_limit"
              data-vv-as="credit score limit"
              @focus="onInputFocus(settings.loan_stoppers)" 
              @blur="onInputCreditBlur(settings.loan_stoppers)"
              :error-messages="errors.collect('credit.credit_limit')"
              label="Credit Score Limit"/>
            <v-list three-line subheader>
              <v-list-tile avatar>
                <v-list-tile-action>
                  <v-checkbox @change="save" v-model="settings.loan_stoppers.credit_score_stopper"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Enable Credit Score Stopper</v-list-tile-title>
                  <v-list-tile-sub-title>Enable/Disable using credit score limit as a loan stopper. </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex>
          <!-- <v-flex md4  class="pl-2 pr-2">
            <va-input 
              v-model="settings.loan_stoppers.dsr" 
              v-validate="'required|decimal'" 
              data-vv-scope="dsr"
              placeholder=" "
              data-vv-name="dsr_limit"
              data-vv-as="debt service ratio limit"
              @focus="onInputFocus(settings.loan_stoppers)" 
              @blur="onInputDSRBlur(settings.loan_stoppers)"
              :error-messages="errors.collect('dsr.dsr_limit')"
              label="Debt Service Ratio Limit"/>
            <v-list three-line subheader>
              <v-list-tile avatar>
                <v-list-tile-action>
                  <v-checkbox @change="save" v-model="settings.loan_stoppers.dsr_stopper"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Enable Debt Service Ratio Stopper</v-list-tile-title>
                  <v-list-tile-sub-title>Enable/Disable using Debt Service Ratio limit as a loan stopper. </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex> -->
          <!-- <v-flex md4 class="pl-2 pr-2">
            <va-input 
              v-model="settings.loan_stoppers.ier"
              v-validate="'required|decimal:min_value:0|:max_value:1000'" 
              data-vv-scope="ie"
              data-vv-name="ier_limit"
              placeholder=" "
              @focus="onInputFocus(settings.loan_stoppers)" 
              @blur="onInputIEBlur(settings.loan_stoppers)" 
              data-vv-as="income:expense ratio limit"
              :error-messages="errors.collect('ie.ier_limit')" 
              label="Income:Expense Limit"/>
            <v-list three-line subheader>
              <v-list-tile avatar>
                <v-list-tile-action>
                  <v-checkbox @change="save" v-model="settings.loan_stoppers.ier_stopper"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>Enable Income:Expense Ratio Stopper</v-list-tile-title>
                  <v-list-tile-sub-title>Enable/Disable using income : expense ratio limit as a loan stopper. </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-flex> -->
        </v-layout>
      </v-tab-item>
      <v-tab-item class="pt-2" key="4">
        <v-layout justify-center align-center>
          <v-card  class="ma-2">
              <v-card-title class="">
                <h3>Payment Frequencies</h3>
                <v-spacer></v-spacer>
                <v-btn @click="addNewFrequency" small color="primary" style="margin-top:-7px" class="mb-0">Add</v-btn>
              </v-card-title>
              <v-data-table class="mt-1" :items="settings.frequencies" :headers="frequencyHeaders" hide-actions="hide-actions">
                <template slot="items" slot-scope="props">
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item, 'name')" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.name = previousItem.name; disableEventStyle = ''" 
                      > 
                      <v-icon style="position: absolute; margin-left:-10px" size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                      <span class="ml-2">{{ props.item.name | capitalize  }}</span>
                    <v-text-field 
                      slot="input" 
                      v-validate="'required|min:1|max:20'" 
                      data-vv-scope="freq"
                      data-vv-name="name"
                      data-vv-as="frequency type"
                      :error-messages="errors.collect('freq.name')"
                      v-model="selectedValue.name"  
                      label="Edit" 
                      single-line counter></v-text-field>
                    </v-edit-dialog> 
                  </td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item, 'noun')" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.noun = previousItem.noun; disableEventStyle = ''" 
                      > 
                      <v-icon style="position: absolute; margin-left:-10px" size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                      <span class="ml-2">{{ props.item.noun | capitalize  }}</span>
                    <v-text-field 
                      slot="input" 
                      v-validate="'required|min:1|max:20'" 
                      data-vv-scope="freq"
                      data-vv-name="noun"
                      data-vv-as="frequency name"
                      :error-messages="errors.collect('freq.noun')"
                      v-model="selectedValue.noun"  
                      label="Edit" 
                      single-line counter></v-text-field>
                    </v-edit-dialog> 
                  </td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.period = previousItem.period; disableEventStyle = ''" 
                      > 
                      <v-icon size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                      {{ props.item.period  }}
                    <v-text-field 
                      slot="input" 
                      v-validate="'required|numeric|min_value:0'" 
                      data-vv-scope="freq"
                      data-vv-name="period"
                      data-vv-as="maximum score"
                      :error-messages="errors.collect('freq.period')"
                      v-model="selectedValue.period"  
                      label="Edit" 
                      single-line counter></v-text-field>
                    </v-edit-dialog> 
                  </td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.frequency_first_payment_days = previousItem.frequency_first_payment_days; disableEventStyle = ''" 
                      > 
                      <v-icon size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                      {{ props.item.frequency_first_payment_days  }}
                    <v-text-field 
                      slot="input" 
                      v-validate="'required|numeric|min_value:1'" 
                      data-vv-scope="freq"
                      data-vv-name="first_payment"
                      data-vv-as="first payment"
                      :error-messages="errors.collect('freq.first_payment')"
                      v-model="selectedValue.frequency_first_payment_days"  
                      label="Edit" 
                      single-line counter></v-text-field>
                    </v-edit-dialog> 
                  </td>
                  <td title="click to edit">
                    <v-edit-dialog  
                      :style="disableEventStyle"
                      lazy
                      large
                      persistent
                      @save="onEditFequencyDialogClosed(props.item)" 
                      @open="onDialogOpened(props.item)" 
                      @cancel="props.item.frequency_display_interest = previousItem.frequency_display_interest; disableEventStyle = ''" 
                      > 
                      <v-icon size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                      {{ props.item.frequency_display_interest  }}
                    <v-select
                      slot="input" 
                      :items="['Annual', 'Current Frequency']" 
                      v-validate="'required'" 
                      data-vv-scope="freq"
                      data-vv-name="frequency_display_interest"
                      data-vv-as="default display interest"
                      :error-messages="errors.collect('freq.frequency_display_interest')"
                      v-model="selectedValue.frequency_display_interest"  
                      label="Edit" 
                      single-line counter
                      />
                    </v-edit-dialog> 
                  </td>
                  <td>
                    <v-checkbox
                    style="margin-top: 10px"
                      @change="onDefaultCheckboxClicked(props.item)"
                      v-model="props.item.default"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-btn v-if="props.item.is_custom" title="remove" @click="removeFrequency(props.item)" icon>
                      <v-icon>remove</v-icon>
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card>
        </v-layout>
      </v-tab-item>
      <v-tab-item class="pt-2" key="5">
        <v-layout justify-center align-center>
          <v-flex md4  class="pl-2 pr-2">
            <label for="">Free Insurance</label>
            <money 
              v-model="settings.insurance.free_insurance" 
              v-validate="'required|decimal'" 
              data-vv-scope="insurance"
              placeholder=" "
              data-vv-name="free_insurance"
              v-bind="money"
              data-vv-as="free insurance"
              @focus.native="onInputFocus(settings.insurance)" 
              @blur.native="onInputInsuranceBlur(settings.insurance)"
              label="Free Insurance"/>
          </v-flex>
          <v-flex md4  class="pl-2 pr-2">
            <label for="">Insurance Floor</label>
            <money
              v-model="settings.insurance.insurance_floor" 
              v-validate="'required|decimal'" 
              v-bind="money"
              data-vv-scope="insurance"
              placeholder=" "
              data-vv-name="insurance_floor"
              data-vv-as="insurance floor"
              @focus.native="onInputFocus(settings.insurance)" 
              @blur.native="onInputInsuranceBlur(settings.insurance)"
              />
          </v-flex>
        </v-layout>
      </v-tab-item>
      <v-tab-item key="6">
        <v-layout>
          <v-flex sm12>
            <v-data-table :headers="deviationTableHeaders"
              :items="settings.deviations">
              <template slot="headers" slot-scope="props">
                <template v-for="(deviationTableHeaders, i) in [processTableHeaders(props.headers)]">
                  <tr :key="i">
                    <th v-for="header in deviationTableHeaders.parents"
                        :key="header.value"
                        :rowspan="header.rowspan"
                        :colspan="header.colspan"
                        :width="header.width"
                        :class="header.align ? `text-xs-${header.align}` : ''">
                      <h2>{{ header.text }}</h2>
                    </th>
                  </tr>
                  <tr v-if="deviationTableHeaders.children"
                      :key="i*20+57">
                    <th v-for="header in deviationTableHeaders.children"
                        :key="header.value"
                        :width="header.width"
                        :class="header.align ? `text-xs-${header.align}` : ''">
                      <h3>{{ header.text }}</h3>
                    </th>
                  </tr>
                </template>
              </template>
              <template slot="items" slot-scope="props">
                <td v-for="(v, i) in deviationTableHeaders"
                    v-if="!v.children"
                    :key="v.value"
                    :class="`text-xs-${v.align}`">
                  {{ v.format ? v.format(props.item[v.value]) : props.item[v.value] }}
                </td>
                <td v-else
                    v-for="(c, idx) in v.children"
                    :key="c.value"
                    :class="`text-xs-${c.align}`">
                  <span v-if="idx == 0 && i == 0">{{ c.format ? c.format(props.item[c.value]) : props.item[c.value] }}</span>
                  <v-edit-dialog 
                    v-else 
                    :style="disableEventStyle"
                    lazy
                    large
                    persistent
                    @save="onEditDeviationDialogClosed(props.item, c.value, idx)" 
                    @open="onDialogOpened(props.item)" 
                    @cancel="props.item[c.value] = previousItem[c.value]; disableEventStyle = ''" 
                    > 
                    <v-icon size="12" class="fa-flip-270 edit-icon mr-2">fa-edit</v-icon>
                    {{ c.format ? c.format(props.item[c.value]) : props.item[c.value] }}
                  <template v-if="['Interest Rate', 'Share Requirement'].includes(props.item.term)">
                  <v-text-field 
                    slot="input" 
                    v-validate="'required|decimal'+(c.value.indexOf('max_penalty') != -1  ? '|min_value:0' : '|max_value:0')" 
                    data-vv-scope="deviation"
                    :data-vv-name="`${c.value}${idx}${props.item.term}`"
                    data-vv-as="value"
                    :error-messages="errors.collect(`deviation.${c.value}${idx}${props.item.term}`)"
                    v-model="selectedValue[c.value]"  
                    label="Edit" 
                    single-line counter></v-text-field>
                  </template>
                  <template v-else>
                    <v-text-field 
                    slot="input" 
                    v-validate="'required|decimal'+(c.value.indexOf('max_reward') != -1  ? '|min_value:0' : '|max_value:0')" 
                    data-vv-scope="deviation"
                    :data-vv-name="`${c.value}${idx}${props.item.term}`"
                    data-vv-as="value"
                    :error-messages="errors.collect(`deviation.${c.value}${idx}${props.item.term}`)"
                    v-model="selectedValue[c.value]"  
                    label="Edit" 
                    single-line counter></v-text-field>
                  </template>
                
                  </v-edit-dialog> 
                </td>
                  
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-tab-item>

      <v-tab-item key="7">
        <v-card class="pa-4 mb-4">
          <h3>Geocoding API setup</h3>
          <p>We recommend that you fill out <b>ONE</b> of the following fields if you are using YAPU Climate Risk Assessement in production. If either 
          of these fields is left empty the system will default to using <a href="https://geocode.maps.co/">Geocode.maps.co</a>.
          </p>
          <v-layout wrap>
            <v-flex md4 sm12  class="pa-2">
              <va-input 
                v-model="settings.yapu.googleAPIKey"  
                v-validate="'min:32|max:64'" 
                data-vv-scope="yapu"
                placeholder=" "
                data-vv-name="googleAPIKey"
                data-vv-as="google api key"
                append-icon="fa-google"
                @focus="onInputFocus(settings.yapu)" 
                @blur="onInputYAPUBlur(settings)"
                :error-messages="errors.collect('yapu.googleAPIKey')"
                persistent-hint
                :hint="`Place your own API here from: https://console.cloud.google.com/apis/`"
                label="Google Map API Key"/>
            </v-flex>
            <v-flex md4 xs12 class="pa-2">
              <va-input 
                v-model="settings.yapu.positionStackAPIKey"  
                v-validate="'min:32|max:64'" 
                data-vv-scope="yapu"
                placeholder=" "
                data-vv-name="positionStackAPIKey"
                data-vv-as="postion stack api key"
                append-icon="fa-google"
                @focus="onInputFocus(settings.yapu)" 
                @blur="onInputYAPUBlur(settings)"
                :error-messages="errors.collect('yapu.positionStackAPIKey')"
                persistent-hint
                hint="Place your own API here from: https://positionstack.com/dashboard"
                label="Position Stack API Key"/>
            </v-flex>
            <v-flex md4 xs12 class="pa-2">
              <va-input 
                v-model="settings.yapu.geocodioAPIKey"  
                v-validate="'min:32|max:64'" 
                data-vv-scope="yapu"
                placeholder=" "
                data-vv-name="geocodioAPIKey"
                data-vv-as="geocodio api key"
                append-icon="fa-google"
                @focus="onInputFocus(settings)" 
                @blur="onInputYAPUBlur(settings.yapu)"
                :error-messages="errors.collect('yapu.geocodioAPIKey')"
                persistent-hint
                hint="Place your own API here from: https://dash.geocod.io/apikey"
                label="Geocodio API Key"/>
            </v-flex>
          </v-layout>

          <h3 class="mt-3">Climate Risk Rating Settings</h3>
          <div class="yapu-settings-table mt-3">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Drought</th>
                  <th>Flooding</th>
                  <th>Storm</th>
                  <th>Frost</th>
                  <th>Heat</th>
                  <th rowspan="2">
                    <p style="text-align: center;">Actions</p>
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>Very Low</th>
                  <th>Low</th>
                  <th>Middle</th>
                  <th>High</th>
                  <th>Very High</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="font-weight-bold">Client Exposure</td>
                  <td>{{asPercent(getClimateRiskScore("Drought",'Client Exposure','veryLow'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Flooding",'Client Exposure','low'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Storm",'Client Exposure','middle'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Frost",'Client Exposure','high'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Heat",'Client Exposure','veryHigh'))}}</td>
                  <td class="text-xs-center">
                      <v-btn 
                        class="text-none" 
                        @click="setEditClimate('Client Exposure')"
                        outline small color="primary">
                        Edit Values
                      </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Client Sensitivity</td>
                  <td>{{asPercent(getClimateRiskScore("Drought",'Client Sensitivity','veryLow'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Flooding",'Client Sensitivity','low'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Storm",'Client Sensitivity','middle'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Frost",'Client Sensitivity','high'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Heat",'Client Sensitivity','veryHigh'))}}</td>
                  <td class="text-xs-center">
                      <v-btn 
                        class="text-none" 
                        @click="setEditClimate('Client Sensitivity')"
                        outline small color="primary">
                        Edit Values
                      </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Adaptive Capacity</td>
                  <td>{{asPercent(getClimateRiskScore("Drought",'Adaptive Capacity','veryLow'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Flooding",'Adaptive Capacity','low'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Storm",'Adaptive Capacity','middle'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Frost",'Adaptive Capacity','high'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Heat",'Adaptive Capacity','veryHigh'))}}</td>
                  <td class="text-xs-center">
                       <v-btn 
                        class="text-none" 
                        @click="setEditClimate('Adaptive Capacity')"
                        outline small color="primary">
                        Edit Values
                      </v-btn>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold">Net Climate Risk</td>
                  <td>{{asPercent(getClimateRiskScore("Drought",'Net Climate Risk','veryLow'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Flooding",'Net Climate Risk','low'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Storm",'Net Climate Risk','middle'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Frost",'Net Climate Risk','high'))}}</td>
                  <td>{{asPercent(getClimateRiskScore("Heat",'Net Climate Risk','veryHigh'))}}</td>
                  <td class="text-xs-center">
                      <v-btn 
                        class="text-none" 
                        @click="setEditClimate('Net Climate Risk')"
                        outline small color="primary">
                        Edit Values
                      </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Global CLimate Risk Ratings -->
          <div class="mt-5">
            <h3>Global Climate Risk Ratings</h3>
              <div class="yapu-settings-table mt-3">
                <table>
                  <thead>
                    <tr>
                      <th style="width: 50%;">Rating</th>
                      <th style="width: 50%;">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style="width: 50%;">Very High</td>
                      <td title="click to edit">
                        <v-edit-dialog  
                          lazy
                          large
                          save-text="Update"
                          @cancel="loadGlobalClimateData()"
                          @save="saveGlobalClimateData()"
                          persistent
                        > 
                          {{ asPercent(globalCimateRiskRatings.veryHigh || 0) }} <span class="text--secondary caption ml-3 font-italic font-weight-light">Click to edit</span>
                          <template v-slot:input>
                            <v-text-field
                              v-validate="`required|min_value:${globalCimateRiskRatings.high}|max_value:100`" 
                              data-vv-scope="globalClimate"
                              data-vv-name="veryHigh"
                              data-vv-as="very high rating"
                              type="number"
                              min="0"
                              max="100"
                              :error-messages="errors.collect('globalClimate.veryHigh')"
                              v-model.number="globalCimateRiskRatings.veryHigh" 
                              label="Edit very high"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%;">High</td>
                      <td title="click to edit">
                        <v-edit-dialog  
                          lazy
                          large
                          save-text="Update"
                          @cancel="loadGlobalClimateData()"
                          @save="saveGlobalClimateData()"
                          persistent
                        > 
                          {{ asPercent(globalCimateRiskRatings.high || 0) }} <span class="text--secondary caption ml-3 font-italic font-weight-light">Click to edit</span>
                          <template v-slot:input>
                            <v-text-field
                              v-validate="`required|min_value:${globalCimateRiskRatings.middle}|max_value:${globalCimateRiskRatings.veryHigh}`" 
                              data-vv-scope="globalClimate"
                              data-vv-name="high"
                              data-vv-as="high rating"
                              type="number"
                              min="0"
                              max="100"
                              :error-messages="errors.collect('globalClimate.high')"
                              v-model.number="globalCimateRiskRatings.high" 
                              label="Edit high"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%;">Middle</td>
                      <td title="click to edit">
                        <v-edit-dialog  
                          lazy
                          large
                          save-text="Update"
                          @cancel="loadGlobalClimateData()"
                          @save="saveGlobalClimateData()"
                          persistent
                        > 
                          {{ asPercent(globalCimateRiskRatings.middle || 0) }} <span class="text--secondary caption ml-3 font-italic font-weight-light">Click to edit</span>
                          <template v-slot:input>
                            <v-text-field
                              v-validate="`required|min_value:${globalCimateRiskRatings.low}|max_value:${globalCimateRiskRatings.high}`" 
                              data-vv-scope="globalClimate"
                              data-vv-name="middle"
                              data-vv-as="middle rating"
                              type="number"
                              min="0"
                              max="100"
                              :error-messages="errors.collect('globalClimate.middle')"
                              v-model.number="globalCimateRiskRatings.middle" 
                              label="Edit middle"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%;">Low</td>
                       <td title="click to edit">
                        <v-edit-dialog  
                          lazy
                          large
                          save-text="Update"
                          @cancel="loadGlobalClimateData()"
                          @save="saveGlobalClimateData()"
                          persistent
                        > 
                          {{ asPercent(globalCimateRiskRatings.low || 0) }} <span class="text--secondary caption ml-3 font-italic font-weight-light">Click to edit</span>
                          <template v-slot:input>
                            <v-text-field
                              v-validate="`required|min_value:${globalCimateRiskRatings.veryLow}|max_value:${globalCimateRiskRatings.middle}`" 
                              data-vv-scope="globalClimate"
                              data-vv-name="low"
                              data-vv-as="low rating"
                              type="number"
                              min="0"
                              max="100"
                              :error-messages="errors.collect('globalClimate.low')"
                              v-model.number="globalCimateRiskRatings.low" 
                              label="Edit low"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 50%;">Very Low</td>
                      <td title="click to edit">
                        <v-edit-dialog  
                          lazy
                          large
                          save-text="Update"
                          @cancel="loadGlobalClimateData()"
                          @save="saveGlobalClimateData()"
                          persistent
                        > 
                          {{ asPercent(globalCimateRiskRatings.veryLow || 0) }} <span class="text--secondary caption ml-3 font-italic font-weight-light">Click to edit</span>
                          <template v-slot:input>
                            <v-text-field
                              v-validate="`required|min_value:0|max_value:100`" 
                              data-vv-scope="globalClimate"
                              data-vv-name="veryLow"
                              data-vv-as="very low rating"
                              type="number"
                              min="0"
                              max="100"
                              :error-messages="errors.collect('globalClimate.veryLow')"
                              v-model.number="globalCimateRiskRatings.veryLow" 
                              label="Edit very low"
                              single-line
                            ></v-text-field>
                          </template>
                        </v-edit-dialog>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </v-card>
        <!-- Modal used to edit climat rate settings -->
        <EditClimateRateModal @save="UpdateClimateData" ref="climateRateModalRef"/>

      </v-tab-item>
    </v-tabs>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

.edit-icon {
  width: 20px;
  font-size: 12px; 
}
</style>

<script>
import { mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import settingsService from '@/services/general-settings-service'
import _ from 'lodash'
import { Money } from "v-money";
import { EventBus } from "@/event-bus";
import EditClimateRateModal from "@/components/EditClimateRateModal.vue";
import { cloneDeep } from "lodash";
export default {
  components: {
    VaInput,
    Money,
    EditClimateRateModal
  },

  async created () {
   let result = await this.fetchSettings()
   await this.loadClimateData()
   await this.loadGlobalClimateData()
   if(!result) {
     await this.save()
   }
  },
  
  beforeDestroy() {
    this.$toast.destroy()
  },

  data() {
    return {
      creditLimitHeaders: [
        { text: 'Rating', align: 'left', sortable: false},
        { text: 'Max Score', align: 'left', sortable: false},
      ],
      globalClimateRiskRatingsHeaders: [
        { text: 'Rating', align: 'left', sortable: false},
        { text: 'Value', align: 'left', sortable: false},
      ],
      dsrLimitHeaders: [
        { text: 'Rating', align: 'left', sortable: false},
        { text: 'Max DSR Score', align: 'left', sortable: false},
        { text: 'Max I:E Ratio Score', align: 'left', sortable: false},
      ],
      frequencyHeaders: [
        { text: 'Frequency Type', align: 'left', sortable: false},
        { text: 'Frequency Name', align: 'left', sortable: false},
        { text: 'No. of Frequencies in Year', align: 'right', sortable: false},
        { text: 'First Payment Days', align: 'right', sortable: false},
        { text: 'Default Interest Displayed', align: 'right', sortable: false},
        { text: 'Default', align: 'right', sortable: false},
        { text: 'Remove', align: 'right', sortable: false},
      ],
      settings : {
        use_risk_scoring: true,
        use_risk_base_lending: true,
        use_checklist: true,
        remove_missing_factors: true,
        allow_processing_officer_to_approve_own_loan: true,
        allow_processing_officer_to_waive_own_loan: true,
        allow_processing_officer_to_disburse_own_loan:true,
        allow_manual_disbursement: false,
        gct: 0.00,
        yapu: {
          googleAPIKey: '',
          positionStackAPIKey: '',
          geocodioAPIKey: '',
        },
        loan_stoppers: {
          dsr: 0,
          dsr_stopper: false,
          ier: 0,
          ier_stopper: false,
          credit_score: 0,
          credit_score_stopper: false
        },

        insurance: {
          free_insurance: 0.00,
          insurance_floor: 0.00,
        },
        credit_score_ratings: [
          {rating_name: 'very low', max_score: '1000'},
          {rating_name: 'low', max_score: '850'},
          {rating_name: 'average', max_score: '700'},
          {rating_name: 'high', max_score: '250'},
          {rating_name: 'very high', max_score: '200'},
          {rating_name: 'too high', max_score: '100'},
        ],
        dsr_ratings: [
          {rating_name: 'high', dsr_max_score: '10', ie_max_score: '10'},
          {rating_name: 'average', dsr_max_score: '5', ie_max_score: '5'},
          {rating_name: 'low', dsr_max_score: '0', ie_max_score: '0'},
        ],
        network_ratings: [
          {rating_name: 'low', max_score: '100' },
          {rating_name: 'average', max_score: '50'},
          {rating_name: 'high', max_score: '0'},
        ],
        frequencies: [
          {name: 'Weekly', period: '52', default: false, frequency_display_interest: "Annual", frequency_first_payment_days: 7, noun: "Week"},
          {name: 'Fortnightly', period: '24', default: false, frequency_display_interest: "Annual", frequency_first_payment_days: 14,  noun: "Fortnight"},
          {name: 'Monthly', period: '12', default: true, frequency_display_interest: "Annual",  frequency_first_payment_days: 30, noun: "Month"},
          {name: 'Quarterly', period: '4', default: false, frequency_display_interest: "Annual",  frequency_first_payment_days: 120, noun: "Quarter"},
          {name: 'Biannually', period: '2', default: false, frequency_display_interest: "Annual", frequency_first_payment_days: 182,  noun: "Biannual"},
          {name: 'Annually', period: '1', default: false, frequency_display_interest: "Annual", frequency_first_payment_days: 365,  noun: "Annual"},
        ],
        deviations:  [
        {
          "term": 'Interest Rate', 
          "motor_vehicle_max_reward": 0, 
          "motor_vehicle_max_penalty": 0, 
          "real_estate_max_reward": 0, 
          "real_estate_max_penalty": 0, 
          "unsecured_max_reward": 0, 
          "unsecured_max_penalty": 0, 
          "cash_secured_max_reward": 0, 
          "cash_secured_max_penalty": 0, 
        },
        {
          "term": 'Tenure', 
          "motor_vehicle_max_reward": 0, 
          "motor_vehicle_max_penalty": 0, 
          "real_estate_max_reward": 0, 
          "real_estate_max_penalty": 0, 
          "unsecured_max_reward": 0, 
          "unsecured_max_penalty": 0, 
          "cash_secured_max_reward": 0, 
          "cash_secured_max_penalty": 0, 
        },
        {
          "term": 'Loan Amount', 
          "motor_vehicle_max_reward": 0, 
          "motor_vehicle_max_penalty": 0, 
          "real_estate_max_reward": 0, 
          "real_estate_max_penalty": 0, 
          "unsecured_max_reward": 0, 
          "unsecured_max_penalty": 0, 
          "cash_secured_max_reward": 0, 
          "cash_secured_max_penalty": 0, 
        },
        {
          "term": 'Share Requirement', 
          "motor_vehicle_max_reward": 0, 
          "motor_vehicle_max_penalty": 0, 
          "real_estate_max_reward": 0, 
          "real_estate_max_penalty": 0, 
          "unsecured_max_reward": 0, 
          "unsecured_max_penalty": 0, 
          "cash_secured_max_reward": 0, 
          "cash_secured_max_penalty": 0, 
        },
        {
          "term": 'Loan To Value', 
          "motor_vehicle_max_reward": 0, 
          "motor_vehicle_max_penalty": 0, 
          "real_estate_max_reward": 0, 
          "real_estate_max_penalty": 0, 
          "unsecured_max_reward": 0, 
          "unsecured_max_penalty": 0, 
          "cash_secured_max_reward": 0, 
          "cash_secured_max_penalty": 0, 
        },
      ]

      },
      disableEventStyle: '',
      previousItem: {},
      selectedValue: 0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$ ",
        precision: 2,
        masked: false
      },

      deviationTableHeaders: [
        {
          text: 'Loan Types',
          value: 'loan_types',
          sortable: false,
          align: 'left',
          children: [
            {
              text: 'Loan Terms',
              value: 'term',
              format: (v) => v,
              align: 'left'
            },
          ]
        },
        {
          text: 'Motor Vehicle',
          sortable: false,
          children: [
            {
              text: 'Max Reward',
              value: 'motor_vehicle_max_reward',
              format: (v) =>  Number(v).toFixed(2)  + ' %',
              align: 'left'
            },
            {
              text: 'Max Penalty',
              value: 'motor_vehicle_max_penalty',
              format: (v) => Number(v).toFixed(2) + ' %',
              align: 'left'
            }
          ]
        },
        {
          text: 'Real Estate',
          sortable: false,
          children: [
            {
              text: 'Max Reward',
              value:'real_estate_max_reward',
              format: (v) => Number(v).toFixed(2) + ' %',
              align: 'left'
            },
            {
              text: 'Max Penalty',
              value: 'real_estate_max_penalty',
              format: (v) => Number(v).toFixed(2) + ' %',
              align: 'left'
            }
          ]
        },
        {
          text: 'Unsecured',
          sortable: false,
          children: [
            {
              text: 'Max Reward',
              value: 'unsecured_max_reward',
              format: (v) => Number(v).toFixed(2) + ' %',
              align: 'left'
            },
            {
              text: 'Max Penalty',
              value: 'unsecured_max_penalty',
              format: (v) =>  Number(v).toFixed(2) + ' %',
              align: 'left'
            }
          ]
        },
        {
          text: 'Cash Secured',
          sortable: false,
          children: [
            {
              text: 'Max Reward',
              value: 'cash_secured_max_reward',
              format: (v) => Number(v).toFixed(2)  + ' %',
              align: 'left'
            },
            {
              text: 'Max Penalty',
              value: 'cash_secured_max_penalty',
              format: (v) =>  Number(v).toFixed(2) + ' %',
              align: 'left'
            }
          ]
        },
      ],
      //climate risk rating array
      climateRiskData: [],
      // Global Climate Risk rating object
      globalCimateRiskRatings:{}
    }
  },

  computed: {
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),
    ...mapActions('climateRiskRatingStore',["getClimateRiskRatings","updateClimateRiskRating","getGlobalClimateRiskRatings","updateGlobalClimateRiskRating"]),

    getClimateRiskScore(riskFactor, type, value){
      if(!this.climateRiskData) return 0;
      if(!riskFactor  || !type) return 0;
      let riskFactorObj = this.climateRiskData.find(r => r.riskFactor === riskFactor);
      if(!riskFactorObj) return 0;
      let score = riskFactorObj.scores.find(score => score.type === type);
      if(!score) return 0;
      return score[value];
    },
    // get climate risk ratings data
    async loadClimateData(){
      this.climateRiskData = await this.getClimateRiskRatings()
    },
    // get global climate risk ratings data
    async loadGlobalClimateData(){
      const gData = await this.getGlobalClimateRiskRatings()
      if(gData && gData.length > 0){
        this.globalCimateRiskRatings = gData[0] || {}
      }
    },
    async saveGlobalClimateData(){
      try {
        const data = cloneDeep(this.globalCimateRiskRatings);
        let invalid = false;
        // check if veryLow is greater than 0 & less than 100
        if(data.veryLow < 0 && data.veryLow > 100) invalid = true;
        //check if low is less than veryLow or greater than middle
        if(data.low < data.veryLow || data.low > data.middle) invalid = true;
        //check if middle is less than low or greater than high
        if(data.middle < data.low || data.middle > data.high) invalid = true;
        //check if highe is less than low or greater than very high
        if(data.high < data.middle || data.high > data.veryHigh) invalid = true;
        //check if highe is less than low or greater than very high
        if(data.veryHigh < data.high || data.veryHigh > 100) invalid = true;
        if(invalid){
          await this.loadGlobalClimateData()
          return this.$toast.error("Please check the values entered. Try entering them from Very High to Very Low","Invalid values",{position: "topCenter"})
        }
        // update data
        await this.updateGlobalClimateRiskRating({id: data._id, body: data})
        this.$toast.success("Sucessfully updated","",{position: "topCenter"})
        
      } catch (error) {
        this.$toast.error(error.response.data.error,"Error",{position: "topCenter"})
      }
    },
    // this is the function that opens the modal and sets the data
    setEditClimate(type){
      // get the value from each parent object then loop through teh scores array and find the type and return the value that corresponds to the riskFactor
      let veryLow = this.climateRiskData.find(r=> r.riskFactor == 'Drought').scores.find(s => s.type == type).veryLow;
      let low = this.climateRiskData.find(r=> r.riskFactor == 'Flooding').scores.find(s => s.type == type).low;
      let middle = this.climateRiskData.find(r=> r.riskFactor == 'Storm').scores.find(s => s.type == type).middle;
      let high = this.climateRiskData.find(r=> r.riskFactor == 'Frost').scores.find(s => s.type == type).high;
      let veryHigh = this.climateRiskData.find(r=> r.riskFactor == 'Heat').scores.find(s => s.type == type).veryHigh;
      // open the modal with the data
      this.$refs.climateRateModalRef.open({type, veryLow, low, middle, high, veryHigh});
    },

// this is the function that is called save is emited from the climate modal
    async UpdateClimateData(data){
      // get the data from the modal
      let {type, veryLow, low, middle, high, veryHigh} = data;
      // loop through the climateRiskData array and find the riskFactor that matches the type and update the value
      this.climateRiskData.forEach(riskFactor => {
        riskFactor.scores.forEach(async (score) => {
          if(score.type == type){
            score.veryLow = Number(veryLow);
            score.low = Number(low);
            score.middle = Number(middle);
            score.high = Number(high);
            score.veryHigh = Number(veryHigh);
            // save data
            await this.updateClimateRiskRating({id: riskFactor._id, type, body: {
              veryLow: Number(veryLow),
              low: Number(low),
              middle: Number(middle),
              high: Number(high),
              veryHigh: Number(veryHigh)
            }});
          }
        })
      })

      this.$toast.success('Climate Risk Rating Updated');
    },

    onDialogOpened(item) {
      this.disableEventStyle = 'pointer-events: none'
      this.previousItem = {...item}
      this.selectedValue = {...item}
    },

    onInputFocus(item) {
      this.previousItem = {...item}
    },

    async onInputYAPUBlur(item) {
      let isValid = await this.$validator.validateAll(`yapu`)
      if(!isValid) {
        item.yapu = this.previousItem
        return;
      } 
      this.save()
    },

    async onInputGCTBlur(item) {
      let isValid = await this.$validator.validateAll("gct")
      if(isValid) {
        this.save()
      } else {
        item.gct = this.previousItem
      }
    },

    async onInputInsuranceBlur(item) {
      let isValid = await this.$validator.validateAll("insurance")
      if(isValid) {
        this.save()
      } else {
        item.insurance = this.previousItem
      }
    },

    async onInputIEBlur(item) {
      let isValid = await this.$validator.validateAll("ie")
      if(isValid) {
        this.save()
      } else {
        item.ier = this.previousItem.ier
      }
    },

    async onInputDSRBlur(item) {
      let isValid = await this.$validator.validateAll("dsr")
      if(isValid) {
        this.save()
      } else {
        item.dsr = this.previousItem.dsr
      }
    },

    async onInputCreditBlur(item) {
      let isValid = await this.$validator.validateAll("credit")
      if(isValid) {
        this.save()
      } else {
        item.credit_score = this.previousItem.credit_score
      }
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let result = await settingsService.findLoanSettings()
        if(Object.keys(result).length > 0) {
          for(let key in result) {
            if(typeof result[key] === 'string' && result[key].indexOf("{'") > -1) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'))
            }
          }

          for(const key in this.settings) {
            if(!result[key]) 
              result[key] = this.settings[key];
          }

          this.settings = result 
          return true
        }
        return false;
      } catch(e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    onEditCreditScoreDialogClosed(item, index) {
      this.disableEventStyle = ''
      if(isNaN(this.selectedValue.max_score) || this.selectedValue.max_score == '') {
        item.max_score = this.previousItem.max_score
        return
      }
      
      let found = false
      this.settings.credit_score_ratings.forEach((el, i) => {
        if(i > index) {
          if (Number(el.max_score) >= Number(this.selectedValue.max_score) && i != index ) {
            item.max_score = this.previousItem.max_score
            found = true
          } 
        } else {
          if(Number(el.max_score) <= Number(this.selectedValue.max_score) && i != index ) {
            item.max_score = this.previousItem.max_score
            found = true
          } 
        }
      })

      if(!found) {
        item.max_score = this.selectedValue.max_score
        this.save()
      }
    },
    
    onEditDSRDialogClosed(item, index) {
      this.disableEventStyle = ''
      if(isNaN(this.selectedValue.dsr_max_score) || this.selectedValue.dsr_max_score == '') {
        item.dsr_max_score = this.previousItem.dsr_max_score
        return
      }
      
      let found = false
      this.settings.dsr_ratings.forEach((el, i) => {
        if(i > index) {
          if (Number(el.dsr_max_score) >= Number(this.selectedValue.dsr_max_score) && i != index ) {
            item.dsr_max_score = this.previousItem.dsr_max_score
            found = true
          } 
        } else {
          if(Number(el.dsr_max_score) <= Number(this.selectedValue.dsr_max_score) && i != index ) {
            item.dsr_max_score = this.previousItem.dsr_max_score
            found = true
          } 
        }
      })

      if(!found) {
        item.dsr_max_score = this.selectedValue.dsr_max_score
        this.save()
      }
    },
    
    onEditIERDialogClosed(item, index) {
      this.disableEventStyle = ''
      if(isNaN(this.selectedValue.ie_max_score) || this.selectedValue.ie_max_score == '') {
        item.ie_max_score = this.previousItem.ie_max_score
        return
      }
      
      let found = false
      this.settings.dsr_ratings.forEach((el, i) => {
        if(i > index) {
          if (Number(el.ie_max_score) >= Number(this.selectedValue.ie_max_score) && i != index ) {
            item.ie_max_score = this.previousItem.ie_max_score
            found = true
          } 
        } else {
          if(Number(el.ie_max_score) <= Number(this.selectedValue.ie_max_score) && i != index ) {
            item.ie_max_score = this.previousItem.ie_max_score
            found = true
          } 
        }
      })

      if(!found) {
        item.ie_max_score = this.selectedValue.ie_max_score
        this.save()
      }
    },
    
    onEditNetRDialogClosed(item, index) {
      this.disableEventStyle = ''
      if(isNaN(this.selectedValue.max_score) || this.selectedValue.max_score == '') {
        item.max_score = this.previousItem.max_score
        return
      }
      
      // let found = false
      // this.settings.network_ratings.forEach((el, i) => {
      //   if(i > index) {
      //     if (Number(el.max_score) <= Number(this.selectedValue.max_score) && i != index ) {
      //       item.max_score = this.previousItem.max_score
      //       found = true
      //     } 
      //   } else {
      //     if(Number(el.max_score) >= Number(this.selectedValue.max_score) && i != index ) {
      //       item.max_score = this.previousItem.max_score
      //       found = true
      //     } 
      //   }
      // })

      // if(!found) {
        item.max_score = this.selectedValue.max_score
        this.save()
      // }
    },

    onDefaultCheckboxClicked(item) {
      this.settings.frequencies.forEach((el, i)=>{
          el.default = false
           this.settings.frequencies.splice(i,1, el)
      })
      item.default = true
      this.save()
    },

    addNewFrequency() {
      let len = this.settings.frequencies || 0
      this.settings.frequencies.push({
        name: 'Frequency '+ (len.length ? len.length + 1 : 0),
        period: 1,
        default: false,
        is_custom: true,
        frequency_first_payment_days: 1,
        frequency_display_interest:"Annual",
        noun: "N/A",
      })
      this.save()
    },

    removeFrequency(item) {
      let index = this.settings.frequencies.findIndex(el => el.name === item.name )
      this.$toast.question('Are you sure you want to remove this payment frequency?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            this.settings.frequencies.splice(index, 1)
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
            this.save()
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
    },

    async onEditDeviationDialogClosed(item, key, index) {
      this.disableEventStyle = ''
      let valid = await this.$validator.validate(`deviation.${key}${index}${item.term}`)
      if(!valid) {
        item[key] = this.previousItem[key];
        return;
      }
      item[key] = this.selectedValue[key];

      await this.save();
    },

    onEditFequencyDialogClosed(item, type) {
      this.disableEventStyle = ''
      if(isNaN(this.selectedValue.period) || this.selectedValue.period == '') {
        item.period = this.previousItem.period
        return
      } 
      if(Number(this.selectedValue.period) <= 0) {
        this.item.period = this.previousItem.period
        return
      }


      if(item.name.trim() === "") {
        this.item = this.previousItem
        this.$toast.error("Frequency cannot be empty. Reverted to previous value", '', {position:'center'})
        return
      }

      

      let found = this.settings.frequencies.filter(el => {
        return this.selectedValue.name.trim() === el.name.trim() 
      })

      
      if(found.length > 0 && type == "name") {
        this.item = this.previousItem
        this.$toast.error("Frequency type already exist. Reverted to previous value", '', {position:'center'})
        return
      }

      found = this.settings.frequencies.filter(el => {
        return this.selectedValue.noun.trim() === el.noun.trim() 
      })

      
      if(found.length > 0 && type == "noun") {
        this.item = this.previousItem
        this.$toast.error("Frequency name already exist. Reverted to previous value", '', {position:'center'})
        return
      }
     

      this.settings.frequencies.forEach((el, i)=> {
        if(item.name === el.name) {
          this.settings.frequencies.splice(i,1, this.selectedValue)
        }
      })
      this.save()
    },

    async save() {
      try {
        this.$toast.destroy()
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let updatedSettings = _.cloneDeep(this.settings)
        for(let key in updatedSettings) {
          if(typeof updatedSettings[key] === 'object')
            updatedSettings[key] = JSON.stringify(updatedSettings[key])
        }

        EventBus.$emit('SETTINGS_CHANGE', this.settings)

        this.$toast.success("Saved settings data", '', {position:'topLeft'})
        await settingsService.updateLoanSettings(updatedSettings)
      } catch(e) {
        console.log(e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    processTableHeaders(headers) {
      const nested = !!headers.some(h => h.children)

      if (nested) {
        let children = []

        const h = headers.map(h => {
          if (h.children && h.children.length > 0) {
            children.push(...h.children)

            return {
              rowspan: 1,
              colspan: h.children.length,
              text: h.text,
              align: h.align
            }
          }
          return {
            rowspan: 2,
            colspan: 1,
            text: h.text,
            align: h.align
          }
        })

        return {
            children: children,
            parents: h
        }
      }
      return {
          parents: headers
      }
    }
   
  }
}
</script>

 
