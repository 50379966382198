<template>
  <div id="customer-form">
    <v-dialog v-model="idDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">{{
          idIndex > -1
            ? "Edit Identification Details"
            : "New Identification Details"
        }}</v-card-title>

        <v-card-text class="mb-4">
          <va-input
            v-model="id.number"
            v-validate="'required|max:20|min:4'"
            data-vv-scope="id"
            :error-messages="errors.collect('id.number')"
            placeholder=" "
            label="Identification Number"
            data-vv-validate-on="blur"
            data-vv-name="number"
            data-vv-as="identification number"
            show-clean
          />

          <va-select
            v-model="id.id_type"
            v-validate="'required'"
            data-vv-scope="id"
            data-vv-as="id type"
            placeholder=" "
            item-value="id_type"
            item-text="id_type"
            label="ID Type"
            :error-messages="errors.collect('id.id_type')"
            :items="idTypes"
            data-vv-name="id_type"
          />

          <va-select
            v-model="id.country"
            v-validate="'required'"
            data-vv-scope="id"
            placeholder=" "
            label="Issue Country"
            :error-messages="errors.collect('id.country')"
            :items="commonState.countries"
            :item-text="'name'"
            :item-value="'name'"
            data-vv-name="country"
          />

          <va-input
            v-model="id.expiry_date"
            v-validate="'required|date_format:YYYY-MM-DD'"
            data-vv-scope="id"
            data-vv-as="expiry_date"
            :error-messages="errors.collect('id.expiry_date')"
            placeholder=" "
            return-masked-value
            mask="####-##-##"
            label="Expiration Date (yyyy-mm-dd)"
            data-vv-validate-on="blur"
            data-vv-name="expiry_date"
          />
        </v-card-text>

        <v-card-actions style="margin-top: -30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeIdDialog">Cancel</v-btn>
          <v-btn color="primary" @click="addId">{{
            idIndex > -1 ? "Update" : "Save"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="formOpened"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <!-- Possible Duplcation Found Dialog -->
      <v-dialog persistent v-model="isUiqDialog" max-width="550">
        <v-card dark>
          <v-card-title class="headline font-weight-bold pb-0 mb-0"
            >Found Customer With Similar Details</v-card-title
          >

          <v-card-text>
            <v-layout>
              <v-flex xs4>
                <v-img
                  aspect-ratio="1"
                  slot="activator"
                  class="mt-3"
                  :src="
                    possibleDuplicatePerson.avatar ||
                      require('@/assets/img/avatar.png')
                  "
                  :lazy-src="``"
                  id="miniAvatar"
                  @click="showUploaderDialog"
                />
              </v-flex>
              <v-flex xs8>
                <div style="text-align: left">
                  <p
                    class="
                      headline
                      mt-3
                      white--text
                      text--darken-3
                      font-weight-bold
                      mb-1
                    "
                  >
                    {{
                      possibleDuplicatePerson.first_name +
                        " " +
                        possibleDuplicatePerson.last_name
                    }}
                  </p>
                  <p
                    class="
                      body-2
                      mb-1
                      white--text
                      text--darken-3
                      font-weight-medium
                    "
                  >
                    {{ possibleDuplicatePerson.trn || "N/A" }} (Tax Number)
                  </p>
                  <p
                    class="
                      body-2
                      mb-2
                      white--text
                      text--darken-3
                      font-weight-bold
                      mb-1
                    "
                  >
                    {{ possibleDuplicatePerson.date_of_birth }} (Date of Birth)
                  </p>
                  <p
                    class="
                      body-2
                      mb-2
                      white--text
                      text--darken-3
                      font-weight-bold
                      mb-1
                    "
                  >
                    {{ possibleDuplicatePerson.guardian_last_name }} (Guardian
                    Last Name)
                  </p>
                </div>
              </v-flex>
            </v-layout>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn flat="flat" @click="isUiqDialog = false"> Cancel </v-btn>
            <v-btn flat="flat" @click="gotoProfile"> Go to Profile </v-btn>
            <v-btn color="red lighten-1" flat="flat" @click="save">
              Still Save?
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-card>
        <v-dialog v-model="isLoading" persistent width="300">
          <v-card color="primary" dark>
            <v-card-text>
              {{ loadingText }}
              <v-progress-linear indeterminate color="white" class="mb-0" />
            </v-card-text>
          </v-card>
        </v-dialog>
        <v-toolbar dense dark color="blue darken-3">
          <v-btn icon dark @click="formOpened = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat :loading="isLoading" @click="onSubmit">Save</v-btn>
          </v-toolbar-items>
          <template slot="extension">
            <v-tabs
              v-model="tabModel"
              fixed-tabs
              centered
              color="blue darken-2"
              slider-color="white"
            >
              <v-tab :href="`#tab-personal`">Personal</v-tab>
              <v-tab :href="`#tab-contact`">Additional Contact</v-tab>
              <v-tab :href="`#tab-identification`">Identification(s)</v-tab>
              <v-tab :href="`#tab-employment`">Employment Details</v-tab>
              <v-tab :href="`#tab-school`">School Details</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>
        <v-tabs-items v-model="tabModel">
          <v-tab-item :value="`tab-personal`" class="pa-4">
            <h3>PERSONAL DETAILS</h3>
            <v-divider></v-divider>
            <v-layout row>
              <v-flex xs12 md3 class="pa-3">
                <v-tooltip bottom>
                  <v-img
                    aspect-ratio="1"
                    slot="activator"
                    class="mt-3"
                    :src="imgDataUrl"
                    :lazy-src="``"
                    id="avatar"
                    @click="showUploaderDialog"
                  />
                  <span>Click to change image</span>
                </v-tooltip>
                <avatar-uploader
                  lang-type="en"
                  field="avatar"
                  @crop-success="cropSuccess"
                  @crop-upload-success="cropUploadSuccess"
                  @crop-upload-fail="cropUploadFail"
                  v-model="showUploader"
                  :params="uploadParams"
                  :width="200"
                  :height="200"
                  :url="uploadUrl"
                  :headers="headers"
                  img-format="png"
                />
              </v-flex>
              <v-flex xs12 md4 class="pa-3">
                <va-input
                  v-model="customer.trn"
                  v-validate="
                    'max:40' +
                      (customer.member_category === 'Youth' ? '' : '|required')
                  "
                  data-vv-scope="customer"
                  data-vv-validate-on="blur"
                  data-vv-as="tax registration number"
                  label="Tax Registration Number *"
                  data-vv-name="trn"
                  placeholder=" "
                  :error-messages="errors.collect('customer.trn')"
                />
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.first_name"
                      v-validate="'required|min:2|max:100'"
                      data-vv-scope="customer"
                      data-vv-validate-on="blur"
                      data-vv-as="first name"
                      label="First Name *"
                      data-vv-name="first_name"
                      placeholder=" "
                      :error-messages="errors.collect('customer.first_name')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.last_name"
                      v-validate="'required|min:3|max:100'"
                      data-vv-scope="customer"
                      data-vv-validate-on="blur"
                      label="Last Name *"
                      data-vv-name="last_name"
                      data-vv-as="last name"
                      placeholder=" "
                      :error-messages="errors.collect('customer.last_name')"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.middle_name"
                      v-validate="'min:1|max:100'"
                      data-vv-scope="customer"
                      data-vv-validate-on="blur"
                      label="Middle Name"
                      data-vv-name="middle_name"
                      data-vv-as="middle name"
                      placeholder=" "
                      :error-messages="errors.collect('customer.middle_name')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.alias"
                      v-validate="'min:1|max:50'"
                      data-vv-scope="customer"
                      data-vv-validate-on="blur"
                      label="Alias"
                      data-vv-name="alias"
                      data-vv-as="alias"
                      placeholder=" "
                      :error-messages="errors.collect('customer.alias')"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 md5 class="pa-3">
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      :disabled="youth"
                      v-model="customer.date_of_birth"
                      v-validate="
                        'required|date_format:YYYY-MM-DD|not_greater_than_today'
                      "
                      data-vv-scope="customer"
                      data-vv-as="date of birth"
                      :error-messages="errors.collect('customer.date_of_birth')"
                      placeholder=" "
                      @change="onDateOFBirthChanged"
                      return-masked-value
                      mask="####-##-##"
                      label="Date of Birth (yyyy-mm-dd) *"
                      data-vv-validate-on="blur"
                      data-vv-name="date_of_birth"
                    />
                  </v-flex>

                  <v-flex xs12 md6 class="pr-2">
                    <va-select
                      v-model="customer.gender"
                      v-validate="'required'"
                      data-vv-scope="customer"
                      placeholder=" "
                      label="Gender *"
                      :error-messages="errors.collect('customer.gender')"
                      data-vv-validate-on="blur"
                      :items="genders"
                      data-vv-name="gender"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-select
                      v-model="customer.marital_status"
                      v-validate="'required'"
                      data-vv-scope="customer"
                      placeholder=" "
                      label="Marital Status *"
                      :error-messages="
                        errors.collect('customer.marital_status')
                      "
                      data-vv-validate-on="blur"
                      data-vv-as="marital status"
                      :items="maritalStatuses"
                      data-vv-name="marital_status"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-select
                      v-model="customer.member_category"
                      v-validate="'required'"
                      data-vv-scope="customer"
                      placeholder=" "
                      label="Member Category *"
                      :error-messages="
                        errors.collect('customer.member_category')
                      "
                      data-vv-validate-on="blur"
                      data-vv-as="member category"
                      :items="memberCategories"
                      data-vv-name="member_category"
                    />
                  </v-flex>
                </v-layout>
                <va-input
                  v-validate="'required'"
                  v-model="customer.guardian_last_name"
                  placeholder=" "
                  data-vv-scope="customer"
                  label="Mother / Gaurdian Last Name *"
                  data-vv-name="guardian_last_name"
                  data-vv-as="guardian last name"
                  :error-messages="
                    errors.collect('customer.guardian_last_name')
                  "
                />
              </v-flex>
            </v-layout>

            <v-divider></v-divider>
            <h3>CONTACT INFORMATION</h3>

            <v-layout row wrap>
              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate="{
                    required: true,
                    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  }"
                  v-model="customer.contact_info.cell"
                  placeholder=" "
                  data-vv-scope="customer"
                  label="Cell *"
                  data-vv-name="cell"
                  data-vv-as="cell"
                  :return-masked-value="true"
                  :persistent-hint="true"
                  :hint="'Expected Format: 000 000 0000'"
                  :mask="'(###) ###-####'"
                  :error-messages="errors.collect('customer.cell')"
                />
              </v-flex>
              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate="{
                    required: false,
                    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  }"
                  v-model="customer.contact_info.phone1"
                  placeholder=" "
                  data-vv-scope="customer"
                  label="Telephone 1"
                  data-vv-name="phone1"
                  data-vv-as="telephone 1"
                  :return-masked-value="true"
                  :persistent-hint="true"
                  :hint="'Expected Format: 000 000 0000'"
                  :mask="'(###) ###-####'"
                  :error-messages="errors.collect('customer.phone1')"
                />
              </v-flex>
              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate="{
                    required: false,
                    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  }"
                  v-model="customer.contact_info.phone2"
                  placeholder=" "
                  data-vv-scope="customer"
                  label="Telephone 2"
                  data-vv-name="phone2"
                  data-vv-as="telephone 2"
                  :return-masked-value="true"
                  :persistent-hint="true"
                  :hint="'Expected Format: 000 000 0000'"
                  :mask="'(###) ###-####'"
                  :error-messages="errors.collect('customer.phone2')"
                />
              </v-flex>

              <v-flex xs12 md3 class="pa-3">
                <va-input
                  v-validate="'email|max:255'"
                  v-model="customer.contact_info.email"
                  data-vv-scope="customer"
                  data-vv-validate-on="blur"
                  placeholder=" "
                  label="Email"
                  data-vv-name="email"
                  :error-messages="errors.collect('customer.email')"
                />
              </v-flex>
            </v-layout>

            <v-divider></v-divider>
            <h3>ADDRESS</h3>
            <v-layout>
              <v-flex xs12 md6 class="pa-2">
                <h4 class="mt-4 mb-4">Living Address</h4>
                <!-- 
                <va-select
                  v-model="customer.living_address.residental_status"
                  v-validate="'required'"
                  data-vv-scope="customer"
                  placeholder=" "
                  label="Residential Status"
                  :error-messages="errors.collect('customer.residential_status')"
                  :items="[]"
                  data-vv-name="residential_status"
                  data-vv-as="residential status"
                /> -->

                <va-autocomplete
                  v-model="customer.living_address.country"
                  v-validate="'required'"
                  data-vv-scope="customer"
                  placeholder=" "
                  label="Country *"
                  @change="onCountryChanged($event)"
                  :error-messages="errors.collect('customer.country')"
                  :items="commonState.countries"
                  :item-text="'name'"
                  :item-value="'name'"
                  data-vv-name="country"
                />
                <va-autocomplete
                  :disabled="!customer.living_address.country"
                  v-model="customer.living_address.province"
                  v-validate="'required'"
                  placeholder=" "
                  label="Province/State *"
                  data-vv-scope="customer"
                  :error-messages="errors.collect('customer.living_province')"
                  :items="provinces"
                  data-vv-name="living_province"
                  data-vv-as="living province"
                />

                <va-input
                  v-model="customer.living_address.city"
                  v-validate="'required|min:3|max:50'"
                  data-vv-scope="customer"
                  :error-messages="errors.collect('customer.living_city')"
                  placeholder=" "
                  label="City/Town *"
                  data-vv-validate-on="blur"
                  data-vv-name="living_city"
                  data-vv-as="living city"
                  show-clean
                />

                <va-input
                  v-model="customer.living_address.street"
                  v-validate="'required|min:3|max:100'"
                  :error-messages="errors.collect('customer.living_street')"
                  data-vv-scope="customer"
                  placeholder=" "
                  label="Address *"
                  data-vv-validate-on="blur"
                  data-vv-name="lliving_street"
                  data-vv-as="living street"
                  show-clean
                />
              </v-flex>

              <v-flex xs12 md6 class="pa-2">
                <h4 class="mt-4">Mailing Address</h4>
                <p class="mb-3 red--text text--darken-4">
                  Leave <b>empty</b> if is the same as <b>living address</b>
                </p>
                <va-autocomplete
                  :disabled="!customer.living_address.country"
                  v-model="customer.mailing_address.province"
                  placeholder=" "
                  label="Province/State"
                  data-vv-scope="customer"
                  :error-messages="errors.collect('customer.mailing_province')"
                  :items="provinces"
                  data-vv-name="mailing_province"
                  data-vv-as="mailing province"
                />

                <va-input
                  v-model="customer.mailing_address.city"
                  v-validate="'min:3|max:50'"
                  data-vv-scope="customer"
                  :error-messages="errors.collect('customer.mailing_city')"
                  placeholder=" "
                  label="City/Town"
                  data-vv-validate-on="blur"
                  data-vv-name="mailing_city"
                  data-vv-as="mailing city"
                />

                <va-input
                  v-model="customer.mailing_address.street"
                  v-validate="'min:3|max:100'"
                  :error-messages="errors.collect('customer.mailing_street')"
                  data-vv-scope="customer"
                  placeholder=" "
                  label="Street/District"
                  data-vv-validate-on="blur"
                  data-vv-name="mailing_street"
                  data-vv-as="mailing street"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>

          <v-tab-item :value="`tab-identification`" class="pa-4">
            <v-toolbar dense dark color="#485468" class="elevation-1">
              <v-toolbar-title>Identifications</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="hidden-sm-and-down">
                <v-btn dark flat @click="openIdDialog">Add</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-data-table
              :headers="idHeaders"
              hide-actions
              :items="customer.identifications"
              class="elevation-1"
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.number }}</td>
                <td class="text-xs-left">
                  {{ props.item.id_type | capitalize }}
                </td>
                <td class="text-xs-left">
                  {{ props.item.country | capitalize }}
                </td>
                <td class="text-xs-left">{{ props.item.expiry_date }}</td>
                <td class="">
                  <v-btn icon small title="Edit" @click="editId(props.item)">
                    <v-icon small>edit</v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    small
                    title="Delete"
                    @click="deleteId(props.item)"
                  >
                    <v-icon small>delete</v-icon>
                  </v-btn>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item :value="`tab-contact`" class="pa-4">
            <v-layout>
              <v-flex class="mr-3">
                <h3 class="mb-4">Nearest Contact</h3>
                <v-layout row wrap>
                  <v-flex xs12 md6 class="pr-3">
                    <va-input
                      v-model="customer.nearest_contact.first_name"
                      v-validate="'required|min:3|max:50'"
                      data-vv-scope="nearest"
                      :error-messages="errors.collect('nearest.first_name')"
                      placeholder=" "
                      label="First Name *"
                      data-vv-validate-on="blur"
                      data-vv-name="first_name"
                      data-vv-as="first name"
                      show-clean
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pl-2">
                    <va-input
                      v-model="customer.nearest_contact.last_name"
                      v-validate="'required|min:3|max:50'"
                      data-vv-scope="nearest"
                      :error-messages="errors.collect('nearest.last_name')"
                      placeholder=" "
                      label="Last Name *"
                      data-vv-validate-on="blur"
                      data-vv-name="last_name"
                      data-vv-as="last name"
                      show-clean
                    />
                  </v-flex>
                </v-layout>

                <va-select
                  :items="relations"
                  v-model="customer.nearest_contact.relation"
                  placeholder="Select Relation *"
                  data-vv-scope="nearest"
                  v-validate="'required'"
                  :data-vv-name="'relation'"
                  :error-messages="errors.first('nearest.relation')"
                ></va-select>

                <v-layout row wrap>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-validate="{
                        required: true,
                        regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      }"
                      v-model="customer.nearest_contact.phone1"
                      placeholder=" "
                      data-vv-scope="nearest"
                      label="Contact Number 1 *"
                      data-vv-name="phone1"
                      data-vv-as="contact number 1"
                      :return-masked-value="true"
                      :persistent-hint="true"
                      :hint="'Expected Format: 000 000 0000'"
                      :mask="'(###) ###-####'"
                      :error-messages="errors.collect('nearest.phone1')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pl-2">
                    <va-input
                      v-validate="{
                        required: false,
                        regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      }"
                      v-model="customer.nearest_contact.phone2"
                      placeholder=" "
                      data-vv-scope="nearest"
                      label="Contact Number 2"
                      data-vv-name="phone1"
                      data-vv-as="contact number 2"
                      :return-masked-value="true"
                      :persistent-hint="true"
                      :hint="'Expected Format: 000 000 0000'"
                      :mask="'(###) ###-####'"
                      :error-messages="errors.collect('nearest.phone2')"
                    />
                  </v-flex>
                </v-layout>

                <v-textarea
                  box
                  class="mt-4"
                  v-model="customer.nearest_contact.address"
                  v-validate="'required|min:3|max:255'"
                  data-vv-scope="nearest"
                  :error-messages="errors.collect('nearest.address')"
                  data-vv-validate-on="blur"
                  data-vv-name="address"
                  label="Address *"
                />
              </v-flex>

              <v-flex
                class="ml-3"
                v-if="
                  customer.marital_status == 'Married' ||
                    customer.marital_status == 'Common-law'
                "
              >
                <h3 class="mb-4">Spouse Information</h3>
                <v-layout row wrap>
                  <v-flex xs12 md6 class="pr-3">
                    <va-input
                      v-model="customer.spouse.first_name"
                      v-validate="
                        'min:3|max:50' +
                          (customer.marital_status == 'Married' ||
                          customer.marital_status == 'Common-law'
                            ? '|required'
                            : '')
                      "
                      data-vv-scope="spouse"
                      :error-messages="errors.collect('spouse.first_name')"
                      placeholder=" "
                      label="First Name *"
                      data-vv-validate-on="blur"
                      data-vv-name="first_name"
                      data-vv-as="first name"
                      show-clean
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pl-2">
                    <va-input
                      v-model="customer.spouse.last_name"
                      v-validate="
                        'min:3|max:50' +
                          (customer.marital_status == 'Married' ||
                          customer.marital_status == 'Common-law'
                            ? '|required'
                            : '')
                      "
                      data-vv-scope="spouse"
                      :error-messages="errors.collect('spouse.last_name')"
                      placeholder=" "
                      label="Last Name *"
                      data-vv-validate-on="blur"
                      data-vv-name="last_name"
                      data-vv-as="last name"
                      show-clean
                    />
                  </v-flex>
                </v-layout>

                <va-input
                  v-model="customer.spouse.anniversary_date"
                  data-vv-scope="spouse"
                  data-vv-as="anniversary date"
                  :error-messages="errors.collect('spouse.anniversary_date')"
                  placeholder=" "
                  return-masked-value
                  mask="##-##"
                  label="Anniversary Date (mm-dd)"
                  data-vv-validate-on="blur"
                  data-vv-name="anniversary_date"
                />

                <v-layout row wrap>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-validate="{
                        required:
                          customer.marital_status == 'Married' ||
                          customer.marital_status == 'Common-law'
                            ? true
                            : false,
                        regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      }"
                      v-model="customer.spouse.phone1"
                      placeholder=" "
                      data-vv-scope="spouse"
                      label="Contact Number 1 *"
                      data-vv-name="phone1"
                      data-vv-as="contact number 1"
                      :return-masked-value="true"
                      :persistent-hint="true"
                      :hint="'Expected Format: 000 000 0000'"
                      :mask="'(###) ###-####'"
                      :error-messages="errors.collect('spouse.phone1')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pl-2">
                    <va-input
                      v-validate="{
                        required: false,
                        regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                      }"
                      v-model="customer.spouse.phone2"
                      placeholder=" "
                      data-vv-scope="spouse"
                      label="Contact Number 2"
                      data-vv-name="phone2"
                      data-vv-as="contact number 2"
                      :return-masked-value="true"
                      :persistent-hint="true"
                      :hint="'Expected Format: 000 000 0000'"
                      :mask="'(###) ###-####'"
                      :error-messages="errors.collect('spouse.phone2')"
                    />
                  </v-flex>
                </v-layout>

                <v-textarea
                  box
                  class="mt-4"
                  v-model="customer.spouse.address"
                  v-validate="'min:3|max:255'"
                  data-vv-scope="spouse"
                  :error-messages="errors.collect('spouse.address')"
                  data-vv-validate-on="blur"
                  data-vv-name="address"
                  label="Address"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>

          <v-tab-item :value="`tab-employment`" class="pa-4">
            <h3>EMPLOYER DETAILS</h3>
            <v-divider></v-divider>
            <v-layout row>
              <v-flex xs12 md6 class="pa-3">
                <va-input
                  v-model="customer.employment_info.employer"
                  v-validate="
                    'min:3|max:120' +
                      (!validateEmploymentSection ? '' : '|required')
                  "
                  data-vv-scope="employment"
                  data-vv-validate-on="blur"
                  data-vv-as="employer"
                  label="Employer"
                  data-vv-name="employer"
                  placeholder=" "
                  :error-messages="errors.collect('employment.employer')"
                />
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.employment_info.position"
                      v-validate="
                        'min:3|max:100' +
                          (!customer.employment_info.employer
                            ? ''
                            : '|required')
                      "
                      data-vv-scope="employment"
                      data-vv-validate-on="blur"
                      label="Current Working Position"
                      data-vv-name="position"
                      data-vv-as="position"
                      placeholder=" "
                      :error-messages="errors.collect('employment.position')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.employment_info.year_of_employment"
                      v-validate="
                        'numeric|min:4|max:4|max_value:' +
                          new Date().getFullYear() +
                          '' +
                          (!customer.employment_info.employer
                            ? ''
                            : '|required')
                      "
                      data-vv-scope="employment"
                      data-vv-validate-on="blur"
                      data-vv-as="year of employment"
                      label="Year of Employment"
                      data-vv-name="year_of_employment"
                      placeholder=" "
                      :error-messages="
                        errors.collect('employment.year_of_employment')
                      "
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 md6 class="pa-3">
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <label>Monthly Income</label>
                    <va-input
                        v-model.number="customer.employment_info.monthly_income"
                        v-validate="'numeric|min_value:1'+
                          (!customer.employment_info.employer
                            ? ''
                            : '|required')"
                        data-vv-scope="employment"
                        data-vv-validate-on="blur"
                        data-vv-as="monthly income"
                        label="Monthly Income"
                        data-vv-name="monthly_income"
                        placeholder=" "
                        :error-messages="errors.collect('employment.monthly_income')"
                        type="number"
                      />
                    
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-autocomplete
                      v-model="customer.employment_info.profession"
                      data-vv-scope="employment"
                      data-vv-data-as="profession"
                      v-validate="
                        !customer.employment_info.employer ? '' : 'required'
                      "
                      placeholder=" "
                      label="Profession"
                      :error-messages="errors.collect('employment.profession')"
                      data-vv-validate-on="blur"
                      :items="professions"
                      :item-text="'sub_risk_factor_name'"
                      :item-value="'sub_risk_factor_name'"
                      data-vv-name="profession"
                    />
                  </v-flex>
                </v-layout>
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-autocomplete
                      v-model="customer.employment_info.industry"
                      data-vv-scope="employment"
                      v-validate="
                        !customer.employment_info.employer ? '' : 'required'
                      "
                      placeholder=" "
                      label="Industry"
                      :error-messages="errors.collect('employment.industry')"
                      data-vv-validate-on="blur"
                      :items="industries"
                      :item-text="'sub_risk_factor_name'"
                      :item-value="'sub_risk_factor_name'"
                      data-vv-name="industry"
                      data-vv-data-as="industry"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-autocomplete
                      v-model="customer.employment_info.sector"
                      data-vv-scope="employemnt"
                      v-validate="
                        !customer.employment_info.employer ? '' : 'required'
                      "
                      placeholder=" "
                      label="Sector"
                      :error-messages="errors.collect('employment.sector')"
                      data-vv-validate-on="blur"
                      :items="sectors"
                      :item-text="'sub_risk_factor_name'"
                      :item-value="'sub_risk_factor_name'"
                      data-vv-name="sector"
                      data-vv-data-as="sector"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>

            <v-layout>
              <v-flex xs12 md6 class="pa-2">
                <h3>ADDRESS</h3>
                <va-autocomplete
                  v-model="customer.employment_info.country"
                  data-vv-scope="employment"
                  v-validate="
                    !customer.employment_info.employer ? '' : 'required'
                  "
                  placeholder=" "
                  label="Country"
                  @change="onEmploymentCountryChanged($event)"
                  :error-messages="errors.collect('employment.country')"
                  :items="commonState.countries"
                  :item-text="'name'"
                  :item-value="'name'"
                  data-vv-name="country"
                />

                <va-autocomplete
                  :disabled="!customer.employment_info.country"
                  v-model="customer.employment_info.province"
                  v-validate="
                    !customer.employment_info.employer ? '' : 'required'
                  "
                  placeholder=" "
                  label="Province/State"
                  data-vv-scope="employment"
                  :error-messages="errors.collect('employment.province')"
                  :items="employmentProvinces"
                  data-vv-name="province"
                  data-vv-as="province"
                />

                <va-input
                  v-model="customer.employment_info.city"
                  v-validate="
                    'min:3|max:50' +
                      (!customer.employment_info.employer ? '' : '|required')
                  "
                  data-vv-scope="employment"
                  :error-messages="errors.collect('employment.city')"
                  placeholder=" "
                  label="City/Town"
                  data-vv-validate-on="blur"
                  data-vv-name="city"
                  data-vv-as="city"
                  show-clean
                />

                <va-input
                  v-model="customer.employment_info.street"
                  v-validate="
                    'min:3|max:100' +
                      (!customer.employment_info.employer ? '' : '|required')
                  "
                  :error-messages="errors.collect('employment.street')"
                  data-vv-scope="employment"
                  placeholder=" "
                  label="Address"
                  data-vv-validate-on="blur"
                  data-vv-name="street"
                  data-vv-as="street"
                  show-clean
                />
              </v-flex>

              <v-flex xs12 md6 class="pa-2">
                <h3>CONTACT INFORMATION</h3>
                <va-input
                  v-validate="'email|max:255'"
                  v-model="customer.employment_info.email"
                  data-vv-scope="employment"
                  data-vv-validate-on="blur"
                  placeholder=" "
                  label="Email"
                  data-vv-name="email"
                  :error-messages="errors.collect('employment.email')"
                />

                <va-input
                  v-validate="{
                    required: false,
                    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  }"
                  v-model="customer.employment_info.telephone"
                  placeholder=" "
                  data-vv-scope="employment"
                  label="Cell"
                  data-vv-name="telephone"
                  :return-masked-value="true"
                  :persistent-hint="true"
                  :hint="'Expected Format: 000 000 0000'"
                  :mask="'(###) ###-####'"
                  :error-messages="errors.collect('employment.telephone')"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>

          <v-tab-item :value="`tab-school`" class="pa-4">
            <h3>SCHOOL DETAILS</h3>
            <v-divider></v-divider>
            <v-layout row>
              <v-flex xs12 md6 class="pa-3">
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.school_info.institution"
                      v-validate="
                        'min:3|max:100' +
                          (!validateSchoolSection ? '' : '|required')
                      "
                      data-vv-scope="school"
                      data-vv-validate-on="blur"
                      label="Institution"
                      data-vv-name="institution"
                      data-vv-as="institution"
                      placeholder=" "
                      :error-messages="errors.collect('school.institution')"
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-select
                      v-model="customer.school_info.institution_type"
                      data-vv-scope="school"
                      placeholder=" "
                      v-validate="
                        !customer.school_info.institution ? '' : 'required'
                      "
                      label="Instituion Type"
                      :error-messages="errors.collect('school.profession')"
                      data-vv-validate-on="blur"
                      :items="institutionTypes"
                      data-vv-name="institution_type"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs12 md6 class="pa-3">
                <v-layout>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.school_info.year_of_enrollment"
                      v-validate="
                        'numeric|min:4|max:4|max_value:' +
                          new Date().getFullYear() +
                          '' +
                          (!customer.school_info.institution ? '' : '|required')
                      "
                      data-vv-scope="school"
                      data-vv-validate-on="blur"
                      data-vv-as="year of enrollment"
                      label="Year of Enrollment"
                      data-vv-name="year_of_enrollment"
                      placeholder=" "
                      ref="enrollment"
                      :error-messages="
                        errors.collect('school.year_of_enrollment')
                      "
                    />
                  </v-flex>
                  <v-flex xs12 md6 class="pr-2">
                    <va-input
                      v-model="customer.school_info.year_of_completion"
                      v-validate="
                        'numeric|min:4|max:4|min_value:' +
                          new Date().getFullYear() +
                          '' +
                          (!customer.school_info.institution ? '' : '|required')
                      "
                      data-vv-scope="school"
                      data-vv-validate-on="blur"
                      data-vv-as="year of completion"
                      label="Year of Completion"
                      data-vv-name="year_of_completion"
                      placeholder=" "
                      :error-messages="
                        errors.collect('school.year_of_completion')
                      "
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-divider></v-divider>

            <v-layout>
              <v-flex xs12 md6 class="pa-2">
                <h3>ADDRESS</h3>

                <va-autocomplete
                  v-model="customer.school_info.country"
                  v-validate="
                    !customer.school_info.institution ? '' : 'required'
                  "
                  data-vv-scope="school"
                  placeholder=" "
                  label="Country"
                  @change="onSchoolCountryChanged($event)"
                  :error-messages="errors.collect('school.country')"
                  :items="commonState.countries"
                  :item-text="'name'"
                  :item-value="'name'"
                  data-vv-name="country"
                />

                <va-autocomplete
                  :disabled="!customer.school_info.country"
                  v-model="customer.school_info.province"
                  v-validate="
                    !customer.school_info.institution ? '' : 'required'
                  "
                  placeholder=" "
                  label="Province/State"
                  data-vv-scope="school"
                  :error-messages="errors.collect('school.province')"
                  :items="schoolProvinces"
                  data-vv-name="province"
                  data-vv-as="province"
                />

                <va-input
                  v-model="customer.school_info.city"
                  v-validate="
                    'min:3|max:50' +
                      (!customer.school_info.institution ? '' : '|required')
                  "
                  data-vv-scope="school"
                  :error-messages="errors.collect('school.city')"
                  placeholder=" "
                  label="City/Town"
                  data-vv-validate-on="blur"
                  data-vv-name="city"
                  data-vv-as="city"
                  show-clean
                />

                <va-input
                  v-model="customer.school_info.street"
                  v-validate="
                    'min:3|max:100' +
                      (!customer.school_info.institution ? '' : '|required')
                  "
                  :error-messages="errors.collect('school.street')"
                  data-vv-scope="school"
                  placeholder=" "
                  label="Address"
                  data-vv-validate-on="blur"
                  data-vv-name="street"
                  data-vv-as="street"
                  show-clean
                />
              </v-flex>

              <v-flex xs12 md6 class="pa-2">
                <h3>CONTACT INFORMATION</h3>
                <va-input
                  v-validate="{
                    required: false,
                    regex: /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                  }"
                  v-model="customer.school_info.telephone"
                  placeholder=" "
                  data-vv-scope="school"
                  label="Telephone"
                  data-vv-name="telephone"
                  :return-masked-value="true"
                  :persistent-hint="true"
                  :hint="'Expected Format: 000 000 0000'"
                  :mask="'(###) ###-####'"
                  :error-messages="errors.collect('school.telephone')"
                />
                <va-input
                  v-validate="'email|max:255'"
                  v-model="customer.school_info.email"
                  data-vv-scope="school"
                  data-vv-validate-on="blur"
                  placeholder=" "
                  label="Email"
                  data-vv-name="email"
                  :error-messages="errors.collect('school.email')"
                />
              </v-flex>
            </v-layout>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
	<!-- Dialog used to tell the user that there is an issue with the Identification records -->
		<v-dialog v-model="idErrorDialog" width="500">
			<v-card>
				<v-card-title class="headline grey lighten-3" primary-title>
					Identification Error
				</v-card-title>

				<v-card-text>
					There seems to be an issue with one of the Identification records for
					this person.<br /><br />
					Please check the
					<span class="primary--text">IDENTIFICATION(s)</span> tab and update
					the record(s).
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						class="elevation-0"
						@click="idErrorDialog = false"
					>
						Got It
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>

<style>
h1,
h2,
h3 {
  color: #485468;
}

#avatar {
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 200px;
  height: 200px;
}

#miniAvatar {
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ddd;
  width: 100px;
  height: 100px;
}
</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import _ from "lodash";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
import VaAutocomplete from "@/components/form-components/VaAutocomplete.vue";
import uploader from "vue-image-crop-upload";
import customerService from "@/services/customer-service";
import settingsService from "@/services/general-settings-service";
import IDService from "@/services/id-type-service";
import validations from "@/common/validator";
import moment from "moment";

import storageService from "@/services/storage-service";
import riskFactorService from "@/services/risk-factor-service";
import AuthService from "@/services/authentication-service";
import { Money } from "v-money";
// import VaInput from "../../../components/form-components/VaInput.vue";
export default {
  components: {
    "avatar-uploader": uploader,
    VaInput,
    VaSelect,
    Money,
    VaAutocomplete,
  },
  data() {
    return {
      uploadUrl: "",
      imgDataUrl: "",
      loadingText: "",
      showUploader: false,
      isLoading: false,
      tabModel: "tab-personal",
      idHeaders: [
        {
          text: "Identification Number",
          align: "left",
          sortable: false,
          value: "number",
        },
        { text: "ID Type", align: "left", sortable: false, value: "id_type" },
        { text: "Country", align: "left", sortable: false, value: "country" },
        {
          text: "Expiration Date",
          align: "left",
          sortable: false,
          value: "expiry_date",
        },
        { text: "Action", align: "left", sortable: false },
      ],
      uploadParams: { ID: "" },
      headers: {
        Authorization: `Bearer ` + storageService.getItem("token") || null,
      },
      id: {},
      idIndex: -1,
      memberCategories: [],
      idDialog: false,
      previousId: {},
      institutionTypes: ["Pre School", "Primary", " Secondary", "Tertiary"],
      provinces: [],
      employmentProvinces: [],
      schoolProvinces: [],
      countries: [],
      idTypes: [],
      professions: [],
      positions: [],
      sectors: [],
      industries: [],
      relations: ["Child", "Mother", "Father", "Sibling", "Friend", "Relative"],
      maritalStatuses: [
        "Married",
        "Divorce",
        "Single",
        "Common-law",
        "Widow/Widower",
      ],
      genders: ["Male", "Female", "Other"],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "$",
        precision: 2,
        masked: false,
      },
      accountSettings: {},

      possibleDuplicatePerson: {},
      isUiqDialog: null,
	  //Boolean used to display ID Error Dialog
	  idErrorDialog: false,
    };
  },
  props: {
    youth: {
      type: Boolean,
      default: false,
    },
    opened: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: function() {
        return {
          contact_info: {},
          living_address: { type: "Living" },
          mailing_address: { type: "Mailing" },
          employment_info: {},
          school_info: {},
          identifications: [],
          spouse: {},
          nearest_contact: {},
        };
      },
    },
  },

  async created() {
    this.setupValidations();
    this.uploadUrl = process.env.VUE_APP_ROOT_API + "persons/avatar";
    this.imgDataUrl = require("@/assets/img/avatar.png");
    let industryDataset = await riskFactorService.findAll(
      "risk_factor_name=eq:Industry"
    );
    let sectorDataset = await riskFactorService.findAll(
      "risk_factor_name=eq:Sector"
    );
    let professionDataset = await riskFactorService.findAll(
      "risk_factor_name=eq:Profession"
    );
    industryDataset.data.forEach(
      (el) => (this.industries = el.sub_risk_factors)
    );
    sectorDataset.data.forEach((el) => (this.sectors = el.sub_risk_factors));
    professionDataset.data.forEach(
      (el) => (this.professions = el.sub_risk_factors)
    );
  },

  watch: {
    opened: async function() {
      try {
        if (!this.opened) {
          this.isLoading = false;
          return false;
        }

        if (!storageService.getItem("user")) {
          AuthService.logout();
          this.isLoading = false;
          return false;
        }

        let user = storageService.getItem("user");
        let livingAddress =
          this.customer.living_address.country || user.branch.branch_country;
        let employmentAddress =
          this.customer.living_address.country || user.branch.branch_country;
        let schoolAddress =
          this.customer.school_info.country || user.branch.branch_country;

        this.loadingText = "Loading...";
        this.isLoading = true;
        this.$validator.reset();

        await this.init();

        this.imgDataUrl =
          this.customer.avatar || require("@/assets/img/avatar.png");

        this.onDateOFBirthChanged();
        this.onCountryChanged(livingAddress);
        this.onEmploymentCountryChanged(employmentAddress);
        this.onSchoolCountryChanged(schoolAddress);

        // this.$refs.monthly_income.$el.getElementsByTagName("input")[0].value =
        // this.customer.employment_info.monthly_income || 0.0;
        this.tabModel = "tab-personal";
        this.uploadParams["ID"] = this.customer.ID;
      } finally {
        this.isLoading = false;
      }
    },
  },

  computed: {
    ...mapState(["appState", "commonState"]),

    formOpened: {
      get: function() {
        return this.opened;
      },

      set: function(newValue) {
        this.$emit("update:opened", newValue);
      },
    },

    formTitle() {
      return this.customer.ID ? "Edit Customer" : "New Customer";
    },

    validateEmploymentSection() {
      let populateEl = [];
      if (this.customer) {
        Object.keys(this.customer.employment_info).forEach((key) => {
          if (
            key === "monthly_income" &&
            this.customer.employment_info[key] > "0.00"
          ) {
            populateEl.push(key);
          } else if (
            !key === "country" &&
            this.customer.employment_info[key] &&
            this.customer.employment_info[key].trim()
          ) {
            populateEl.push(key);
          }
        });
      }
      return populateEl.length > 0;
    },

    validateSchoolSection() {
      let populateEl = [];
      if (this.customer) {
        Object.keys(this.customer.school_info).forEach((key) => {
          if (
            !key === "country" &&
            this.customer.school_info[key] &&
            this.customer.school_info[key].trim()
          ) {
            populateEl.push(key);
          }
        });
      }
      return populateEl.length > 0;
    },
  },
  methods: {
    ...mapGetters(["getTimezonesAndOffsets"]),
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),
    ...mapActions(["getCountries", "getCurrencies", "getTimezones"]),

    async init() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        await this.fetchIDs();
        await this.fetchccountASettings();
        await this.getCountries();
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async fetchccountASettings() {
      try {
        let result = await settingsService.findAccountSettings();

        if (result !== {}) {
          for (let key in result) {
            if (
              typeof result[key] === "string" &&
              result[key].indexOf("{'") > -1
            ) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }
          this.accountSettings = result;
        }
      } catch (e) {
        throw e;
      }
    },

    setupValidations() {
      this.$validator.extend(
        validations.greaterThanToday.name,
        validations.greaterThanToday.validator
      );
      this.$validator.extend(
        validations.isBigger.name,
        validations.isBigger.validator,
        { hasTarget: true }
      );
      this.$validator.extend(
        validations.notNegative.name,
        validations.notNegative.validator
      );
    },

    onCountryChanged(ev) {
      this.provinces = [];
      this.customer.living_address.country = ev;
      this.customer.mailing_address.country = ev;
      for (let key in this.commonState.countries) {
        if (this.commonState.countries[key].name === ev) {
          let divisions = [];
          Object.keys(this.commonState.countries[key].divisions).forEach(
            (keyx) => {
              divisions.push(this.commonState.countries[key].divisions[keyx]);
            }
          );
          this.provinces = divisions;
          break;
        }
      }
    },

    onDateOFBirthChanged() {
      let youthAge = Number(this.accountSettings.youth_max_age);
      let seniorAge = Number(this.accountSettings.senior_min_age);
      let age = Number(
        moment().diff(
          moment(this.customer.date_of_birth, "YYYY-MM-DD"),
          "years"
        )
      );
      this.memberCategories = ["Regular", "Staff", "Volunteer"];
      if (age <= youthAge) {
        this.memberCategories = ["Youth", "Staff", "Volunteer"];
        this.customer.member_category = "Youth";
      } else if (age >= seniorAge) {
        this.memberCategories = ["Senior", "Staff", "Volunteer"];
        this.customer.member_category = "Senior";
      }
    },

    onEmploymentCountryChanged(ev) {
      this.employmentProvinces = [];
      this.customer.employment_info.country = ev;
      for (let key in this.commonState.countries) {
        if (this.commonState.countries[key].name === ev) {
          let divisions = [];
          Object.keys(this.commonState.countries[key].divisions).forEach(
            (keyx) => {
              divisions.push(this.commonState.countries[key].divisions[keyx]);
            }
          );
          this.employmentProvinces = divisions;
          break;
        }
      }
    },

    onSchoolCountryChanged(ev) {
      this.schoolProvinces = [];
      this.customer.school_info.country = ev;
      for (let key in this.commonState.countries) {
        if (this.commonState.countries[key].name === ev) {
          let divisions = [];
          Object.keys(this.commonState.countries[key].divisions).forEach(
            (keyx) => {
              divisions.push(this.commonState.countries[key].divisions[keyx]);
            }
          );
          this.schoolProvinces = divisions;
          break;
        }
      }
    },

    openIdDialog() {
      this.idDialog = true;
      this.idIndex = -1;
      this.previousId = {};
      this.id.country = this.customer.living_address.country;
    },

    closeIdDialog() {
      this.idDialog = false;
      if (this.idIndex > -1)
        this.customer.identifications.splice(this.idIndex, 1, this.previousId);

      this.id = { ...{} };
      this.$validator.reset({ scope: "id" });
    },

    async addId() {
      let isValid = await this.$validator.validateAll("id");
      if (!isValid) {
        this.$toast.error(
          "Please ensure all fields are valid before proceeding to save.",
          "",
          { position: "topCenter" }
        );
        return false;
      }

      let exist = await this.doesIDExist();
      if (exist) return;

      if (this.idIndex > -1) {
        this.$toast.success("Successfully updated Id.", "", {
          position: "topCenter",
        });
      } else {
        this.customer.identifications.push(this.id);
        this.$validator.reset({ scope: "id" });
      }

      this.id = {};
      this.idIndex = -1;
      this.idDialog = false;
    },

    editId(item) {
      let index = this.customer.identifications.indexOf(item);
      this.previousId = { ...item };
      this.idIndex = index;
      this.id = item;
      this.idDialog = true;
    },

    deleteId(item) {
      let index = this.customer.identifications.indexOf(item);
      this.customer.identifications.splice(index, 1);
    },

    async doesIDExist() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
      let search = `number=eq:${this.id.number}:and&country=eq:${
        this.id.country
      }:and&id_type=${this.id.id_type}`;
      let foundList = [];
      try {
        if (this.idIndex > -1) {
          let filteredList = this.customer.identifications.map(
            (el, i) => i !== this.idIndex
          );
          foundList = filteredList.filter(
            (el) =>
              el.number == this.id.number &&
              el.country == this.id.country &&
              el.id_type == this.id.id_type
          );
        } else {
          foundList = this.customer.identifications.filter(
            (el) =>
              el.number == this.id.number &&
              el.country == this.id.country &&
              el.id_type == this.id.id_type
          );
        }

        if (foundList.length > 0) {
          this.$toast.warning(
            "Identification Number already exist on this person.",
            "",
            { position: "topCenter", timeout: 7000 }
          );
          return true;
        }

        let items = await customerService.findById(search, "", {
          skip: 0,
          limit: 1,
        });

        items = items.data;
        if (items.length > 0) {
          this.$toast.warning("Identification Number already exist.", "", {
            position: "topCenter",
            timeout: 7000,
          });
          return true;
        }

        return false;
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
        return true;
      }
    },

    async checkIfPersonIsUnqiue() {
      this.loadingText = "Checking Uniqueness...";
      this.isLoading = true;
      let result = { data: [] };

      try {
        let searchMap = {
          trn: this.customer.trn,
          first_name: this.customer.first_name,
          last_name: this.customer.last_name,
          date_of_birth: this.customer.date_of_birth,
          guardian_last_name: this.customer.guardian_last_name,
        };

        let search = ``;

        //remove empty strings
        Object.keys(searchMap).forEach((key) => {
          if (!searchMap[key] || !searchMap[key].trim()) {
            delete searchMap[key];
          }
        });

        if (this.customer.ID) {
          let resultSet = await customerService.findOne(this.customer.ID);
          let previousData = resultSet.data;

          if (searchMap["trn"] === previousData["trn"]) {
            delete searchMap["trn"];
          }
          if (
            previousData["first_name"] === searchMap["first_name"] &&
            previousData["last_name"] === searchMap["last_name"] &&
            previousData["date_of_birth"] === searchMap["date_of_birth"] &&
            previousData["guardian_last_name"] ===
              searchMap["guardian_last_name"]
          ) {
            delete searchMap["first_name"];
            delete searchMap["last_name"];
            delete searchMap["date_of_birth"];
            delete searchMap["guardian_last_name"];
          }
        }

        Object.keys(searchMap).forEach((key) => {
          if (key === "trn") {
            search += `${key}=eq:${searchMap[key]}:or&`;
          } else {
            search += `${key}=eq:${searchMap[key]}:and&`;
          }
        });

        if (search !== "")
          result = await customerService.findAll(search, "", {
            skip: 0,
            limit: 0,
          });
      } catch (e) {
        throw e;
      }
      return result.data;
    },

    gotoProfile() {
      this.isUiqDialog = false;
      this.formOpened = false;
      this.$emit("gotoprofile", this.possibleDuplicatePerson);
    },

    showUploaderDialog() {
      if (!this.customer.ID) {
        this.$toast.destroy();
        this.$toast.error(
          "Unable to complete action please save customer details then try again",
          "",
          { position: "topRight" }
        );
        return;
      }
      this.showUploader = true;
    },

    cropSuccess(imgDataUrl) {
      this.croppedImage = imgDataUrl;
    },

    cropUploadSuccess(jsonData) {
      this.imgDataUrl = this.croppedImage;
      this.customer.avatar = jsonData.data;
      this.$emit("updated", { ...this.customer });
      this.$toast.success("Customer Image was successfully changed");
    },

    cropUploadFail() {
      this.$toast.error(
        "Unable to upload file due to an unexpected error please try again"
      );
    },

    async onSubmit() {
      let isValid = await this.$validator.validateAll("customer");
      if (!isValid) {
        this.tabModel = "tab-personal";
        this.$toast.error(
          "Unable to complete action please ensure all fields are valid",
          "",
          { position: "topCenter" }
        );
        return;
      }

      isValid = await this.$validator.validateAll("nearest");
      let isValid2 = await this.$validator.validateAll("spouse");
      if (!isValid || !isValid2) {
        this.tabModel = "tab-contact";
        this.$toast.error(
          "Unable to complete action please ensure all fields are valid",
          "",
          { position: "topCenter" }
        );
        return;
      }

      isValid = await this.$validator.validateAll("employment");
      if (!isValid) {
        this.tabModel = "tab-employment";
        this.$toast.error(
          "Unable to complete action please ensure all fields are valid",
          "",
          { position: "topCenter" }
        );
        return;
      }

      isValid = await this.$validator.validateAll("school");
      if (!isValid) {
        this.tabModel = "tab-school";
        this.$toast.error(
          "Unable to complete action please ensure all fields are valid",
          "",
          { position: "topCenter" }
        );
        return;
      }

      Object.keys(this.customer.living_address).forEach((key) => {
        if (!this.customer.mailing_address[key])
          this.customer.mailing_address[key] = this.customer.living_address[
            key
          ];
      });

      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);

        let result = await this.checkIfPersonIsUnqiue();
        if (result.length === 0) {
          await this.save();
          return;
        }

        this.possibleDuplicatePerson = result[0];
        this.isUiqDialog = true;
      } catch (e) {
        console.error("[saving business] ", e);
		//Check if the error is based on the ID type being missing
		if (e.response.data.error == '"id_type" is required' && e.response.status == 422) {
			this.idErrorDialog = true;
			return;
		}
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },

    async save() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        this.loadingText = "Saving...";
        this.isLoading = true;
        let editedObj = _.cloneDeep(this.customer);

        if (
          !editedObj.employment_info.employer ||
          !editedObj.employment_info.employer.trim()
        ) {
          delete editedObj["employment_info"];
        } else {
          // editedObj.employment_info.monthly_income = Number(
          //   editedObj.employment_info.monthly_income.replace(/,/g, "")
          // );
        }

        if (
          !editedObj.school_info.institution ||
          !editedObj.school_info.institution.trim()
        )
          delete editedObj["school_info"];

        if (!editedObj.ID) {
          let response = await customerService.create(editedObj);
          let resultSet = await customerService.findOne(response.data);

          if (!resultSet.data.employment_info)
            resultSet.data.employment_info = {};
          if (!resultSet.data.school_info) resultSet.data.school_info = {};
          if (!resultSet.data.spouse) resultSet.data.spouse = {};
          if (!resultSet.data.nearest_contact)
            resultSet.data.nearest_contact = {};

          this.uploadParams["ID"] = response.data;
          this.$emit("created", _.cloneDeep(resultSet.data));
          this.$toast.success("Successfully added customer record!", "OK", {
            position: "topRight",
          });
          this.tabModel = "tab-personal";
        } else {
          this.deleteUnwantedProperties(editedObj);
          delete editedObj.ID;

          await customerService.update(this.customer.ID, editedObj);
          this.$toast.success("Successfully updated customer!", "OK", {
            position: "topRight",
          });
          this.$emit("updated", { ...this.customer });
          this.formOpened = false;
        }
      } catch (e) {
        if (e.response) {
          if (e.response.status !== 409) {
            throw e;
          }
          this.$toast.error(e.response.data.error.message, "", {
            position: "center",
          });
        } else {
          throw e;
        }
      }
    },

    deleteUnwantedProperties(obj) {
      for (let key in obj) {
        if (typeof obj[key] === "object" && obj[key] !== null) {
          this.deleteUnwantedProperties(obj[key]);
        } else if (
          key === "date_created" ||
          key === "date_updated" ||
          key === "avatar" ||
          key === "age"
        ) {
          delete obj[key];
        }

        if (obj[key] === null) {
          obj[key] = "";
        }
      }
    },

    async fetchIDs() {
      try {
        this.isLoading = true;
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let response = await IDService.findAll();
        this.idTypes = response.data;
      } catch (e) {
        console.log("fetching: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
