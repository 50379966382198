<template>
  <v-app id="app-layout">
    <v-dialog v-model="timeoutModal" :max-width="'400px'" persistent>
      <v-card class="px-4 pt-4 pb-2">
        <v-layout>
          <v-flex class="text-xs-center">
            <v-progress-circular
              :rotate="360"
              :size="100"
              :width="15"
              :value="timeoutCountdown"
              color="teal"
            >
              {{ timeoutCountdown }}
            </v-progress-circular>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex class="text-xs-center">
            <p class="title font-weight-light mt-4">
              Due to inactivity your session will terminate at the end of the
              countdown.
            </p>
          </v-flex>
        </v-layout>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" flat @click="logOut">Sign out</v-btn>
          <v-btn color="teal darken-1" flat @click="resumeSession"
            >Stay Signed In</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <detect-network v-on:detected-condition="detected">
      <!-- <div slot="online">Your Online Content!</div>
      <div slot="offline">Your Offline Content!</div>-->
    </detect-network>
    <v-navigation-drawer
      fixed
      v-model="appState.showSidebarModel"
      :mini-variant="appState.sidebarMini"
      mini-variant-width="60"
      clipped
      app
      permanent
      width="290"
    >
      <v-layout fill-height>
        <v-navigation-drawer
          class="mini-bar"
          mini-variant-width="60"
          mini-variant
          permanent
          stateless
          value="true"
        >
          <v-list class="grow" dense>
            <template v-for="item in links">
              <v-list-tile :key="item.text" :to="item.route">
                <v-list-tile-action>
                  <v-btn icon>
                    <v-icon :color="'#777'">{{ item.icon }}</v-icon>
                  </v-btn>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.text }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </v-list>

          <v-list class="grow" style="position: absolute; bottom: 5px" dense>
            <v-list-tile>
              <v-list-tile-action>
                <v-btn :to="'/app/audit-reports/'" icon>
                  <v-icon :color="'#777'">history</v-icon>
                </v-btn>
              </v-list-tile-action>
            </v-list-tile>

            <v-list-tile>
              <v-list-tile-action>
                <v-badge color="error" right overlap>
                  <template
                    v-slot:badge
                    v-if="
                      releaseNotesState.update_count != 0 &&
                      releaseNotesState.view_software_updates == false
                    "
                  >
                    <span>{{ releaseNotesState.update_count }}</span>
                  </template>
                  <v-btn :to="'/app/release-notes/'" icon>
                    <v-icon :color="'#777'">system_update_alt</v-icon>
                  </v-btn>
                </v-badge>
              </v-list-tile-action>
            </v-list-tile>

            <v-list-tile :to="'/app/settings'" v-if="canAccessSettings">
              <v-list-tile-action>
                <v-btn icon>
                  <v-icon :color="'#777'">settings</v-icon>
                </v-btn>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title></v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-navigation-drawer>

        <v-list
          class="grow extended-sidebar"
          dense
          v-if="!appState.sidebarMini"
        >
          <template v-for="(item, i) in appState.sidebarRoutes">
            <v-subheader row v-if="item.heading" :key="i" class="pb-0">{{
              item.heading
            }}</v-subheader>

            <template v-if="item.title">
              <v-list-tile
                :key="i"
                v-if="!item.hide"
                :to="item.route"
                style="height: 34px"
              >
                <v-list-tile-action
                  :key="i"
                  v-if="item.title"
                  style="min-width: 24px"
                >
                  <v-icon size="14">{{ item.icon }}</v-icon>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>{{ item.title }}</v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </template>
          </template>
        </v-list>
      </v-layout>
    </v-navigation-drawer>
    <v-alert
      :type="appState.pageAlert.type"
      class="app-alert animated faster slideInDown"
      :value="appState.pageAlert.visible"
      @input="onPageAlertClosed"
      dismissible
      >{{ appState.pageAlert.content }}</v-alert
    >
    <v-toolbar
      style="padding-left: 0px"
      color="blue darken-3"
      dark
      app
      clipped-right
      :clipped-left="$vuetify.breakpoint.mdAndUp"
      fixed
      dense
    >
      <v-toolbar-side-icon
        v-show="appState.showSidebarMenuControl"
        @click.stop="toggleMiniBar()"
      ></v-toolbar-side-icon>
      <span class="title ml-3 mr-5">
        App&nbsp;
        <span class="text">Center</span>
      </span>

      <v-spacer></v-spacer>
      <v-avatar class="mr-2" size="36">
        <img :src="getAuthUserAvatar" />
      </v-avatar>

      <v-menu offset-y="offset-y">
        <v-btn flat="flat" slot="activator" small="small">
          {{
            !getAuthUser.first_name
              ? "System Admin"
              : getAuthUser.first_name + " " + getAuthUser.last_name
          }}
          <v-icon>keyboard_arrow_down</v-icon>
        </v-btn>
        <v-list class="pa-0">
          <v-list-tile @click="gotoAccountSettings()">
            <v-icon class="mr-2">person_pin</v-icon>
            <v-list-tile-title>Account Settings</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="gotoAccountStat()">
            <v-icon class="mr-2">poll</v-icon>
            <v-list-tile-title>My Daily Report</v-list-tile-title>
          </v-list-tile>
          <v-list-tile @click="logOut">
            <v-icon class="mr-2" small>fa-power-off</v-icon>
            <v-list-tile-title>Logout</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>

    <v-content style="background: #f9f9f9">
      <router-view></router-view>
    </v-content>
  </v-app>
</template>
<style scope>
.mini-bar .v-list__tile {
  padding: 0px 7px;
}

.extended-sidebar {
  overflow: auto;
}
.extended-sidebar .v-subheader {
  font-weight: bold;
  color: #505a6b;
}
.extended-sidebar .v-list__tile__title {
  font-weight: 400;
  font-size: 14px;
  color: #505a6b;
}
.extended-sidebar .v-list__tile__action {
  min-width: 32px;
}

.app-alert {
  z-index: 9999;
  position: absolute;
  width: 100%;
  padding: 10px;
  margin-top: 0;
}
</style>
<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import NetworkDetection from "v-offline";
import AuthService from "@/services/authentication-service";
import storageService from "@/services/storage-service";
import jwtDecoder from "jwt-decode";

export default {
  components: {
    detectNetwork: NetworkDetection,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      try {
        const token = storageService.getItem("token");
        const decodedValues = jwtDecoder(token);
        if (decodedValues.exp < Date.now() / 1000) {
          alert(`Your session has expired. Please login again. `);
          AuthService.logout();
          return;
        }
        const user = storageService.getItem("user") || {};
        if (user.should_change_password && to.path != "/app/account-settings") {
          vm.$router.push("/app/account-settings");
        }
      } catch (e) {
        AuthService.logout();
        console.log(e);
      }
    });
  },

  data() {
    return {
      canAccessSettings: false,
      timeoutModal: false,
      timeoutCountdown: 100,
      interval: null,

      userData: {},
      appSettingsDialog: true,
      links: [
        { icon: "apps", text: "Apps", route: "/app/modules" },
        {
          icon: "notifications",
          text: "Notifications",
          route: "/app/notifications"
        }
      ],
    };
  },

  onIdle() {
    this.timeoutModal = true;
    this.timeoutCountdown = 100;
    this.interval = setInterval(() => {
      this.timeoutCountdown =
        this.timeoutCountdown > 0 ? (this.timeoutCountdown -= 5) : 0;
      if (this.timeoutCountdown == 0) {
        clearInterval(this.interval);
        this.logOut();
      }
    }, 1000);
  },

  created() {
    this.initialize();
  },

  computed: {
    ...mapState(["appState", "authState", "releaseNotesState"]),
    ...mapGetters(["getAuthUserAvatar", "getAuthUser"]),
  },
  methods: {
    ...mapMutations(["TOGGLE_SIDEBAR_MINI", "SHOW_UNEXPECTED_ERROR_DIALOG"]),
    ...mapActions(["getSoftwareUpdates"]),

    initialize() {
      this.user = storageService.getItem("user") || {};
      this.getSoftwareUpdates();
      let found = this.user.app_access.find(
        (el) => el.app_name == "System Settings"
      );
      this.canAccessSettings = found !== undefined;
      if (
        storageService.getItem("view_software_updates") == null ||
        storageService.getItem("view_software_updates") == undefined
      )
        storageService.setItem("view_software_updates", false);
      if (this.user.is_admin == true) this.canAccessSettings = true;
    },

    toggleMiniBar() {
      this.TOGGLE_SIDEBAR_MINI();
    },

    gotoAccountSettings() {
      this.$router.push("/app/account-settings");
    },
    gotoAccountStat() {
      this.$router.push("/app/user-stat");
    },
    logOut() {
      AuthService.logout();
    },

    resumeSession() {
      this.timeoutCountdown = 100;
      clearInterval(this.interval);
      this.timeoutModal = false;
    },

    onPageAlertClosed() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },

    detected(e) {
      if (e) {
        this.$toast.info("Your are connected", "", {
          timeout: 4000,
          position: "bottomRight",
        });
      } else {
        this.$toast.error("Lost connection", "", {
          timeout: 4000,
          position: "bottomRight",
        });
      }
    },
  },
};
</script>
