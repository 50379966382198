<template>
 <div>
   <h2>Loan Fee Setup</h2>
   <v-divider class="mb-4"></v-divider>
    <v-dialog persistent v-model="dialog" max-width="700px" class="pb-3">
      <v-card class="pb-2">
        
      <v-card-title>
        <v-toolbar-title><span style="border-bottom: 2px dashed #777; color: #505a6b">{{formTitle}}</span></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-btn icon  @click="close">
          <v-icon color="#505a6b">close</v-icon>
        </v-btn>
      </v-card-title>

        <v-card-text>
            <v-layout row>
              <v-flex xs6 md6 lg6>
                <va-input
                  class="mr-2"
                  v-model="editedItem.loan_fee_code"
                  v-validate="'required|min:2|max:20'"
                  data-vv-scope="fee"
                  :error-messages="errors.collect('fee.loan_fee_code')"
                  placeholder=" "
                  label="Fee Code"
                  data-vv-validate-on="blur"         
                  data-vv-name="loan_fee_code"
                  data-vv-as="loan fee code"
                  :disabled="isDisabled"
                  show-clean />
              </v-flex>
              <v-flex xs6 md6 lg6>
                <va-input 
                  class="mr-3"
                  v-model="editedItem.loan_fee_name"
                  v-validate="'required|min:3|max:40'"
                  placeholder=" "
                  label="Fee Name"
                  data-vv-scope="fee"
                  data-vv-as="loan fee name"
                  :error-messages="errors.collect('loan.loan_fee_name')"
                  :disabled="isDisabled"
                  data-vv-name="loan_fee_name"/>
              </v-flex>
            </v-layout>
            <v-checkbox class="mt-0" v-model="editedItem.attract_gct" label="Attract G.C.T"></v-checkbox>

            <h3>Policies</h3>
            <br/>

            <div style="overflow-y: auto !important; max-height: 240px;"  class="elevation-1">
              <v-data-table :headers="policyHeaders" :items="editedItem.policies"  hide-actions>
                <template slot="items" slot-scope="props">
                  <tr>
                  <td  class="text-xs-center">{{ props.item.min | currency}}</td>
                  <td  class="text-xs-center">
                    <span v-if="props.item.max == '-'">{{ props.item.min | currency }}  and Over </span>
                    <span v-else>{{ props.item.max | currency}}</span>
                    </td>
                  <td  class="text-xs-right">
                    <v-edit-dialog   
                      lazy
                      @close="onEditDialogClosed" 
                      @open="onEditDialogOpened(props.item)" 
                      > 
                      {{ props.item.percentage + ' %' }}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|max_value:100'" 
                        data-vv-scope="policy"
                        data-vv-name="percentage"
                        :error-messages="errors.collect('policy.percentage')"
                        v-model="props.item.percentage"  
                        data-vv-as="percentage amount"
                        label="Edit" 
                        single-line counter></v-text-field>
                    </v-edit-dialog>
                  </td>
                  <td  class="text-xs-center">
                    <v-edit-dialog  
                      lazy
                      @close="onEditDialogClosed" 
                      @open="onEditDialogOpened(props.item)" 
                      > 
                      {{ props.item.flat | currency}}
                      <v-text-field 
                        slot="input" 
                        v-validate="'required|decimal|min_value:0'" 
                        data-vv-scope="policy"
                        data-vv-name="flat"
                        :error-messages="errors.collect('policy.flat')"
                        v-model="props.item.flat"  
                        data-vv-as="flat amount"
                        label="Edit" 
                        single-line counter></v-text-field>
                    </v-edit-dialog>
                  </td>

                  </tr>
                </template>
                <template slot="no-data">
                  
                </template>
              </v-data-table>
            </div>
      
        </v-card-text>

        <v-card-actions class="ml-2 mr-2">
          <v-btn color="primary" outline @click="onGenerateRangeClicked">Generate Policies</v-btn>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>

          <v-btn color="primary"  @click="submit" :loading="isLoading">Save</v-btn>
        </v-card-actions>
        
      </v-card>
    
    </v-dialog>

    <v-dialog v-model="rangeDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">New Range Generation</v-card-title>

        <v-card-text class="mb-4">
          <va-input
            v-model="newRange.min"
            v-validate="'required|min_value:0'"
            data-vv-scope="newRange"
            :error-messages="errors.collect('newRange.min')"
            placeholder=" "
            label="Starting amount" 
            data-vv-validate-on="blur"     
            data-vv-name="min"
            data-vv-as="starting amount"
            show-clean />

            <va-input
              v-model="newRange.rows"
              v-validate="'required|numeric|min_value:1|max_value:50'"
              data-vv-scope="newRange"
              :error-messages="errors.collect('newRange.rows')"
              placeholder=" "
              label="Number of rows"
              data-vv-validate-on="blur" 
              data-vv-name="rows"
              show-clean />
            <va-input
              v-model="newRange.interval"
              v-validate="'required|numeric|min_value:1'"
              data-vv-scope="newRange"
              :error-messages="errors.collect('newRange.interval')"
              placeholder=" "
              label="Intervals"
              data-vv-validate-on="blur"         
              data-vv-name="interval"
              show-clean />
        </v-card-text>

        <v-card-actions style="margin-top:-30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeGenerateRangeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="onGenerateRange">Generate</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
    <v-toolbar dense color="white" class="pa-0">
      <v-text-field
        v-model="globalSearch"
        @keyup="onSearch()"
        solo
        label="Search"
        prepend-inner-icon="search"
      ></v-text-field>

      <v-divider class="mr-2" vertical></v-divider>

      <v-btn @click='openNewDialog' color="primary" dark class="mb-2">New Loan Fee</v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      class="elevation-1"
      :items="items"
      :loading="isLoading"
      :total-items="totalCount"
      :pagination.sync="pagination"
      :rows-per-page-items='[10,20, 30,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    >
     <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.loan_fee_code }}</td>
        <td>{{ props.item.loan_fee_name }}</td>
        <td>{{ props.item.date_created }}</td>
        <td>
          <v-icon title="edit" small class="mr-2" @click="onEditItemClicked(props.item, props.index)">
            edit
          </v-icon>
          <v-icon title="delete" small class="mr-2" @click="onDeleteItemClicked(props.item, props.index)" v-if="!isDeleteDisabled(props.item)">
            delete
          </v-icon>
        </td>
      </template>
      <template slot="no-data">
        
      </template>
    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import PaginationMixin from '@/mixins/PaginationMixin'
import loanFeeService from '@/services/loan-fee-service'
import moment from 'moment'
import _ from 'lodash'
export default {
  components: {
    VaInput,
  },

  mixins: [PaginationMixin],

  created () {
    this.initialize()
  },
  
  beforeDestroy() {
    this.$toast.destroy()
  },

  data() {
    return {
      dialog: false,
      rangeDialog: false,
      feeSearch: '',
      isLoading: false,
      defaultFee: {
        loan_fee_code : '',
        loan_fee_name : '',
        policies: []
      },
      fees: [],
      totalCount: 0,
      policyHeaders: [
        { text: 'Min', align: 'center', sortable: false, value: 'min'},
        { text: 'Max', align: 'center', sortable: false, value: 'max'},
        { text: 'Percentage', align: 'left', sortable: false, value: 'percentage'},
        { text: 'Flat', align: 'left', sortable: false, value: 'flat'},
      ],
      headers: [
        { text: 'Loan Fee Code', align: 'left', sortable: true, value: 'loan_fee_code'},
        { text: 'Loan Fee Name', align: 'left', sortable: true, value: 'loan_fee_name'},
        { text: 'Date Created', value: 'date_created' },
        { text: 'Action', value: ''},
      ],
      items: [],
      editedIndex: -1,
      editedItem: {},
      newRange: {
        min: '',
        max: '',
        interval: '',
        percentage: '',
        flat: ''
      },
      previousValue: {},
      selectedPolicy: {},
      previousPolicy: {},
      disabledLoanFeeCodes: ["PSR", "PSC", "PSA", "PSU","104", "109", "110", "105","103","101","111","112","SC113","SC114","SC115","SC116"],
    }
  },

  computed: {
    ...mapState([
      'appState',
    ]), 
    
    formTitle () {
      return this.editedIndex === -1 ? 'New Loan Fee' : 'Edit Loan Fee'
    },

    isDisabled() {
      return this.disabledLoanFeeCodes.includes(this.editedItem.loan_fee_code);
    },
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),
    
    isDeleteDisabled(item) {
      return this.disabledLoanFeeCodes.includes(item.loan_fee_code);
    },

    async initialize () {
      try{
        this.editedItem = Object.assign({}, this.defaultFee)
      } catch(e) {
        console.log('initializing: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, this.defaultFee)
      this.editedIndex = -1
      this.$validator.reset({scope: 'fee'})
    },

    openNewDialog() {
      this.previousPolicy = []
      this.editedItem = Object.assign({}, this.defaultFee)
      this.dialog = true
    },

    onEditItemClicked(item, index) {
      this.editedIndex = index
      this.editedItem = {...item}
      this.previousValue = Object.assign({}, item)
      this.dialog = true
    },

    onDeleteItemClicked(item, index) {
      this.resetState()
      this.$toast.question('Are you sure you want to delete this loan fee?', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [['<button><b>YES</b></button>', async (instance, toast) => {
            try {
            
              await loanFeeService.delete(this.items[index].ID)
              this.items.splice(index, 1)
              this.$toast.warning('fee has been removed!', '', {position: 'topCenter'})
            } catch(e) {
              console.log('deleting fee: ',e)
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
              throw e
            }
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
     
      
    },

    async fetchData() {
      try {
        this.resetState()
        let search = `loan_fee_name=lk:${this.globalSearch}:or&loan_fee_code=lk:${this.globalSearch}:or`

        let response =  await loanFeeService.findAll(search, this.sortString, {skip: this.skip, limit: this.limit})
        this.items = response.data
        this.items.forEach((el)=>{
          el.policies.forEach((elx)=> {
            elx.percentage = elx.percentage * 100
          })
        })
        this.totalCount = response.total
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } 
    },

    async submit() {
      this.resetState()
      if(this.editedItem.policies.length == 0) {
        this.$toast.error('Loan fee must have at least one policy. Please generate a policy.', '', {position: 'topCenter'})
      }
      let isValid = await this.$validator.validateAll("fee")
      if(isValid && this.editedItem.policies.length > 0) {
        this.save()
      } else {
        this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
      }
    },

    async onGenerateRangeClicked() {
      this.rangeDialog = true
    },

    async save () {
      try {
        let exist = await this.doesRecordExist()
        if(exist) return

        this.isLoading = true

        let data = _.cloneDeep(this.editedItem)
        delete data['ID']
        delete data['date_created']
        delete data['date_updated']
      
        data.policies.forEach((el)=>{
          el.percentage = el.percentage / 100
        })
      
        if (this.editedIndex > -1) {
          await loanFeeService.update(this.editedItem.ID, data)
          this.items.splice(this.editedIndex, 1, this.editedItem)
          this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
        } else {
          let response = await loanFeeService.create(data)
          this.editedItem.ID = response.data
          this.editedItem.date_created = moment().utc().format('YYYY-MM-DD HH:mm:ss')
         
          this.items.push(this.editedItem)
          this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
        }
        console.log('savig', this.editedItem)
        this.previousValue = {...this.editedItem}
        this.close()
        
      } catch(e) {
        console.log('saving/updating: ',e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      } finally {
        this.isLoading = false
      }
    },

    async doesRecordExist() {
      let search = `loan_fee_name=eq:${this.editedItem.loan_fee_name}:or&loan_fee_code=eq:${this.editedItem.loan_fee_code}:or`
      let feeLists = await loanFeeService.findAll(search, '&sort=loan_fee_name:asc', {skip: 0, limit: 100})
      let msg = 'A record already exist with the following values: '
      let existProps = []

      let lists = feeLists.data

      if (this.editedIndex > -1) {
        lists = lists.filter((el)=> {
          return el.ID !== this.editedItem.ID
        })
      }
      lists.forEach((el) => {
        if(this.editedItem.loan_fee_name.toLowerCase() === el.loan_fee_name.toLowerCase()) {
          existProps.push('Fee Name: '+this.editedItem.loan_fee_name)
        }
        if(this.editedItem.loan_fee_code.toLowerCase() === el.loan_fee_code.toLowerCase()) {
          existProps.push('Code Name: '+this.editedItem.loan_fee_code)
        }
      })
      if(existProps.length > 0) {
       this.$toast.warning(msg+existProps.join(', '), '', {position: 'topCenter', timeout: 7000})
        return true
      } 

      return false      
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    },

    filterFees(fees) {
      var app = this
      return fees.filter(function(fee) {
        let search = app.feeSearch ? app.feeSearch.toLowerCase() : ''
        return fee.loan_fee_name.toLowerCase().indexOf(search) != -1
      })
    },

    async onGenerateRange() {
      let isValid = await this.$validator.validateAll("newRange")
      let start = Number(this.newRange.min)
      let rows = Number(this.newRange.rows)
      let interval = Number(this.newRange.interval)

      if(isValid) {
        let ranges = []
        let i = start
        while(ranges.length < rows) {
          ranges.push(i)
          i = i + interval
        }

        this.editedItem.policies = []
        ranges.forEach((i, idx) => {
          let policy = {min:'', max:'', interval: '', percentage: 0.00, flat: 0.00}
          policy.min = i
          if(idx == ranges.length - 1)
            policy.max = '-'
          else
            policy.max = ranges[idx+1]
          
          this.editedItem.policies.push(policy)
        })
        
        this.closeGenerateRangeDialog()
      } else {
        this.$toast.error('Please check to ensure that the value entered is valid.', '', {position: 'topCenter'})
      }
    },

    closeGenerateRangeDialog() {
      this.rangeDialog = false
      this.$validator.reset({scope: 'newRange'})
      this.newRange = Object.assign({}, {min:'', max:'', interval: '', percentage: '', flat: ''})
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
    },

    /** Inline Edit dialog methods */
    onEditDialogOpened(item) {
      this.$validator.reset({scope: 'policy'})
      this.selectedPolicy = item
      this.previousPolicy = Object.assign({}, item)
    },

    async onEditDialogClosed() {
      let isValid = await this.$validator.validateAll("policy")
      if(!isValid) {
        this.selectedPolicy.percentage = this.previousPolicy.percentage
        this.selectedPolicy.flat = this.previousPolicy.flat
      }
    },

  
   
  }
}
</script>

 
