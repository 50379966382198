<template>
    <div>
        <!-- EDIT DIALOG -->
        <v-layout flat color="white">

            <v-spacer></v-spacer>
            <v-dialog persistent v-model="dialog" max-width="650px">
                <v-toolbar dense color="primary" dark class="elevation-3">
                    <v-toolbar-title>Edit DSR Calculations Method</v-toolbar-title>

                    <v-spacer></v-spacer>

                </v-toolbar>
                <v-card>

                    <v-card-text>
                        <v-layout align-content-space-between justify-space-between row wrap>
                            <v-flex xs12>
                                <va-input v-model="editedItem.ratio_name" class="mr-2"
                                    v-validate="'required|min:3|max:50'" data-vv-scope="ratio"
                                    :error-messages="errors.collect('ratio.ratio_name')" placeholder=" "
                                    label="Ratio Name" data-vv-validate-on="blur" data-vv-name="ratio_name"
                                    data-vv-as="ratio name" show-clean />
                            </v-flex>
                            <v-flex xs12>
                                <va-input v-model="editedItem.formula" class="mr-2"
                                    v-validate="'required|min:3|max:50'" data-vv-scope="ratio"
                                    :error-messages="errors.collect('ratio.formula')" placeholder=" "
                                    label="Formula" data-vv-validate-on="blur" data-vv-name="formula"
                                    data-vv-as="formula" :disabled="true" show-clean />
                            </v-flex>
                            <v-flex v-if="isExpenseIncome" xs12>
                                <va-select v-model="editedItem.expense_definition" v-validate="'required'" class="ml-2"
                                    placeholder=" " label="Expense Definition" data-vv-scope="ratio"
                                    data-vv-as="expense definition"
                                    :error-messages="errors.collect('ratio.expense_definition')"
                                    :items="expenseDefinitions" :item-text="'text'" :item-value="'value'"
                                    data-vv-name="expense_definition" />
                            </v-flex>
                            <v-flex v-if="!isGrossIncome" xs12>
                                <va-select v-model="editedItem.net_income_definition" v-validate="isGrossIncome ? 'required' : ''" class="ml-2"
                                    placeholder=" " label="Net Income Definition" data-vv-scope="ratio"
                                    data-vv-as="net income definition"
                                    :error-messages="errors.collect('ratio.net_income_definition')"
                                    :items="netpayDefinitions" :item-text="'text'" :item-value="'value'"
                                    data-vv-name="net_income_definition" />
                            </v-flex>
                            <v-flex xs12>
                                <va-input v-model="editedItem.stopper_limit" class="mr-2" :item-text="'number'"
                                    v-validate="'required|min:1|max:3'" data-vv-scope="ratio"
                                    :error-messages="errors.collect('ratio.stopper_limit')" placeholder=" "
                                    label="Loan Stopper Limit" data-vv-validate-on="blur" data-vv-name="stopper_limit"
                                    data-vv-as="stopper limit" show-clean />
                            </v-flex>
                        </v-layout>
                    </v-card-text>

                    <v-card-actions class="ml-4 mr-4">
                        <v-spacer></v-spacer>
                        <v-btn flat @click="close">Cancel</v-btn>
                        <v-btn color="primary" @click="submit">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>

        <h2>DSR Calculation Methods</h2>
        <v-divider class="mb-4"></v-divider>
        <v-data-table :headers="headers" class="elevation-1" :items="this.items" :loading="isLoading" :search="search">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template slot="items" slot-scope="props">
                <td>
                    <v-checkbox :disabled="isLoading || props.item.is_active" @change="onActiveChanged(props.item)"
                        v-model="props.item.is_active" primary hide-details></v-checkbox>
                </td>
                <td>{{ props.item.ratio_name }}</td>
                <td>{{ props.item.ratio_category }}</td>
                <td>{{ props.item.formula }}</td>
                <td>{{ props.item.stopper_limit }}</td>
                <td>
                    <v-checkbox :disabled="isLoading" @change="onStopperChanged(props.item)"
                        v-model="props.item.stopper_active" primary hide-details></v-checkbox>
                </td>
                <td>
                    <v-checkbox
                     :disabled="isLoading || !props.item.is_active"
                      @change="onDefaultCheckboxClicked(props.item)"
                      v-model="props.item.is_credit_score"
                      primary hide-details
                    ></v-checkbox>
                </td>
                <td>
                    <v-icon title="edit" small class="mr-2" @click="onEditItemClicked(props.item)">
                        edit
                    </v-icon>
                </td>
            </template>
            <template slot="no-data">

            </template>

        </v-data-table>
    </div>
</template>

<style type="css" scoped>
    h1, h2, h3 {
  color: #505a6b;}

</style>

<script>
    import {
        mapMutations,
    } from 'vuex'
    import VaInput from '@/components/form-components/VaInput.vue'
    import VaSelect from '@/components/form-components/VaSelect.vue'
    import PaginationMixin from '@/mixins/PaginationMixin'
    import dsrService from '@/services/dsr-calculation-settings'

    export default {
        components: {
            VaInput,
            VaSelect
        },

        mixins: [PaginationMixin],

        created() {
            this.fetchData()
        },

        beforeDestroy() {
            this.$toast.destroy()
        },

        data() {
            return {
                dialog: false,
                isLoading: true,
                search: '',
                headers: [{
                        text: 'Active',
                        align: 'left',
                        sortable: false,
                        value: 'is_active'
                    },
                    {
                        text: 'Ratio Name',
                        align: 'left',
                        sortable: false,
                        value: 'ratio_name'
                    },
                    {
                        text: 'Ratio Category',
                        align: 'left',
                        sortable: false,
                        value: 'ratio_category'
                    },
                    {
                        text: 'Formula',
                        align: 'left',
                        sortable: false,
                        value: 'formula'
                    },
                    {
                        text: 'Stopper Limit',
                        align: 'right',
                        sortable: false,
                        value: 'stopper_limit'
                    },
                    {
                        text: 'Enable Loan Stopper',
                        align: 'left',
                        sortable: false,
                        value: 'stopper_active'
                    },
                    {
                        text: 'Use in Credit Risk Scoring',
                        align: 'left',
                        sortable: false,
                        value: 'is_credit_score'
                    },
                    {
                        text: 'Action',
                        value: '',
                        sortable: false
                    },
                ],
                items: [],
                netpayDefinitions: [{
                        value: 'np = gp - (sd + ns)',
                        text: 'Net Income = Gross Income - (Statutory Deductions + Non-Statutory Deductions)'
                    },
                    {
                        value: 'np = gp - sd',
                        text: 'Net Income = Gross Income - Statutory Deductions'
                    },
                    {
                        value: 'np = gp',
                        text: 'Net Income = Gross Income'
                    }
                ],
                expenseDefinitions: [{
                        value: 'e = (ns + de)',
                        text: 'Expenses  = (Non-Statutory Deductions + Domestic Expenses)'
                    },
                    {
                        value: 'e = de',
                        text: 'Expenses = Domestic Expenses'
                    },
                    {
                        value: 'e = (sd + ns + de)',
                        text: 'Expenses  = (Statutory Deductions + Non-Statutory Deductions + Domestic Expenses)'
                    },
                    {
                        value: 'e = (sd + de)',
                        text: 'Expenses  = (Statutory Deductions + Domestic Expenses)'
                    }
                ],
                editedItem: {},
                provinces: [],
                message: {
                    visible: false,
                    title: '',
                    content: '',
                    type: 'success'
                },
            }
        },

        computed: {
            isExpenseIncome() {
              return this.editedItem.formula && this.editedItem.formula.includes('Expense') 
            },

            isGrossIncome() {
              return this.editedItem.formula && this.editedItem.formula.includes('Gross') 
            },

            filteredList() {
                return this.items.filter(f => {
                    return ''//f.ratio_name.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        },

        methods: {
            ...mapMutations([
                'SHOW_UNEXPECTED_ERROR_DIALOG'
            ]),

            close() {
                this.$validator.reset({
                    scope: 'ratio'
                })
                this.dialog = false
                this.editedItem = Object.assign({}, )
                this.fetchData()
            },

            onEditItemClicked(item) {
                item.expense_definition = item.expense_definition || 'e = de';
                item.net_income_definition = item.net_income_definition || 'np = gp - (sd + ns)';

                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            async fetchData() {
                try {
                    let res = await dsrService.findAll()
                    this.items = res.data
                } catch (e) {
                    console.log('fetching data: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                } finally {
                    this.isLoading = false
                }
            },

            async submit() {
                this.resetState()
                let isValid = await this.$validator.validateAll("ratio")
                if (!isValid) {
                    this.$toast.error('Please check to ensure that all fields are valid.', '', {
                        position: 'topCenter'
                    })
                    return
                }

                await this.save()
            },

            async save() {
                try {
                  if(this.editedItem.formula.includes("Gross")) {
                    this.editedItem.net_income_definition = "N/A"
                  }

                  if(!this.editedItem.formula.includes("Expense")) {
                    this.editedItem.expense_definition = "N/A"
                  }

                  await dsrService.update(this.editedItem.ID, this.editedItem)
                  this.$toast.success('Successfully updated record!', '', {
                      position: 'topCenter'
                  })
                  this.close()
                } catch (e) {
                    console.log('updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },

            resetState() {
                this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
            },

            async onActiveChanged(item) {
                let updatedIndex = -1;
                try {
                    this.$toast.destroy();
                    
                    //ensure only one of each category is active
                    for(const el of this.items) {
                      if(el.ID !== item.ID && el.is_active && item.is_active && el.ratio_category == item.ratio_category) {
                        el.is_active = false;

                        if(el.is_credit_score) {
                          el.is_credit_score = false;
                          item.is_credit_score = true;
                          this.onDefaultCheckboxClicked(el, true);
                          this.onDefaultCheckboxClicked(item, true);
                        }

                        updatedIndex++;
                        await dsrService.changeIsActive(el.ID, el.is_active)
                      }
                    }

                    await dsrService.changeIsActive(item.ID, item.is_active)

                    this.$toast.success('Successfully updated record!', '', {
                        position: 'topCenter'
                    })
                } catch (e) {
                    if(updatedIndex > -1) {
                      this.items[updatedIndex] = !this.items[updatedIndex].is_active;
                    }

                    item.is_active = !item.is_active
                    console.log('updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },

            async onStopperChanged(item) {
                try {
                    this.$toast.destroy()

                    await dsrService.changeStopperActive(item.ID, item.stopper_active)

                    this.$toast.success('Successfully updated record!', '', {
                        position: 'topCenter'
                    })
                } catch (e) {
                    item.stopper_active = !item.stopper_active
                    console.log('updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },

            onDefaultCheckboxClicked(item, hideMessage) {
                this.items.forEach((el, i)=>{
                    // el.is_credit_score = false
                    // this.items.splice(i,1, el)
                      if (el.ID === item.ID) {
                        el.is_credit_score = item.is_credit_score;
                        this.items.splice(i, 1, el);
                    }
                })
                // item.is_credit_score = true
                this.onCreditScoreChanged(item, hideMessage)
            },

            async onCreditScoreChanged(item, hideMessage) {
                try {
                    this.$toast.destroy();
                    await dsrService.changeCreditScoreStatus(item.ID, item.is_credit_score)
                    
                    if(!hideMessage) {
                      this.$toast.success('Successfully updated record!', '', {
                          position: 'topCenter'
                      })
                    }

                } catch (e) {
                    item.is_credit_score = !item.is_credit_score
                    console.log('updating: ', e)
                    this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
                }
            },
        }
    }
</script>
