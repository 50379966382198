<template>
<div>
  <v-btn color="primary" @click="print()">Print Report</v-btn>
  <div id="Print">
    <v-layout row wrap>
      <v-flex xs12 class="font-weight-bold body-2 text-xs-center pa-2">{{user.first_name | capitalize}} {{user.last_name | capitalize}}'s Loan-Pro Activity Report</v-flex>
      <v-flex xs12 class="font-weight-bold body-2 text-xs-center pa-2">Branch: {{user.branch.branch_name | capitalize}} - Role: {{user.role.role_name | capitalize}}</v-flex>
    </v-layout>
    <table class="table table-bordered" v-if="doc_data.length != 0">
      <thead class="font-weight-bold caption">
        <tr  class="m-0">
          <th>Date and Time</th>
          <th>Action</th>
          <th>Component</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody class="caption" >
        <tr v-for="(a, i) in doc_data" :key="i" class="m-0">
          <td>{{setDate(a.date)}}</td>
          <td>{{a.action}}</td>
          <td>{{a.label.replace(/([a-zA-Z])([A-Z])([a-z])/g, '$1 $2$3')}}</td>
          <td>
            <div v-if="a.description" v-html="keysAndValues(a.description)"></div>
          </td>
        </tr>
      </tbody>
    </table>

    <v-layout row wrap v-if="doc_data.length == 0">
      <v-flex xs12 md12 style="padding:80px;">
        <v-flex xs12 md12 class="text-xs-center">
          <v-icon color="primary" size="70">assessment</v-icon>
        </v-flex>
        <v-flex xs12 pt-2 pb-4>
          <h2 class="text-xs-center">No Data Available</h2>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</div>
</template>

<script>
import statMixin from "./stats.mixin";
import statService from "../../../services/user_stat.service";
import storageService from "@/services/storage-service";
export default {
  data() {
    return {
      doc_data: [],
      hide: false,
      expand: false,
      user : {}
    };
  },
  methods: {
    setDate(date){
      return this.$moment(date).format("YYYY-MM-DD hh:mm:ss A")
    },
    print() {
       
      const options = {
        name: '_blank',
        specs: [
          'fullscreen=yes',
          'titlebar=yes',
          'scrollbars=yes'
        ],
        styles: [
          'https://cdn.jsdelivr.net/npm/vuetify@1.5/dist/vuetify.min.css',
          'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
          'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900|Material+Icons',
        ]
      }
      this.$htmlToPaper("Print", options, data => {
        console.log("Printing completed or was cancelled!");
        this.hide = false;
      });
    },
     checkValue(change){
      if(change == undefined || change == null){
        return false
      }else{
        return true
      }
    }
  },
  mixins:[statMixin],
  name:"LoanProStatistics",
  async beforeMount() {
    this.user = storageService.getItem("user");
    let date = this.$moment(date).format("YYYY-MM-DD");
    let result = await statService.getUserActivity(this.user.ID, 'LoanPro', 0, 100, date);
    let data = result.data;
    this.doc_data = data;
  },
};
</script>

<style scoped src="../../../assets/css/bootstrap.css"></style>
<style scoped>
.borderless th {
    border: none !important;
}
</style>