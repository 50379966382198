<template>
  <v-container>
    <div>
      <v-dialog v-model="dialog" persistent>
        <v-card flat>
          <v-card-title class="headline white--text primary"
            >Add Form Data
            <v-spacer></v-spacer>
            <v-btn dark color="primary" depressed @click="dialog = false"
              >Exit</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-layout row wrap mx-1>
                <v-flex pr-2 xs4>
                  <va-input
                    label="NAME"
                    placeholder="Enter Name"
                    v-model="formData.name"
                  ></va-input>
                </v-flex>

                <v-flex xs4>
                  <va-input
                    label="CU NUMBER"
                    placeholder="Enter CU Number"
                    v-model="formData.cu_number_1"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="CONTACT GROUP"
                    placeholder="Enter Contact Group"
                    v-model="formData.contact_group_1"
                  ></va-input>
                </v-flex>

                <v-flex pr-2 xs4>
                  <va-input
                    label="CO-MAKER"
                    placeholder="Enter Co-maker"
                    v-model="formData.comaker"
                  ></va-input>
                </v-flex>

                <v-flex xs4>
                  <va-input
                    label="CU NUMBER"
                    placeholder="Enter CU Number"
                    v-model="formData.cu_number_2"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="CONTACT GROUP"
                    placeholder="Enter Contact Group"
                    v-model="formData.contact_group_2"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs3>
                  <va-input
                    label="NOTE NUMBER"
                    placeholder="Enter Note Number"
                    v-model="formData.note_number"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="NEW"
                    placeholder="Enter New"
                    v-model="formData.new"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="REFINANCED"
                    placeholder="Enter Refinanced"
                    v-model="formData.refinanced"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="RESCHEDULED"
                    placeholder="Enter Rescheduled"
                    v-model="formData.rescheduled"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs3>
                  <va-input
                    label="DATE DISBURSED"
                    placeholder="Enter Date Disbursed"
                    v-model="formData.date_disbursed"
                  ></va-input>
                </v-flex>
                <v-flex xs12 class="pa-3">
                  <v-divider color="black"></v-divider
                ></v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="ANNUAL PERCENTAGE RATE"
                    placeholder="Enter Annual Percentage Rate"
                    v-model="formData.annual_rate"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs4>
                  <va-input
                    label="MONTHLY PERCENTAGE RATE"
                    placeholder="Enter Monthly Precentage Rate"
                    v-model="formData.monthly_rate"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="LOAN FEES"
                    placeholder="Enter Loan Fees"
                    v-model="formData.loan_fees"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="EXISTING LOAN BALANCE"
                    placeholder="Enter Existing Loan Balance"
                    v-model="formData.existing_balance"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="NEW LOAN AMOUNT"
                    placeholder="Enter New Loan Amount"
                    v-model="formData.new_balance"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="TOTAL LOAN"
                    placeholder="Enter Total Loan Amount"
                    v-model="formData.total_balance"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs3>
                  <va-input
                    label="NO. OF PAYMENTS"
                    placeholder="Enter Number of Payments"
                    v-model="formData.num_payments"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="LOAN INSTALLMENTS"
                    placeholder="Enter Loan Installments"
                    v-model="formData.loan_installments"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="FIRST PAYMENT DUE"
                    placeholder="Enter First Payment Due"
                    v-model="formData.first_payment_due"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs2>
                  <va-input
                    label="PAYMENT FREQUENCY"
                    placeholder="Enter Payment Frequency"
                    v-model="formData.payment_frequency"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs3>
                  <va-input
                    label="FINAL PAYMENT DUE ON"
                    placeholder="Enter Final Payment Due On"
                    v-model="formData.final_payment_due"
                  ></va-input>
                </v-flex>

                <v-flex xs12 class="pa-3">
                  <v-divider color="black"></v-divider
                ></v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="SHARES"
                    placeholder="Enter Shares"
                    v-model="formData.shares"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="RETIREMENT ACCOUNT "
                    placeholder="Enter Retirement Account"
                    v-model="formData.retirement_account"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    v-money="money"
                    label="FIXED DEPOSIT $/#"
                    placeholder="Enter Fixed Deposit"
                    v-model="formData.fixed_deposit"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="IOC"
                    placeholder="Enter IOC"
                    v-model="formData.ioc"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="ENGINE #"
                    placeholder="Enter Engine #"
                    v-model="formData.engine_number"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="CHASIS #"
                    placeholder="Enter Chasis #"
                    v-model="formData.chasis_number"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="REGISTRATION #"
                    placeholder="Enter Registration $"
                    v-model="formData.registration_number"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="COLOR"
                    placeholder="Enter Color"
                    v-model="formData.color"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs4>
                  <va-input
                    label="MAKE/MODEL"
                    placeholder="Enter Make/Model"
                    v-model="formData.make"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs12>
                  <va-input
                    label="MBOS ON PROPERTY AT"
                    placeholder="Enter Mbos on Property At"
                    v-model="formData.mbos"
                  ></va-input>
                </v-flex>

                <v-flex pl-2 xs6>
                  <va-input
                    label="TERMINATION BENEFITS"
                    placeholder="Enter Termination Benefits"
                    v-model="formData.termination_benefits"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs6>
                  <va-input
                    label="INSURANCE POLICY $/DETAILS"
                    placeholder="Enter Insurance Policy Details"
                    v-model="formData.insurance_policy_details"
                  ></va-input>
                </v-flex>
                <v-flex pl-2 xs12>
                  <va-input
                    label="OTHER"
                    placeholder="Enter Other"
                    v-model="formData.other"
                  ></va-input>
                </v-flex>
              </v-layout>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="formData = {}"
              >Clear</v-btn
            >
            <v-btn color="blue darken-1" flat @click="dialog = false"
              >Add</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn @click="dialog = true" color="blue darken-2" dark bottom right>
        Add Form Data
      </v-btn>
    </div>
    <div id="body">
      <v-card elevation="5" raised>
        <v-card-title class="justify-center pt-4" style="font-size: 16px"
          >COMMUNITY CARE CREDIT UNION CO-OPERATIVE SOCIETY
          LIMITED</v-card-title
        >
        <h4 class="text-xs-center bold pb-5" style="font-size: 14px">
          LOAN DISCLOSURE STATEMENT AND ADD ON LOAN AGREEMENT
        </h4>
        <div class="grid-container">
          <div class="grid-item">
            NAME <span class="line text-xs-center">{{ formData.name }}</span>
          </div>
          <div class="grid-item">
            CU NUMBER
            <span class="line text-xs-center">{{ formData.cu_number_1 }}</span>
          </div>
          <div class="grid-item">
            CONTACT GROUP
            <span class="line text-xs-center">{{
              formData.contact_group_1
            }}</span>
          </div>
          <div class="grid-item">
            CO-MAKER
            <span class="shortline text-xs-center">{{ formData.comaker }}</span>
          </div>
          <div class="grid-item">
            CU NUMBER
            <span class="line text-xs-center">{{ formData.cu_number_2 }}</span>
          </div>
          <div class="grid-item">
            CONTACT GROUP
            <span class="line text-xs-center">{{
              formData.contact_group_2
            }}</span>
          </div>
        </div>
        <div class="grid-container-2">
          <div class="grid-item">
            NOTE NUMBER
            <span class="text-xs-center shorterline">{{
              formData.note_number
            }}</span>
          </div>
          <div class="grid-item text-xs-center">
            NEW <span class="shorterline">{{ formData.new }}</span>
          </div>
          <div class="grid-item text-xs-center">
            REFINANCED
            <span class="shorterline">{{ formData.refinanced }}</span>
          </div>
          <div class="grid-item text-xs-center">
            RESCHEDULED
            <span class="shorterline">{{ formData.rescheduled }}</span>
          </div>
          <div class="grid-item text-xs-center">
            DATE DISBURSED
            <span class="shorterline">{{ formData.date_disbursed }}</span>
          </div>
        </div>
        <v-layout row wrap class="px-3 pb-3 pt-1">
          <table style="width: 100%">
            <tr>
              <th colspan="5">
                <div class="text-xs-center" style="font-size: 12px">
                  LOAN AGREEMENT
                </div>
                By signing this Agreement, I promise to pay Community Care
                Credit Union all sums borrowed under this Note, plus INTEREST,
                in accordance with the terms detailed below.
              </th>
            </tr>
            <tr>
              <th class="text-xs-center" style="font-size: 12px" colspan="2">
                INTEREST RATE
              </th>
              <th class="text-xs-center" style="font-size: 12px">FEES</th>
              <th class="text-xs-center" style="font-size: 12px" colspan="2">
                LOAN DETAILS
              </th>
            </tr>
            <tr>
              <td colspan="2">
                <div>
                  <span class="shorterline text-xs-center">{{
                    formData.annual_rate
                  }}</span
                  >% annual percentage rate
                </div>
                OR
                <div>
                  <span class="shorterline text-xs-center">{{
                    formData.monthly_rate
                  }}</span
                  >% per month
                </div>
              </td>
              <td>
                Loan Fee
                <div>
                  $<span class="shorterline text-xs-center">{{
                    formData.loan_fees
                  }}</span>
                </div>
              </td>
              <td colspan="2">
                <v-flex style="font-size: 12px"
                  >EXISTING LOAN BALANCE $<span
                    class="shorterline text-xs-center"
                    >{{ formData.existing_balance }}</span
                  ></v-flex
                >
                <v-flex style="font-size: 12px"
                  >NEW LOAN AMOUNT $<span class="shorterline text-xs-center">{{
                    formData.new_balance
                  }}</span></v-flex
                >
                <v-flex style="font-size: 12px"
                  >TOTAL LOAN $<span class="shorterline text-xs-center">{{
                    formData.total_balance
                  }}</span></v-flex
                >
              </td>
            </tr>
            <tr>
              <th class="text-xs-center" style="font-size: 12px">
                NO. OF <br />
                PAYMENTS
              </th>
              <th class="text-xs-center" style="font-size: 12px">
                LOAN <br />
                INSTALLMENT
              </th>
              <th class="text-xs-center" style="font-size: 12px">
                FIRST PAYMENT <br />
                DUE
              </th>
              <th class="text-xs-center" style="font-size: 12px">
                PAYMENT FREQUENCY <br />
                WKLY [ ] <span class="pl-3"></span> BIMTHLY [ ]
                <span class="pl-3"></span> MTE [ ]
              </th>
              <th class="text-xs-center" style="font-size: 12px">
                FINAL PAYMENT DUE ON
              </th>
            </tr>
            <tr>
              <td class="text-xs-center">
                <span class="text-xs-center">{{ formData.num_payments }}</span>
              </td>
              <td class="text-xs-center">
                <span class="text-xs-center">{{
                  formData.loan_installments
                }}</span>
              </td>
              <td class="text-xs-center">
                <span class="text-xs-center">{{
                  formData.first_payment_due
                }}</span>
              </td>
              <td class="text-xs-center">
                <span class="text-xs-center">{{
                  formData.payment_frequency
                }}</span>
              </td>
              <td class="text-xs-center">
                <span class="text-xs-center">{{
                  formData.final_payment_due
                }}</span>
              </td>
            </tr>
          </table>
          <div class="py-4">
            <p class="bold" style="font-size: 12px">COLLATERAL</p>
            <v-layout row wrap style="font-size: 12px">
              <v-flex sm12 xs12 pa-1 md4
                >SHARES $:<span class="shortline text-xs-center">{{
                  formData.shares
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >RETIREMENT ACCOUNT $:<span class="shortline text-xs-center">{{
                  formData.retirement_account
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >FIXED DEPOSIT $/#:<span class="shortline text-xs-center">{{
                  formData.fixed_deposit
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >IOC:<span class="shortline text-xs-center">{{
                  formData.ioc
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >ENGINE #:<span class="shortline text-xs-center">{{
                  formData.engine_number
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >CHASIS #:<span class="shortline text-xs-center">{{
                  formData.chasis_number
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >REGISTRATION #:<span class="shortline text-xs-center">{{
                  formData.registration_number
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >COLOR:<span class="shortline text-xs-center">{{
                  formData.color
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md4
                >MAKE/MODEL:<span class="shortline text-xs-center">{{
                  formData.make
                }}</span></v-flex
              >
              <v-flex sm12 xs12 pa-1 md12
                >MBOS ON PROPERTY AT:<span class="shortline text-xs-center">{{
                  formData.mbos
                }}</span>
              </v-flex>
              <v-flex sm12 xs12 pa-1 md6
                >TERMINATION BENEFITS:<span class="shortline text-xs-center">{{
                  formData.termination_benefits
                }}</span>
              </v-flex>
              <v-flex sm12 xs12 pa-1 md6
                >INSURANCE POLICY $/DETAILS:<span
                  class="shortline text-xs-center"
                  >{{ formData.insurance_policy_details }}</span
                ></v-flex
              >
              <v-flex sm12 xs12 pa-1 md12
                >OTHER:
                <span class="line text-xs-center">{{
                  formData.other
                }}</span></v-flex
              >
            </v-layout>
          </div>
          <div>
            <p>
              <span class="bold">Late Fees</span><br />
              I agree to pay a late charge of $25.00, if any loan payment is
              received forty (40) days past the due date, and a similar fee for
              every thirty (30) days late thereafter.
            </p>
            <p>
              <span class="bold">Prepayment</span><br />
              The Note may be prepaid in full or in part at any time without
              penalty. All prepayments shall first be applied to outstanding
              late fees, then to accrued interest and thereafter to the
              principal loan amount
            </p>
            <p>
              <span class="bold">Pledged Shares</span><br />
              <span class="bold"
                >I understand that I may not make a Share/Savings Withdrawal
                while I have a loan balance in excess of my share/savings
                balance.</span
              >
              In signing this agreement, I agree that all collateral I have
              pledged to the Credit Union to secure this and other loan
              obligations, in the past and in the future, will secure my
              obligations under this Agreement; up to the value of the
              outstanding loan as security towards payment of the principal sum,
              interest and expenses which may be due or become due.
            </p>
            <p>
              <span class="bold">Insurance</span><br />
              I understand that I must maintain and furnish proof of insurance
              on the collateral or real estate securing this loan. The insurance
              will contain loss payable clause and endorsement clause naming
              Community Care Credit Union as the Mortgagors. This policy must
              cover all risk and remain in force for the entire term of the
              loan. Proof of insurance coverage must be presented to the Credit
              Union on demand during the term of the loan. I understand that
              failure to maintain the agreed insurance coverage and failure to
              produce proof of insurance coverage shall constitute a breach of
              this Agreement, and the holder may enforce the terms of agreement
              for Default as contained herein,
            </p>
            <p>
              <span class="bold">Non-Payment/Default </span><br />
              I agree that should I fail to make all repayments due under this
              agreement the total amount outstanding will immediately become
              repayable and will be repaid in full, plus any accrued interest,
              from my credit union savings accounts. Upon default of payment of
              any installment of this note, or in the event the money borrowed
              on this note is not used for the purpose set forth in the
              application, or in case of any misrepresentation or misstatement
              made by the borrower or co-makers in obtaining this loan, or in
              case the holder shall deem the security breached thereof for any
              reason whatsoever, then this note, or so much hereof as may
              remain, shall at the option of the holder immediately become due
              and payable. In the event of default, the maker or makers hereof
              pledge any shares/deposit now or hereafter held by them in the
              Credit Union, as additional security for the payment of this
              obligation, and I hereby authorize the General Manager to apply
              any or all such shares, deposits or repayment on shares or
              deposits, to the payment of this loan, interest, costs or
              expenses.
            </p>
            <p>
              <span class="bold">Collection Costs</span><br />
              Also, if the holder thereof after default, shall place this note
              in the hands of its solicitors (or licensed collection agency) for
              collection, the undersigned agrees to pay an additional sum as a
              fee for collection equal to twenty-five per cent of the entire
              obligation which remains due and unpaid after there has been
              credited to the maker the amount of his or her share/deposit
              account in the Credit Union, and after there has also been applied
              in reduction such sums if any as may be realized from the sale of
              any other security in the possession of the Credit Union, which is
              being held as collateral against the loan; such charge for
              collection in no event to be less than five dollars.
            </p>
            <p>
              In addition: - I agree to be bound by all the terms and conditions
              applicable in the current Community Care Credit Union Co-operative
              Society Limited Loan Policy. I acknowledge that I have received a
              copy of this Agreement and that I have fully read and understand
              all its terms and conditions.
              <span class="bold" style="font-size: 12px"
                >PLEASE READ BEFORE SIGNING.</span
              >
            </p>
          </div>
        </v-layout>
        <v-layout class="px-3" row wrap>
          <v-flex xs12 sm12 md6>
            <span class="longline"></span>
            <p class="font-weight-bold mb-4" style="font-size: 12px">
              SIGNATURE:<span style="padding-left: 150px">DATE:</span>
            </p>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <span class="longline"></span>
            <p class="font-weight-bold" style="font-size: 12px">
              WITNESS:<span style="padding-left: 100px">DATE:</span>
            </p>
          </v-flex>
        </v-layout>

        <v-layout class="px-3" row wrap>
          <v-flex xs12 sm12 md6>
            <span class="longline"></span>
            <p class="font-weight-bold mb-4" style="font-size: 12px">
              CO-MAKER SIGNATURE:
              <span style="padding-left: 90px">DATE:</span>
            </p>
          </v-flex>
          <v-flex xs12 sm12 md6>
            <span class="longline"></span>
            <p class="font-weight-bold" style="font-size: 12px">
              WITNESS:
              <span style="padding-left: 100px">DATE:</span>
            </p>
          </v-flex>
        </v-layout>
        <div class="px-3 pb-3">
          <v-divider color="black"></v-divider>
        </div>
        <p class="px-3 pb-4 text-xs-right">02/2017</p>
      </v-card>
    </div>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    formData: {},
    money: {
      decimal: ".",
      thousands: ",",
      prefix: "",
      precision: 2,
      masked: false,
    },
    dialog: false,
  }),
};
</script>
<style scoped>
#body {
  font-family: Calibri, sans-serif;
}
.grid-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto;
  padding: 5px;
}

.grid-container-2 {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto auto auto;
  padding: 5px;
}

.grid-item {
  padding: 5px;
  font-size: 12px;
}
.line {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  max-width: 150px;
  font-weight: bolder;
}
.longline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 100%;
  max-width: 300px;
  font-weight: bolder;
}
.shortline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  max-width: 100px;
  font-weight: bolder;
}
.shorterline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  max-width: 100px;
  font-weight: bolder;
}
.bold {
  font-weight: bold;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>
