<template>
  <div id="apps">
    <v-container fluid>
      <v-layout justify-start align-start>
        <h1 style="color: #172b4d">Release Notes</h1>
      </v-layout>
      <v-layout justify-end align-end class="mb-2">
        <!-- <input type="search" placeholder="Search"> -->
      </v-layout>
    </v-container>
    <v-container grid-list-lg class="mt-0 pt-0" fluid>
      <v-layout justify-center row wrap>
        <v-flex
          v-for="app in apps"
          :key="app.app_name"
          lg2
          sm4
          xs6
          class="mt-1"
        >
          <v-card color="#fff" href="#">
            <v-layout class="pl-1 pr-1">
              <!-- <v-btn small icon>
               <v-icon small>more_vert</v-icon>
              </v-btn> -->
              <v-spacer></v-spacer>
            </v-layout>

            <v-layout justify-center align-center>
              <v-flex xs5>
                <v-img
                  :src="app.app_icon"
                  aspect-ratio="1"
                  class="grey lighten-2 img-circle"
                >
                  <v-layout slot="placeholder" ma-0>
                    <v-progress-circular
                      indeterminate
                      :size="20"
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-layout>
                  <v-expand-transition> </v-expand-transition>
                </v-img>
              </v-flex>
            </v-layout>
            <v-card-text style="text-align: center; margin-top: -5px">
              <div
                style="
                  color: #505a6b;
                  font-size: 14px;
                  word-wrap: wrap;
                  font-weight: bold;
                "
              >
                {{ app.app_name }} <br />v.{{ app.app_version }}
              </div>
              <v-badge color="error" right overlap>
                <template
                  v-slot:badge
                  v-if="
                    releaseNotesState.software_list.length > 0 &&
                    releaseNotesState.software_list.includes(app.app_name) && 
                    releaseNotesState.view_software_updates == false"
                >
                  <span>!</span>
                </template>
                <v-btn small color="primary" @click="open(app)"
                  >Release Notes</v-btn
                >
              </v-badge>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
      <v-dialog
        v-model="viewHistory"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="close">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ app_name }} Release Notes</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items
              v-show="display"
            >
              <v-btn dark flat @click="openNotes()">Add</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <h2 class="pa-4">Version History</h2>
          <v-list v-if="items.length != 0">
            <v-list-tile v-for="item in items" :key="item.release_notes.ID">
              <v-icon
                v-if="item.release_notes.latest_version == true"
                small
                color="yellow darken-2"
                title="Latest Version"
                style="cursor: pointer"
                >star</v-icon
              >
              <v-icon v-else small style="cursor: pointer">star</v-icon>
              <v-list-tile-content
                @click="viewNotes(item)"
                title="View Release Notes Details"
              >
                <div
                  style="
                    background: white;
                    position: relative;
                    width: 80px;
                    z-index: 10;
                    cursor: pointer;
                  "
                >
                  {{ item.release_notes.version }}
                </div>
                <hr />
              </v-list-tile-content>
              <v-list-tile @click="viewNotes(item)">
                {{ item.release_notes.date_created }}
              </v-list-tile>
              <v-btn
                v-show="display"
                icon
                @click="editVersion(item)"
                title="Edit Release Notes Version"
                ><v-icon color="success">edit</v-icon></v-btn
              >
              <v-btn
                icon
                @click="deleteVersion(item)"
                title="Delete Release Notes Version"
                ><v-icon color="error">delete</v-icon></v-btn
              >
            </v-list-tile>
          </v-list>
          <div
            v-else
            class="text-xs-center"
            style="
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            "
          >
            <v-icon color="error" large>error</v-icon>
            <h3>Release Notes Not Available!</h3>
          </div>
        </v-card>
      </v-dialog>
      <!-- Add release notes version dialog -->
      <v-dialog v-model="addNotes" persistent max-width="360">
        <v-toolbar dense color="primary" dark tabs>
          <v-toolbar-title>{{formTitle}} Release Notes</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card>
          <v-card-text class="mb-4">
            <v-text-field
              v-model="version"
              label="Release Version"
              required
            ></v-text-field>
            <v-checkbox v-model="latest_version" label="Latest Version">
            </v-checkbox>
          </v-card-text>

          <v-card-actions style="margin-top: -30px" class="ml-4 mr-4">
            <v-spacer></v-spacer>
            <v-btn
              flat
              @click="
                (addNotes = false), (latest_version = false), (version = '')
              "
              >Cancel</v-btn
            >
            <v-btn @click.stop="saveVersion" color="primary">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- View release notes dialog -->
      <v-dialog
        v-model="viewRelease"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card flat>
          <v-toolbar dark color="primary">
            <v-btn icon dark @click="(viewRelease = false), (notes_data = {})">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title
              >{{ app_name }} Release Notes -
              {{ release_version }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items
              v-show="display"
            >
              <v-btn dark flat @click="editNotes(notes_data)">Save</v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <div id="editor" v-if="display">
            <textarea :value="input" @input="update"></textarea>
            <div v-html="compiledMarkdown"></div>
          </div>
          <div id="editor2" v-else>
            <div v-html="compiledMarkdown"></div>
          </div>
        </v-card>
      </v-dialog>
      <!-- Loader dialog -->
      <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Loading....
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<style scoped>
.img-circle {
  border-radius: 50%;
}

input {
  outline: none;
}
input[type="search"] {
  -webkit-appearance: textfield;
  font-family: inherit;
  font-size: 100%;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button {
  display: none;
}

input[type="search"] {
  background: #eee url(../../assets/img/outline-search.svg) no-repeat 9px center;
  border: solid 1px #ccc;
  padding: 9px 10px 9px 32px;
  width: 55px;

  -webkit-border-radius: 10em;
  -moz-border-radius: 10em;
  border-radius: 10em;

  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
input[type="search"]:focus {
  width: 200px;
  background-color: #fff;
  border-color: #ccc;
}

input:-moz-placeholder {
  color: #999;
}
input::-webkit-input-placeholder {
  color: #999;
}
hr {
  bottom: 5px;
  position: relative;
  z-index: 1;
  width: 100%;
}
#editor {
  margin: 0;
  height: 1000px;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
}
#editor2 {
  margin: 0;
  margin-top: 100px;
  height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #333;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

textarea,
#editor div {
  display: inline-block;
  width: 49%;
  height: 100%;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 20px;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: "Monaco", courier, monospace;
  padding: 20px;
}
</style>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import moment from "moment";
import companyService from "@/services/company-service";
import releaseNotesService from "@/services/release-notes-service";
import storageService from "@/services/storage-service";
import marked from "marked";
import DOMpurify from "dompurify";
import _ from "lodash";

export default {
  components: {},

  data() {
    return {
      user: null,
      addNotes: false,
      viewHistory: false,
      viewRelease: false,
      latest_version: false,
      ID: "",
      version: "",
      old_version: "",
      app_name: "",
      release_version: "",
      apps: [],
      notes: {},
      notes_data: {},
      url: "",
      fileName: "",
      fileObject: null,
      cardResult: "",
      name: "",
      size: "",
      type: "",
      lastModifiedDate: "",
      loaderDialog: false,
      items: [],
      input: "## This version has not been updated yet!",
      display: false
    };
  },

  computed: {
    ...mapState(["releaseNotesState"]),
    ...mapGetters(["getAuthUser"]),

    formTitle () {
      return this.ID == "" ? 'Add' : 'Edit'
    },
    compiledMarkdown: function () {
      return marked(this.input, {
        highlight: function (code, language) {
          const hljs = require("highlight.js");
          const validLanguage = hljs.getLanguage(language)
            ? language
            : "plaintext";
          return hljs.highlight(validLanguage, code).value;
        },
        pedantic: false,
        gfm: true,
        breaks: false,
        sanitize: false,
        smartLists: true,
        smartypants: false,
        xhtml: false,
      });
    },
  },

  created() {
    this.user = storageService.getItem("user");
    this.initialize();
    this.TOGGLE_SIDEBAR_VISIBILITY(false);
    this.SET_SIDEBAR_ROUTES([]);
  },

  methods: {
    ...mapMutations([
      "TOGGLE_SIDEBAR_VISIBILITY",
      "SHOW_UNEXPECTED_ERROR_DIALOG",
      "SET_SIDEBAR_ROUTES",
    ]),
    ...mapActions(["updateSoftwareList"]),


    update: _.debounce(function (e) {
      this.input = e.target.value;
    }, 300),

    async saveVersion() {
      let notes = {
        ID: this.ID,
        app_name: this.app_name,
        version: this.version,
        latest_version: this.latest_version,
        old_version: this.old_version,
        details: "",
      };

      if (this.version == "") {
        this.$toast.error("Please enter version!", "", {
          position: "topCenter",
        });
      } else {
        try {
          let result = {};
          if (this.ID != "") {
            result = await releaseNotesService.editVersion(notes);
          } else {
            result = await releaseNotesService.createVersion(notes);
          }

          if (result.status == 0) {
            this.$toast.success(result.message, "", {
              position: "topCenter",
            });
            this.addNotes = false;
          } else if (result.status == 1) {
            this.$toast.error(result.message, "", {
              position: "topCenter",
            });
          } else {
            this.$toast.warning(result.message, "", {
              position: "topCenter",
            });
          }
        } catch (e) {
          this.$toast.error("Error adding release notes version!", "", {
            position: "topCenter",
          });
        } finally {
          this.getNotes(this.app_name);
        }
      }
    },
    async editNotes(notes_data) {
      let notes = {
        ID: notes_data.release_notes.ID,
        details: DOMpurify.sanitize(this.input),
      };

      try {
        let result = await releaseNotesService.editNotes(notes);
        if (result.status == 0) {
          this.$toast.success(result.message, "", {
            position: "topCenter",
          });
        } else {
          this.$toast.error(result.message, "", {
            position: "topCenter",
          });
        }
      } catch (e) {
        this.$toast.error("Error adding release notes details!", "", {
          position: "topCenter",
        });
      } finally {
        this.getNotes(this.app_name);
      }
    },
    async editVersion(version) {
      this.ID = version.release_notes.ID;
      this.version = version.release_notes.version;
      this.old_version = version.release_notes.version;
      this.latest_version = version.release_notes.latest_version
        ? version.release_notes.latest_version
        : false;
      this.addNotes = true;
    },
    async deleteVersion(version) {
      this.$toast.question(
        "You are about to delete release notes version " +
          version.release_notes.version +
          ". Are you sure you want to perform this action?",
        "",
        {
          timeout: 20000,
          close: false,
          overlay: true,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              async (instance, toast) => {
                try {
                  let result = await releaseNotesService.deleteVersion(
                    version.release_notes.ID
                  );
                  if (result.status == 0) {
                    this.$toast.success(result.message, "", {
                      position: "topCenter",
                    });
                  } else {
                    this.$toast.error(result.message, "", {
                      position: "topCenter",
                    });
                  }
                } catch (e) {
                  this.$toast.error(
                    "Error deleting release notes details!",
                    "",
                    {
                      position: "topCenter",
                    }
                  );
                } finally {
                  this.getNotes(this.app_name);
                }
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
              true,
            ],
            [
              "<button>NO</button>",
              function (instance, toast) {
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
            ],
          ],
        }
      );
    },
    async getNotes(app_name) {
      try {
        this.loaderDialog = true;
        moment.locale("en");
        this.items = await releaseNotesService.findVersions({ app_name: app_name });
        this.items.forEach(
          (el) =>
            (el.release_notes.date_created = moment(
              el.release_notes.date_created
            ).fromNow())
        );
      } catch (e) {
        this.$toast.error("Error retrieving release notes!", "", {
            position: "topCenter",
          });
      } finally {
        this.loaderDialog = false;
      }
    },

    async initialize() {
    this.display = this.user.is_admin
      try {
        this.apps = [];
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let result = await companyService.fetchModules();
        let apps = await releaseNotesService.addApps();
        for (let key in result) {
          let found = false;
          this.user.app_access.forEach((app) => {
            if (
              app.app_name === result[key].app_name &&
              result[key].is_active
            ) {
              found = true;
            }
          });

          if (found || this.user.is_admin) {
            this.apps.push(result[key]);
          }
        }
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    open(app) {
      this.viewHistory = true;      
      this.getNotes(app.app_name);
      this.app_name = app.app_name;
    },

    async close() {
      this.updateSoftwareList(this.app_name);
      this.viewHistory = false;
      this.app_name = "";
      this.items = [];
    },

    openNotes() {
      this.addNotes = true;
      this.ID = "";      
      this.version = "";
      this.latest_version = false;
    },
    viewNotes(data) {
      this.viewRelease = true;
      this.notes_data = data;
      this.input = data.release_notes.details.length == 0 ? '## This version has not been updated yet!' : data.release_notes.details;
      this.release_version = data.release_notes.version;
    },
  },
};
</script>
