<template>
  <div id="login">
    <v-container fluid fill-height class="loginOverlay">
      <v-layout flex align-center justify-center>
        <v-flex class="mt-4" xs12 sm4>
          <v-card class="pa-3 elevation-1">
            <!-- <iframe src="http://localhost:8080" ref="loanproWindow" frameborder="0"></iframe> -->
            <v-card-text>
              <v-img aspect-ratio="1" slot="activator" :src="this.logo" id="logo"/>
              <h3 id="company-name">{{authState.company.company_short_name || ''}}</h3>
              <v-alert :value="msgObj.msg !== ''" :color="msgObj.type">{{msgObj.msg}}</v-alert>
            <v-alert :value="authState.errorMessage != ''" color="orange darken-3" style="text-align: center">
              <b>{{authState.errorMessage}}</b>
            </v-alert>
              <div>
                <v-form ref="form">
                  <v-text-field
                    focus
                    prepend-inner-icon="person_outline"
                    @keyup.enter.native="onLoginClicked()"
                    v-validate="'required|email'"
                    data-vv-scope="login"
                    data-vv-validate-on="blur"
                    v-model="user.email"
                    :error-messages="errors.collect('login.email')"
                    placeholder="Email"
                    type="text"
                    name="email"
                  />
                  <v-text-field
                    prepend-inner-icon="lock"
                    @keyup.enter.native="onLoginClicked()"
                    v-validate="'required'"
                    data-vv-scope="login"
                    placeholder="Password"
                    :error-messages="errors.collect('login.password')"
                    v-model="user.password"
                    type="password"
                    name="password"
                  />

                  <v-layout justify-center align-content-center row align-center>
                    <v-btn
                      @click.native="onLoginClicked()"
                      style="width:180px"
                      :loading="authState.isLoading"
                      :disabled="authState.isLoading"
                      class="mb-3 mt-4 blue darken-4 white--text"
                    >Login</v-btn>
                  </v-layout>

                  <v-layout justify-center align-content-center row align-center>
                    <v-dialog v-model="dialog" persistent max-width="460">
                      <a slot="activator" href="#">Forgot Password?</a>
                      <v-card>
                        <v-card-title class="headline">Request Password Change?</v-card-title>
                        <v-card-text style="margin-top:-15px">
                        <v-alert :value="reqErr !== ''" :type="'error'">{{this.reqErr}}</v-alert> 
                          <p
                            class="mb-3"
                          >Enter your email address. A link will be sent to your email account to reset your password.</p>
                          <v-text-field
                            label="Email Addresss"
                            v-validate="'required|email'"
                            data-vv-scope="request"
                            data-vv-name="email"
                            data-vv-validate-on="blur"
                            :error-messages="errors.collect('request.email')"
                            placeholder="email@example.com"
                            v-model="email"
                          />
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="red darken-1"
                            flat
                            @click="dialog = false; email='';"
                          >Cancel</v-btn>
                          <v-btn
                            color="blue darken-1"
                            :loading="loading"
                            :disabled="loading"
                            flat
                            @click="onRequestEmailChangedClicked()"
                          >Send</v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-layout>
                </v-form>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<style scoped>
.login-form {
  margin-top: 100px;
}
.v-card {
  min-width: 340px;
}

#company-name {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  word-wrap: break-word;
  color: #505a6b;
}
#va-input >>> .va-form-control {
  margin: 0px;
  padding: 0px;
}
#logo {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 1px solid #ddd;
}
</style>

<script>
import VaInput from "@/components/form-components/VaInput.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AuthService from "@/services/authentication-service"
export default {
  components: {
    VaInput
  },

  created() {
    this.initialize();
  },

  watch : {
    dialog: function () {
      if(this.dialog) {
        this.email = this.user.email
      }
    }
  },

  data() {
    return {
      loading: false,
      reqErr: '',
      dialog: false,
      msgObj: {
        msg: '',
        type: '',
      },
      email: "",
      logo: "",
      user: {
        email: "",
        password: ""
      }
    };
  },

  computed: {
    ...mapState(["authState"])
  },

  methods: {
    ...mapMutations(["SET_ERROR_MESSAGE"]),
    ...mapGetters(["getAuthUser"]),
    ...mapActions([
      "authenticate",
      "fetchCompanyDetails"
    ]),

    async initialize() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get("redirect");
        switch (myParam) {
          case "1":
            this.msgObj.type = "error"
            this.msgObj.msg =
              "For security reasons your session has expired. Please Login to continue session.";
            break;
          case "2":
            this.msgObj.type = "info"
            this.msgObj.msg =
              "Your password has been reset. Please Login to continue session.";
            break;
        }
        await this.fetchCompanyDetails();
        this.logo =
          this.authState.company.logo ||
          require("@/assets/img/technosoft_shorten_logo.jpg");
      } catch (e) {
        console.log(e);
      }
    },

    onLoginClicked() {
      this.msgObj.msg = "";
      this.$validator.validateAll("login").then(async res => {
        if (res) {
          await this.authenticate(this.user);
          if (this.authState.isAuthenticated) {
            let obj = this.getAuthUser();
            // console.log('this.refs.loanproWindow: ', this.$refs.loanproWindow.contentWindow);
            // this.$refs.loanproWindow.contentWindow.postMessage(JSON.stringify(obj), "*");
            this.$router.push("/app");
          }
        }
      });
    },

   async onRequestEmailChangedClicked() {
      this.reqErr = '';
      this.loading = true
      this.$validator.validateAll("request").then(async () => {
        try {
          await AuthService.requestPassordChange(this.email);
         
          this.dialog = false;
        } catch (e) {
          if(!e.response) {
            console.log(e)
            this.reqErr ="Something went wrong, try again. If problem persist contact administrator."
            return;
          }
          if(e.response.status === 404) {
            
          }
        } finally {
          if (!this.dialog) {
            this.$toast.info('If the email you entered exist then a reset link has been sent to it.', '', {
              position: 'topCenter'
            })
          }
          this.loading = false
        }
      });
    }
  }
};
</script>
