<template>
  <div id="application-settings-layout">
    <router-view></router-view>
  </div>
</template>

<style>
h1, h2, h3 {
  color: #485468;}

</style>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {

  },
  data() {
    return {
      tabModel: ''
    }
  },

  created() {                             
  },

  computed: {
    
    ...mapState([
      'appState',
    ]),

  },
  
  methods: {
   
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    async init() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }
    },

  }
}
</script>
