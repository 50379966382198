<template>
  <div id="account-settings-layout">
    <v-tabs class="elevation-1" color="white" >
      <v-tabs-slider color="primary lighten-2"></v-tabs-slider>

      <v-tab v-for="(item, index) in routes" :key="index" :to="item.route">
        {{ item.title }}
      </v-tab>
    </v-tabs>
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<style></style>

<script>
import { mapMutations } from "vuex";
export default {
  created() {
    this.TOGGLE_SIDEBAR_VISIBILITY(true);
  },

  data() {
    return {
      routes: [
        {
          title: "Account Types",
          route: "/app/settings/account-types-settings/account-types",
          icon: "fa-file",
          iconSize: "12px",
        },
        {
          title: "Sub Account Types",
          route: "/app/settings/account-types-settings/sub-accounts",
          icon: "fa-file-alt",
          iconSize: "12px",
        },
        {
          title: "Account Definitions",
          route: "/app/settings/account-types-settings/account-definitions",
          icon: "fa-file-invoice",
          iconSize: "12px",
        },
      ],
    };
  },

  beforeDestroy() {},

  methods: {
    ...mapMutations([
      "SET_SIDEBAR_ROUTES",
      "TOGGLE_SIDEBAR_MINI",
      "TOGGLE_SIDEBAR_VISIBILITY",
    ]),
  },
};
</script>
