<template>
<div>
  <v-card>
    <v-card-title class="primary white--text pa-1">
      <v-spacer></v-spacer>
      <p class="headline mb-0">
        <v-icon color="white" size="30" class="mr-2">business</v-icon>Department Details
      </p>
      <v-spacer></v-spacer>
      <v-btn icon dark @click="dialog = true, ID = '', errors.clear()" title="Add Department">
        <v-icon size="30">add</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-layout row wrap>
        <!-- display if there is no branch details -->
        <v-flex xs12 md12 style="padding:80px;" v-if="department_data.length == 0">
          <v-flex xs12 md12 class="text-xs-center">
            <v-icon color="primary" size="70">assistant</v-icon>
          </v-flex>
          <v-flex xs12 pt-2 pb-4>
            <h2 class="text-xs-center">Nothing Here</h2>
          </v-flex>
        </v-flex>

        <v-flex xs12 md12 v-else>
          <v-card flat>
            <v-card-title>
              <va-input
                v-model="search"
                append-icon="search"
                placeholder="Search Table"
                single-line
                style="width:100%"
              ></va-input>
            </v-card-title>

            <v-card-text>
              <v-data-table
                :headers="headers"
                :items="filteredData"
                :loading="search_load"
                class="v-table"
              >
                <template v-slot:items="props">
                  <td>{{ props.item.branch.branch_name }}</td>
                  <td>{{ props.item.department.department_name }}</td>
                  <td>{{ props.item.department.department_code }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      size="20"
                      color="primary"
                      @click="infoDialog(props.item)"
                      title="View Department"
                    >info</v-icon>
                    <v-icon
                      size="20"
                      color="success"
                      @click="editDialog(props.item)"
                      title="Edit Department"
                    >edit</v-icon>
                    <v-icon
                      size="20"
                      color="error"
                      @click="confirmDelete(props.item.department.ID)"
                      title="Delete Department"
                    >delete_sweep</v-icon>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>

  <!-- dialog with form to add department details -->
  <v-dialog v-model="dialog" width="50%" persistent scrollable>
    <v-card>
      <v-card-title class="primary white--text">
        <v-btn icon dark @click="close">
          <v-icon>close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title>{{formTitle}} Department Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text style="padding:30px;">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-layout row wrap>
            <v-flex xs12 md12>
              <va-select
                data-vv-name="branch name"
                :error-messages="errors.collect('branch name')"
                v-validate="{required: true}"
                data-vv-validate-on="blur|change"
                v-model="branch_name"
                :items="branch_data"
                item-text="branch_name"
                item-value="branch_name"
                placeholder="Select A Branch"
              ></va-select>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12 md12>
              <va-input
                data-vv-name="department name"
                :error-messages="errors.collect('department name')"
                v-validate="{required: true, max:25, min:3 }"
                data-vv-validate-on="blur|change"
                v-model="department_name"
                placeholder="Enter Department Name"
              ></va-input>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12 md12>
              <va-input
                data-vv-name="department code"
                :error-messages="errors.collect('department code')"
                v-validate="{required: true, max:5, min:2 }"
                data-vv-validate-on="blur|change"
                v-model="department_code"
                placeholder="Enter Department Code"
                required
              ></va-input>
            </v-flex>
          </v-layout>

          <v-layout>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" color="primary" @click="validate">Save</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="error" @click="reset">Clear</v-btn>
            <v-spacer></v-spacer>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- dialog with department details -->
  <v-dialog v-model="dialog2" width="50%" persistent scrollable>
    <v-card>
      <v-card-title class="primary white--text pa-1">
        <v-btn icon dark @click="dialog2 = false">
          <v-icon>close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title
          class="title font-weight-bold"
        >{{department.department_name}} Department Details</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-card-text style="padding:30px">
        <v-layout row wrap>
          <v-flex xs12 md12>
            <div class="subheading font-weight-bold">Branch Name</div>
            <div class="body-2 font-weight-regular">{{branch.branch_name}}</div>
            <br />
            <div class="subheading font-weight-bold">Department Name</div>
            <div class="body-2 font-weight-regular">{{department.department_name}}</div>
            <br />
            <div class="subheading font-weight-bold">Department Code</div>
            <div class="body-2 font-weight-regular">{{department.department_code}}</div>
            <br />
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>

  <!-- confirm delete dialog -->
  <v-dialog v-model="dialog3" max-width="50%">
    <v-card>
      <v-card-title class="primary el-border">
        <v-layout align-center justify-center>
          <h2 class="white--text">Confirm Delete</h2>
        </v-layout>
      </v-card-title>
      <v-card-text
        class="el-border font-weight-bold body-2 text-xs-center"
      >Are you sure you want to delete this Branch?</v-card-text>
      <v-layout class="pa-2" align-end justify-end>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="deletedept()">Delete</v-btn>
        <v-btn color="error" @click="dialog3 = false">Cancel</v-btn>
        <v-spacer></v-spacer>
      </v-layout>
    </v-card>
  </v-dialog>

  <!-- loader dialog -->
  <v-dialog v-model="DepartmentStore.loading" max-width="10%">
    <div class="text-xs-center" style="background-color:white;">
      <v-progress-circular
        :rotate="-90"
        :size="100"
        :width="15"
        :value="value"
        color="primary"
      >{{ value }} Loading...</v-progress-circular>
    </div>
  </v-dialog>
</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import BranchService from "@/services/branch-service";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
export default {
  components: {
    VaInput,
    VaSelect
  },
  name: "DepartmentSettings",
  data() {
    return {
      dialog: false,
      dialog2: false,
      dialog3: false,
      search_load: false,
      interval: {},
      value: 0,
      search: "",
      branch_data: [],
      department_data: [],
      department: {},
      branch: {},
      old_unique: "",
      ID: "",
      branch_name: "",
      department_name: "",
      department_code: "",
      valid: true,
      headers: [
        {
          text: "Branch Name",
          align: "left",
          sortable: true,
          value: "branch_name"
        },
        {
          text: "Department Name",
          align: "left",
          sortable: true,
          value: "department_name"
        },
        {
          text: "Department Code",
          align: "left",
          sortable: true,
          value: "department_code"
        },
        {
          text: "Actions",
          align: "left",
          sortable: true,
          value: "actions"
        }
      ]
    };
  },

  methods: {
    ...mapGetters(["getAllBranch", "getAllDepartment"]),
    ...mapActions([
      "createDepartment",
      "updateDepartment",
      "findAllBranch",
      "findAllDepartment",
      "deleteDepartment"
    ]),

    validate() {
      if (this.$refs.form.validate() && this.$validator.validateAll()) {
        const data = {
          ID: this.ID,
          branch_name: this.branch_name,
          department_name: this.department_name,
          department_code: this.department_code
        };
        if (this.ID == "") {
          this.add(data);
        } else {
          this.edit(data);
        }
      } else {
        this.$toast.error("Please complete the form with valid data.", "", {
          position: "topCenter"
        });
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },

    editDialog(data) {
      this.ID = data.department.ID;
      this.branch_name = data.branch.branch_name;
      this.department_name = data.department.department_name;
      this.department_code = data.department.department_code;
      this.old_unique = {
        d_name: this.department_name,
        b_name: this.branch_name
      };
      this.dialog = true;
    },

    infoDialog(data) {
      this.department = data.department;
      this.branch = data.branch;
      this.dialog2 = true;
    },

    async add(obj) {
      try {
        const result = await this.createDepartment(obj);
        if (result.status == 0) {
          this.get();
          this.$toast.success(result.message, "", { position: "topCenter" });
          this.$refs.form.reset();
          this.errors.clear();
        } else {
          this.$toast.error(result.message, "", { position: "topCenter" });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },

    async edit(obj) {
      try {
        obj.old_unique = this.old_unique;
        const result = await this.updateDepartment(obj);
        if (result.status == 0) {
          this.get();
          this.$toast.success(result.message, "", { position: "topCenter" });
          this.$refs.form.reset();
          this.dialog = false;
        } else {
          this.$toast.error(result.message, "", { position: "topCenter" });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },

    confirmDelete(id) {
      this.ID = id;
      this.dialog3 = true;
    },
    
    async deletedept() {
      try {
        const result = await this.deleteDepartment(this.ID);
        if (result.status == 0) {
          this.get();
          this.$toast.success(result.message, "", { position: "topCenter" });
          this.dialog3 = false;
        } else {
          this.$toast.error(result.message, "", { position: "topCenter" });
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },

    async get() {
      try {
        let result = await this.findAllDepartment();
        if (result) {
          this.department_data = this.DepartmentStore.department_data;
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    },

    async getBranch() {
      try {
        let response = await BranchService.findAll();
        if (response) {
          response.data.forEach(el => {
            Object.keys(el).forEach(key => {
              el[key] =
                el[key] == "false" ||
                el[key] == "undefined" ||
                el[key] == "null"
                  ? false
                  : el[key];
            });
          });
          this.branch_data = response.data;
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", { position: "topCenter" });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            { position: "topCenter" }
          );
        }
      }
    }
  },
  watch: {
    search(val) {
      if (val == "") {
        this.search_load = false;
      } else {
        this.search_load = true;
      }
    }
  },
  computed: {
    ...mapState(["DepartmentStore", "BranchStore"]),
    formTitle() {
      return this.ID != "" ? "Edit" : "Add";
    },
    filteredData: function() {
      if (this.search == "") {
        return this.department_data;
      } else {
        let array = this.department_data.filter(
          data =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.search.toLowerCase()) !== -1
        );
        return array;
      }
    }
  },
  mounted() {
    this.get();
    this.getBranch();
    this.interval = setInterval(() => {
      if (this.value === 100) {
        return (this.value = 0);
      }
      this.value += 10;
    }, 1000);
  }
};
</script>

<style>
.v-progress-circular {
  margin: 1rem;
}
</style>

