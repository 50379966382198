<template>
  <div>
    <!-- Modals -->
    <v-dialog v-model="rangeDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">New Range Generation</v-card-title>

        <v-card-text class="mb-4">
          <va-input
            v-model="newRange.min"
            v-validate="'required|min_value:0'"
            data-vv-scope="newRange"
            :error-messages="errors.collect('newRange.min')"
            placeholder=" "
            label="Starting amount"
            data-vv-validate-on="blur"
            data-vv-name="min"
            data-vv-as="starting amount"
            show-clean />

          <va-input
            v-model="newRange.rows"
            v-validate="'required|numeric|min_value:1|max_value:7'"
            data-vv-scope="newRange"
            :error-messages="errors.collect('newRange.rows')"
            placeholder=" "
            label="Number of rows"
            data-vv-validate-on="blur"
            data-vv-name="rows"
            show-clean />
          <va-input
            v-model="newRange.interval"
            v-validate="'required|not_negative'"
            data-vv-scope="newRange"
            :error-messages="errors.collect('newRange.interval')"
            placeholder=" "
            v-money="money"
            ref="interval"
            label="Incremental Amount"
            data-vv-validate-on="blur"
            data-vv-name="incremental amount"
            show-clean />
        </v-card-text>

        <v-card-actions style="margin-top: -30px" class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closeGenerateRangeDialog">Cancel</v-btn>
          <v-btn color="primary" @click="onGenerateRange">Generate</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="policyDialog" max-width="420px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ policyFormTitle }}
          </span>
        </v-card-title>

        <v-card-text>
          <va-select
            class="mr-3"
            v-model="policyEditedItem.type"
            v-validate="'required'"
            :disabled="this.policyEditedIndex > -1"
            placeholder=" "
            label="Policy Type"
            data-vv-scope="policy"
            :error-messages="errors.collect('policy.type')"
            :items="policyTypes"
            data-vv-as="policy type"
            data-vv-name="type" />

          <v-layout>
            <va-input
              class="mr-2"
              v-model="policyEditedItem.tenure"
              v-validate="'required|numeric|min_value:0|max_value:900'"
              data-vv-scope="policy"
              :error-messages="errors.collect('policy.tenure')"
              placeholder=" "
              label="Maximum Tenure (Months)"
              data-vv-validate-on="blur"
              data-vv-name="tenure"
              data-as="maximum tenure"
              show-clean />

            <va-input
              class="mr-2"
              v-model="policyEditedItem.interest"
              v-validate="'required|decimal|min_value:0|max_value:100'"
              data-vv-scope="policy"
              :error-messages="errors.collect('policy.interest')"
              placeholder=" "
              label="Annual Interest Rate (%)"
              data-vv-validate-on="blur"
              data-vv-name="interest"
              data-vv-as="interest rate"
              show-clean />
          </v-layout>
          <v-layout>
            <va-input
              class="mr-2"
              v-model="policyEditedItem.value_collateral_used"
              v-validate="'required|decimal|min_value:0|max_value:1000'"
              data-vv-scope="policy"
              :error-messages="errors.collect('policy.ltv')"
              placeholder=" "
              label="Loan to Value Ratio (%)"
              data-vv-validate-on="blur"
              data-vv-name="ltv"
              data-vv-as="loan to value ratio"
              show-clean />

            <va-input
              class="mr-2"
              v-model="policyEditedItem.shares"
              v-validate="'required|decimal|min_value:0|max_value:1000'"
              data-vv-scope="policy"
              :error-messages="errors.collect('policy.shares')"
              placeholder=" "
              label="Base Share Requirement (%)"
              data-vv-validate-on="blur"
              data-vv-name="shares"
              data-vv-as="base shares"
              show-clean />
          </v-layout>
          <v-layout>
            <va-input
              class="mr-2"
              v-model="policyEditedItem.dsr"
              v-validate="'required|decimal|min_value:0|max_value:100'"
              data-vv-scope="policy"
              :error-messages="errors.collect('policy.dsr')"
              placeholder=" "
              label="Debt Service Ratio (%)"
              data-vv-validate-on="blur"
              data-vv-name="dsr"
              data-vv-as="debt service ratio"
              show-clean />
          </v-layout>
        </v-card-text>

        <v-card-actions class="ml-4 mr-4">
          <v-spacer></v-spacer>
          <v-btn flat @click="closePolicyDialog">Cancel</v-btn>
          <v-btn color="primary" @click="addPolicy">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <h2>Loan Product Management</h2>
    <v-divider class="mb-4"></v-divider>

    <v-dialog persistent v-model="dialog" max-width="980px">
      <v-toolbar dense color="primary" class="elevation-1" dark tabs>
        <v-btn class="elevation-0" color="blue" @click="close"> Exit </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn
          dark
          flat
          :loading="loanProductState.isLoading"
          :disabled="loanProductState.isLoading"
          @click="submit">
          Save
        </v-btn>
        <template slot="extension">
          <v-tabs v-model="productTab" color="primary" dark slider-color="#eee">
            <v-tab href="#tab-details" active ripple> Loan Product Details </v-tab>
            <v-tab href="#tab-policy" ripple> Policies </v-tab>
            <v-tab href="#tab-service" ripple> Processing Fee </v-tab>
            <v-tab href="#tab-consolidation" ripple> Consolidation/Top Up </v-tab>
            <v-tab href="#tab-checklist" v-if="loanSettings.use_checklist" ripple>
              Loan Review Checklist Items
            </v-tab>
            <!-- TODO: Uncomment once this section is being used -->
            <!-- <v-tab href="#tab-e-service" ripple> E-Services </v-tab> -->
            <v-tab href="#tab-documents" ripple> Documents </v-tab>
            <v-tab v-if="needAgePolicy" href="#tab-age" ripple> Age Policies </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>
      <v-tabs-items v-model="productTab">
        <!-- TODO: Uncomment once this section is being used -->
        <!-- <v-tab-item :value="'tab-e-service'">
          <v-card flat class="fixed-height">
            <v-list subheader two-line>
              <v-list-tile>
                <v-list-tile-action>
                  <v-checkbox
                    v-model="editedItem.is_e_service_product"
                  ></v-checkbox>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title>Enable on E-Service</v-list-tile-title>
                  <v-list-tile-sub-title
                    >Allow your customers to see this product when applying from
                    E-Services.</v-list-tile-sub-title
                  >
                </v-list-tile-content>
              </v-list-tile>

              <v-list-tile>
                <v-list-tile-action>
                  <v-checkbox
                    v-model="editedItem.require_salary_deduction"
                  ></v-checkbox>
                </v-list-tile-action>

                <v-list-tile-content>
                  <v-list-tile-title
                    >Requires Salary Deduction</v-list-tile-title
                  >
                  <v-list-tile-sub-title
                    >Only allows for Salary Deduction as the only method of
                    payment.</v-list-tile-sub-title
                  >
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
            <v-layout class="ml-3">
              <v-flex xs4>
                <va-input
                  class="ml-2"
                  v-model="editedItem.response_time"
                  v-validate="'required|integer|min:0|max:744'"
                  data-vv-scope="eServices"
                  :error-messages="errors.collect('eServices.response_time')"
                  placeholder=" "
                  label="Response Time (Hours)"
                  data-vv-validate-on="blur"
                  data-vv-name="response_time"
                  data-vv-as="response time"
                  show-clean
                />
              </v-flex>
            </v-layout>
            <v-layout class="mx-3">
              <v-flex xs12>
                <v-textarea
                  class="ml-2"
                  v-model="editedItem.description"
                  v-validate="'min:0|max:4000'"
                  data-vv-scope="eServices"
                  :error-messages="errors.collect('eServices.description')"
                  placeholder="Add your product description here. "
                  label="Description"
                  data-vv-validate-on="blur"
                  data-vv-name="descritption"
                  show-clean
                />
              </v-flex>
            </v-layout>
          </v-card>
        </v-tab-item> -->
        <v-tab-item :value="'tab-documents'">
          <v-list two-line>
            <v-subheader>
              <v-checkbox
                :value="allDocumentsSelected"
                @change="
                  toggleSelectAllDocuments($event);
                  checkAllDocumentsSelected();
                "
                label="Select all documents" />
              <v-spacer></v-spacer>
              <v-text-field
                placeholder="Search..."
                style="width: 140px !important"
                v-model="documentSearch"
                clearable
                single-line
                append-icon="search" />
            </v-subheader>
            <v-divider></v-divider>
            <div style="overflow-y: scroll !important; min-height: 300px; max-height: 400px">
              <v-list-tile v-for="item in searchedDocuments" :key="item.name">
                <v-list-tile-action>
                  <v-checkbox
                    :value="item"
                    multiple
                    v-model="editedItem.loan_documents"
                    @change="checkAllDocumentsSelected()" />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.name | capitalize }}
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </v-list>
        </v-tab-item>
        <v-tab-item :value="'tab-details'">
          <v-card flat class="fixed-height">
            <v-card-text>
              <v-layout wrap row>
                <va-input
                  class="ml-2"
                  v-model="editedItem.loan_product_code"
                  v-validate="'required|min:3|max:50'"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_product_code')"
                  placeholder=" "
                  label="Loan Product Code"
                  data-vv-validate-on="blur"
                  data-vv-name="loan_product_code"
                  data-vv-as="product code"
                  show-clean />

                <va-input
                  class="ml-2"
                  v-model="editedItem.loan_product_name"
                  v-validate="'required|min:3|max:50'"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_product_name')"
                  placeholder=" "
                  :width="'300px'"
                  label="Loan Product Name"
                  data-vv-validate-on="blur"
                  data-vv-name="loan_product_name"
                  data-vv-as="product name"
                  show-clean />

                <va-select
                  class="ml-2"
                  v-model="editedItem.loan_type_id"
                  v-validate="'required'"
                  placeholder=" "
                  :disabled="disableLoanType"
                  label="Loan Product Type"
                  @change="onLoanTypeChanged($event)"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_type_id')"
                  :items="loanTypeState.loanTypes"
                  :item-text="'loan_type_name'"
                  :item-value="'ID'"
                  data-vv-name="loan_type_id"
                  data-vv-as="loan type" />

                <va-select
                  class="ml-2"
                  v-model="editedItem.loan_product_type"
                  v-validate="'required'"
                  placeholder=" "
                  label="Loan Product Classification"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_product_type')"
                  :items="['Personal', 'Business', 'Both']"
                  data-vv-name="loan_product_type"
                  data-vv-as="classification" />
              </v-layout>
              <v-layout>
                <va-input
                  class="ml-2"
                  v-model.lazy="editedItem.loan_floor"
                  v-validate="'required|not_negative'"
                  data-vv-scope="loan"
                  v-money="money"
                  :error-messages="errors.collect('loan.loan_floor')"
                  placeholder=" "
                  label="Loan Floor"
                  :width="'300px'"
                  ref="loan_floor"
                  data-vv-validate-on="blur"
                  data-vv-name="loan_floor"
                  data-vv-as="loan floor"
                  show-clean />
                <va-input
                  class="ml-2"
                  v-model.lazy="editedItem.loan_ceiling"
                  v-validate="
                    `required|is_bigger:${
                      editedItem.loan_floor
                        ? editedItem.loan_floor.toString().split(',').join('')
                        : 0
                    }|not_negative`
                  "
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_ceiling')"
                  placeholder=" "
                  :width="'300px'"
                  v-money="money"
                  label="Loan Ceiling"
                  data-vv-validate-on="blur"
                  data-vv-name="loan_ceiling"
                  data-vv-as="loan ceiling"
                  ref="loan_ceiling"
                  show-clean />
                <va-input
                  class="ml-2"
                  v-model.lazy="editedItem.loan_pool"
                  v-validate="
                    `required|is_bigger:${
                      editedItem.loan_ceiling
                        ? editedItem.loan_ceiling.toString().split(',').join('')
                        : 0
                    }|not_negative`
                  "
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.loan_pool')"
                  placeholder=" "
                  v-money="money"
                  :width="'300px'"
                  label="Loan Pool Size"
                  data-vv-validate-on="blur"
                  data-vv-name="loan_pool"
                  ref="loan_pool"
                  data-vv-as="pool size"
                  show-clean />
              </v-layout>
              <v-layout>
                <va-input
                  class="ml-2"
                  v-model="editedItem.start_date"
                  v-validate="'required|date_format:YYYY-MM-DD'"
                  data-vv-scope="loan"
                  data-vv-as="start date"
                  :error-messages="errors.collect('loan.start_date')"
                  placeholder=" "
                  ref="afterTarget"
                  return-masked-value
                  mask="####-##-##"
                  :width="'450px'"
                  label="Start Date (yyyy-mm-dd)"
                  data-vv-validate-on="blur"
                  data-vv-name="start_date"
                  show-clean />
                <va-input
                  class="ml-2"
                  v-model="editedItem.end_date"
                  v-validate="'required|date_format:YYYY-MM-DD|after:afterTarget'"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.end_date')"
                  placeholder=" "
                  return-masked-value
                  persistent-hint
                  :width="'450px'"
                  data-vv-as="end date"
                  label="End Date (yyyy-mm-dd)"
                  mask="####-##-##"
                  data-vv-validate-on="blur"
                  data-vv-name="end_date"
                  show-clean />
              </v-layout>
              <v-layout>
                <va-input
                  class="ml-2"
                  v-model="editedItem.risk_factor_score"
                  v-validate="'required|numeric:min_value:0'"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.risk_factor_score')"
                  placeholder=" "
                  data-vv-as="risk factor score"
                  :width="'450px'"
                  label="Risk Factor Score (%)"
                  data-vv-validate-on="blur"
                  data-vv-name="risk_factor_score"
                  show-clean />

                <va-input
                  v-if="needAgePolicy"
                  class="ml-2"
                  v-model="editedItem.minimum_age"
                  v-validate="'required|numeric:min_value:0'"
                  data-vv-scope="loan"
                  :error-messages="errors.collect('loan.minimum_age')"
                  placeholder=" "
                  :width="'450px'"
                  data-vv-as="minimum age"
                  label="Maximum Age"
                  data-vv-validate-on="blur"
                  data-vv-name="minimum_age"
                  @focus="onAgeFieldClicked"
                  @blur="onAgeEdited"
                  show-clean />
              </v-layout>

              <v-layout class="mr-3 ml-3" align-content-center>
                <v-flex>
                  <p>Monthly Payment Calculation</p>
                  <v-radio-group v-model="editedItem.monthly_payment_calculation">
                    <v-radio value="reducing" label="Reducing Balance"></v-radio>
                    <v-radio value="addon" label="Add On"></v-radio>
                    <v-radio value="percentage_of" label="Percentage of Principal"></v-radio>
                  </v-radio-group>
                </v-flex>

                <v-flex>
                  <p>Product Sub Type Settings</p>
                  <v-radio-group v-model="editedItem.loan_product_sub_type">
                    <v-radio value="revolving" label="Revolving"></v-radio>
                    <v-radio value="none" label="No Sub Type"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex>
                  <p>Assessment Settings</p>
                  <v-checkbox
                    v-model="editedItem.use_adjustments"
                    label="Use Risk Adjustments"></v-checkbox>
                  <v-checkbox
                    style="margin-top: -20px"
                    v-model="editedItem.use_risk_factor_score"
                    label="Use Risk Factor Score"></v-checkbox>
                  <v-checkbox
                    style="margin-top: -20px"
                    v-model="editedItem.use_yapu_assessment"
                    label="Use YAPU Climate Risk Assessment"></v-checkbox>
                </v-flex>
                <v-flex>
                  <p>Optional Pages</p>
                  <v-checkbox
                    v-model="editedItem.use_dsr"
                    label="Need Debt Service Ratio"></v-checkbox>
                  <v-checkbox
                    style="margin-top: -20px"
                    v-model="editedItem.use_networth"
                    label="Need Net Worth"></v-checkbox>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-policy'">
          <v-card flat class="fixed-height">
            <v-card-text>
              <v-data-table
                :headers="policyHeaders"
                :items="editedItem.policies"
                :loading="loanProductState.isLoading"
                hide-actions>
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.type }}</td>
                  <td>{{ props.item.tenure + " Months" }}</td>
                  <td>{{ (props.item.interest || 0) + " %" }}</td>
                  <td>{{ (props.item.value_collateral_used || 0) + " %" }}</td>
                  <td>{{ (props.item.shares || 0) + " %" }}</td>
                  <!-- <td>{{ props.item.dsr }}</td>-->
                  <td>
                    <v-icon small class="mr-2" @click="onEditPolicyClicked(props.item)">
                      edit
                    </v-icon>
                    <v-icon small class="mr-2" @click="onDeletePolicyClicked(props.item)">
                      delete
                    </v-icon>
                  </td>
                </template>
                <template slot="no-data"> </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="mr-2">
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="addPolicyClicked" dark class="mb-2">Add Policy</v-btn>
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-service'">
          <v-card flat class="fixed-height">
            <v-card-text>
              <v-data-table
                :headers="serviceChargeHeaders"
                class="elevation-1"
                :items="editedItem.service_charges"
                hide-actions>
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-center">
                      {{ props.item.min | currency }}
                    </td>
                    <td class="text-xs-center">
                      {{ props.item.max | currency }}
                    </td>
                    <td title="click to edit" class="text-xs-right">
                      <v-edit-dialog
                        lazy
                        @close="onEditServiceChargeDialogClosed"
                        @open="onEditServiceChargeDialogOpened(props.item)">
                        {{ props.item.percentage + " %" }}
                        <v-text-field
                          slot="input"
                          v-validate="'required|decimal|max_value:100'"
                          data-vv-scope="policy"
                          data-vv-name="percentage"
                          :error-messages="errors.collect('policy.percentage')"
                          v-model="props.item.percentage"
                          data-vv-as="percentage amount"
                          label="Edit"
                          single-line
                          counter></v-text-field>
                      </v-edit-dialog>
                    </td>
                    <td title="click to edit" class="text-xs-center">
                      <v-edit-dialog
                        lazy
                        @close="onEditServiceChargeDialogClosed"
                        @open="onEditServiceChargeDialogOpened(props.item)">
                        {{ props.item.flat | currency }}
                        <v-text-field
                          slot="input"
                          v-validate="'required|decimal|min_value:0'"
                          data-vv-scope="policy"
                          data-vv-name="flat"
                          :error-messages="errors.collect('policy.flat')"
                          v-model="props.item.flat"
                          data-vv-as="flat amount"
                          label="Edit"
                          single-line
                          counter></v-text-field>
                      </v-edit-dialog>
                    </td>
                  </tr>
                </template>
                <template slot="no-data"> </template>
              </v-data-table>
            </v-card-text>
            <v-card-actions class="mr-2 ml-2">
              <v-checkbox v-model="editedItem.attract_gct" label="Attract G.C.T"></v-checkbox>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onGenerateRangeClicked" dark class="mb-2"
                >Add Processing Fee</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-consolidation'">
          <v-card flat class="fixed-height">
            <v-card-text>
              <v-layout row wrap>
                <v-flex xs12 sm6 lg6>
                  <va-select
                    class="ml-2"
                    v-model="editedItem.loan_policy_terms_to_use"
                    v-validate="'required'"
                    placeholder=" "
                    label="Loan Policy Terms Used"
                    data-vv-scope="consolidation"
                    :error-messages="errors.collect('consolidation.loan_policy_terms_to_use')"
                    :items="policyTerms"
                    :item-text="'text'"
                    :item-value="'value'"
                    data-vv-name="loan_policy_terms_to_use"
                    data-vv-as="loan policy term" />
                </v-flex>
                <v-flex xs12 sm6 lg6>
                  <va-select
                    class="ml-2"
                    v-model="editedItem.apply_service_charge_to"
                    v-validate="'required'"
                    placeholder=" "
                    label="Apply Service Charges to"
                    data-vv-scope="consolidation"
                    :error-messages="errors.collect('consolidation.apply_service_charge_to')"
                    :items="serviceChargeApplications"
                    :item-text="'text'"
                    :item-value="'value'"
                    data-vv-name="apply_service_charge_to"
                    data-vv-as="apply service charge to" />
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item :value="'tab-checklist'">
          <v-list two-line>
            <v-subheader>
              <v-checkbox
                :label="'Select All Items'"
                v-model="allChecklistCheckBox"
                @change="
                  onSelectAllActions($event);
                  checkAllCheck();
                "></v-checkbox>
              <v-spacer></v-spacer>
              <v-text-field
                placeholder="Search..."
                style="width: 140px !important"
                v-model="checklistSearch"
                clearable
                single-line
                append-icon="search"></v-text-field>
            </v-subheader>
            <v-divider></v-divider>
            <div style="overflow-y: scroll !important; min-height: 300px; max-height: 400px">
              <v-list-tile v-for="item in filterChecklist(checklist)" :key="item.checklist_name">
                <v-list-tile-action>
                  <v-checkbox v-model="item.is_selected" @change="checkAllCheck()"></v-checkbox>
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title>
                    {{ item.checklist_name | capitalize }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    Type: {{ item.checklist_type | capitalize }} / Priority:
                    {{ item.priority | capitalize({ onlyFirstLetter: false }) }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </div>
          </v-list>
        </v-tab-item>
        <v-tab-item :value="'tab-age'" v-if="needAgePolicy">
          <v-card flat class="fixed-height">
            <v-card-text>
              <v-tabs v-model="ageTab" centered>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab href="#tab-member-age">Member Age Policies</v-tab>
                <v-tab href="#tab-staff-age">Staff Age Policies</v-tab>
              </v-tabs>

              <v-tabs-items v-model="ageTab">
                <v-tab-item :value="'tab-member-age'">
                  <v-data-table
                    :headers="agePolicyHeaders"
                    class="elevation-1"
                    :items="editedItem.member_age_policies"
                    hide-actions>
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td class="text-xs-center">{{ props.item.age }}</td>
                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.member_age_policies, 'floor')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.loan_floor | currency }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="loan_floor"
                              :error-messages="errors.collect('age.loan_floor')"
                              v-model="props.item.loan_floor"
                              data-vv-as="loan floor"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="
                              onEditAgeDialogClosed(editedItem.member_age_policies, 'ceiling')
                            "
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.loan_ceiling | currency }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="loan_ceiling"
                              :error-messages="errors.collect('age.loan_ceiling')"
                              v-model="props.item.loan_ceiling"
                              data-vv-as="loan ceiling"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.member_age_policies, 'tenure')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.tenure }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="tenure"
                              :error-messages="errors.collect('age.tenure')"
                              v-model="props.item.tenure"
                              data-vv-as="tenure"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td title="click to edit" class="text-xs-right">
                          <v-edit-dialog
                            lazy
                            @close="
                              onEditAgeDialogClosed(editedItem.member_age_policies, 'interest')
                            "
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.interest + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:100'"
                              data-vv-scope="age"
                              data-vv-name="interest"
                              :error-messages="errors.collect('age.interest')"
                              v-model="props.item.interest"
                              data-vv-as="interest"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.member_age_policies, 'shares')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.shares + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:1000'"
                              data-vv-scope="age"
                              data-vv-name="shares"
                              :error-messages="errors.collect('age.shares')"
                              v-model="props.item.shares"
                              data-vv-as="shares"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.member_age_policies, 'dsr')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.dsr + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:100'"
                              data-vv-scope="age"
                              data-vv-name="dsr"
                              :error-messages="errors.collect('age.dsr')"
                              v-model="props.item.dsr"
                              data-vv-as="dsr"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>

                        <td title="click to edit" class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.member_age_policies, 'ltv')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.value_collateral_used + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:1000'"
                              data-vv-scope="age"
                              data-vv-name="value_collateral_used"
                              :error-messages="errors.collect('age.value_collateral_used')"
                              v-model="props.item.value_collateral_used"
                              data-vv-as="value_collateral_used"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data"> </template>
                  </v-data-table>
                </v-tab-item>

                <v-tab-item :value="'tab-staff-age'">
                  <v-data-table
                    :headers="agePolicyHeaders"
                    class="elevation-1"
                    :items="editedItem.staff_age_policies"
                    hide-actions>
                    <template slot="items" slot-scope="props">
                      <tr>
                        <td class="text-xs-center">{{ props.item.age }}</td>
                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'floor')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.loan_floor | currency }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="loan_floor"
                              :error-messages="errors.collect('age.loan_floor')"
                              v-model="props.item.loan_floor"
                              data-vv-as="loan floor"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'ceiling')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.loan_ceiling | currency }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="loan_ceiling"
                              :error-messages="errors.collect('age.loan_ceiling')"
                              v-model="props.item.loan_ceiling"
                              data-vv-as="loan ceiling"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'tenure')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.tenure }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0'"
                              data-vv-scope="age"
                              data-vv-name="tenure"
                              :error-messages="errors.collect('age.tenure')"
                              v-model="props.item.tenure"
                              data-vv-as="tenure"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td class="text-xs-right">
                          <v-edit-dialog
                            lazy
                            @close="
                              onEditAgeDialogClosed(editedItem.staff_age_policies, 'interest')
                            "
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.interest + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:100'"
                              data-vv-scope="age"
                              data-vv-name="interest"
                              :error-messages="errors.collect('age.interest')"
                              v-model="props.item.interest"
                              data-vv-as="interest"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'shares')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.shares + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:1000'"
                              data-vv-scope="age"
                              data-vv-name="shares"
                              :error-messages="errors.collect('age.shares')"
                              v-model="props.item.shares"
                              data-vv-as="shares"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'dsr')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.dsr + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:100'"
                              data-vv-scope="age"
                              data-vv-name="dsr"
                              :error-messages="errors.collect('age.dsr')"
                              v-model="props.item.dsr"
                              data-vv-as="dsr"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>

                        <td class="text-xs-center">
                          <v-edit-dialog
                            lazy
                            @close="onEditAgeDialogClosed(editedItem.staff_age_policies, 'ltv')"
                            @open="onAgeDialogOpened(props.item)">
                            {{ props.item.value_collateral_used + " %" }}
                            <v-text-field
                              slot="input"
                              v-validate="'required|decimal|min_value:0|max_value:1000'"
                              data-vv-scope="age"
                              data-vv-name="value_collateral_used"
                              :error-messages="errors.collect('age.value_collateral_used')"
                              v-model="props.item.value_collateral_used"
                              data-vv-as="value_collateral_used"
                              label="Edit"
                              single-line
                              counter></v-text-field>
                          </v-edit-dialog>
                        </td>
                      </tr>
                    </template>
                    <template slot="no-data"> </template>
                  </v-data-table>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-dialog>
    <v-toolbar dense color="white" class="pa-0">
      <v-text-field
        v-model="globalSearch"
        @keyup="onSearch()"
        solo
        label="Search"
        prepend-inner-icon="search"></v-text-field>

      <v-divider class="mr-2" vertical></v-divider>

      <v-btn color="primary" @click="openModal"> New Loan Product </v-btn>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      class="elevation-1"
      :items="items"
      :loading="loanProductState.isLoading"
      :pagination.sync="pagination"
      :total-items="totalCount"
      :rows-per-page-items="[
        10,
        20,
        30,
        { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 },
      ]">
      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>
          <v-checkbox
            :disabled="loanProductState.isLoading"
            @change="onActivieChanged(props.item)"
            v-model="props.item.is_active"
            primary
            hide-details></v-checkbox>
        </td>
        <td>{{ props.item.loan_product_code }}</td>
        <td>{{ props.item.loan_product_name }}</td>
        <td>{{ props.item.loan_product_type }}</td>
        <td>{{ props.item.loan_type_name }}</td>
        <td>{{ props.item.start_date }}</td>
        <td>{{ props.item.end_date }}</td>
        <td>
          <v-icon small title="edit" @click="onEditItemClicked(props.item)"> edit </v-icon>
        </td>
      </template>
      <template slot="no-data"> </template>
    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1,
h2,
h3 {
  color: #505a6b;
}

.fixed-height {
  min-height: 500px;
  overflow-y: auto;
}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import VaInput from "@/components/form-components/VaInput.vue";
import VaSelect from "@/components/form-components/VaSelect.vue";
import PaginationMixin from "@/mixins/PaginationMixin";
import loanProductService from "@/services/loan-product-service";
import validations from "@/common/validator";
import checklistService from "@/services/loan-checklist-service";
import settingsService from "@/services/general-settings-service";
import _ from "lodash";

export default {
  components: {
    VaInput,
    VaSelect,
  },

  mixins: [PaginationMixin],

  created() {
    this.initialize();
  },

  beforeDestroy() {
    this.$toast.destroy();
  },
  data() {
    return {
      // var used to store search state of document tab
      documentSearch: "",
      allDocumentsSelected: false,
      loanSettings: {},
      toast: null,
      dialog: false,
      policyDialog: false,
      needAgePolicy: false,
      allChecklistCheckBox: false,
      checklistSearch: "",
      productTab: "tab-details",
      policyTypes: [],
      checklist: [],
      checklistCache: [],
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: true,
      },
      serviceChargeHeaders: [
        { text: "Min", align: "center", sortable: false, value: "min" },
        { text: "Max", align: "center", sortable: false, value: "max" },
        {
          text: "Percentage",
          align: "left",
          sortable: false,
          value: "percentage",
        },
        { text: "Flat", align: "left", sortable: false, value: "flat" },
      ],
      agePolicyHeaders: [
        { text: "Age", align: "center", sortable: false, value: "age" },
        {
          text: "Loan Floor",
          align: "center",
          sortable: false,
          value: "loan_floor",
        },
        {
          text: "Loan Ceiling",
          align: "center",
          sortable: false,
          value: "loan_ceiling",
        },
        { text: "Tenure", align: "left", sortable: false, value: "tenure" },
        {
          text: "Annual Interest",
          align: "left",
          sortable: false,
          value: "interest",
        },
        { text: "Base Share", align: "left", sortable: false, value: "shares" },
        {
          text: "DSR Requirements",
          align: "left",
          sortable: false,
          value: "dsr",
        },
        {
          text: "Loan To Value Ratio",
          align: "left",
          sortable: false,
          value: "value_collateral_used",
        },
      ],
      policyHeaders: [
        { text: "Policy Type", align: "left", sortable: true, value: "type" },
        { text: "Max Tenure", align: "left", sortable: true, value: "tenure" },
        {
          text: "Annual Interest Rate",
          align: "left",
          sortable: true,
          value: "interest",
        },
        {
          text: "Loan to Value Ratio",
          align: "left",
          sortable: true,
          value: "value_collateral_used",
        },
        {
          text: "Base Share Requirement",
          align: "left",
          sortable: true,
          value: "shares",
        },
        // { text: 'Maximum DSR', align: 'left', sortable: true, value: 'dsr'},
        { text: "", align: "left", sortable: false },
      ],
      headers: [
        { text: "Active", align: "left", sortable: true, value: "is_active" },
        {
          text: "Product Code",
          align: "left",
          sortable: true,
          value: "loan_product_code",
        },
        {
          text: "Product Name",
          align: "left",
          sortable: true,
          value: "loan_product_name",
        },
        {
          text: "Product Type",
          align: "left",
          sortable: true,
          value: "loan_product_type",
        },
        {
          text: "Loan Type",
          align: "left",
          sortable: true,
          value: "loan_type_name",
        },
        {
          text: "Start Date",
          align: "left",
          sortable: false,
          value: "start_date",
        },
        { text: "End Date", align: "left", sortable: false, value: "end_date" },
        { text: "Action", value: "" },
      ],
      policyTerms: [
        { text: "Use Previous Terms", value: "previous" },
        { text: "Use Current Terms", value: "current" },
        { text: "Use Weighted Terms", value: "weighted" },
      ],
      serviceChargeApplications: [
        { text: "New Amount", value: "new" },
        { text: "Total Amount", value: "total" },
      ],
      items: [],
      total: 0,
      disableLoanType: false,
      previousAge: -1,
      editedIndex: -1,
      editedItem: {},
      provinces: [],
      policyEditedItem: {},
      policyEditedIndex: -1,
      rangeDialog: false,
      selectedServiceCharge: {},
      previousServicePolicy: {},
      newRange: {},
      ageTab: "tab-member-age",
      selectedAgePolicy: {},
      previousAgePolicy: {},
      message: {
        visible: false,
        title: "",
        content: "",
        type: "success",
      },
    };
  },

  computed: {
    ...mapState(["appState", "loanTypeState", "loanProductState"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Loan Product" : "Edit Loan Product";
    },
    policyFormTitle() {
      return this.policyEditedIndex === -1 ? "New Policy" : "Edit Policy";
    },
    filteredDocumentsByLoanType() {
      // If no loan type is selected, return all the documents
      if (!this.editedItem.loan_type_id) return this.loanProductState.documents;
      // Get the loan type category of the loan product
      const loanTypeCategory = this.loanTypeState.loanTypes.find(
        (lt) => lt.ID === this.editedItem.loan_type_id,
      ).loan_type_category;
      // Filter the documents based on the loan type category
      return this.loanProductState.documents.filter((el) => {
        return (
          el.loan_types.includes("all") || el.loan_types.includes(loanTypeCategory.toLowerCase())
        );
      });
    },
    searchedDocuments() {
      // If no search, return all filtered documents
      if (!this.documentSearch) return this.filteredDocumentsByLoanType;
      // If there is a search, filter the documents based on the search param
      return this.filteredDocumentsByLoanType.filter((el) => {
        return el.name.toLowerCase().includes(this.documentSearch.toLowerCase());
      });
    },
  },

  methods: {
    ...mapGetters(["getTimezonesAndOffsets"]),
    ...mapMutations(["SHOW_UNEXPECTED_ERROR_DIALOG"]),
    ...mapActions(["getLoanTypes", "getLoanProducts", "createLoanProduct", "updateLoanProduct"]),

    async initialize() {
      try {
        await this.fetchSettings();

        this.setupValidations();
        this.editedItem = Object.assign({}, this.loanProductState.loanProduct);

        await this.getLoanTypes({ filter: "", sort: "" });
      } catch (e) {
        console.log("saving/updating: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async fetchSettings() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let result = await settingsService.findLoanSettings();
        if (Object.keys(result).length > 0) {
          for (let key in result) {
            if (typeof result[key] === "string" && result[key].indexOf("{'") > -1) {
              result[key] = JSON.parse(result[key].replace(/'/g, '"'));
            }
          }
          this.loanSettings = result;
          return true;
        }
        return false;
      } catch (e) {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    checkAllCheck() {
      let len = this.checklist.length;
      let selectedLength = this.checklist.filter((el) => el.is_selected).length;

      this.allChecklistCheckBox = selectedLength == len;
    },

    toggleSelectAllDocuments(ev) {
      this.allDocumentsSelected = ev;
      if (ev) {
        this.editedItem.loan_documents = _.cloneDeep(this.searchedDocuments);
      } else {
        this.editedItem.loan_documents = [];
      }
    },

    documentIncluded(document) {
      if (!document) return false;
      if (!this.editedItem || !this.editedItem.loan_documents) return false;
      return this.editedItem.loan_documents.some((el) => el.name === document.name);
    },

    checkAllDocumentsSelected() {
      if (!this.editedItem || !this.editedItem.loan_documents) return;
      let len = this.searchedDocuments.length;
      let selectedLength = this.editedItem.loan_documents.length;

      this.allDocumentsSelected = selectedLength == len;
    },

    setupOnDialogOpened() {
      let type = this.loanTypeState.loanTypes.find((el) => el.ID === this.editedItem.loan_type_id);
      if (type) {
        this.checklist = type.loan_type_checklist;
        this.checklistCache = type.loan_type_checklist;
      }

      this.checklist = this.checklistCache.map((el) => {
        el.is_selected = false;
        this.editedItem.loan_product_checklist.forEach((document) => {
          if (el.ID == document.ID) {
            el.is_selected = true;
          }
        });
        return el;
      });
    },

    openModal() {
      this.productTab = "tab-details";
      this.editedItem.policies = [];
      this.dialog = true;
    },

    setupValidations() {
      this.$validator.extend(validations.isBigger.name, validations.isBigger.validator, {
        hasTarget: false,
      });
      this.$validator.extend(validations.notNegative.name, validations.notNegative.validator);
    },

    onLoanTypeChanged(ev) {
      let found = this.loanTypeState.loanTypes.find((el) => el.ID === ev);
      if (!this.editedItem.minimum_age) this.editedItem.minimum_age = 0;

      this.needAgePolicy = false;

      //Set Default response time
      if (!this.editedItem.response_time) {
        this.editedItem.response_time = found.response_time;
      }

      if (found) {
        if (found.loan_type_category === "Motor Vehicle") {
          this.needAgePolicy = true;
        }
      }
      this.setupOnDialogOpened();
    },

    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.loanProductState.loanProduct);
      this.editedIndex = -1;
      this.disableLoanType = false;
      this.documentSearch = "";
      this.allDocumentsSelected = false;
      this.$refs.loan_floor.$el.getElementsByTagName("input")[0].value = 0;
      this.$refs.loan_ceiling.$el.getElementsByTagName("input")[0].value = 0;
      this.$refs.loan_pool.$el.getElementsByTagName("input")[0].value = 0;
      this.$validator.reset({ scope: "loan" });
    },

    async onActivieChanged(item) {
      try {
        await loanProductService.changeIsActive(item.ID, item.is_active);

        this.$toast.success("Successfully updated record!", "", {
          position: "topCenter",
        });
      } catch (e) {
        item.is_active = !item.is_active;
        console.log("saving/updating: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    onEditItemClicked(item) {
      let temp = _.cloneDeep(item);
      this.disableLoanType = true;
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = _.cloneDeep(item);
      this.$refs.loan_floor.$el.getElementsByTagName("input")[0].value = temp.loan_floor;
      this.$refs.loan_ceiling.$el.getElementsByTagName("input")[0].value = temp.loan_ceiling;
      this.$refs.loan_pool.$el.getElementsByTagName("input")[0].value = temp.loan_pool;
      this.onLoanTypeChanged(this.editedItem.loan_type_id);
      Object.keys(this.editedItem).forEach((key) => {
        if (this.editedItem[key] === "true" || this.editedItem[key] === "false")
          this.editedItem[key] = JSON.parse(this.editedItem[key]);
      });

      // convert loan_documents to array
      if (this.editedItem.loan_documents) {
        this.editedItem.loan_documents = JSON.parse(
          this.editedItem.loan_documents.replace(/'/g, '"'),
        );
      } else {
        this.editedItem.loan_documents = [];
      }

      this.reverseFormattedDecimal();
      this.setupOnDialogOpened();
      this.checkAllCheck();
      this.checkAllDocumentsSelected();

      if (!this.editedItem.response_time) {
        this.editedItem.response_time = 24;
      }
      this.dialog = true;
    },

    async fetchData() {
      try {
        this.resetState();
        let search = `loan_product_name=lk:${this.globalSearch}:or&loan_product_code=lk:${this.globalSearch}:or`;

        await this.getLoanProducts({
          filter: search,
          sort: this.sortString,
          paginate: { skip: this.skip, limit: this.limit },
        });
        this.items = this.loanProductState.loanProducts;
        this.totalCount = this.loanProductState.totalCount;
      } catch (e) {
        console.log("fetching data: ", e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    reverseFormattedDecimal() {
      const obj = this.editedItem;
      obj.loan_ceiling = obj.loan_ceiling.toString().replace(/,/g, "");
      obj.loan_floor = obj.loan_floor.toString().replace(/,/g, "");
      obj.loan_pool = obj.loan_pool.toString().replace(/,/g, "");

      obj.service_charges.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.flat = o.flat.toString().replace(/,/g, "");
        o.percentage = o.percentage * 100;
        arr[i] = o;
      });

      obj.member_age_policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.loan_ceiling = o.loan_ceiling.toString().replace(/,/g, "");
        o.loan_floor = o.loan_floor.toString().replace(/,/g, "");
        o.interest = Number((Number(o.interest) * 100).toFixed(2));
        o.shares = Number((Number(o.shares) * 100).toFixed(2));
        o.dsr = Number((Number(o.dsr) * 100).toFixed(2));
        o.value_collateral_used = Number((Number(o.value_collateral_used) * 100).toFixed(2));
        arr[i] = o;
      });

      obj.staff_age_policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.loan_ceiling = o.loan_ceiling.toString().replace(/,/g, "");
        o.loan_floor = o.loan_floor.toString().replace(/,/g, "");
        o.interest = Number((Number(o.interest) * 100).toFixed(2));
        o.shares = Number((Number(o.shares) * 100).toFixed(2));
        o.dsr = Number((Number(o.dsr) * 100).toFixed(2));
        o.value_collateral_used = Number((Number(o.value_collateral_used) * 100).toFixed(2));
        arr[i] = o;
      });

      obj.policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.interest = Number((Number(o.interest) * 100).toFixed(2));
        o.shares = Number((Number(o.shares) * 100).toFixed(2));
        o.dsr = Number((Number(o.dsr) * 100).toFixed(2));
        o.value_collateral_used = Number((Number(o.value_collateral_used) * 100).toFixed(2));
        arr[i] = o;
      });
    },

    formatKnownValuesToDecimal() {
      const obj = _.cloneDeep(this.editedItem);
      obj.loan_ceiling = obj.loan_ceiling.toString().replace(/,/g, "");
      obj.loan_floor = obj.loan_floor.toString().replace(/,/g, "");
      obj.loan_pool = obj.loan_pool.toString().replace(/,/g, "");

      obj.service_charges.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.flat = o.flat.toString().replace(/,/g, "");
        o.percentage = o.percentage / 100;
        arr[i] = o;
      });

      obj.member_age_policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.loan_ceiling = o.loan_ceiling.toString().replace(/,/g, "");
        o.loan_floor = o.loan_floor.toString().replace(/,/g, "");
        o.interest = Number(o.interest) / 100;

        o.shares = Number(o.shares) / 100;
        (o.dsr = Number(o.dsr) / 100),
          (o.value_collateral_used = Number(o.value_collateral_used) / 100);
        arr[i] = o;
      });

      obj.staff_age_policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.loan_ceiling = o.loan_ceiling.toString().replace(/,/g, "");
        o.loan_floor = o.loan_floor.toString().replace(/,/g, "");
        o.interest = Number(o.interest) / 100;

        o.shares = Number(o.shares) / 100;
        (o.dsr = Number(o.dsr) / 100),
          (o.value_collateral_used = Number(o.value_collateral_used) / 100);
        arr[i] = o;
      });

      obj.policies.forEach((el, i, arr) => {
        let o = _.clone(el);
        o.interest = Number(o.interest) / 100;

        o.shares = Number(o.shares) / 100;
        (o.dsr = Number(o.dsr) / 100),
          (o.value_collateral_used = Number(o.value_collateral_used) / 100);
        arr[i] = o;
      });

      // stringify the loan_documents property
      obj.loan_documents = JSON.stringify(obj.loan_documents || []);
      return obj;
    },

    async submit() {
      this.resetState();
      let isValid = await this.validate();

      if (isValid) {
        this.save();
      }
    },

    async validate() {
      let isValid = await this.$validator.validateAll("loan");
      if (!isValid) {
        this.$toast.error("Please fill out loan details section before proceeding to save.", "", {
          position: "topCenter",
        });
        this.productTab = "tab-details";
        return false;
      }

      if (this.editedItem.policies.length == 0) {
        this.$toast.error("Please add at least one policy before proceeding to save.", "", {
          position: "topCenter",
        });
        this.productTab = "tab-policy";
        return false;
      }

      if (this.editedItem.service_charges.length == 0) {
        this.$toast.error("Please add processing fee before proceeding to save.", "", {
          position: "topCenter",
        });
        this.productTab = "tab-service";
        return false;
      }

      isValid = await this.$validator.validateAll("eServices");
      if (!isValid) {
        this.$toast.error("Please fill out the E-Services section before proceeding to save.", "", {
          position: "topCenter",
        });
        this.productTab = "tab-e-service";
        return false;
      }

      return true;
    },

    async save() {
      try {
        let exist = await this.doesRecordExist();
        if (exist) return;

        let edited = this.formatKnownValuesToDecimal();
        edited.loan_product_checklist = this.checklist.filter((el) => el.is_selected);
        if (this.editedIndex > -1) {
          await this.updateLoanProduct({
            index: this.editedIndex,
            obj: edited,
          });
          this.$toast.success("Successfully updated record!", "", {
            position: "topCenter",
          });
        } else {
          for (let i = 0; i < this.loanTypeState.loanTypes.length; i++) {
            if (this.loanTypeState.loanTypes[i].ID == edited.loan_type_id) {
              edited.loan_type_name = this.loanTypeState.loanTypes[i].loan_type_name;
              break;
            }
          }

          await this.createLoanProduct(edited);
          this.$toast.success("Successfully inserted record!", "", {
            position: "topCenter",
          });
        }
        this.close();
      } catch (e) {
        console.log("saving/updating: ", e);
        if (e.response && e.response.status == 409) {
          this.$toast.warning("Loan Product Name or Code already exist", "", {
            position: "center",
          });
        }
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    async doesRecordExist() {
      let search = `loan_product_name=eq:${this.editedItem.loan_product_name}:or&loan_product_code=eq:${this.editedItem.loan_product_code}:and`;
      let items = await loanProductService.findAll(search, "&sort=loan_product_name:asc", {
        skip: 0,
        limit: 100,
      });
      let msg = "A record already exist with the following values: ";
      let existProps = [];

      items = items.data;

      if (this.editedIndex > -1) {
        items = items.filter((el) => {
          return el.ID !== this.editedItem.ID;
        });
      }

      items.forEach((el) => {
        if (
          this.editedItem.loan_product_name.toLowerCase() === el.loan_product_name.toLowerCase()
        ) {
          existProps.push("Loan Product Name: " + this.editedItem.loan_product_name);
        }
        if (
          this.editedItem.loan_product_code.toLowerCase() === el.loan_product_code.toLowerCase()
        ) {
          existProps.push("Loan Product Code: " + this.editedItem.loan_product_code);
        }
      });
      if (existProps.length > 0) {
        this.$toast.warning(msg + existProps.join(", "), "", {
          position: "topCenter",
          timeout: 7000,
        });
        return true;
      }

      return false;
    },

    resetState() {
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },

    onSelectAllActions(ev) {
      this.checklist.forEach((el) => {
        if (ev) {
          el.is_selected = true;
        } else {
          el.is_selected = false;
        }
      });
      this.$forceUpdate();
    },

    /** policy modal & table methods*/
    closePolicyDialog() {
      this.policyEditedItem = { ...{} };
      this.policyEditedIndex = -1;
      this.policyDialog = false;
    },

    async addPolicyClicked() {
      let isValid = await this.$validator.validateAll("loan");
      if (!isValid) {
        this.$toast.error(
          "Please fill out loan details section before proceeding to add policy.",
          "",
          { position: "topCenter" },
        );
        this.productTab = "tab-details";
        return;
      }

      this.policyEditedItem = { ...this.loanProductState.policy };
      this.policyTypes = ["Member", "Staff"];
      this.editedItem.policies.forEach((el) => {
        if (el.type == "Member") {
          let index = this.policyTypes.indexOf("Member");
          this.policyTypes.splice(index, 1);
        }
        if (el.type == "Staff") {
          let index = this.policyTypes.indexOf("Staff");
          this.policyTypes.splice(index, 1);
        }
      });
      this.policyDialog = true;
    },

    async addPolicy() {
      let isValid = await this.$validator.validateAll("policy");
      if (!isValid) {
        this.$toast.error("Please to check to ensure policy form is valid", "", {
          position: "TopCenter",
        });
        return;
      }
      if (this.policyEditedIndex === -1) {
        this.editedItem.policies.push(this.policyEditedItem);
        if (this.editedItem.policies.length >= 1) {
          if (
            Number(this.editedItem.minimum_age) >= 0 &&
            !isNaN(Number(this.editedItem.minimum_age))
          )
            this.generateAgeRange();
        }
      } else {
        this.editedItem.policies.splice(this.policyEditedIndex, 1, this.policyEditedItem);
      }
      this.closePolicyDialog();
    },

    async onEditPolicyClicked(item) {
      let isValid = await this.$validator.validateAll("loan");
      if (!isValid) {
        this.$toast.error(
          "Please fill out loan details section before proceeding to edit policy.",
          "",
          { position: "topCenter" },
        );
        this.productTab = "tab-details";
        return;
      }

      this.policyEditedItem = { ...item };
      this.editedItem.policies.forEach((el, i) => {
        if (el.type == this.policyEditedItem.type) this.policyEditedIndex = i;

        if (el.type == "Member") {
          let index = this.policyTypes.indexOf("Member");
          this.policyTypes.splice(index, 1);
        }
        if (el.type == "Staff") {
          let index = this.policyTypes.indexOf("Staff");
          this.policyTypes.splice(index, 1);
        }
      });

      this.policyTypes.push(this.policyEditedItem.type);

      this.policyDialog = true;
    },

    // generateAgePolicyIfNull() {
    //   let i = 0;
    //   let end = this.editedItem.minimum_age
    //   let ranges = []
    //   while(i <= end) {
    //     ranges.push(i)
    //     i++
    //   }
    //   let staffPolicy = this.editedItem.policies.find((el) => el.type === 'Staff')
    //   let memberPolicy = this.editedItem.policies.find((el) => el.type === 'Member')

    //   this.editedItem.member_age_policies = []
    //   this.editedItem.staff_age_policies = []

    //   ranges.forEach((el) => {
    //     if(staffPolicy &&
    //        !this.editedItem.staff_age_policies) {
    //       let policy = {...staffPolicy}
    //       policy.age = el
    //       policy.loan_floor = this.editedItem.loan_floor.toString().replace(/,/g,'')
    //       policy.loan_ceiling = this.editedItem.loan_ceiling.toString().replace(/,/g,'')
    //       policy.interest = staffPolicy.interest
    //       policy.dsr = staffPolicy.dsr
    //       policy.shares = staffPolicy.shares
    //       policy.value_collateral_used = staffPolicy.value_collateral_used
    //       policy.tenure = staffPolicy.tenure
    //       this.editedItem.staff_age_policies.push(policy)
    //     }

    //     if(memberPolicy &&
    //        !this.editedItem.member_age_policies) {
    //       let policy = {...memberPolicy}
    //       policy.age = el
    //       policy.loan_floor = this.editedItem.loan_floor.toString().replace(/,/g,'')
    //       policy.loan_ceiling = this.editedItem.loan_ceiling.toString().replace(/,/g,'')
    //       policy.interest = memberPolicy.interest
    //       policy.dsr = memberPolicy.dsr
    //       policy.shares = memberPolicy.shares
    //       policy.value_collateral_used = memberPolicy.value_collateral_used
    //       policy.tenure = memberPolicy.tenure
    //       this.editedItem.member_age_policies.push(policy)
    //     }

    //   })

    // },

    onDeletePolicyClicked(item) {
      let index;
      this.editedItem.policies.forEach((el, i) => {
        if (el.type == item.type) index = i;
      });
      this.policyTypes.push(item.type);
      this.editedItem.policies.splice(index, 1);
    },

    /** service charge modal and table methods */
    async onGenerateRangeClicked() {
      let isValid = await this.$validator.validateAll("loan");
      if (!isValid) {
        this.$toast.error(
          "Please fill out loan details section before proceeding to edit policy.",
          "",
          { position: "topCenter" },
        );
        this.productTab = "tab-details";
        return;
      }
      this.rangeDialog = true;
    },

    async onGenerateRange() {
      let isValid = await this.$validator.validateAll("newRange");
      let start = Number(this.newRange.min);
      let rows = Number(this.newRange.rows);
      if (isValid) {
        let interval = Number(this.newRange.interval.toString().replace(/,/g, ""));
        if (rows > 1 && interval == 0) {
          this.$toast.error("Incremental amount must be greater than 0.", "", {
            position: "topCenter",
          });
          return;
        }
        let ranges = [];
        let i = start;
        while (ranges.length < rows) {
          ranges.push(i);
          i = i + interval;
        }

        this.editedItem.service_charges = [];
        ranges.forEach((i, idx) => {
          let policy = {
            min: "",
            max: "",
            interval: "",
            percentage: 0.0,
            flat: 0.0,
          };
          policy.min = i;
          if (idx == ranges.length - 1) policy.max = "-";
          else policy.max = ranges[idx + 1];

          this.editedItem.service_charges.push(policy);
        });

        this.closeGenerateRangeDialog();
      } else {
        this.$toast.error("Please check to ensure that the value entered is valid.", "", {
          position: "topCenter",
        });
      }
    },

    closeGenerateRangeDialog() {
      this.rangeDialog = false;
      this.$validator.reset({ scope: "newRange" });
      this.newRange = Object.assign(
        {},
        { min: "", max: "", interval: "", percentage: "", flat: "" },
      );
      this.$refs.interval.$el.getElementsByTagName("input")[0].value = 0;
      this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
    },

    async onEditServiceChargeDialogClosed() {
      let isValid = await this.$validator.validateAll("service");
      if (!isValid) {
        this.selectedServiceCharge.percentage = this.previousServicePolicy.percentage;
        this.selectedServiceCharge.flat = this.previousServicePolicy.flat;
      }
    },

    onEditServiceChargeDialogOpened(item) {
      this.$validator.reset({ scope: "service" });
      this.selectedServiceCharge = item;
      this.previousServicePolicy = Object.assign({}, item);
    },

    /** age policy methods */

    onAgeDialogOpened(item) {
      this.$validator.reset({ scope: "age" });
      this.selectedAgePolicy = item;
      this.previousAgePolicy = Object.assign({}, item);
    },

    async onEditAgeDialogClosed(items, field) {
      let isValid = await this.$validator.validateAll("age");
      if (!isValid) {
        this.copyObject(this.selectedAgePolicy, this.previousAgePolicy);
        this.$toast.error("Unable to save due to invalid data reverting to previous settings", "", {
          position: "topCenter",
          timeout: 7000,
        });
        return;
      }

      if (Number(this.selectedAgePolicy.loan_floor) > Number(this.selectedAgePolicy.loan_ceiling)) {
        this.copyObject(this.selectedAgePolicy, this.previousAgePolicy);
        this.$toast.error(
          "Loan floor cannot be greater than loan ceiling reverting to previous settings",
          "",
          { position: "topCenter", timeout: 7000 },
        );
        return;
      }

      let index = items.find((el) => el.age === this.selectedAgePolicy.age);
      let filterList = items.filter((el, i) => i < index);
      for (let i = 0; i < filterList.length; i++) {
        if (
          filterList[i].loan_ceiling < this.selectedAgePolicy.loan_ceiling &&
          field === "ceiling"
        ) {
          this.$toast.warning(
            "Compared to newer age value(s) the loan ceiling should be lower",
            "",
            { position: "topCenter", timeout: 5000 },
          );
          break;
        }
        if (filterList[i].tenure > this.selectedAgePolicy.tenure && field === "tenure") {
          this.$toast.warning("Compared to newer age value(s) the tenure should be higher", "", {
            position: "topCenter",
            timeout: 5000,
          });
          break;
        }
        if (filterList[i].interest > this.selectedAgePolicy.interest && field === "interest") {
          this.$toast.warning("Compared to newer age value(s) the interest should be higher", "", {
            position: "topCenter",
            timeout: 5000,
          });
          break;
        }
        if (filterList[i].shares > this.selectedAgePolicy.shares && field === "shares") {
          this.$toast.warning(
            "Compared to newer age value(s) the base shares should be higher",
            "",
            { position: "topCenter", timeout: 5000 },
          );
          break;
        }
        if (
          filterList[i].value_collateral_used > this.selectedAgePolicy.value_collateral_used &&
          field === "ltv"
        ) {
          this.$toast.warning(
            "Compared to newer age value(s) the loan to value ratio should be lower",
            "",
            { position: "topCenter", timeout: 5000 },
          );
          break;
        }
      }
    },

    copyObject(target, objToCopy) {
      Object.keys(objToCopy).forEach((key) => {
        if (target[key]) {
          target[key] = objToCopy[key];
        }
      });
    },

    onAgeFieldClicked() {
      this.previousAge = this.editedItem.minimum_age;
    },

    onAgeEdited() {
      if (
        this.editedItem.policies.length > 0 &&
        this.previousAge !== this.editedItem.minimum_age &&
        !isNaN(Number(this.editedItem.minimum_age))
      ) {
        let $this = this;
        this.toast = this.$toast.question(
          "By changing this you will reset the age policies, are you sure want to perform this action?",
          "",
          {
            timeout: 20000,
            close: false,
            overlay: true,
            position: "center",
            buttons: [
              [
                "<button><b>YES</b></button>",
                async (instance, toast) => {
                  $this.generateAgeRange();
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
                true,
              ],
              [
                "<button>NO</button>",
                function (instance, toast) {
                  $this.editedItem.minimum_age = $this.previousAge;
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
              ],
            ],
          },
        );
      }
    },

    generateAgeRange() {
      let i = 0;
      let end = this.editedItem.minimum_age;
      let ranges = [];
      while (i <= end) {
        ranges.push(i);
        i++;
      }

      let staffPolicy = this.editedItem.policies.find((el) => el.type === "Staff");
      let memberPolicy = this.editedItem.policies.find((el) => el.type === "Member");

      this.editedItem.member_age_policies = [];
      this.editedItem.staff_age_policies = [];
      ranges.forEach((el) => {
        let policy = { ...this.loanProductState.agePolicy };

        if (memberPolicy) {
          let copy = { ...memberPolicy };
          delete copy["type"];

          policy = { ...copy };
          policy.age = el;
          policy.loan_floor = this.editedItem.loan_floor.toString().replace(/,/g, "");
          policy.loan_ceiling = this.editedItem.loan_ceiling.toString().replace(/,/g, "");
          policy.interest = memberPolicy.interest;
          policy.dsr = memberPolicy.dsr;
          policy.shares = memberPolicy.shares;
          policy.value_collateral_used = memberPolicy.value_collateral_used;
          policy.tenure = memberPolicy.tenure;
          if (memberPolicy) {
            this.editedItem.member_age_policies.push(policy);
          }
        }

        if (staffPolicy) {
          let staffCopy = { ...staffPolicy };
          delete staffCopy["type"];
          policy = { ...staffCopy };
          policy.age = el;
          policy.loan_floor = this.editedItem.loan_floor.toString().replace(/,/g, "");
          policy.loan_ceiling = this.editedItem.loan_ceiling.toString().replace(/,/g, "");
          policy.interest = staffPolicy.interest;
          policy.dsr = staffPolicy.dsr;
          policy.shares = staffPolicy.shares;
          policy.value_collateral_used = staffPolicy.value_collateral_used;
          policy.tenure = staffPolicy.tenure;
          this.editedItem.staff_age_policies.push(policy);
        }
      });
    },

    filterChecklist(items) {
      var app = this;
      return items.filter(function (fee) {
        let search = app.checklistSearch ? app.checklistSearch.toLowerCase() : "";
        return fee.checklist_name.toLowerCase().indexOf(search) != -1;
      });
    },
  },
};
</script>

 
