
<template>
  <div id="setup">
    <!-- Dialogs -->
    <v-dialog v-model="isUnexpectedError" persistent width="400">
      <v-card dark>
        <v-card-title class="headline">An unexpected error has occured.</v-card-title>

        <v-card-text>Please check your internet connection. If problem persist contact administrator.</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  flat="flat" :to="'/login'">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="pageLoading" persistent width="300">
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          {{pageLoadingMessage}}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="unVerifiedDialog" persistent max-width="290">
      <v-card dark>
        <v-card-title class="headline">Unable to verify Token</v-card-title>

        <v-card-text>Please ensure you clicked the lastest link sent to your email</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn  flat="flat" :to="'/login'">
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 
    <!-- Steps -->
   <v-stepper v-model="currentProgress">
    <v-stepper-header>
      <v-stepper-step :complete="currentProgress > 1" step="1">
        Company Details
      </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="currentProgress > 2" step="2">Company Administration</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="currentProgress > 3" step="3">Head Office Details</v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4">Setup Summary</v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-alert :value="true" v-if="message.visible" :class="{'animated slideInLeft faster': message.visible, 'animated sildeOutup': !message.visible}" type="error">
        <b>{{message.title}}</b> {{message.content}} 
      </v-alert>
      <v-stepper-content step="1">
        <v-card
          class="mb-4"
          flat
          min-height="200px"
        >
          <va-input
            v-model="setup.company.company_name"
            v-validate="'required|min:3|max:150'"
            data-vv-scope="company"
            data-vv-validate-on="blur"
            data-vv-as="company full name"
            label="Company Full Name"
            data-vv-name="name"
            :width="'450px'"
            placeholder=" "
            :error-messages="errors.collect('company.name')"
            show-clean />

           <va-input
            v-model="setup.company.company_short_name"
            v-validate="'required|min:3|max:60'"
            data-vv-scope="company"
            data-vv-validate-on="blur"
            label="Company Short Name"
            data-vv-name="short_name"
            data-vv-as="company short name"
            :width="'360px'"
            placeholder=" "
            :error-messages="errors.collect('company.short_name')"
            show-clean />
          
          <va-input
            v-model="setup.company.company_website"
            v-validate="'url:require_protocol|max:250'"
            data-vv-validate-on="blur"
            data-vv-scope="company"
            :width="'360px'"
            data-vv-name="website"
            :error-messages="errors.collect('company.website')"
            label="Company Website"
            data-vv-as="company website"
            placeholder=" "
            show-clean />
        </v-card>
        <div class="stepper-footer">
          <v-btn color="primary" @click="onNextClicked">Continue</v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card class="mb-4" flat min-height="200px">
          <h2 class="title underline">Administrator Details</h2>
          
          <va-input
            v-model="setup.user.email"
            v-validate="'required|email'" 
            data-vv-validate-on="blur"
            data-vv-scope="user"
            label="Administrator Email"
            placeholder=" "
            data-vv-name="email"
            data-vv-as="adiministrator email"
            :error-messages="errors.collect('user.email')"
            :width="'420px'" />

          <va-input
            v-model="setup.user.password"
            v-validate="'required|verify_password'"
            data-vv-scope="user"
            data-vv-name="password"
            label="Password"
            placeholder=" "
            hint="Minimum 8 characters"
            type="password"
            ref="password"
            :width="'360px'" 
            :error-messages="errors.collect('user.password')"           
            />
            
          <va-input
            v-model="setup.user.confirm_password"
            v-validate="'required|confirmed:password'"
            data-vv-scope="user"
            type="password"
            label="Confirm Password"
            placeholder=" "
            :error-messages="errors.collect('user.confirm')"
            :width="'360px'"
            data-vv-name="confirm"
            data-vv-as="confirm password"/>
        </v-card>
        
        <div class="stepper-footer">
          <v-btn flat  @click="onBackClicked">Back</v-btn>
          <v-btn color="primary" @click="onNextClicked">Continue</v-btn>
        </div>
      </v-stepper-content>

      <v-stepper-content step="3">
          <v-card class="mb-4" flat min-height="200px">
            <h2 class="title underline">Central Branch Details</h2>
            <v-container  fluid>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <va-input
                    v-model="setup.branch.branch_code"
                    v-validate="'required|min:2|max:15'"
                    data-vv-scope="branch"
                    data-vv-validate-on="blur"
                    data-vv-as="branch code"
                    :max-width="'420px'"           
                    data-vv-name="branch_code"
                    :error-messages="errors.collect('branch.branch_code')"
                    placeholder=" "
                    label="Branch Code"
                    show-clean />
              
                  <va-input
                    v-validate="'required|min:3|max:250'" 
                    v-model="setup.branch.branch_name"
                    data-vv-scope="branch"
                    data-vv-validate-on="blur"
                    data-vv-name="name"
                    data-vv-as="branch name"
                    :max-width="'420px'"
                    placeholder=" "
                    label="Branch Name"
                    :error-messages="errors.collect('branch.name')"
                    show-clean />
              
                  <va-input
                    v-validate="{ required: true, regex:/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/}"
                    v-model="setup.branch.branch_telephone"  
                    placeholder=" "
                    data-vv-scope="branch"
                    label="Contact Number"
                    data-vv-name="telephone"
                    data-vv-as="contact number"
                    :max-width="'420px'"
                    :return-masked-value="true"
                    :persistent-hint="true"
                    :hint="'Expected Format: 000 000 0000'"
                    :mask="'(###) ###-####'"
                    :error-messages="errors.collect('branch.branch_telephone')"
                    show-clean />
            
                  <va-input
                    v-validate="'required|email|max:255'" 
                    v-model="setup.branch.branch_email"
                    data-vv-scope="branch"
                    data-vv-validate-on="blur"
                    placeholder=" "
                    label="Email"
                    data-vv-name="email"
                    :max-width="'420px'"
                    :error-messages="errors.collect('branch.branch_email')"
                    show-clean />
                  
                  <v-layout row >
                      <va-select 
                        class="pr-4"
                        v-model="setup.branch.branch_currency"
                        v-validate="'required'" 
                        data-vv-scope="branch"
                        placeholder=" "
                        label="Default Currency"
                        data-vv-validate-on="blur"
                        :items="commonState.currencies"
                        :item-text="'name'"
                        :item-value="'code'"
                        :error-messages="errors.collect('branch.default_currency')"
                        data-vv-name="default_currency"/>
                    
                      <va-select 
                        v-model="setup.branch.branch_timezone"
                        v-validate="'required'" 
                        data-vv-scope="branch"
                        placeholder=" "
                        label="Default Timezone"
                        :error-messages="errors.collect('branch.timezone')"
                        data-vv-validate-on="blur"
                        :items="getTimezonesAndOffsets()"
                        :item-text="'format'"
                        :item-value="'zone'"
                        data-vv-name="timezone"/>
                  </v-layout>
                </v-flex>

                <v-flex xs12 md6>
                  <va-select 
                    v-model="setup.branch.branch_country"
                    v-validate="'required'" 
                    data-vv-scope="branch"
                    placeholder=" "
                    label="Country"
                    :max-width="'420px'"
                    @change="onCountryChanged($event)"
                    
                    :error-messages="errors.collect('branch.country')"
                    :items="commonState.countries"
                    :item-text="'name'"
                    :item-value="'name'"
                    data-vv-name="country"/>
                
                  <va-select 
                      :disabled="!setup.branch.branch_country"
                      v-model="setup.branch.branch_province"
                      v-validate="'required'"
                      placeholder=" "
                      label="Province/State"
                      data-vv-scope="branch"
                      :error-messages="errors.collect('branch.province')"
                      :max-width="'420px'"
                      :items="provinces"
                      data-vv-name="province"/>

                    <va-input
                      v-model="setup.branch.branch_city"
                      v-validate="'required|min:3|max:50'"
                      data-vv-scope="branch"
                      :error-messages="errors.collect('branch.city')"
                      placeholder=" "
                      label="City/Town"
                      :max-width="'420px'"  
                      data-vv-validate-on="blur"         
                      data-vv-name="city"
                      show-clean />
                
                    <va-input
                      v-model="setup.branch.branch_street"
                      v-validate="'required|min:3|max:100'"
                      :error-messages="errors.collect('branch.street')"
                      data-vv-scope="branch"
                      placeholder=" "
                      label="Street"
                      :max-width="'420px'"   
                      data-vv-validate-on="blur"        
                      data-vv-name="street"
                      show-clean />
                  </v-flex> 
              </v-layout>
            </v-container>
          </v-card>          
          <div class="stepper-footer">
            <v-btn flat  @click="onBackClicked">Back</v-btn>
            <v-btn color="primary" @click="onNextClicked">Continue</v-btn>
          </div>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-card flat class="mb-4">
            
            <v-container>

              <h2 class="title underline">Setup Details</h2>
              <p>Please check to ensure that the information entered is correct.</p>
              <v-divider class="ma-4"></v-divider>
              <v-layout wrap row>
                <v-flex xs12 md6 lg6>
                  <h3>Company Details</h3> 
                  <ul>
                    <li><b>Company Name:</b> {{setup.company.company_name}}</li>
                    <li><b>Company Website:</b> {{setup.company.company_website || 'N/A'}}</li>
                    <li><b>Company Administrator Email:</b> {{setup.user.email }}</li>
                  </ul>
                </v-flex>
                <v-flex xs12 md6 lg6>
                  <h3>Central Branch Details</h3> 
                  <ul>
                    <li><b>Branch Name:</b> {{setup.company.company_name}}</li>
                    <li><b>Office Email:</b> {{setup.branch.branch_email}}</li>
                    <li><b>Office Telephone:</b> {{setup.branch.branch_telephone }}</li>
                    <li><b>Currency:</b> {{setup.branch.branch_currency}}</li>
                    <li><b>Address:</b> {{setup.branch.branch_street+', '+setup.branch.branch_city+', '+setup.branch.branch_province+', '+setup.branch.branch_country}}</li>
                    <li><b>Timezone:</b> {{setup.branch.branch_timezone}}</li>
                  </ul>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <div class="stepper-footer">
            <v-btn flat  @click="onBackClicked">Back</v-btn>
            <v-btn color="primary" @click="onNextClicked">Save</v-btn>
          </div>
        </v-stepper-content>
        </v-stepper-items>
    </v-stepper>
  </div>
</template>

<style scoped>
#setup {
  padding:40px}
ul{
  list-style: none;
  margin-left: -20px;}
  ul b {
    color: #444;}
.stepper-footer {
  float: right}
</style>

<script>
import { mapState, mapGetters } from 'vuex'
import CompanyService from '@/services/company-service.js'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import validations from '@/common/validator'
import { timeout } from 'q';
export default {
  components: {
    VaInput,
    VaSelect
  },
  data() {
    return {
      setup: {
        user: {
          email: '',
          password: '',
          confirm_password: '',
        },
        company: {
          company_name: '',
          company_short_name: '',
          company_website: ''
        },
        branch: {
          branch_name: '',
          branch_code: '',
          branch_telephone: '',
          branch_email:'',
          branch_currency:'',
          branch_timezone:'',
          branch_street: '',
          branch_city: '',
          branch_province: '',
          branch_country: '',
          branch_risk_factor_group: '-'
        }
      },
      message: {
        visible: false,
        title: '',
        content: '',
        type:''
      },
      loading: false,
      pageLoading: false,
      pageLoadingMessage: '',
      isVerified: false,
      unVerifiedDialog: false,
      isUnexpectedError: false,
      saved: false,
      isEventActive: false,
      provinces: [],
      currentProgress: 1,
      timeout: null,
    }
  },

  created() {
    this.init()
  },

  beforeDestroy() {
    clearTimeout(timeout)
  },

  computed: {
    ...mapState([
      'appState',
      'commonState'
    ])
  },

  methods: {
    ...mapGetters([
      'getTimezonesAndOffsets',
    ]),

    async init() {
      try {
        this.$validator.extend(validations.verifyPassword.name, validations.verifyPassword.validator)
        await this.verifyToken()
      } catch (e) {
        this.isUnexpectedError = true
      }
    }, 

    async onNextClicked() {
      this.message.visible = false
      if(this.isEventActive) 
        return

      this.isEventActive = true
      
      await this.checkFormValidity()

      if(this.errors.any()) {
        this.message.title = 'Invalid!'
        this.message.content = 'Please ensure that each section is filled out correctly to proceed.'
        this.message.type = 'danger'
        this.message.visible = true
      } 
      else if(this.currentProgress == 4) {
        this.submit()
      } else {
        this.currentProgress++
      }

      this.isEventActive = false
      return
    },
    
    async onBackClicked() {
      await this.checkFormValidity()

      if(this.errors.any()) {
        this.message.title = 'Invalid!'
        this.message.content = 'Please ensure that each section is filled out correctly to proceed.'
        this.message.type = 'danger'
        this.message.visible = true
        return
      } 

      if(this.currentProgress !== 1) {
        this.currentProgress--
      }
    },

    onCountryChanged(ev) {
      this.provinces = []
      this.setup.branch.branch_country = ev
      for(let key in this.commonState.countries) {
        if(this.commonState.countries[key].name === ev) {
          let divisions = []
          Object.keys(this.commonState.countries[key].divisions).forEach((keyx)=>{
            divisions.push(this.commonState.countries[key].divisions[keyx])
          })
          this.provinces =  divisions
          break
        }
      }
    },

    checkFormValidity() {
      let scope = 'company'
      switch(this.currentProgress) {
        case 2:
          scope = 'user'
          break
        case 3:
          scope = 'branch'
      }
      
      return this.$validator.validateAll(scope)
    },

    async submit() {
      if(!this.isVerified) {
        this.unVerifiedDialog = true
        return
      }

      this.message.visible = false
      this.loading = true
      try{
        await CompanyService.setup(this.setup)
        this.saved = true
        this.pageLoadingMessage = "Saved successfully... Please wait redirecting to login screen"
        this.pageLoading = true
        this.timeout = setTimeout(()=> this.$router.push('/login'), 5000)
      } catch(e) {
        console.log('Error: ',e)
        this.message.title = 'An unexpected error has occured.'
        this.message.content = 'Please check your internet connection. If problem persist contact administrator.'
        this.message.type = 'danger'
        this.message.visible = true
      } finally {
        this.loading = false
      }
    },

    async verifyToken() {
      let token = this.$route.params.token
      this.pageLoadingMessage = "Verifying Token Please wait..."
      this.pageLoading = true
      try {
        let result = await CompanyService.verifySetupToken(token)
        this.isVerified = result.data
        if(!this.isVerified) {
          this.unVerifiedDialog = true
          return
        } 
      
      } catch (e) {
        this.isUnexpectedError = true
        this.timeout = setTimeout(()=> this.$router.push('/login'), 5000)
      } finally {
        this.pageLoading = false
      }
    },

  },

  
  
}
</script>
