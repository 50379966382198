<template>
  <div id="customer-container">
    <v-container fluid>
      <router-view></router-view>
    </v-container>
  </div>
</template>

<style>

</style>

<script>
import {mapMutations} from 'vuex'
export default {
  created() {
   this.init()
   this.TOGGLE_SIDEBAR_VISIBILITY(true)
  },

  data() {
    return {
      routes: [        
        { title: 'Dashboard', route: '/app/customer-management/dashboard', icon: 'fa-dashboard-o', iconSize: '12px'},
        { title: 'Customers', route: '/app/customer-management/customers', icon: 'fa-file', iconSize: '12px'},
      ]
    }
  },

  methods: {
    ...mapMutations([
      'SET_SIDEBAR_ROUTES',
      'TOGGLE_SIDEBAR_VISIBILITY'
    ]),
    init() {
      this.SET_SIDEBAR_ROUTES(this.routes)
    }
  },


}
</script>
