<template>
  <v-container>
    <div>
      <!-- ADD FORM DATA DIALOG -->
      <!-- <v-dialog v-model="dialog" persistent> -->
      <v-card flat>
        <v-card-title class="headline white--text primary"
          >Add Form Data
          <v-spacer></v-spacer>
          <!-- <v-btn dark color="primary" depressed @click="dialog = false"
              >Exit</v-btn
            > -->
          <v-btn dark color="primary" depressed @click="formData = {}"
            >Clear</v-btn
          >
          <v-btn dark color="primary" depressed @click="dialog = false"
            >Add</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-layout row wrap mx-1>
              <v-flex pr-2 xs4>
                <va-input
                  label="ACCOUNT NO."
                  placeholder="Enter Account Number"
                  v-model="formData.account_number"
                ></va-input>
              </v-flex>

              <v-flex xs4>
                <v-menu
                  ref="menu_date"
                  v-model="menu_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formData.date"
                      label="DATE"
                      readonly
                      v-on="on"
                      placeholder="Select date"
                      box
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="formData.date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="2000-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex pl-2 xs4>
                <v-menu
                  ref="menu_membership"
                  v-model="menu_membership"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formData.membership_date"
                      label="DATE OF MEMBERSHIP"
                      readonly
                      v-on="on"
                      placeholder="Select date of membership"
                      box
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="formData.membership_date"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="2000-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex pr-2 xs4>
                <va-input
                  label="NAME"
                  placeholder="Enter Name"
                  v-model="formData.name"
                ></va-input>
              </v-flex>

              <v-flex xs4>
                <v-menu
                  ref="menu_dob"
                  v-model="menu_dob"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="formData.dob"
                      label="DATE OF Birth"
                      readonly
                      v-on="on"
                      placeholder="Select date of birth"
                      box
                      clearable
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    ref="picker"
                    v-model="formData.dob"
                    :max="new Date().toISOString().substr(0, 10)"
                    min="1900-01-01"
                    @change="save"
                  ></v-date-picker>
                </v-menu>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="TELEPHONE"
                  placeholder="Enter Telephone"
                  v-model="formData.telephone"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="EMAIL"
                  placeholder="Enter Email"
                  v-model="formData.email"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="HOME ADDRESS"
                  placeholder="Enter Home Address"
                  v-model="formData.home_address"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="HOME ADDRESS CONT'D"
                  placeholder="Enter Home Address cont'd"
                  v-model="formData.home_address_continued"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs6>
                <va-input
                  label="PREVIOUS ADDRESS"
                  placeholder="Enter Previuos Address"
                  v-model="formData.previous_address"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs6>
                <va-input
                  v-money="money"
                  label="CASH REQUIRED (figures)"
                  placeholder="Enter Cash required in figures"
                  v-model="formData.cash_required_figures"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs12>
                <va-input
                  label="CASH REQUIRED (word)"
                  placeholder="Enter Cash required in words"
                  v-model="formData.cash_required_words"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs12>
                <va-input
                  label="LOAN PURPOSE"
                  placeholder="Enter Loan Purpose"
                  v-model="formData.loan_purpose"
                ></va-input>
              </v-flex>

              <v-flex xs12 class="pa-3">
                <v-divider color="black"></v-divider
              ></v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  v-money="money"
                  label="SHARES"
                  placeholder="Enter Shares"
                  v-model="formData.shares"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs4>
                <va-input
                  v-money="money"
                  label="DEPOSITS"
                  placeholder="Enter Deposits"
                  v-model="formData.deposits"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs4>
                <va-input
                  v-money="money"
                  label="OTHER"
                  placeholder="Enter Other Security"
                  v-model="formData.other_security"
                ></va-input>
              </v-flex>

              <v-flex xs12>INCOME INFORMATION<br /></v-flex>

              <v-flex pl-2 xs6>
                <va-input
                  label="EMPLOYED BY"
                  placeholder="Enter Employer Name"
                  v-model="formData.employer_name"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs6>
                <va-input
                  label="ADDRESS"
                  placeholder="Enter Employer Address"
                  v-model="formData.employer_address"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="POSITION"
                  placeholder="Enter Position"
                  v-model="formData.position"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="HOW LONG"
                  placeholder="Enter How Long Position Held For"
                  v-model="formData.position_period"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="PHONE"
                  placeholder="Enter Employer Phone"
                  v-model="formData.employer_phone"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs6>
                <va-input
                  v-money="money"
                  label="SALARY (Monthly/Weekly)"
                  placeholder="Enter Monthly/Weekly Salary"
                  v-model="formData.salary"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs6>
                <va-input
                  label="OTHER INCOME & SOURCE"
                  placeholder="Enter Other Income & Source"
                  v-model="formData.other_income_source"
                ></va-input>
              </v-flex>

              <v-flex xs12 class="pa-3">
                <v-divider color="black"></v-divider
              ></v-flex>

              <v-flex sm12 xs12 pa-1 md12>
                <p class="bold" style="font-size: 12px">
                  REAL ESTATE INFORMATION (If Any)
                  <v-btn small icon color="success" @click="estateDialog = true"
                    ><v-icon>add</v-icon></v-btn
                  >
                </p>
              </v-flex>

              <table style="width: 100%">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Location of Property</th>
                      <th class="text-left">Valuation ($)</th>
                      <th class="text-left">Valuated By Whom</th>
                      <th class="text-left">Amount of Mortgage ($)</th>
                      <th class="text-left">Monthly Payment ($)</th>
                      <th class="text-left">Mortgage Holder</th>
                      <th class="text-left">Monthly Rental ($)</th>
                      <th class="text-left">Remove Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in estates" :key="item.ID">
                      <td>{{ item.property_location }}</td>
                      <td>{{ item.property_valuation }}</td>
                      <td>{{ item.property_valuated_by }}</td>
                      <td>{{ item.property_mortgage }}</td>
                      <td>{{ item.monthly_mortgage_payment }}</td>
                      <td>{{ item.mortgage_holder }}</td>
                      <td>{{ item.monthly_rental }}</td>
                      <td>
                        <v-icon color="error" @click="removeEstate(item)"
                          >remove</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <v-flex sm12 xs12 pa-1 md12 pt-4>
                <p class="bold" style="font-size: 12px">
                  AUTOMOBILE INFORMATION (If Any)
                  <v-btn icon small color="success" @click="autoDialog = true"
                    ><v-icon>add</v-icon></v-btn
                  >
                </p>
              </v-flex>

              <table style="width: 100%">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Make and Model of Car</th>
                      <th class="text-left">Estimated Value ($)</th>
                      <th class="text-left">Remove Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in automobiles" :key="item.ID">
                      <td>{{ item.make_and_model }}</td>
                      <td>{{ item.estimated_value }}</td>
                      <td>
                        <v-icon color="error" @click="removeAutomobile(item)"
                          >remove</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <v-divider color="black" style="margin: 15px"></v-divider>
              <v-flex pl-2 xs12>
                <va-input
                  label="NUMBER OF DEPENDENTS (state ages)"
                  placeholder="Enter number of dependents"
                  v-model="formData.dependents"
                ></va-input>
              </v-flex>

              <v-flex pl-2 xs4>
                <va-input
                  label="NAME OF WIFE/HUSBAND"
                  placeholder="Enter name of spouse"
                  v-model="formData.spouse"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs4>
                <va-input
                  label="EMPLOYED BY"
                  placeholder="Enter spouse's employer"
                  v-model="formData.spouse_employer"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs4>
                <va-input
                  label="HOW LONG"
                  placeholder="Enter how long"
                  v-model="formData.spouse_position_period"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs12>
                <va-input
                  label="ADDRESS"
                  placeholder="Enter spouse's employer address"
                  v-model="formData.spouse_employer_address"
                ></va-input>
              </v-flex>
              <v-flex pl-2 xs12>
                <va-input
                  label="SALARY (Monthly/Weekly)"
                  placeholder="Enter spouse's salary"
                  v-model="formData.spouse_salary"
                ></va-input>
              </v-flex>

              <v-flex sm12 xs12 pa-1 md12>
                <p class="bold" style="font-size: 12px">
                  RELATIVES<v-btn
                    small
                    icon
                    color="success"
                    @click="relativeDialog = true"
                    ><v-icon>add</v-icon></v-btn
                  >
                </p>
              </v-flex>

              <table style="width: 100%">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Telephone</th>
                      <th class="text-left">Home Address</th>
                      <th class="text-left">Employed By</th>
                      <th class="text-left">Employer Address</th>
                      <th class="text-left">Remove Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in relatives" :key="item.ID">
                      <td>{{ item.relative_name }}</td>
                      <td>{{ item.relative_telephone }}</td>
                      <td>{{ item.relative_home_address }}</td>
                      <td>{{ item.relative_employer }}</td>
                      <td>{{ item.relative_employer_address }}</td>
                      <td>
                        <v-icon color="error" @click="removeRelative(item)"
                          >remove</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <v-flex sm12 xs12 pa-1 md12 pt-4>
                <p class="bold" style="font-size: 12px">
                  REFERENCES
                  <v-btn
                    small
                    icon
                    color="success"
                    @click="referenceDialog = true"
                    ><v-icon>add</v-icon></v-btn
                  >
                </p>
              </v-flex>

              <table style="width: 100%">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Name</th>
                      <th class="text-left">Telephone</th>
                      <th class="text-left">Home Address</th>
                      <th class="text-left">Employed By</th>
                      <th class="text-left">Employer Address</th>
                      <th class="text-left">Remove Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in references" :key="item.ID">
                      <td>{{ item.reference_name }}</td>
                      <td>{{ item.refernce_telephone }}</td>
                      <td>{{ item.reference_home_address }}</td>
                      <td>{{ item.reference_employer }}</td>
                      <td>{{ item.reference_employer_address }}</td>
                      <td>
                        <v-icon color="error" @click="removeReference(item)"
                          >remove</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>

              <v-flex sm12 xs12 pa-1 md12 pt-4>
                <p class="bold" style="font-size: 12px">
                  CREDIT OBLIGATIONS INCLUDING HIRE PURCHASE (List All Debts)
                  <v-btn
                    small
                    icon
                    color="success"
                    @click="creditorDialog = true"
                    ><v-icon>add</v-icon></v-btn
                  >
                </p>
              </v-flex>

              <table style="width: 100%">
                <template>
                  <thead>
                    <tr>
                      <th class="text-left">Name of Creditor</th>
                      <th class="text-left">Address</th>
                      <th class="text-left">Monthly Payment ($)</th>
                      <th class="text-left">Balance Owing ($)</th>
                      <th class="text-left">Remove Item</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in creditors" :key="item.ID">
                      <td>{{ item.creditor }}</td>
                      <td>{{ item.creditor_address }}</td>
                      <td>{{ item.monthly_payment }}</td>
                      <td>{{ item.balance_owing }}</td>
                      <td>
                        <v-icon color="error" @click="removeCreditor(item)"
                          >remove</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </table>
            </v-layout>
          </v-form>
        </v-card-text>
        <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="formData = {}"
              >Clear</v-btn
            >
            <v-btn color="blue darken-1" flat @click="dialog = false"
              >Add</v-btn
            >
          </v-card-actions> -->
      </v-card>
      <!-- </v-dialog> -->
      <!-- ADD ESTATE INFORMATION DIALOG -->
      <v-dialog v-model="estateDialog" width="80%" persistent scrollable>
        <v-card>
          <v-card-title class="primary white--text">
            <v-btn icon dark @click="estateDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Real Estate Details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text style="padding: 30px">
            <v-form>
              <v-layout row wrap>
                <v-flex xs6 pl-2>
                  <va-input
                    label="LOCATION"
                    placeholder="Enter location"
                    v-model="estateDetails.property_location"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="VALUATION"
                    v-money="money"
                    v-model="estateDetails.property_valuation"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="BY WHOM"
                    placeholder="Enter valuator name"
                    v-model="estateDetails.property_valuated_by"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="MORTGAGE"
                    v-money="money"
                    v-model="estateDetails.property_mortgage"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="MONTHLY PAYMENT"
                    v-money="money"
                    v-model="estateDetails.monthly_mortgage_payment"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="MORTGAGE HOLDER"
                    placeholder="Enter mortgage holder"
                    v-model="estateDetails.mortgage_holder"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="MONTHLY RENTAL"
                    v-money="money"
                    v-model="estateDetails.monthly_rental"
                  >
                  </va-input>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addEstate()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="estateDetails = {}">Clear</v-btn>
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- ADD AUTOMOBILE INFORMATION DIALOG -->
      <v-dialog v-model="autoDialog" width="50%" persistent scrollable>
        <v-card>
          <v-card-title class="primary white--text">
            <v-btn icon dark @click="autoDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Automobile Details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text style="padding: 30px">
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 pl-2>
                  <va-input
                    label="MAKE AND MODEL"
                    placeholder="Enter make and model"
                    v-model="autoDetails.make_and_model"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs12 pl-2>
                  <va-input
                    label="ESTIMATED VALUE"
                    v-money="money"
                    v-model="autoDetails.estimated_value"
                  >
                  </va-input>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addAutomobile()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="autoDetails = {}">Clear</v-btn>
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ADD RELATIVE INFORMATION DIALOG -->
      <v-dialog v-model="relativeDialog" width="80%" persistent scrollable>
        <v-card>
          <v-card-title class="primary white--text">
            <v-btn icon dark @click="relativeDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Relative Details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text style="padding: 30px">
            <v-form>
              <v-layout row wrap>
                <v-flex xs6 pl-2>
                  <va-input
                    label="NAME"
                    placeholder="Enter relative's name"
                    v-model="relativeDetails.relative_name"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="TELEPHONE"
                    placeholder="Enter relative's telephone"
                    v-model="relativeDetails.relative_telephone"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs12 pl-2>
                  <va-input
                    label="HOME ADDRESS"
                    placeholder="Enter relative's home address"
                    v-model="relativeDetails.relative_home_address"
                  >
                  </va-input>
                </v-flex>

                <v-flex xs6 pl-2>
                  <va-input
                    label="EMPLOYED BY"
                    placeholder="Enter relative's employer"
                    v-model="relativeDetails.relative_employer"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="EMPLOYER'S ADDRESS"
                    placeholder="Enter relative's employer address"
                    v-model="relativeDetails.relative_employer_address"
                  >
                  </va-input>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addRelative()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="relativeDetails = {}">Clear</v-btn>
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ADD REFERENCE INFORMATION DIALOG -->
      <v-dialog v-model="referenceDialog" width="80%" persistent scrollable>
        <v-card>
          <v-card-title class="primary white--text">
            <v-btn icon dark @click="referenceDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Reference Details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text style="padding: 30px">
            <v-form>
              <v-layout row wrap>
                <v-flex xs6 pl-2>
                  <va-input
                    label="NAME"
                    placeholder="Enter reference's name"
                    v-model="referenceDetails.reference_name"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="TELEPHONE"
                    placeholder="Enter reference's telephone"
                    v-model="referenceDetails.reference_telephone"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs12 pl-2>
                  <va-input
                    label="HOME ADDRESS"
                    placeholder="Enter reference's home address"
                    v-model="referenceDetails.reference_home_address"
                  >
                  </va-input>
                </v-flex>

                <v-flex xs6 pl-2>
                  <va-input
                    label="EMPLOYED BY"
                    placeholder="Enter reference's employer"
                    v-model="referenceDetails.reference_employer"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="EMPLOYER'S ADDRESS"
                    placeholder="Enter reference's employer address"
                    v-model="referenceDetails.reference_employer_address"
                  >
                  </va-input>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addReference()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="referenceDetails = {}"
                  >Clear</v-btn
                >
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- ADD CREDITORS INFORMATION DIALOG -->
      <v-dialog v-model="creditorDialog" width="80%" persistent scrollable>
        <v-card>
          <v-card-title class="primary white--text">
            <v-btn icon dark @click="creditorDialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-toolbar-title>Add Creditor Details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-card-title>

          <v-card-text style="padding: 30px">
            <v-form>
              <v-layout row wrap>
                <v-flex xs12 pl-2>
                  <va-input
                    label="NAME"
                    placeholder="Enter creditor's name"
                    v-model="creditorDetails.creditor"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs12 pl-2>
                  <va-input
                    label="ADDRESS"
                    placeholder="Enter creditor's address"
                    v-model="creditorDetails.creditor_address"
                  >
                  </va-input>
                </v-flex>

                <v-flex xs6 pl-2>
                  <va-input
                    label="MONTHLY PAYMENT"
                    v-money="money"
                    v-model="creditorDetails.monthly_payment"
                  >
                  </va-input>
                </v-flex>
                <v-flex xs6 pl-2>
                  <va-input
                    label="BALANCE OWING"
                    v-money="money"
                    v-model="creditorDetails.balance_owing"
                  >
                  </va-input>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="addCreditor()">Save</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="creditorDetails = {}">Clear</v-btn>
                <v-spacer></v-spacer>
              </v-layout>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- <v-btn @click="dialog = true" color="blue darken-2" dark bottom right>
        Add Form Data
      </v-btn> -->
    </div>
    <!-- TEMPLATE -->
    <v-dialog>
      <div id="body">
        <v-card elevation="5" raised>
          <v-card-title class="justify-center pt-4" style="font-size: 16px">{{
            company.company_name
          }}</v-card-title>
          <h4 class="text-xs-center bold pb-5" style="font-size: 14px">
            APPLICATION FOR LOAN
          </h4>
          <div class="grid-container">
            <div class="grid-item">
              Account No
              <span class="line pl-4">{{ formData.account_number }}</span>
            </div>
            <div class="grid-item">
              Date <span class="line pl-4">{{ formData.date }}</span>
              <p
                style="
                  font-size: 8px !important;
                  padding-left: 25%;
                  padding-top: 5px;
                "
              >
                YYYY/MM/DD
              </p>
            </div>
            <div class="grid-item">
              Date of Membership
              <span class="line pl-4">{{ formData.membership_date }}</span>
              <p
                style="
                  font-size: 8px !important;
                  padding-left: 50%;
                  padding-top: 5px;
                "
              >
                YYYY/MM/DD
              </p>
            </div>
          </div>
          <div class="grid-container-3" style="padding-top: 0">
            <div class="grid-item">
              Name
              <span class="longline pl-4">{{ formData.name }}</span>
            </div>
          </div>
          <div class="grid-container-4">
            <div class="grid-item">
              Date of Birth
              <span class="line pl-4">{{ formData.dob }}</span>
              <span
                style="
                  font-size: 8px !important;
                  padding-left: 30%;
                  padding-top: 5px;
                "
                >YYYY/MM/DD</span
              >
            </div>
            <div class="grid-item">
              Home Address
              <span class="line pl-4">{{ formData.home_address }}</span>
            </div>
            <div class="grid-item">
              Telephone
              <span class="line pl-4">{{ formData.telephone }}</span>
            </div>
            <div class="grid-item">
              Home Address cont'd
              <span class="text-xs-center shorterline">{{
                formData.home_address_continued
              }}</span>
            </div>
            <div class="grid-item">
              Email address
              <span class="text-xs-center shorterline">{{
                formData.email
              }}</span>
            </div>
            <div class="grid-item">
              Previous Address
              <span class="text-xs-center shorterline">{{
                formData.previous_address
              }}</span>
            </div>
          </div>
          <div class="grid-container-3">
            <div class="grid-item">
              Cash Required (Words and Figures)
              <span class="line pl-4">{{ formData.cash_required_words }}</span>
            </div>
          </div>
          <div class="grid-container-3">
            <div class="grid-item">
              $
              <span class="longline pl-4">{{
                formData.cash_required_figures
              }}</span>
            </div>
          </div>
          <div class="grid-container-3">
            <div class="grid-item">
              I desire this loan for the following provident or provided
              purposes
              <span class="longline pl-4">{{ formData.loan_purpose }}</span>
            </div>
          </div>
          <v-flex xs12 class="pa-3">
            <v-divider color="black"></v-divider>
          </v-flex>
          <v-layout row wrap>
            <div class="py-4">
              <v-layout row wrap style="font-size: 12px">
                <v-flex xs12>I offer as security-:</v-flex>
                <v-flex xs4 pa-1
                  >Shares $:<span class="shortline pl-4">{{
                    formData.shares
                  }}</span></v-flex
                >
                <v-flex xs4 pa-1
                  >Deposits $:<span class="shortline pl-4">{{
                    formData.deposits
                  }}</span></v-flex
                >
                <v-flex xs4 pa-1
                  >Other:<span class="shortline pl-4">{{
                    formData.fixed_deposit
                  }}</span></v-flex
                >
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      INCOME INFORMATION
                    </p></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Employed By
                    <span class="longline pl-4">{{
                      formData.employer_name
                    }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Address
                    <span class="longline pl-4">{{
                      formData.employer_address
                    }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Position
                    <span class="longline pl-4">{{
                      formData.position
                    }}</span></v-flex
                  >
                  <v-layout row>
                    <v-flex sm12 xs6 pa-1 md6>
                      How Long
                      <span class="line pl-4">{{
                        formData.position_period
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs6 pa-1 md6>
                      Ph
                      <span class="line pl-4">{{
                        formData.employer_phone
                      }}</span></v-flex
                    >
                  </v-layout>
                  <v-flex sm12 xs12 pa-1 md12>
                    Salary - Monthly/Weekly $
                    <span class="line pl-4">{{ formData.salary }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Other Income & Source
                    <span class="line pl-4">{{
                      formData.other_income_source
                    }}</span></v-flex
                  >
                </v-flex>
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      REAL ESTATE INFORMATION (If Any)
                    </p></v-flex
                  >
                  <div v-for="item in estates" :key="item.ID">
                    <v-flex sm12 xs12 pa-1 md12>
                      Location of Property
                      <span class="longline pl-4">{{
                        item.property_location
                      }}</span></v-flex
                    >
                    <v-layout row>
                      <v-flex sm12 xs6 pa-1 md6>
                        Valuation $
                        <span class="line pl-4">{{
                          item.property_valution
                        }}</span></v-flex
                      >
                      <v-flex sm12 xs6 pa-1 md6>
                        By Whom
                        <span class="line pl-4">{{
                          item.property_valuated_by
                        }}</span></v-flex
                      >
                    </v-layout>
                    <v-flex sm12 xs12 pa-1 md12>
                      Amount of Mortgage $
                      <span class="longline pl-4">{{
                        item.property_mortgage
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Monthly Payment $
                      <span class="longline pl-4">{{
                        item.monthly_mortgage_payment
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Mortgage Holder
                      <span class="longline pl-4">{{
                        item.mortgage_holder
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Monthly Rental
                      <span class="longline pl-4">{{
                        item.monthly_rental
                      }}</span></v-flex
                    >
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      AUTOMOBILE INFORMATION (If Any)
                    </p></v-flex
                  >
                  <div v-for="item in automobiles" :key="item.ID">
                    <v-flex sm12 xs12 pa-1 md12>
                      Make & Model of Car
                      <span class="longline pl-4">{{
                        item.make_and_model
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Estimated Value $
                      <span class="longline pl-4">{{
                        item.estimated_value
                      }}</span></v-flex
                    >
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      ADDITIONAL INFORMATION
                    </p></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Number of Dependents (state ages)
                    <span class="line pl-4">{{
                      formData.dependents
                    }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Name of Wife/Husband
                    <span class="line pl-4">{{ formData.spouse }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Employed By
                    <span class="longline pl-4">{{
                      formData.spouse_employer
                    }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    How Long
                    <span class="line pl-4">{{
                      formData.spouse_position_period
                    }}</span></v-flex
                  >

                  <v-flex sm12 xs12 pa-1 md12>
                    Address
                    <span class="longline pl-4">{{
                      formData.spouse_employer_address
                    }}</span></v-flex
                  >
                  <v-flex sm12 xs12 pa-1 md12>
                    Salary - Monthly/Weekly $
                    <span class="line pl-4">{{
                      formData.spouse_salary
                    }}</span></v-flex
                  >
                </v-flex>
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      RELATIVES (State Relationship)
                    </p></v-flex
                  >
                  <div v-for="item in relatives" :key="item.ID">
                    <v-flex sm12 xs12 pa-1 md12>
                      Name
                      <span class="longline pl-4">{{
                        item.relative_name
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Telephone
                      <span class="longline pl-4">{{
                        item.relative_telephone
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Home Address
                      <span class="longline pl-4">{{
                        item.relative_home_address
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Employed By
                      <span class="longline pl-4">{{
                        item.relative_employer
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Address
                      <span class="longline pl-4">{{
                        item.relative_employer_address
                      }}</span></v-flex
                    >
                  </div>
                </v-flex>
                <v-flex xs12>
                  <v-flex sm12 xs12 pa-1 md12>
                    <p class="bold" style="font-size: 12px">
                      REFERENCES
                    </p></v-flex
                  >
                  <div v-for="item in references" :key="item.ID">
                    <v-flex sm12 xs12 pa-1 md12>
                      Name
                      <span class="longline pl-4">{{
                        item.reference_name
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Telephone
                      <span class="longline pl-4">{{
                        item.reference_telephone
                      }}</span></v-flex
                    >

                    <v-flex sm12 xs12 pa-1 md12>
                      Home Address
                      <span class="longline pl-4">{{
                        item.reference_home_address
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Employed By
                      <span class="longline pl-4">{{
                        item.reference_employer
                      }}</span></v-flex
                    >
                    <v-flex sm12 xs12 pa-1 md12>
                      Address
                      <span class="longline pl-4">{{
                        item.reference_employer_address
                      }}</span></v-flex
                    >
                  </div>
                </v-flex>

                <p class="bold" style="font-size: 12px">
                  CREDIT OBLIGATIONS INCLUDING HIRE PURCHASE (List All Debts)
                </p>
                <v-flex xs12>
                  <table style="width: 95%">
                    <thead>
                      <tr>
                        <th class="text-xs-center">Name of Creditor(s)</th>
                        <th class="text-xs-center">Address</th>
                        <th class="text-xs-center">Monthly Payment ($)</th>
                        <th class="text-xs-center">Balance Owing ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in creditors" :key="item.ID">
                        <td>{{ item.creditor }}</td>
                        <td>{{ item.creditor_address }}</td>
                        <td>{{ item.monthly_payment }}</td>
                        <td>{{ item.balance_owing }}</td>
                      </tr>
                    </tbody>
                  </table>
                </v-flex>
              </v-layout>
            </div>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <span class="line"></span>
              <p class="font-weight-bold mb-4">Name of Member</p>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <span class="line"></span>
              <p class="font-weight-bold">Signature of Member</p>
            </v-flex>
          </v-layout>

          <v-layout row wrap>
            <v-flex xs12 sm12 md6>
              <span class="line"></span>
              <p class="font-weight-bold mb-4">Name of Witness</p>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <span class="line"></span>
              <p class="font-weight-bold">Signature of Witness</p>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-dialog>
  </v-container>
</template>
<script>
import companyService from "@/services/company-service";
import VaInput from "../../../../components/form-components/VaInput.vue";

export default {
  components: { VaInput },
  data() {
    return {
      formData: {},
      estates: [],
      estateDetails: {},
      estateDialog: false,
      automobiles: [],
      autoDetails: {},
      autoDialog: false,
      creditors: [],
      creditorDetails: {},
      creditorDialog: false,
      references: [],
      referenceDetails: {},
      referenceDialog: false,
      relatives: [],
      relativeDetails: {},
      relativeDialog: false,
      company: {},
      company_logo: "",
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        precision: 2,
        masked: false,
      },
      dialog: false,
      menu_date: false,
      menu_membership: false,
      menu_dob: false,
    };
  },

  methods: {
    addEstate() {
      this.estateDetails.ID = this.estates.length;
      this.estates.push(this.estateDetails);
      this.estateDetails = {};
      this.estateDetails.property_valuation = 0;
      this.estateDialog = false;
    },
    removeEstate(estateDetails) {
      this.estates.splice(estateDetails.ID, 1);
    },
    addAutomobile() {
      this.autoDetails.ID = this.automobiles.length;
      this.automobiles.push(this.autoDetails);
      this.autoDetails = {};
      this.autoDialog = false;
    },
    removeAutomobile(autoDetails) {
      this.automobiles.splice(autoDetails.ID, 1);
    },
    addRelative() {
      this.relativeDetails.ID = this.relatives.length;
      this.relatives.push(this.relativeDetails);
      this.relativeDetails = {};
      this.relativeDialog = false;
    },
    removeRelative(relativeDetails) {
      this.relatives.splice(relativeDetails.ID, 1);
    },
    addReference() {
      this.referenceDetails.ID = this.references.length;
      this.references.push(this.referenceDetails);
      this.referenceDetails = {};
      this.referenceDialog = false;
    },
    removeReference(referenceDetails) {
      this.references.splice(referenceDetails.ID, 1);
    },
    addCreditor() {
      this.creditorDetails.ID = this.creditors.length;
      this.creditors.push(this.creditorDetails);
      this.creditorDetails = {};
      this.creditorDialog = false;
    },
    removeCreditor(creditorDetails) {
      this.creditors.splice(creditorDetails.ID, 1);
    },
    async getLogo() {
      try {
        let result = await companyService.find();
        let logo_result = await fetch(result.data.logo);

        if (Object.keys(result.data).length > 0) {
          this.company = result.data;
          this.company_logo =
            logo_result.status == 200 ? this.company.logo : null;
        } else {
          this.company = {};
          this.company_logo = null;
        }
      } catch (error) {
        try {
          const err = await error;
          const msg = err.response.data.error;
          this.$toast.error(`${msg}`, "", {
            position: "topCenter",
          });
        } catch (e) {
          this.$toast.error(
            "Network Error. Please check your internet connection",
            "",
            {
              position: "topCenter",
            }
          );
        }
      }
    },
    save(date) {
      this.$refs.menu_date.save(date);
      this.$refs.menu_membership.save(date);
      this.$refs.menu_dob.save(date);
    },
  },
  mounted() {
    this.getLogo();
  },
};
</script>
<style scoped>
#body {
  font-family: Calibri, sans-serif;
}
.grid-container {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto;
  padding: 5px;
}

.grid-container-2 {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto auto auto auto;
  padding: 5px;
}

.grid-container-3 {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto;
  padding: 5px;
}

.grid-container-4 {
  display: grid;
  grid-gap: 5px;
  grid-template-columns: auto auto;
  padding: 5px;
}

.grid-item {
  padding: 5px;
  font-size: 12px;
}
.line {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  font-weight: bolder;
}
.longline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 100%;
  max-width: 550px;
  font-weight: bolder;
}
.shortline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  max-width: 100px;
  font-weight: bolder;
}
.shorterline {
  display: inline-block;
  border-bottom: 1px solid;
  width: 50%;
  max-width: 100px;
  font-weight: bolder;
}
.bold {
  font-weight: bold;
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding: 5px;
  text-align: left;
}
</style>