<template>
 <div>
    <v-dialog  v-model="dialog" persistent  width="900" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{formTitle}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat @click="save">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
          <div class="pa-4">
             <v-text-field
              style="width: 420px;"
              v-model="editedItem.role_name"
              v-validate="'required|min:2|max:20'"
              data-vv-scope="role"
              data-vv-as="role name"
              data-vv-name="role_name"
              data-vv-validate-on="blur"
              :error-messages="errors.first('role.role_name')"
              class="va-form-control"
              label="Role Name"
              placeholder="eg. Manager"
            />
            <v-textarea 
              style="width: 620px" 
              v-model="editedItem.role_description" 
              v-validate="'min:2|max:255'"
              data-vv-scope="role"
              data-vv-as="role description"
              data-vv-name="role_description"
              data-vv-validate-on="blur"
              :error-messages="errors.first('role.role_description')"
              rows="3" 
              counter="255"
              label="Description"
              placeholder="Description here..." />
          </div>
          <div class="pr-4 pl-4">
             <v-tabs v-model="tabModel" class="elevation-2" slider-color="red darken-2" >
              <v-tab :href="`#tab-access`">Access Privileges</v-tab>
              <v-tab :href="`#tab-app`">App Privileges</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabModel">
              <v-tab-item :value="`tab-access`" class="pa-4">
                <v-list-tile v-for="item in appsAndPrivileges" :key="item.app_name">
                  <v-list-tile-action>
                    <v-checkbox  @change="populatePrivileges($event, item)" v-model="item.is_selected"></v-checkbox> 
                  </v-list-tile-action>
                  <v-list-tile-title>
                  <v-list-tile-title>
                    {{item.app_name}}                    
                  </v-list-tile-title>
                    
                  </v-list-tile-title>
                </v-list-tile>
              </v-tab-item>

              <v-tab-item :value="`tab-app`" class="pa-4">
                <v-data-table
                  class="elevation-2"
                  :headers="privilegesHeader"
                  :items="privileges"
                  hide-actions
                >
                <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
                  <template slot="items" slot-scope="props">
                    <td>{{ props.item.privilege_name }}</td>
                    <td>{{ props.item.privilege_app }}</td>
                    <td>{{ props.item.privilege_description }}</td>
                    <td class="pt-3"><v-checkbox  v-model="props.item.is_activated" /></td>
                  </template>
                  <template slot="no-data">
                    
                  </template>
                </v-data-table>
              </v-tab-item>
            </v-tabs-items>
          </div>
           

      </v-card>
    </v-dialog>
    <v-card class="pa-2 pl-4 mb-3 elevation-0">
      <h2>User Roles Management</h2>
    </v-card>
   
    <v-toolbar  class="elevation-2"  color="white">
       <v-text-field
        v-model="globalSearch"
        class="va-form-control"
        append-icon="search"
        label=""
        @keyup="onSearch()"
        solo
        placeholder="Search"
     />
     <v-spacer></v-spacer>
      <v-spacer></v-spacer>
    
      <v-btn color="primary" dark @click="openDialog" class="mb-2">New Role</v-btn>
      
    </v-toolbar>
    <v-data-table
      class="elevation-2"
      :headers="headers"
      :items="items"
      :loading="isLoading"
      :total-items="totalCount"
      :pagination.sync="pagination"
      :rows-per-page-items='[10,20, 30,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]'
    >
     <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
      <template slot="items" slot-scope="props">
        <td>{{ props.item.role_name }}</td>
        <td>{{ props.item.role_description }}</td>
        <td>
          <v-icon small class="mr-2" @click="onEditItemClicked(props.item)">
            edit
          </v-icon>
          <v-icon small @click="deleteItem(props.index, props.item)">
            delete
          </v-icon>
        </td>
      </template>
      <template slot="no-data">
        
      </template>
    </v-data-table>
  </div>
</template>

<style type="css" scoped>
h1, h2, h3 {
  color: #505a6b;}
</style>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import VaInput from '@/components/form-components/VaInput.vue'
import VaSelect from '@/components/form-components/VaSelect.vue'
import PaginationMixin from '@/mixins/PaginationMixin'

import companyService from "@/services/company-service";
import roleService from "@/services/role-service";
import _ from 'lodash';
export default {
  components: {
    VaInput,
    VaSelect
  },

  mixins: [PaginationMixin],

  created () {
    this.initialize()
  },

  data() {
    return {
      dialog: false,
      isLoading: false,
      tabModel: 'tab-access',
      headers: [
        { text: 'Name', align: 'left', sortable: true, value: 'role_name'},
        { text: 'Description', align: 'left', sortable: true, value: 'role_description'},
        { text: 'Action', value: ''},
      ],
      items: [],
      appsAndPrivileges: [],
      privileges:[],
      privilegesHeader: [
        { text: 'Name', align: 'left', sortable: true, value: 'role_name'},
        { text: 'App', align: 'left', sortable: true, value: 'privilege_app'},
        { text: 'Description', align: 'left', sortable: false},
        { text: 'Activated', align: 'left', sortable: false}
      ],
      userScope: ['Company', 'Region', 'Branch'],
      editedIndex: -1,
      editedItem: {},
      provinces:[],
      message: {
        visible: false,
        title: '',
        content: '',
        type:true
      }, 
      item: {
        role_name: '',
        role_description: '',
        access:[]
      },
      localApps: [
        {
          app_name: 'System Settings',
          privileges: [

          ]
        }
      ]
    }
  },

  computed: {
    ...mapState([
      'appState',
      'commonState',
    ]), 
    
    formTitle () {
      return this.editedIndex === -1 ? 'New Role' : 'Edit Role'
    }
  },

  methods: {
    ...mapMutations([
      'SHOW_UNEXPECTED_ERROR_DIALOG'
    ]),

    async initialize () {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);    
        let data = await companyService.fetchModules();
        let res = []
        for(let key in data) {
          if(data[key].is_active) {
            res.push(data[key])
          }
        }
        Object.keys(res).map((key) => {
          this.appsAndPrivileges.push(res[key])
        })

        this.appsAndPrivileges = this.appsAndPrivileges.concat(this.localApps)
        
     } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    close () {
      this.dialog = false
      this.editedItem = Object.assign({}, {
        role_name: '',
        role_description: '',
        access:[]
      })
      this.editedIndex = -1
      this.$validator.reset({scope: 'role'});
      this.resetPrivileges();
    },

    openDialog() {
      this.editedIndex = -1;
      this.editedItem = {
        role_name: '',
        role_description: '',
        access:[]
      };
      this.dialog = true
    },

    populatePrivileges(isSelected, obj) {
      if(isSelected) {
        if(obj.privileges) {
          Object.keys(obj.privileges).map((key) => {
            obj.privileges[key].privilege_app = obj.app_name
            this.privileges.push(obj.privileges[key])
          })
        }
      } else {
        this.privileges = this.privileges.filter((el) => el.privilege_app !== obj.app_name )
      }
    },

    onEditItemClicked(item) {
      this.resetPrivileges()
      this.privileges = []

      this.editedIndex = this.items.indexOf(item)
      this.editedItem = item
      this.appsAndPrivileges.forEach((el)=> {
        let found = 0
        let privilegeNames = []
        this.editedItem.access.forEach((elx)=>{
          if(el.app_name === elx.app_name) {
          
            el.is_selected = true;

            el.privileges.forEach((i) => {
              let f = false
              i.privilege_app = el.app_name
              elx.privileges.forEach(ix => {
                if(i.privilege_name === ix.privilege_name) {
                  ix.privilege_app = el.app_name
                  ix.is_activated = ix.is_activated
                  f = ix
                }
              })
              if(f && !privilegeNames.includes(f.privilege_name)) {
                privilegeNames.push(f.privilege_name)
                this.privileges.push(f)
              } else if (!privilegeNames.includes(i.privilege_name)) {
                privilegeNames.push(i.privilege_name)
                this.privileges.push(i)
              }
            })
           
            found = 1
          }
        })
        if(found === 0) {
          el.is_selected = false;
        }
      })

      this.dialog = true
    },

    resetPrivileges() {
      this.appsAndPrivileges = this.appsAndPrivileges.map((el) => {
        el.is_selected = false
        return el
      })
      this.privileges = []
      this.tabModel = 'tab-access'
    },

    async fetchData() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        this.isLoading = true
        let search = `role_name=lk:${this.globalSearch}:or`
        let res = await roleService.findAll(search, this.sortString, {skip: this.skip, limit: this.limit})
        this.items = res.data
        this.totalCount = res.total
      } catch(e) {
        console.log('fetching data: ', e)
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
      }  finally {
        this.isLoading = false
      }
    },

    async deleteItem(index, item) {
      this.$toast.question('All Users attached to this role will be affected. Are you sure you want to perform this action.', '', {
        timeout: 20000,
        close: false,
        overlay: true,  
        position: 'center',
        buttons: [
          ['<button><b>YES</b></button>', async (instance, toast) => {
            try {
              this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
              
              await roleService.delete(item.ID)
              this.items.splice(index,1)
              this.$toast.warning('Deleted successfully.', '', {position: 'center'})

            } catch(e) {
              console.log('fetching data: ', e)
              this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
            } 
            instance.hide({ transitionOut: 'fadeOut' }, toast, 'button')
          }, true],
          ['<button>NO</button>', function (instance, toast) { instance.hide({ transitionOut: 'fadeOut' }, toast, 'button') }],
        ]
      })
      
    },

    async save () {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false)
        let isValid = await this.$validator.validateAll("role")
        if(isValid) {
          let data = _.cloneDeep(this.editedItem)
          data.access = []
          this.appsAndPrivileges.map((el)=>{
            if(el.is_selected) {
              data.access.push(_.cloneDeep(el))
            }
          })

         
          data.access.forEach((el)=>{
            let list = []
            this.privileges.map((elx)=>{
              if(el.app_name === elx.privilege_app) 
                list.push(elx)
            })

            if(list.length > 0) {
              el.privileges = list;
            }
          })

          data.role_description = data.role_description || '-'
          if (this.editedIndex > -1) {
            delete data["date_created"]
            delete data["date_updated"]
            delete data["ID"]
            delete data["is_active"]

            await roleService.update(this.editedItem.ID, data)
            this.items.splice(this.editedIndex,1, this.editedItem)
            this.$toast.success('Successfully updated record!', '', {position: 'topCenter'})
          } else {
            let res = await roleService.create(data)
            data.ID = res.data
            this.items.push(data)
            this.$toast.success('Successfully inserted record!', '', {position: 'topCenter'})
          }
          this.close()
        } else {
          this.$toast.error('Please check to ensure that all fields are valid.', '', {position: 'topCenter'})
        }
      } catch(e) {
        if(e.response) {
          if(e.response.status === 409)
            this.$toast.error(e.response.data.error.message, '',{position: 'center'})
          else
            this.SHOW_UNEXPECTED_ERROR_DIALOG(true)
        }
      }
    },

  }
}
</script>

 
