<template>
  <div>
    <v-card class="pa-2 mb-3">
      <h2>Loan Waiver Policy Settings</h2>
    </v-card>
    <v-layout wrap row>
      <v-flex md4 sm12 v-for="data in items" :key="data.value" class="pa-2 mb-3">
        <v-card>
          <v-card-title class="body-2 font-weight-bold">
            <span>{{data.title}}</span>
            <v-spacer></v-spacer>
            <v-icon title="edit" class="mr-2" @click="setTitle(data.value, data.title)">edit</v-icon>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>

    <!-- general waiver policy dialog -->
    <v-dialog v-model="general_dialog" fullscreen persisitent scrollable>
      <v-card>
        <v-card-title class="primary white--text title" style="max-height:80px">
          {{title}}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="general_dialog = false" icon>Exit</v-btn>
        </v-card-title>
        <v-card-text>
          <GeneralSettings v-if="general_dialog"></GeneralSettings>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- insurance waiver policy dialog-->
    <v-dialog v-model="insurance_dialog" fullscreen persisitent scrollable>
      <v-card>
        <v-card-title class="primary white--text title" style="max-height:80px">
          {{title}}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="insurance_dialog = false" icon>Exit</v-btn>
        </v-card-title>
        <v-card-text>
          <InsuranceSettings v-if="insurance_dialog"></InsuranceSettings>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- dsr waiver policy dialog -->
    <v-dialog v-model="dsr_dialog" fullscreen persisitent scrollable>
      <v-card>
        <v-card-title class="primary white--text title" style="max-height:80px">
          {{title}}
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dsr_dialog = false" icon>Exit</v-btn>
        </v-card-title>
        <v-card-text>
          <DSRSettings v-if="dsr_dialog"></DSRSettings>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import GeneralSettings from "./Loan-Waiver-Policy-Settings";
import InsuranceSettings from "./Insurance-Waiver-Policy-Settings";
import DSRSettings from "./DSR-Waiver-Policy-Settings";

export default {
  name: "AllLoanWaiverPolicySettings",
  components: {
    GeneralSettings,
    InsuranceSettings,
    DSRSettings
  },
  data() {
    return {
      rowsPerPageItems: [4, 8, 12],
      pagination: { rowsPerPage: 4 },
      items: [
        { title: "General Loan Waiver Policy", value: "gp" },
        { title: "Insurance Loan Waiver Policy", value: "ip" },
        { title: "Debt Service Ratio Loan Waiver Policy", value: "dp" }
      ],
      general_dialog: false,
      insurance_dialog: false,
      dsr_dialog: false,
      title: ""
    };
  },
  methods: {
    setTitle(data, title) {
      this.title = title;
      if (data.includes("gp")) {
        this.general_dialog = true;
      } else if (data.includes("ip")) {
        this.insurance_dialog = true;
      } else {
        this.dsr_dialog = true;
      }
    }
  }
};
</script>