<template>
  <div id="account" class="pt-5">
    <v-card
      class="pa-3 mb-3 elevation-0"
      style="margin: 0 auto !important; width: 400px"
    >
      <h2 style="text-align: center; padding-bottom: 15px">Account Settings</h2>
      <v-tooltip bottom>
        <v-img
          style="margin: 0 auto !important; border: 2px #eee solid"
          aspect-ratio="1"
          slot="activator"
          :src="imgDataUrl"
          :lazy-src="``"
          id="avatar"
          @click="toggleShow"
        />
        <span>Click to change profile picture</span>
      </v-tooltip>
      <avatar-uploader
        lang-type="en"
        field="avatar"
        @crop-success="cropSuccess"
        @crop-upload-success="cropUploadSuccess"
        @crop-upload-fail="cropUploadFail"
        v-model="show"
        :params="uploadParams"
        :width="200"
        :height="200"
        :url="uploadUrl"
        :headers="headers"
        img-format="png"
      />
      <v-tooltip bottom>
        <va-input
          class="mt-5"
          :error-messages="errors.collect('user.email')"
          v-validate="'required|min:3|max:150'"
          data-vv-validate-on="blur"
          data-vv-scope="user"
          data-vv-name="email"
          :max-width="'520px'"
          slot="activator"
          :disabled="true"
          v-model="user.email"
          :counter="150"
          label="Account Email"
          placeholder=" "
        ></va-input>
        <span
          >You can only change this email in user settings or in the customer
          management area. Elevated Privileges may be required</span
        >
      </v-tooltip>

      <!-- <v-btn class="mt-4" :loading="isLoading" color="primary" @click="onUpdateClicked">Update</v-btn> -->
      <v-btn
        block
        class="mt-4"
        color="grey darken-3"
        dark
        @click="onChangePasswordClick"
      >
        <v-icon style="font-size: 20px" class="mr-3">fas fa-key</v-icon>
        Change Password</v-btn
      >
    </v-card>

    <change-password-modal
      :opened.sync="showPasswordModal"
      @submit="onPasswordSubmit($event)"
    ></change-password-modal>
  </div>
</template>

<style scoped>
h1,
h2,
h3 {
  color: #505a6b;
}

#avatar {
  margin: 5px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #eee;
}

[contenteditable="true"]:focus {
  outline: none;
  border-radius: 4px;
  background: #fff !important;
  color: #42526e !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px dashed #888;
}

[contenteditable="true"]:hover {
  background: #f4f5f7;
  border-radius: 4px;
}
</style>

<script scoped>
import "babel-polyfill";

import { mapMutations } from "vuex";
import uploader from "vue-image-crop-upload";
import VaInput from "@/components/form-components/VaInput.vue";
import changePasswordModal from "@/components/Change-Password-Modal.vue";
import storageService from "@/services/storage-service";

export default {
  components: {
    "avatar-uploader": uploader,
    "change-password-modal": changePasswordModal,
    VaInput,
  },

  data() {
    return {
      uploadUrl: "",
      showPasswordModal: false,
      isLoading: false,
      user: {},
      show: false,
      uploadParams: { ID: "" },
      headers: {
        Authorization: `Bearer ` + storageService.getItem("token") || null,
      },
      imgDataUrl: require("@/assets/img/avatar.png"),
    };
  },

  created() {
    this.user = storageService.getItem("user") || {};
    this.initialize();
    if (this.user.should_change_password) {
      alert(
        `You are required to change your password to continue using the applicaiton. `
      );
    }
  },

  beforeRouteLeave(to, from, next) {
    this.user = storageService.getItem("user") || {};
    if (!this.user.should_change_password) {
      next();
    } else {
      next(false);
    }
  },

  beforeDestroy() {
    this.$toast.destroy();
  },

  methods: {
    ...mapMutations([
      "LOGIN_USER",
      "SHOW_UNEXPECTED_ERROR_DIALOG",
      "TOGGLE_SIDEBAR_VISIBILITY",
    ]),

    initialize() {
      this.TOGGLE_SIDEBAR_VISIBILITY(false);
      this.uploadUrl = process.env.VUE_APP_ROOT_API + "persons/avatar";
      this.user = storageService.getItem("user");
      this.uploadParams["ID"] = this.user.ID;

      this.imgDataUrl = this.user.avatar || require("@/assets/img/avatar.png");
    },

    toggleShow() {
      this.show = !this.show;
    },

    cropSuccess(imgDataUrl) {
      this.croppedImage = imgDataUrl;
    },

    cropUploadSuccess(jsonData) {
      this.imgDataUrl = this.croppedImage;
      this.user = storageService.getItem("user");
      this.user.avatar = jsonData.data;

      storageService.setItem("user", JSON.stringify(this.user));
      this.LOGIN_USER(this.user);

      this.$toast.success("Account profile image was successfully changed");
    },

    cropUploadFail() {
      this.$toast.error(
        "Unable to upload file due to an unexpected error please try again"
      );
    },

    async onUpdateClicked() {
      try {
        this.SHOW_UNEXPECTED_ERROR_DIALOG(false);
        let isValid = await this.$validator.validateAll("company");

        if (isValid) {
          let updatedObj = Object.assign({}, this.company);
          delete updatedObj["logo"];
          delete updatedObj["ID"];
          delete updatedObj["date_created"];
          delete updatedObj["date_updated"];

          await this.updateCompanyDetails(updatedObj);
          this.$toast.success("Account details were updated successfully");
        } else {
          this.$toast.error(
            "Please check to ensure that the values entered are valid.",
            "",
            { position: "topCenter" }
          );
        }
      } catch (e) {
        console.log(e);
        this.SHOW_UNEXPECTED_ERROR_DIALOG(true);
      }
    },

    onPasswordSubmit(e) {
      if (e) {
        this.user.should_change_password = false;
        storageService.setItem("user", JSON.stringify(this.user));
        console.log(e);
      }
    },

    onChangePasswordClick() {
      this.showPasswordModal = true;
    },
  },
};
</script>